.l-hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    letter-spacing: 0.05rem;
  }
  @media only screen and (max-width: 767px) {
    .l-hero {
      flex-direction: column-reverse;
      min-height: auto;
    }
  }
  .l-hero .left {
    width: 40%;
    padding-left: 10%;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 767px) {
    .l-hero .left {
      width: 100%;
      padding: 0 5%;
    }
  }
  /*
  .l-hero .left h1 {
    font-family: Axiform-black;
    font-size: 46px;
    margin-bottom: 30px;
    margin-top: 8vh;
    line-height: 4.6rem;
  }
  */ 
  .l-hero .left h1 {

    /* font-family: Axiform-black; */
    /* font-size: 40px; */
    /* font-size: 35px; */
    /* margin-bottom: 30px; */
    margin-top: 9vh;
    font-size: 40px;
    font-family: Axiform-bold;
    line-height: 4rem;
    color: #363636 ; 
  }

  @media only screen and (max-width: 767px) {
    .l-hero .left h1 {
      margin-top: 3vh;
      line-height: 2.5rem;
    }
  }
  @media only screen and (max-width: 1399px) {
    .l-hero .left h1 {
      font-size: 2em;
      
    }
  }
  .l-hero .left p {
    font-family: Axiform;
    font-size: 16px;
    color: #7C828D;
    margin-bottom: 30px;
    letter-spacing: 0.02rem;
    /*
    font-size: 8px;
    color: #7C828D;
    margin-bottom: 30px;
    */
  }
  .l-hero .left .input {
    box-shadow: 0px 5px 10px 5px rgba(54, 54, 54, 0.1);
    border-left: 3px solid #f06022;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0px 10px;
  }
  @media only screen and (max-width: 767px) {
    .l-hero .left .input {
      box-sizing: border-box;
      width: 100%;
    }
  }
  .l-hero .left .input img {
    margin: 15px 0px;
  }
  .l-hero .left .input input {
    padding: 9px;
    border: none;
  }
  @media only screen and (max-width: 767px) {
    .l-hero .left .input input {
      box-sizing: box-sizing;
    }
  }

  .l-hero .left .btn {
    /* padding: 15px 30px; */
    font-size: 16px;
    font-family: Axiform-bold;
    letter-spacing: 1.2px;
    border-radius: 5px;
    color: white;
    background: #F75E02 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #F75E0240;
    border-color: transparent;
  }

  .l-hero .left .countries {
    /* margin-bottom: 20px; */
    font-size: 16px;
    color: #7C828D;
    font-family: Axiform;
    font-weight: 520;
    /* letter-spacing: -0.01rem; */
    letter-spacing: 0.05rem;
    /* font-weight: bold ; */
  }
  .l-hero .left .countries img {
    margin-left: 10px;
    width: 3rem ; 
    /* width: 2.7rem; */
  }
  @media only screen and (max-width: 767px) {
    .l-hero .left .countries img {
      width: 30px;
      margin-left: 10px;
    }
  }
  .l-hero .right {
    width: 60%;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (max-width: 767px) {
    .l-hero .right {
      width: 100%;
      min-height: auto;
      /* padding-top: 15vh; */
      padding-top: 18vh;
    }
  }
  .l-hero .right .bg {
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
  }
  .l-hero .right .img {
    width: 65%;
    margin-top: -50px;
  }
  .l-hero .play-btn {
    position: absolute;
    width: 10%;
    left: 40%;
    margin-top: -60px;
    margin-left: -10px;
    cursor: pointer;
  }

  /* ---- added 75% to play button ---- */ 
  @media only screen and (max-width: 767px) {
    .l-hero .play-btn {
      position: absolute;
      width: 10%;
      left: 40%;
      top: 76%; 
      margin-top: -60px;
      margin-left: -10px;
      cursor: pointer;
    }
  }

  .l-hero .btn {
    color: #363636;
    padding: 10px 20px;
    border: none;
    background: transparent;
    color: white;
    position: relative;
    cursor: pointer;
    letter-spacing: 0.05rem;
  }
  .l-hero .btn.primary {
    background: #f06022;
    border-radius: 5px;
    transition: 0.3s;
  }
  .l-hero .btn.primary:hover {
    transform: translateY(-3px);
    /* box-shadow: 0px 5px 10px 5px #f00000; */
    box-shadow: 0px 5px 10px #8b310b;
  }

  .foreText {
    font-family: Axiform-bold;
    font-size: 8px;
    color: #7C828D;
    margin-bottom: 30px;
    letter-spacing: 0.05rem;
    /* margin-bottom: 40px; */
    /* letter-spacing: -0.01rem; */
    
  }

  #geocoder {
    justify-content: center !important;
    align-items: center;
    
    
  }

  #geocoder .mapboxgl-ctrl-geocoder--icon {
    display : none !important; 
    visibility: hidden !important;
  }

  #geocoder .mapboxgl-ctrl {
    margin-top: -4rem !important ; 
    margin-bottom: 4rem; 
    
  }

  #geocoder .mapboxgl-ctrl-geocoder--input {
    text-align: center;
    padding: 0 !important;
    font-size: 12px !important;
    border-left: 8px solid #f06022 !important; 
  }


@media screen and (min-width: 640px){
  #geocoder .mapboxgl-ctrl-geocoder--input {
        height: 42px !important; 
        font-size: 0.9rem !important; 
        font-family: 'Axiform' !important;
        text-align: center;
        padding: 0 !important;
    }

  #geocoder .mapboxgl-ctrl-geocoder {
        width: 100% !important;
        font-size: 8px; 
        min-width: 25vw !important;
        z-index: 1 !important
    }

  #geocoder .suggestions-wrapper {
    font-size: 8px !important;
  }
}

.browsecta {
  padding: 8px 8px 8px 8px; 
  align-items: center !important; 
  border-radius: 4px 0px 0px 4px ;
  border-right: 8px solid #f06022 !important; 
  box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
  text-align: center;
  font-size: 16px;
  text-align: center;
  width: 100%;
  border: 0;
  background-color: transparent;
  height: 42px;
  color: #0477F5;
  /* background-color: #F08221;  */
  letter-spacing: 0.04rem ; 
  font-weight: bolder;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 8vw !important ; 
  cursor: default ; 
}

@media only screen and (max-width: 767px) {
  .browsebtncenteralign {
    align-items : center !important
   }
  .browsecta {
      width: 100px !important ; 
  }
  .setormobile {
    margin-top: -10px;
    margin-bottom: 5px
  }
}

.subtextsHeroAni {
  font-size: smaller;
  cursor: default;
  display: inline-block;
  position: relative; 
}

