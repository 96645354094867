.content-wrapper{
    background-color: #E5E5E5;
    font-style: Axiform !important; 
  }


body {
  font-family: Axiform !important ; 
}


.container{
     max-width:1200px ;
     margin: auto ;
     padding: 0 15px ;
  }
  .row{
     display: flex ;
     align-items: center ;
     justify-content: space-between ;
  }
  .imgclassheaderlookup {
    max-width: 100%;
  }

  
  @media(max-width:1230px){
    .container{
      max-width:100%;
    }
  }

  
  
  