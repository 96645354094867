
.bgnew {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    width: 57%;
    height: 32%; 

}

.titlehead {
  font-size: 4em;
  font-family: Axiform-Bold;
}


#geocoder .mapboxgl-ctrl-geocoder {
  z-index: 1001 !important;
  margin-left: 10px;
  top: 55px;
}

#geocoder .mapboxgl-ctrl-bottom-right {
  bottom: 12vh !important;
}

@media only screen and (max-width: 767px) {
  
    .bgnew{
      width: 50%;
      height: 35%; 
    }

    .titlehead {
      font-size: 1.8em;
      font-family: Axiform-Bold;
    }

}

.countries {
    font-size: 14px;
    color: #7C828D;
    font-family: Axiform;
    font-weight: 520;
    letter-spacing: 0.05rem;
}

.countries img {
    margin-right: 15px;
}

.selectedCountryIcon {
  width: 3rem;
  border-bottom: 3px solid #FFF; 
  /* border-radius: 5px; */
}

.deselectedCountryIcon {
  width: 2rem;
  border-bottom: 3px solid #FFF;
}

.deselectedCountryIcon:hover {
  border-bottom: 3px solid #0477F5;; 
}



.startFreeTextHero {
    font-family: 'Axiform';
    font-size: 12px;
    font-weight: bolder;
    /* color: #0477F5; */
    color: grey;
    letter-spacing: 0.05rem;
    /* padding-left: 18px; */
  }


  .faqsCountry {
    border: 0.02px solid #8584841a ; 
    /* padding-bottom: 2px !important ;  */
    border-radius :  4px ;
    font-size: small; 
  }

.faqsHero {
    /*
    font-size: smaller ; 
    letter-spacing: 0.5px;
    */
    font-family: 'Axiform';
    font-size: 0.9rem;
    /* font-weight: bolder; */
    color:#665992 ;
    /* color: #0477F5; */
    letter-spacing: 0.05rem;
}

@media only screen and (max-width: 767px) {
    .faqsHero {
      font-size: 12px ; 
    }
}

.herocenteredlanding {
  align-items: center;
  display: flex;
  flex-direction: column ;
  justify-content: center;
  height: 100vh;
}