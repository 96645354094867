#detailsForm input {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border: 1px solid #808080ad;
  border-radius: 5px;
  padding-left: 10px;
  
}
#detailsForm select {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
  border: 1px solid #808080ad;
  border-radius: 5px;
  padding-left: 10px;
  
}

#detailsForm .title {
  color:#4a4e52;
  font-size: 14px ;
  font-family: 'Roboto', sans-serif;
}

.details-page{
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 10rem;
    background-color: white;
    /* align-items: center; */
  }

  .details-inner {
    width: 500px;
    padding:10px
  }

  .form-items-detail {
    display: flex;
    align-items: center;
  }
  .form-items-detail .form-item.name {
    width: 50%;
    padding-right: 10px;
  }
  .form-items-detail .form-item.lastname {
    width: 50%;
  }

  .form-items-detail .form-item.company{
    width: 100%;
    /* padding-right: 10px; */
  }


  .details-button button {
    border: none;
    width: 100%;
    padding: 8px 10px;
    font-size: 16px;
    border-radius: 5px;
    
    color: #ffffff;
    background: #F75E02 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 5px 10px #F75E0240; */

  }