
/* *{
    -ms-overflow-style: none;
}

::-webkit-scrollbar {
    display: none;
} */


.showPlzSelected { 
    margin-left: 5px; 
    background-color: #D6D6D6;
    /* overflow-y: visible !important; */
    /* overflow-y : scroll !important ; */
    height: 58px;
    overflow-y: scroll !important;
}


.bottomPanelHeaderText {
    font-size: 0.8rem;
    color : #2069C2;
    font-weight: bolder;
    cursor: default ;
    /* padding: 0.30rem; */
    cursor: default;
    letter-spacing: 0.03rem ;
}

.ModalBottomPanelTable {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    padding: 2em;
    min-width: 40em;
    max-width: 50%;
    min-height: 10em;
    color: #f00;
    background-color: #fff;
    border-radius: 8px;
    transform: translate(-50%, -50%);
    outline: transparent;
  }
  
  .OverlayBottomPanelTable {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
  }

  .ModalTableHeaderCell {
    color: black ;  
    font-weight: bold ;  
  }

  .hrModal {
    width: 100% ; 
    text-align: left ; 
    margin-left: 0 ,
  }

  /*
.downloadText {

    letter-spacing: 0.03rem ;
    text-decoration: none; 
  }

  .downloadText:hover {
    color: red;
  }
  */
  .hover-underline-animation {
    display: inline-block;
    position: relative;
    /* color: #0087ca; */
    /* font-weight: bolder; */
    /* letter-spacing: 0.03rem ; */
    cursor: default;
  }
  
  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }