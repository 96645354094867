#listing-billboard .listingDetails_section {
    width: 60%;
    height: 100%;
    overflow-y: scroll;
}

#listing-billboard .listingDetails_section::-webkit-scrollbar {
    /* display: none; */
    width: 10px; /* Mostly for vertical scrollbars */
    /* height: 8px; Mostly for horizontal scrollbars */
    
}

#listing-billboard .listingDetails_section::-webkit-scrollbar-thumb { /* Foreground */
    background: #999;
    border-radius: 8px;
  }

#listing-billboard .listingDetails_section::-webkit-scrollbar-track { /* Background */
    /* background: #333; */
}

#listing-billboard .listingDetails_section .title h2 {
    /* margin-bottom: 6px;
    font-size: 20px;
    line-height: 24px;
    color: #333333; */
    font-weight: lighter;
    margin-bottom: 6px;
    font-size: 28px;
    line-height: 34px;
    color: #333333!important ; 
    
}

#listing-billboard .listingDetails_section .title .link {
    color: #333333;
    /* font-weight: 500; */
    font-size: 12px;
}

#listing-billboard .listingDetails_section .sort_by {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #828282;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 8px;
}

#listing-billboard .listingDetails_section .sort_by span {
    display: flex;
    align-items: center;
    /* color: #DF6937; */
    color: #F16022;
    margin-left: 5px;
}

#listing-billboard .listingDetails_section .zip_code {
    margin-bottom: 16px;
}

#listing-billboard .listingDetails_section .zip_code .title {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    margin: 0 0 10px;
}


#listing-billboard .listingDetails_section .zip_code .zip_code_number {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#listing-billboard .listingDetails_section .zip_code .zip_code_number .number {
    border-radius: 8px;
    padding: 8px 16px;
    color: #4F4F4F;
    border: 1px solid rgba(224, 224, 224, 1);
    background: transparent;
    margin-right: 12px;
}

#listing-billboard .listingDetails_section .zip_code .zip_code_number .number:last-child {
    margin-right: 0;
}

#listing-billboard .listingDetails_section .zip_code .zip_code_number .active {
    border-color: #333333;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    color: #333333;
    background: #FFFFFF;
}

#listing-billboard .listingDetails_section .view_details_box .view_box {
    background: #FFFFFF;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title h2 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title .available_box {
    display: flex;
    align-items: center;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title .available_box .css-j204z7-MuiFormControlLabel-root {
    margin: 0 16px 0 0;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title .available_box .css-j204z7-MuiFormControlLabel-root .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0 6px 0 0;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title .available_box .MuiCheckbox-root:hover {
    background-color: transparent;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title .available_box .available_check.Mui-checked svg {
    color: #27AE60;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title .available_box .favorite {
    padding: 0;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .title .available_box .favorite.Mui-checked svg {
    color: #eb5757;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .address {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .address img {
    margin-right: 6px;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .address {
    font-weight: 500;
    font-size: 12px;
    color: #4F4F4F;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .daily_update {
    display: flex;
    align-items: center;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .daily_update .daily_impre img,
.listingDetails_section .view_details_box .view_box .daily_update .daily_approx img {
    margin-right: 6px;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .daily_update .daily_impre,
.listingDetails_section .view_details_box .view_box .daily_update .daily_approx {
    font-weight: 500;
    font-size: 12px;
    color: #4F4F4F;
    display: flex;
    align-items: center;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .daily_update .daily_impre {
    margin-right: 26px;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .hr {
    margin: 12px 0;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .request_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .request_info .info {
    font-weight: 600;
    font-size: 12.5px;
    color: #DF6937;
    /* color: #F16022; */
}

#listing-billboard .listingDetails_section .view_details_box .view_box .request_info .view_detail {
    font-weight: 600;
    font-size: 12px;
    color: #2F80ED;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .request_info .info,
.listingDetails_section .view_details_box .view_box .request_info .view_detail {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .request_info .info svg {
    margin-right: 6px;
}

#listing-billboard .listingDetails_section .view_details_box .view_box .request_info .view_detail svg {
    margin-left: 2px;
}

@media (max-width: 992px) {
    #listing-billboard  .listingDetails_section {
        width: 100%;
        margin-bottom: 20px;
    }
}

@media (max-width: 575px) {
    #listing-billboard  .listingDetails_section .zip_code .zip_code_number .number {
        margin-bottom: 10px;
    }
}