.Apartment-wrapper .Apartment-content{
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px;
    border: 1px solid #E0E0E0;
    margin-bottom: 16px;
}
.Apartment-title{
    position: relative;
    font-size: 16px;
    line-height: 18px;
}
.Apartment-wrapper .Apartment-title::after {
    content: '';
    background-image: none !important ;
    /* background-image: url('https://res.cloudinary.com/zipscores-collection/image/upload/v1658683172/free-tools/building_1.svg') ; */
    height: 60px;
    width: 60px;
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
}
.Apartment-description{
    display: flex;
    align-items: center;
    /* padding-top: 70px; */
    padding-top: 10px !important;
    flex-wrap: wrap;
}
.Apartment-description .map-title {
    padding-top: 10px;
}
.Apartment-map {
    width: 132px !important;
    height: 132px !important;
    overflow: hidden;
}
.Apartment-detail {
    padding-right: 45px;
    text-align: center;
    margin-bottom: 40px;
}
.Apartment-description .Apartment-detail:last-child{
    padding-right: 0;
}

@media(max-width:1200px){
    .Apartment-description{
        flex-wrap: wrap;
    }
}
@media (max-width:767px) {
    .Apartment-wrapper .Apartment-content {
        padding: 20px;
    }
    .Apartment-description{
        justify-content: center;
    }
    .Apartment-detail {
        padding: 0 15px;
    }
}