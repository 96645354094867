.dashboard-content-wrapper-info .dashboard-title-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.dashboard-content-wrapper .dashboard-title h4 {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    margin: 0;
    color: #363636 ;
    margin-left: 10px;
}
.dashboard-info-block-info p {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 12.5px;
    line-height: 26px;
    color: #4F4F4F;
    margin: 0;
}
.dashboard-content-wrapper-info {
    background: #FFFFFF;
    border: 1px solid #db7f58;
    border-radius: 8px;
    padding: 18px;
}
.dashboard-content-wrapper-main-info {
    padding: 24px 0 
    /* padding: 10px 0 !important; */
}

.dashboard-content-wrapper-info-noborder {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    padding: 18px;
}


.dashboard-content-wrapper-info-black {
    /* border: 1px solid #4f4f4f; */
    border: 2px solid #0F62BB;
    border-radius: 8px;
    padding: 24px;
}


.billboard-header-title {
    color: #0F62BB ;
    line-height: 1.4 ; 
    font-size: 2.3rem
}

.billboard-header-image {
    width: 250px
}

@media(max-width:991px){

    .billboard-header-title {
        font-size: 24px
    }

    .billboard-header-image  {
        visibility: 40px
    }

}

@media(max-width:767px){



}

@media(max-width:660px){


}

@media(max-width:425px){

}