.switch {
    position: relative;
    display: inline-block;
    /* width: 90px; */
    width: 32px;
    /* height: 34px; */
    /* height: 90px; */
    height: 80px;
    
  }
  
  .switch input {display:none;}
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: #ca2222; */
    background-color: #FFFFFF;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid #b9b9b9; 

  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 2.5px;
    bottom: 4px;
    background-color: #bbbbbb;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    /* background-color: #2ab934; */
    background-color: #FFFFFF;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateY(48px);
    -ms-transform: translateY(48px);
    transform: translateY(-48px);
  }
  
  .on
  {
    display: none;
  }
  
  .on, .off
  {
    /* color: white; */
    color: black;
    position: absolute;
    transform: translate(-50%,-50%);
    /* transform: translate(50%,50%); */
    top: 50%;
    left: 50%;
    /* bottom: 5px; */
    font-size: 14px;
    font-family: Axiform-black;
  }
  
  input:checked+ .slider .on
  {display: block;}
  
  input:checked + .slider .off
  {display: none;}
  
  /*--------- END --------*/
  
  /* Rounded sliders */
  .slider.round {
    /* border-radius: 34px; */
    border-radius: 4px;
  }
  
  .slider.round:before {
    /* border-radius: 50%; */
    border-radius: 10%;
}