.container-wrap {
    /* background-color: green; */
}

.empty-back {
    
    
    /*
    left: 36px;
    top: 106px;
    min-width: 600px;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 12px;
    background: rgba(255, 255, 255, 0.9);
    padding: 2px 0px;
    font-size: 90%;
    position: fixed;
    overflow: auto;
    max-height: 50%;
    
    left: 50px;
    top: 322.406px;
    min-width: 547.602px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px;
    background: rgba(255, 255, 255, 0.9);
    padding: 2px 0px;
    font-size: 90%;
    position: fixed;
    overflow: auto;
    max-height: 50%;
    */
}

#auto-complete-home .wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: center;
    width: 100vw;
    /* margin-top: 10rem; */
    justify-content: space-between;
    padding: 50px;
    min-height: 100vh;
}

#auto-complete-home .inline {
    display: flex;
    width: 100%;
}

#auto-complete-home .cta {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 3;
}

#auto-complete-home .cta > .search_filter {
    position: relative;
    width: 100%;
}

#auto-complete-home .flag-select {
    margin-left: 20px;
}

#auto-complete-home .one_card > div img {
    width: 15px;
    position: relative;
    top: -1px;
}

#auto-complete-home .flag-select, .flag-select button {
    background-color: white;
    height: 56px;
    border-radius: 16px;
    box-shadow: none;
    border: none;
}

#auto-complete-home .text {
    font-size: 2rem;
    margin-left: -31rem;
}

#auto-complete-home .btn {
    background-color: #0ea5e9;
}

#auto-complete-home .btn:hover {
    background-color: #0369a1;
}

#auto-complete-home input {
    border-bottom: 2px solid #363636;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    padding: 16px 24px;
    height: 56px;
    border-radius: 5px;
    font-size: 1.2rem;
    padding-left: 30px;
    box-shadow: none;
    border: none;
    background: #FFFFFF;
    border-radius: 16px;
    font-size: 16px;
    width: 600px;
}

#auto-complete-home .img {
    max-width: 20px;
    max-height: 20px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 8px;
}

#auto-complete-home .no-suggestions {
    color: #999;
    padding: 0.7rem;
    font-size: 2rem;
}

#auto-complete-home .suggestion-parent {
    padding: 20px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 65px;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    width: 100%;
    z-index: 111;
}

#auto-complete-home .flag-select ul {
    position: relative;
    right: 0;
    left: 0;
    top: 0px;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

#auto-complete-home .suggestions {
    border: none;
    border-radius: 5px;
    border-top-width: 0;
    list-style: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* max-height: 100px; */
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    /* transform: translate(5px, 20px) scale(1.1); */
    /* transition: transform 3s cubic-bezier(0.4, 0.0, 0.2, 1); */
    /* -ms-overflow-style: none;
    scrollbar-width: none; */
}

#auto-complete-home .inline input {
    border: 1px solid #E0E0E0;
    width: 100%;
}

#auto-complete-home .flag-select ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-width: 200px;
}

#auto-complete-home .flag-select ul::-webkit-scrollbar {
    display: none;
}

#auto-complete-home .suggestions::-webkit-scrollbar-thumb {
    background-color: #363636;
}

#auto-complete-home .suggestions li {
    /* padding: 0.4em 0.7rem; */
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
}


#auto-complete-home .suggestions_title {
    font-size: 20px;
    text-align: left;
}

#auto-complete-home .suggestion-active,
.suggestions li:hover {
    color: rgb(10, 10, 10);
    cursor: pointer;
    font-weight: 300;
}

#auto-complete-home .auto-com {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

#auto-complete-home .auto-com > div > div{
    width: 100%;
}

#auto-complete-home  .auto-com > div > div > div {
    max-height: 200px !important;
    background-color: #fff;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px !important;
    z-index: 12;
    margin-top: 10px;
}


#auto-complete-home .gptresponsetext-parent {
    padding: 0px;
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 65px;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    width: 100%;
    z-index: 111;
}


#auto-complete-home  .gptresponsetext {
    /* border: none; */
    /* border-radius: 5px; */
    /* border-top-width: 0; */
    /* list-style: none; */
    background-color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-y: auto;
    padding: 0px; 
    width: 100%;
    box-shadow: none !important ; 
    padding: 18px ; 
    
    
}


#auto-complete-home .auto-com input {
    width: 100%;
    padding-left: 35px !important;
}

#auto-complete-home .auto-com .prefix_search {
    top: 20px;
    left: 10px !important;
    width: 18px;
    position: absolute;

}

#auto-complete-home .auto-com .sufix_search {
    top: 18px;
    right: 12px !important ; 
    position: absolute;
    /* text-align: right; */

}

#auto-complete-home .auto-com .motherfuckingtoggler {
    margin-top: -40px;
    position: absolute;
    text-align: left;

}

#auto-complete-home .auto-com .motherfuckingtoggler-gpt {
    margin-top: -96px;
    position: absolute;
    text-align: left;
}

#auto-complete-home .suggestions::-webkit-scrollbar {
    display: none;
}

#auto-complete-home .card_wrap {
    z-index: 3;
    display: flex;
    flex-direction: row;
    width: 100%;
}

#auto-complete-home .one_card {
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #F0F0F7;
    border-radius: 16px;
    display: flex;
    flex-direction: row;
    margin-right: 12px;
}

#auto-complete-home .addition_card {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    background: #E5682B !important;
    border: 1px solid #F0F0F7 !important;
    box-sizing: border-box !important;
    border-radius: 16px !important;
    padding: 16px !important;
    width: 20% !important;
}

#auto-complete-home .addition_card img {
    width: 16px !important;
}

#auto-complete-home .addition_card p {
    margin: 0;
    margin-right: 10px;
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;    
}

#auto-complete-home .one_card > img {
    width: 34px;
    height: 34px;
    margin-bottom: auto;
}

#auto-complete-home .one_card h6 {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
}

#auto-complete-home .one_card small {
    font-size: 12px;
    line-height: 18px;
    color: #828282;
}

#auto-complete-home .one_card p {
    color: #E5682B;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 15px 0 0;
}

#auto-complete-home .one_card > div {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

#auto-complete-home .user {
    z-index: 2;
    color: #333333;
}

#auto-complete-home .user .addition_card {
    background-color: #fff !important;
    padding: 12px 24px !important;
    background: #FFFFFF !important;
    border: 1px solid #F0F0F7 !important;
    box-sizing: border-box !important;
    border-radius: 16px !important;
    justify-content: space-between !important;
    width: 100% !important;
}

#auto-complete-home .user .addition_card p {
    color: #000;
    margin: 0 0 0 10px;

}

#auto-complete-home .GptText {

    color: #544c4c ; 
    background-color : #FF8BFF ;
    font-size: 11px ;
    font-weight: bold ;
    padding: 8px ;
    border-radius: 4px ;
    margin-left: 3px ;
    letter-spacing: 1px ; 
}


@media (max-width: 768px) {
    #auto-complete-home .wrapper {
        padding: 15px;
    }

    #auto-complete-home .card_wrap, .cta {
        flex-direction: column;
    }

    #auto-complete-home .inline input, .suggestions {
        width: 100%;
    }

    #auto-complete-home .flag-select {
        margin-left: 0;
    }

    #auto-complete-home .one_card {
        margin-right: 0px;
    }

    #auto-complete-home .one_card, .addition_card, .flag-select {
        margin-bottom: 20px;
    }

    #auto-complete-home .addition_card {
        width: 100% !important;
        cursor: default ;

    }

    #auto-complete-home .inline {
        position: relative;
        /* left: 25px; */

    }

    #auto-complete-home .cta > .search_filter {
        left: 0;
        margin-bottom: 20px;
    }
}


#auto-complete-home .rs-btn-toggle-checked {
    background-color: #1B72F5 !important;
}