

.chatBox .chatData{
    padding-right: 10px;
}

.chatBox .chatData::-webkit-scrollbar{
    width: 5px;
    background-color: transparent;
}

.chatBox .chatData::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: transparent;
}

.chatBox .chatData::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #e1e1e1;
}

/* Chat Box Styling to make it full screen */
.chatBox {
    /* height: calc(73vh); */
    height: calc(71vh);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

/* Message Styling */
.chatBox .msg {
    border: 1px solid #E0E0E0;
    /* padding: 16px 16px 20px; */
    padding: 14px 14px 16px 16px;
    /* padding: 14px; */
    border-radius: 16px;
    display: flex;
    align-items: start;
    margin-bottom: 16px;
    background: #F0F6FB;
}

.chatBox .msgadd {
    background: #F0F6FB;
    border-color: #F0F6FB;
    width: 80%;
    margin-left: auto;
}

.chatBox .msg img {
    width: 24px;
    margin-right: 12px;
}


.chatBox .msg p {
    color: #333333;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.chatBox .msg p > ol {
    margin-bottom: 0rem
 }
 
 /*  STYLE OF THE TABLES HERE  */

/* MarkdownStyles.css */

.chatBox .msg p > table {
    font-size: .875em;
    /* line-height: 1.7142857; */
    margin-top: 2em;
    margin-bottom: 2em;
    table-layout: auto;
    text-align: left;
    /* width: 100%; */
    border-collapse: separate;
    border-spacing: 0px 0px;
}

.chatBox .msg p > table th {
    font-weight: bold;
    text-align: left; /* changed from -internal-center for browser compatibility */
    vertical-align: inherit;
    display: table-cell;
    background-color: #ff7438;
}

.chatBox .msg p > table tbody {
    background-color: #fafafa;
}

.chatBox .msg p > table td, 
.chatBox .msg p > table th {
    padding: 10px 15px;
    border: 0.5px solid #e0e0e0;
}

/* Your provided style for lists, included for reference */
.chatBox .msg p > ol {
    margin-bottom: 0rem;
}


/* For headers */
.chatBox .msg p > h1,
.chatBox .msg p > h2,
.chatBox .msg p > h3,
.chatBox .msg p > h4,
.chatBox .msg p > h5,
.chatBox .msg p > h6 {
    font-weight: bold;
    text-decoration: underline;
    margin: 1.25em 0;
}

.chatBox .msg p > h1 { font-size: 14px; }
.chatBox .msg p > h2 { font-size: 13.5px; }
.chatBox .msg p > h3 { font-size: 13px; }
.chatBox .msg p > h4 { font-size: 12.5px; }
.chatBox .msg p > h5,
.chatBox .msg p > h6 { font-size: 12px; }

/* For paragraphs */
.chatBox .msg p {
    margin: 1em 0;
    font-size: 12px;
    line-height: 1.5;
    /* font-family: Arial, Helvetica, sans-serif; */
}

/* For code blocks */
.chatBox .msg p > code {
    display: block;
    background-color: #f4f4f4;
    padding: 8px;
    margin: 1em 0;
    border-radius: 4px;
    font-family: 'Courier New', monospace;
    font-size: 12px;
}

/* For inline code */
.chatBox .msg p > code.inline {
    display: inline;
    background-color: #e8e8e8;
    padding: 2px 4px;
    border-radius: 2px;
    font-family: 'Courier New', monospace;
    font-size: 12px;
}

/* For lists */
.chatBox .msg p > ol, 
.chatBox .msg p > ul {
    margin: 1em 0;
    padding-left: 1.25em;
    font-size: 12px;
}

.chatBox .msg p > ul > li {
    list-style-type: disc;
}

.chatBox .msg p > ol > li {
    list-style-type: decimal;
}


/* ==== WORKS ====== */

.msgBox .containt {
    background: #FAFAFA;
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    padding: 13px 16px;
    display: flex;
    width: 100%;
}

.msgBox .containt img {
    width: 24px;
}

.containt input,
.containt select option,
.containt textarea {
    width: 100%;
    height: 32px;  /* Adjust this to your preference */
    background: transparent;
    border: none;
    outline: none;
    padding-left: 12px;
    font-size: 12px;
    font-weight: 500;
    color: #4F4F4F ; 
}

.containt textarea::-webkit-scrollbar {
    width: 0; 
}

.containt textarea {
    scrollbar-width: none; 
}


.sendBtn {
    /* background: #0071BD; */
    background: #f75e00;
    /* background: #1B72F5; */
    padding: 13px;
    border-radius: 16px;
    margin-left: 19px;
    border: none !important;
}


.containt {
    position: relative; /* To help position the country dropdown */
}

.countryDropdown {
    position: absolute; 
    top: 5px; /* Adjust as needed */
    left: 5px; /* Adjust as needed */
    border: none;
    background: transparent;
    z-index: 2; /* To ensure dropdown is above textarea */
}

/* Style for the dropdown options */
.countryDropdown option {
    padding-left: 30px; /* Space for flag icon */
    background-repeat: no-repeat;
    background-position: 5px center; /* Position of the flag icon */
    background-size: 20px 20px; /* Size of the flag icon */
}

.countryDropdown option[data-flag-icon] {
    background-image: url('https://img.icons8.com/?size=512&id=1349&format=png');
}

.countryDropdown option[data-flag-icon="path-to-usa-flag.png"] {
    background-image: url('https://img.icons8.com/?size=512&id=1349&format=png');
}

.msgBox {
    /* This assumes that your d-flex is equivalent to display: flex; */
    justify-content: space-between; /* This will create space between the flag selector, the textarea, and the send button */
    align-items: center; /* Vertically centers the children */
}

.ReactFlagsSelect-module_selectFlag__2q5gC {
    font-size: 45px !important;
} 

.flag-select ul {
    top: auto; /* reset the top value */
    bottom: 100%; /* set it to appear just above the flag-select component */
    margin-bottom: 2px; /* Optional: a little space between the dropdown and the select component */
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
}

.flag-select ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-width: 200px;
}

.flag-select ul::-webkit-scrollbar {
    display: none;
}

.custom-background-copilot {
    min-height: 100vh; 
    background-image: url('./bg-copilot.jpg');
    /* background-image: url('./bg-copilot1.jpg'); */
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center center; 
    /* background-color: #9EADC7; */
}



/*  prompt suggestions ++++  */ 

.prompt-suggestion-grow {
    top: 50%; 
    flex-grow: 1;
    position: relative; 
    z-index: 1000; 
}

.prompt-suggestion-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center it both horizontally and vertically */
    width: 55%;
    max-width: 900px;
    padding: 0.20rem;
    z-index: 1001;
}

.prompt-suggestion-grid {
    display: grid;
    width: 100%;
    grid-auto-flow: row;
    grid-template-columns: 1fr; /* This ensures each item occupies its own row */
    gap: 0.50rem;
    justify-content: space-around;
}


.prompt-suggestion-item {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}


/*
.prompt-suggestion-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-color: #f3f3f3;
    border-width: 0.5px;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    background-color: rgba(255, 255, 255, 1);
    border-color: rgba(0, 0, 0, .1);
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: left;
    width: 100%;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, .02);
    color: rgba(64, 65, 79, 1);
    white-space: nowrap;
}
*/ 

.prompt-suggestion-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-width: 0.5px;
    border-radius: 0.25rem;
    padding: 0.5rem 0.75rem;
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: left;
    width: 100%;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, .02);
    white-space: nowrap;
    /*
    
    // prio 2     
    background-color: #dbeff7;
    border-color: #b8d5e6;
    color: #00384d;
    
    // prio 1     
    */
    
    background-color: #eaf3fa;
    border-color: #d1e7f8;
    color: #005b7f;

  
}

/* == perfect ==>> 

    background-color: #263238;
    border-color: #37474f;
    color: #eceff1;
*/ 

.prompt-suggestion-content {
    font-family: 'Arial', sans-serif;
    display: flex;
    width: 100%;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.prompt-suggestion-text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.prompt-suggestion-title {
    /* font-weight: bold; */
    font-weight: 550 ;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
}

.prompt-suggestion-subtitle {
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
}

.prompt-suggestion-icon {
    position: absolute;
    bottom: 0;
    right: -1px;
    top: 0;
    display: flex;
    align-items: center;
    border-radius:  0.25rem;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 0));
    padding-left: 1.5rem;
    padding-right: 0.75rem;
    color: rgba(64, 65, 79, 1);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.prompt-suggestion-btn:hover .prompt-suggestion-icon {
    opacity: 1;
}

.prompt-library-link-color {
    font-size: 12px ; 
    font-weight: 600;
}

.prompt-library-link-color > a {
    color: #FFFFFF!important;
    text-decoration: underline;
}
