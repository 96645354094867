.pageHeader {
    font-size: 36px;
    font-family: Axiform;
    /* color: #424B4F; */
    /* letter-spacing: 0.05rem; */
}

.info {
    margin-top: -5px;
    font-size: 1rem;
    color: #424B4F;
    letter-spacing: 0.05rem;
}

.Modal {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    display: block !important;
    padding: 2em !important;
    min-width: 75vw !important;
    /* max-width: 40% !important; */
    min-height: 80vh !important;
    /* color: #f00 !important; */
    background-color: #fff !important;
    border-radius: 5px !important;
    transform: translate(-50%, -50%) !important;
    outline: transparent !important;
    font-family: Axiform;
    /* color:  #424B4F; */
    color:  #484848;
    letter-spacing: 0.03rem;
  }

  .Overlay {
    z-index: 986695 !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    background-color:  #424B4F;
  }
