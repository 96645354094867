.userText {
    text-align: left;
    font: 25px/19px Axiform-Bold;
    letter-spacing: 1.5px;
    color: #363636;
    opacity: 1;
  }

.userCount {
    font-family: Axiform;
    margin-left: 10px;
    padding: 0.5rem ;
    border: 2.5px solid #A2A2A2;
    border-radius: 50%;
    text-align: center;

  }

.userCountText {
    font: Bold 20px/24px Axiform;
    letter-spacing: 1.5px;
    color: #F75E02;
    opacity: 1;
  }


.textUsername {
    /* font : Normal 16px/25px Axiform ;  */
    font : Normal 14px/20px Axiform ;
    color : #363636 ;
    padding-top : 10px ;
    letter-spacing: 1px;
    background: #e1dfdf 0% 0% no-repeat padding-box;
    padding: 10px;
    border-radius: 5px;
    margin-left: -1rem;
}

.textEmail {
    font : Normal 14px/20px Axiform ;
    color : #7e7d7d ;
    letter-spacing: 1px;
}

.pendingInvite {
  font : Normal 12px/20px Axiform ;
  color : #721c24;
  /* color : #7e7d7d ; */
  letter-spacing: 1px;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  /* padding: 2em; */
  min-width: 30em;
  max-width: 50%;
  min-height: 5em;
  max-height: 94vh;
  font-family: Axiform;
  color: #424B4F;
  background-color: #fff;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  outline: transparent;
  overflow-y: scroll;
}


.Modal::-webkit-scrollbar {
  display: none;
}

.Overlay {
  z-index: 129;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}

.iconStyle{
  background-color: transparent;
  font-size: 2em ;
}

.iconStyle:hover {
  font-size: 2em ;
  color: #2983eb;
}

.addUserText {
  font: normal normal normal 14px/18px Axiform;
  letter-spacing: 0px;
  color: #a7a6a6;
  opacity: 1;
}

.boxScribble{
  margin-top: 3vh;
  bottom: 20vh;
  width: 50%;
  height: 5vh;
  background: #F75E00 0% 0% no-repeat padding-box;
  color: #fff;
  box-shadow: 0px 5px 10px #f75e0240;
  border-radius: 5px;
  opacity: 1;
  justify-content: center;
  text-align: center;
  cursor: default !important;
  line-height: 42px;
  letter-spacing: 1.2px;
}

.boxScribble:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 10px #8b310b;
}

.scribbleText {
  font: 18px/24px Axiform;
  letter-spacing: 0.5px;
  font-weight: 150;
  /* color: #424B4F; */
  color: #FFFFFF;
  opacity: 1;
}

.showMore {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    font-size: smaller;
    font-family: Axiform;
    letter-spacing: 0.5px;
}

.upgrade {
  font: 16px/24px Axiform-Bold;
  letter-spacing: 1.4px;
  color: #F75E02; 
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer ; 
  border-bottom: 2px solid #E1DFDF;
}

.upgradePremium {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 12px 24px; */
  gap: 24px;

  position: relative;
  width: 155px;
  height: 48px;

  background: linear-gradient(90deg, #FFC036 0%, #FFAC10 100%);
  border-radius: 16px;
}

.premiumText {
  
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #FFFFFF;
}