/* AppSumo.css */
.appsumo-coupon-container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0; 
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .appsumo-coupon-content {
    max-width: 400px;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff; /* Set your desired content background color */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
    text-align: center;
  }
  
  .appsumo-coupon-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .appsumo-coupon-subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .appsumo-coupon-form-group {
    margin-bottom: 20px;
  }
  
  .appsumo-coupon-form-control {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .appsumo-coupon-error {
    color: red !important;
    margin-top: 5px;
}

  .appsumo-coupon-submit-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .appsumo-coupon-submit-button:hover {
    background-color: #0056b3;
  }
  
  .appsumo-coupon-error {
    color: red;
    margin-top: 5px;
  }

  .appsumo-coupon-success {
    margin-top: 20px;
}

.success-message {
    font-weight: bold;
    font-size: 16px; 
    margin-bottom: 10px;
}

.app-sumo-paragraph {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 10px;
}

.app-sumo-list {
  list-style-type: none; 
  margin-left: 0;
  padding-left: 0;
}

.app-sumo-list li {
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.app-sumo-list li::before {
  content: "\2022"; 
  color: #0073e6; 
  font-size: 16px;
  margin-right: 8px; 
}


