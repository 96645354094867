.l-about {
  /* padding: 60px 10%; */
  padding: 50px 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.05rem;
}
.l-about .btn {
  color: #363636;
  padding: 10px 20px;
  border: none;
  background: transparent;
  color: white;
  position: relative;
  cursor: pointer;
}
.l-about .btn.primary {
  background: #f06022;
  border-radius: 5px;
  transition: 0.3s;
}
.l-about .btn.primary:hover {
  transform: translateY(-3px);
  /* box-shadow: 0px 5px 10px 5px #f00000; */
  box-shadow: 0px 5px 10px #8b310b;
}
@media only screen and (max-width: 767px) {
  .l-about {
    flex-direction: column-reverse;
    padding: 30px 5%;
  }
}
.l-about .left, .l-about .right {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .l-about .left, .l-about .right {
    width: 100%;
  }
}
.l-about .left {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.l-about .left img {
  width: 60%;
  margin-right: 20%;
}
@media only screen and (max-width: 767px) {
  .l-about .right {
    text-align: center;
    margin-bottom: 30px;
  }
}
.l-about .right h2 {
  margin-bottom: 30px;
  font-size: 50px;
  font-family: Axiform-bold;
  width: 80%;
  line-height: 1.2;
  text-align: center;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  .l-about .right h2 {
    width: 100%;
  }
}
@media only screen and (max-width: 1399px) {
  .l-about .right h2 {
    font-size: 2em;
  }
}
.l-about .right p {
  font-size: 14px;
  font-family: Axiform;
  /* opacity: 0.5; */
  margin-bottom: 30px;
  margin-top: 3em;
}

.l-about .right p > a {
  color: #363636 ;
}

.l-about .right p > a:hover {
  color: #0477F5 ;
}



.l-about .right .btn {
  font-size: 16px;
  font-family: Axiform-bold;
  padding: 15px 30px;
  margin-top: 3em;
  letter-spacing: 0.05rem;
}

.l-about .carousel-indicators {
  left: auto !important;
}

.l-about .carousel-indicators li {
  width: 30px;
  height: 3px;
  background-color: #000;
  opacity: .5;
  cursor: pointer;
  margin: 0 5px;
}

.l-about .carousel-indicators li.active {
  background-color: #f06022;
}

.l-about .sr-only {
  display: none;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f06022'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f06022'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
