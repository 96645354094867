/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable-next-line */
/* stylelint-disable-next-line */
/* stylelint-disable-next-line */
/* stylelint-disable */
/* stylelint-enable */
/* stylelint-disable */
/* * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body {
  font-size: 14px;
  line-height: 1.42857143;
  color: #575757;
  background-color: #fff;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
hr {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button {
  border-radius: 0;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: not-allowed;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
textarea {
  overflow: auto;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
}
[tabindex='-1'] {
  outline: none;
}
input[type='button'],
input[type='submit'],
input[type='reset'],
button {
  border-width: 0;
}
input::-ms-clear {
  display: none;
}
input[type='file']::-webkit-file-upload-button,
input[type='file']::-ms-browse {
  border-width: 0;
  background: transparent;
  color: currentColor;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.clearfix::before,
.clearfix::after {
  content: ' ';
  display: table;
}
.clearfix::after {
  clear: both;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.hidden {
  display: none !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.in {
  display: block;
}
tr.collapse.in {
  display: table-row;
}
tbody.collapse.in {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease, visibility 0.35s ease;
  transition: height 0.35s ease, visibility 0.35s ease;
}
.sr-only {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
} */
@-webkit-keyframes shake {
    0%,
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    16.7%,
    50%,
    83.3% {
      -webkit-transform: translate3d(-10px, 0, 0);
              transform: translate3d(-10px, 0, 0);
    }
    33.3%,
    66.7% {
      -webkit-transform: translate3d(10px, 0, 0);
              transform: translate3d(10px, 0, 0);
    }
  }
  @keyframes shake {
    0%,
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
    16.7%,
    50%,
    83.3% {
      -webkit-transform: translate3d(-10px, 0, 0);
              transform: translate3d(-10px, 0, 0);
    }
    33.3%,
    66.7% {
      -webkit-transform: translate3d(10px, 0, 0);
              transform: translate3d(10px, 0, 0);
    }
  }
  @-webkit-keyframes slideUpIn {
    0% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @keyframes slideUpIn {
    0% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @-webkit-keyframes slideDownIn {
    0% {
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @keyframes slideDownIn {
    0% {
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @-webkit-keyframes slideLeftIn {
    0% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
    }
    100% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
  @keyframes slideLeftIn {
    0% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
    }
    100% {
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
  @-webkit-keyframes slideRightIn {
    0% {
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
    }
    100% {
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
  @keyframes slideRightIn {
    0% {
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
    }
    100% {
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
    }
  }
  @-webkit-keyframes errorMessageSlideUpIn {
    0% {
      -webkit-transform: translate3d(0, 2px, 0);
              transform: translate3d(0, 2px, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes errorMessageSlideUpIn {
    0% {
      -webkit-transform: translate3d(0, 2px, 0);
              transform: translate3d(0, 2px, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes errorMessageSlideDownIn {
    0% {
      -webkit-transform: translate3d(0, -2px, 0);
              transform: translate3d(0, -2px, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes errorMessageSlideDownIn {
    0% {
      -webkit-transform: translate3d(0, -2px, 0);
              transform: translate3d(0, -2px, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes errorMessageSlideLeftIn {
    0% {
      -webkit-transform: translate3d(-2px, 0, 0);
              transform: translate3d(-2px, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes errorMessageSlideLeftIn {
    0% {
      -webkit-transform: translate3d(-2px, 0, 0);
              transform: translate3d(-2px, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes errorMessageSlideRightIn {
    0% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @keyframes errorMessageSlideRightIn {
    0% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
      visibility: visible;
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
    }
  }
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes buttonSpin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes buttonSpin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes messageMoveIn {
    0% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @keyframes messageMoveIn {
    0% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @-webkit-keyframes messageMoveOut {
    0% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      max-height: 150px;
    }
    100% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      max-height: 0;
      overflow: hidden;
    }
  }
  @keyframes messageMoveOut {
    0% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      max-height: 150px;
    }
    100% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      max-height: 0;
      overflow: hidden;
    }
  }
  @-webkit-keyframes alertMoverIn {
    0% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @keyframes alertMoverIn {
    0% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @-webkit-keyframes alertMoveOut {
    0% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      max-height: 60px;
    }
    100% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      max-height: 0;
      overflow: hidden;
    }
  }
  @keyframes alertMoveOut {
    0% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      max-height: 60px;
    }
    100% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      max-height: 0;
      overflow: hidden;
    }
  }
  @-webkit-keyframes notificationMoverIn {
    0% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @keyframes notificationMoverIn {
    0% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
    }
    100% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }
  }
  @-webkit-keyframes notificationMoveOut {
    0% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      max-height: 100px;
    }
    100% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      max-height: 0;
      overflow: hidden;
    }
  }
  @keyframes notificationMoveOut {
    0% {
      opacity: 1;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      max-height: 100px;
    }
    100% {
      opacity: 0;
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      max-height: 0;
      overflow: hidden;
    }
  }
  @-webkit-keyframes notificationMoveInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes notificationMoveInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes notificationMoveInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes notificationMoveInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes bounceIn {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes bounceIn {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes bounceOut {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
  }
  @keyframes bounceOut {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
  }
  @-webkit-keyframes slideInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes slideInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes slideOutLeft {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
  }
  @keyframes slideOutLeft {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
    }
  }
  @-webkit-keyframes slideInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes slideInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes slideOutRight {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  }
  @keyframes slideOutRight {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0);
    }
  }
  @-webkit-keyframes slideInTop {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes slideInTop {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes slideOutTop {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
    }
  }
  @keyframes slideOutTop {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
              transform: translate3d(0, -100%, 0);
    }
  }
  @-webkit-keyframes slideInBottom {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @keyframes slideInBottom {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
  }
  @-webkit-keyframes slideOutBottom {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
  }
  @keyframes slideOutBottom {
    from {
      opacity: 1;
      -webkit-transform: none;
              transform: none;
    }
    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
              transform: translate3d(0, 100%, 0);
    }
  }
  @-webkit-keyframes loaderSpin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes loaderSpin {
    from {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @-webkit-keyframes slideDown {
    from {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    to {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @keyframes slideDown {
    from {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    to {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  @-webkit-keyframes progress-active {
    0% {
      opacity: 0.1;
      width: 0;
    }
    20% {
      opacity: 0.5;
      width: 0;
    }
    100% {
      opacity: 0;
      width: 100%;
    }
  }
  @keyframes progress-active {
    0% {
      opacity: 0.1;
      width: 0;
    }
    20% {
      opacity: 0.5;
      width: 0;
    }
    100% {
      opacity: 0;
      width: 100%;
    }
  }
  @-webkit-keyframes progress-active-vertical {
    0% {
      opacity: 0.1;
      height: 0;
    }
    20% {
      opacity: 0.5;
      height: 0;
    }
    100% {
      opacity: 0;
      height: 100%;
    }
  }
  @keyframes progress-active-vertical {
    0% {
      opacity: 0.1;
      height: 0;
    }
    20% {
      opacity: 0.5;
      height: 0;
    }
    100% {
      opacity: 0;
      height: 100%;
    }
  }
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  @-webkit-keyframes sideNavFoldedText {
    0% {
      max-width: 200px;
      opacity: 0.8;
    }
    100% {
      max-width: 0;
      opacity: 0;
    }
  }
  @keyframes sideNavFoldedText {
    0% {
      max-width: 200px;
      opacity: 0.8;
    }
    100% {
      max-width: 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes placeholder-active {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @keyframes placeholder-active {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
  @-webkit-keyframes moveLeftHalf {
    0% {
      -webkit-transform: none;
              transform: none;
    }
    100% {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  @keyframes moveLeftHalf {
    0% {
      -webkit-transform: none;
              transform: none;
    }
    100% {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
    }
  }
  @-webkit-keyframes moveLeftHalf-vertical {
    0% {
      -webkit-transform: none;
              transform: none;
    }
    100% {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
    }
  }
  @keyframes moveLeftHalf-vertical {
    0% {
      -webkit-transform: none;
              transform: none;
    }
    100% {
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
    }
  }
  @font-face {
    font-family: 'rsuite-icon-font';
    src: url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.ttf') format('truetype'), url('https://cdn.jsdelivr.net/npm/rsuite/dist/styles/fonts/rsuite-icon-font.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  /* html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  body {
    font-family: Apple-System, Arial, Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', STXihei, sans-serif;
  }
  a {
    color: #1675e0;
    text-decoration: none;
  }
  a:hover,
  a:focus {
    color: #0a5dc2;
    text-decoration: underline;
  }
  a:active {
    color: #004299;
    text-decoration: underline;
  }
  a:focus {
    outline: 0;
  }
  img {
    vertical-align: middle;
  }
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #e5e5ea;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: inherit;
    font-weight: bolder;
    color: inherit;
    margin: 0;
  }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: normal;
    line-height: 1;
    color: #c5c6c7;
  }
  h1 {
    font-size: 46px;
    line-height: 62px;
  }
  h2 {
    font-size: 36px;
    line-height: 50px;
  }
  h3 {
    font-size: 28px;
    line-height: 42px;
  }
  h4 {
    font-size: 22px;
    line-height: 34px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
  }
  h6 {
    font-size: 16px;
    line-height: 22px;
  }
  p {
    margin: 0;
  }
  p + p {
    margin-top: 8px;
  }
  small {
    font-size: 85%;
  }
  mark {
    background-color: #fff9e6;
    padding: 0.2em;
    font-weight: bolder;
  }
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 10px;
  }
  ul ul,
  ol ul,
  ul ol,
  ol ol {
    margin-bottom: 0;
  }
  dl {
    margin-top: 0;
    margin-bottom: 10px;
  }
  dt,
  dd {
    line-height: 1.42857143;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin-left: 0;
    margin-bottom: 10px;
  }
  @font-face {
    font-family: Apple-System;
    src: local(-apple-system), local(BlinkMacSystemFont), local(system-ui);
  } */
  
  
  
  .rs-alert {
    position: fixed;
    z-index: 1080;
    pointer-events: none;
  }
  .rs-alert-item {
    pointer-events: auto;
  }
  .rs-alert-item-wrapper:not(.rs-alert-fade-exited) + .rs-alert-item-wrapper {
    margin-top: 10px;
  }
  .rs-alert-container {
    left: 50%;
  }
  .rs-alert-item-wrapper {
    display: block;
    text-align: center;
    width: 100%;
    margin-left: -50%;
  }
  .rs-alert-item-wrapper .rs-alert-item {
    display: inline-block;
    position: relative;
  }
  .rs-alert-item-content {
    text-align: left;
    padding: 20px;
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    max-width: 400px;
    padding-left: 42px;
    padding-right: 46px;
    position: relative;
  }
  .rs-alert-item-content p {
    margin-bottom: 0;
  }
  .rs-alert-item-content .rs-icon {
    position: absolute;
    top: 23px;
    left: 20px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 12px;
  }
  .rs-alert-item-close {
    position: absolute;
    top: 20px;
    right: 16px;
    padding: 4px;
    font-size: 12px;
    line-height: 1;
    outline: none !important;
    cursor: pointer;
  }
  .rs-alert-item-close-x::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-alert-fade-exited,
  .rs-alert-fade-entering {
    opacity: 0;
  }
  .rs-alert-fade-exited {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
  .rs-alert-fade-entered {
    -webkit-animation: alertMoverIn 0.3s ease-in forwards;
            animation: alertMoverIn 0.3s ease-in forwards;
  }
  .rs-alert-fade-leave-active {
    -webkit-animation: alertMoveOut 0.3s ease-out forwards;
            animation: alertMoveOut 0.3s ease-out forwards;
  }
  .rs-alert-container .rs-alert-success .rs-alert-item-content {
    background-color: #edfae1;
    color: #575757;
  }
  .rs-alert-container .rs-alert-success .rs-alert-item-content .rs-icon {
    color: #4caf50;
  }
  .rs-alert-container .rs-alert-success .rs-alert-item-close {
    color: #4caf50;
  }
  .rs-alert-container .rs-alert-info .rs-alert-item-content {
    background-color: #e9f5fe;
    color: #575757;
  }
  .rs-alert-container .rs-alert-info .rs-alert-item-content .rs-icon {
    color: #2196f3;
  }
  .rs-alert-container .rs-alert-info .rs-alert-item-close {
    color: #2196f3;
  }
  .rs-alert-container .rs-alert-warning .rs-alert-item-content {
    background-color: #fff9e6;
    color: #575757;
  }
  .rs-alert-container .rs-alert-warning .rs-alert-item-content .rs-icon {
    color: #ffb300;
  }
  .rs-alert-container .rs-alert-warning .rs-alert-item-close {
    color: #ffb300;
  }
  .rs-alert-container .rs-alert-error .rs-alert-item-content {
    background-color: #fde9ef;
    color: #575757;
  }
  .rs-alert-container .rs-alert-error .rs-alert-item-content .rs-icon {
    color: #f44336;
  }
  .rs-alert-container .rs-alert-error .rs-alert-item-close {
    color: #f44336;
  }
  .slide-out {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .slide-in {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .right.slide-in {
    -webkit-animation-name: slideInRight;
            animation-name: slideInRight;
  }
  .right.slide-out {
    -webkit-animation-name: slideOutRight;
            animation-name: slideOutRight;
  }
  .left.slide-in {
    -webkit-animation-name: slideInLeft;
            animation-name: slideInLeft;
  }
  .left.slide-out {
    -webkit-animation-name: slideOutLeft;
            animation-name: slideOutLeft;
  }
  .top.slide-in {
    -webkit-animation-name: slideInTop;
            animation-name: slideInTop;
  }
  .top.slide-out {
    -webkit-animation-name: slideOutTop;
            animation-name: slideOutTop;
  }
  .bottom.slide-in {
    -webkit-animation-name: slideInBottom;
            animation-name: slideInBottom;
  }
  .bottom.slide-out {
    -webkit-animation-name: slideOutBottom;
            animation-name: slideOutBottom;
  }
  .bounce-in {
    -webkit-animation-name: bounceIn;
            animation-name: bounceIn;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
            animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .bounce-out {
    -webkit-animation-name: bounceOut;
            animation-name: bounceOut;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
            animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .rs-picker-subtle .picker-subtle-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    outline: none;
    cursor: pointer;
  }
  .rs-picker-subtle .picker-subtle-toggle-custom-title {
    padding: 0 !important;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle {
    cursor: not-allowed;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active {
    background: none;
  }
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:hover::after,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:focus::after,
  .rs-picker-subtle.rs-picker-disabled .picker-subtle-toggle:active::after {
    display: none;
  }
  .rs-auto-complete-menu {
    margin-bottom: 6px;
    overflow-y: auto;
    margin-top: 6px;
  }
  .rs-auto-complete-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-auto-complete-item {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #575757;
    cursor: pointer;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    text-decoration: none;
    width: 100%;
    /* stylelint-disable-next-line */
  }
  .ReactVirtualized__Grid .rs-auto-complete-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-auto-complete-item,
  .rs-auto-complete-item:hover,
  .rs-auto-complete-item:active,
  .rs-auto-complete-item:focus {
    text-decoration: none;
  }
  .rs-auto-complete-item-focus,
  .rs-auto-complete-item:hover {
    color: #575757;
    background-color: #f2faff;
  }
  .rs-auto-complete .rs-input:focus::-webkit-textfield-decoration-container {
    visibility: hidden;
    pointer-events: none;
  }
  .rs-avatar {
    background-color: #d9d9d9;
    color: #fff;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border-radius: 4px;
    overflow: hidden;
    width: 40px;
    height: 40px;
    font-size: 18px;
  }
  .rs-avatar > .rs-icon {
    font-size: 29px;
  }
  .rs-avatar > .rs-icon > svg {
    height: 29px;
  }
  .rs-avatar > .rs-avatar-image {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .rs-avatar > .rs-icon {
    vertical-align: middle;
  }
  .rs-avatar-image {
    position: relative;
  }
  .rs-avatar-image::before {
    content: attr(alt);
    position: absolute;
    width: 100%;
    height: inherit;
    top: 0;
    left: 0;
    background: #d9d9d9;
    text-align: center;
    padding: 0 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-avatar-circle {
    border-radius: 50%;
  }
  .rs-avatar-lg {
    width: 60px;
    height: 60px;
    font-size: 26px;
  }
  .rs-avatar-lg > .rs-icon {
    font-size: 43px;
  }
  .rs-avatar-lg > .rs-icon > svg {
    height: 43px;
  }
  .rs-avatar-lg > .rs-avatar-image {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .rs-avatar-sm {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .rs-avatar-sm > .rs-icon {
    font-size: 22px;
  }
  .rs-avatar-sm > .rs-icon > svg {
    height: 22px;
  }
  .rs-avatar-sm > .rs-avatar-image {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .rs-avatar-xs {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  .rs-avatar-xs > .rs-icon {
    font-size: 16px;
  }
  .rs-avatar-xs > .rs-icon > svg {
    height: 16px;
  }
  .rs-avatar-xs > .rs-avatar-image {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
  .rs-badge {
    display: inline-block;
  }
  .rs-badge-wrapper {
    position: relative;
  }
  .rs-badge-independent,
  .rs-badge-content {
    background-color: #f44336;
    border-radius: 8px;
    font-size: 12px;
    line-height: 16px;
    padding: 0 5px;
    color: #fff;
  }
  .rs-badge-independent.rs-badge-dot,
  .rs-badge-wrapper .rs-badge-content:empty {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    padding: 0;
  }
  .rs-badge-wrapper .rs-badge-content {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
    z-index: 1;
  }
  .rs-breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
  }
  .rs-breadcrumb > li {
    display: inline-block;
    font-size: 12px;
  }
  .rs-breadcrumb-item > a {
    cursor: pointer;
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
    color: #1675e0;
    text-decoration: none;
  }
  .rs-breadcrumb-item > a:hover,
  .rs-breadcrumb-item > a:focus {
    color: #0a5dc2;
    text-decoration: underline;
  }
  .rs-breadcrumb-item > a:active {
    color: #004299;
    text-decoration: underline;
  }
  .rs-breadcrumb-item > a:focus {
    outline: 0;
  }
  .rs-breadcrumb-separator {
    margin: 0 4px;
  }
  .rs-breadcrumb-item-active {
    color: #8e8e93;
  }
  .rs-btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* stylelint-disable */
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-btn.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-btn.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-btn.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-btn.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-btn.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-btn.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-btn.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-btn:hover,
  .rs-btn:focus {
    color: #575757;
    text-decoration: none;
  }
  .rs-btn:active,
  .rs-btn.rs-btn-active {
    outline: 0;
    background-image: none;
  }
  .rs-btn.rs-btn-disabled {
    cursor: not-allowed !important;
    opacity: 0.3;
  }
  .rs-btn.rs-btn-disabled .rs-ripple-pond {
    display: none !important;
  }
  a.rs-btn {
    text-decoration: none;
  }
  .rs-btn-default,
  .rs-btn-primary,
  .rs-btn-subtle {
    overflow: hidden;
    position: relative;
    
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-btn-default,
    .rs-btn-primary,
    .rs-btn-subtle {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-disable-line property-no-vendor-prefix */
    }
  }
  .rs-btn-default:not(.rs-btn-disabled) .rs-ripple,
  .rs-btn-primary:not(.rs-btn-disabled) .rs-ripple,
  .rs-btn-subtle:not(.rs-btn-disabled) .rs-ripple {
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
  }
  .rs-btn-default:not(.rs-btn-disabled) .rs-ripple-rippling,
  .rs-btn-primary:not(.rs-btn-disabled) .rs-ripple-rippling,
  .rs-btn-subtle:not(.rs-btn-disabled) .rs-ripple-rippling {
    -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  .rs-btn-default:not(.rs-btn-disabled) .rs-ripple-pond,
  .rs-btn-primary:not(.rs-btn-disabled) .rs-ripple-pond,
  .rs-btn-subtle:not(.rs-btn-disabled) .rs-ripple-pond {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .rs-btn-loading {
    color: transparent !important;
    position: relative;
    cursor: default;
    pointer-events: none;
  }
  .rs-btn-loading > .rs-btn-spin::before,
  .rs-btn-loading > .rs-btn-spin::after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
  }
  .rs-btn-xs.rs-btn-loading > .rs-btn-spin::before,
  .rs-btn-xs.rs-btn-loading > .rs-btn-spin::after {
    width: 16px;
    height: 16px;
  }
  .rs-btn-loading > .rs-btn-spin::before {
    border: 3px solid #f7f7fa;
  }
  .rs-btn-primary.rs-btn-loading > .rs-btn-spin::before {
    opacity: 0.3;
  }
  .rs-btn-loading > .rs-btn-spin::after {
    border-width: 3px;
    border-color: #a6a6a6 transparent transparent;
    border-style: solid;
    -webkit-animation: buttonSpin 0.6s infinite linear;
            animation: buttonSpin 0.6s infinite linear;
  }
  .rs-btn-primary.rs-btn-loading > .rs-btn-spin::after {
    border-top-color: #fff;
  }
  .rs-btn-default {
    color: #575757;
    background: #f7f7fa;
  }
  .rs-btn-default.rs-btn-loading:hover,
  .rs-btn-default.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-default.rs-btn-loading:focus,
  .rs-btn-default.rs-btn-loading:active,
  .rs-btn-default.rs-btn-loading:hover:active {
    color: #575757;
    background-color: #f7f7fa; 
    /* background-color: red;  */

  }
  .rs-btn-default,
  .rs-btn-default > .rs-icon {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
  }
  .rs-btn-default:focus {
    color: #575757;
    background-color: #e5e5ea;
  }
  .rs-btn-default:hover {
    color: #575757;
    background-color: #e5e5ea;
  }
  .rs-btn-default:active,
  .rs-btn-default.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-default {
    color: #575757;
    /* background-color: #d9d9d9; */
    background-color: #0477f5 ;
    background-image: none;
  }
  .rs-btn-default:active:hover,
  .rs-btn-default.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-default:hover,
  .rs-btn-default:active:focus,
  .rs-btn-default.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default:focus,
  .rs-btn-default:active.focus,
  .rs-btn-default.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.focus {
    color: #575757;
    background-color: #d9d9d9;
    /* background-color: #0477f5 ; */
  }
  .rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-default,
  .rs-btn-default.rs-btn-disabled:hover,
  .rs-dropdown-disabled .rs-btn-default:hover,
  .rs-btn-default.rs-btn-disabled:focus,
  .rs-dropdown-disabled .rs-btn-default:focus,
  .rs-btn-default.rs-btn-disabled:active,
  .rs-dropdown-disabled .rs-btn-default:active,
  .rs-btn-default.rs-btn-disabled:active:focus,
  .rs-dropdown-disabled .rs-btn-default:active:focus {
    opacity: 1;
    color: #c5c6c7;
    background-color: #f7f7fa;
  }
  .rs-btn-default.rs-btn-red {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #f44336;
  }
  .rs-btn-default.rs-btn-red.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #eb3626;
  }
  .rs-btn-default.rs-btn-red.rs-btn-loading:hover,
  .rs-btn-default.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-default.rs-btn-red.rs-btn-loading:focus,
  .rs-btn-default.rs-btn-red.rs-btn-loading:active,
  .rs-btn-default.rs-btn-red.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #f44336;
  }
  .rs-btn-default.rs-btn-red:focus,
  .rs-btn-default.rs-btn-red.rs-btn-focus {
    color: #fff;
    background-color: #eb3626;
  }
  .rs-btn-default.rs-btn-red:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-red.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #d62915;
  }
  .rs-btn-default.rs-btn-red:hover {
    color: #fff;
  }
  .rs-btn-default.rs-btn-red:not(.rs-btn-disabled):hover {
    background-color: #eb3626;
  }
  .rs-btn-default.rs-btn-red:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #d62915;
  }
  .rs-btn-default.rs-btn-red:active,
  .rs-btn-default.rs-btn-red.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red {
    color: #fff;
    background-color: #d62915;
  }
  .rs-btn-default.rs-btn-red:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-red.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #b81c07;
  }
  .rs-btn-default.rs-btn-red:active:hover,
  .rs-btn-default.rs-btn-red.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red:hover,
  .rs-btn-default.rs-btn-red:active:focus,
  .rs-btn-default.rs-btn-red.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red:focus,
  .rs-btn-default.rs-btn-red:active.focus,
  .rs-btn-default.rs-btn-red.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red.focus {
    color: #fff;
    background-color: #d62915;
  }
  .rs-btn-default.rs-btn-red:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-red.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-red:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-red.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-red:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-red.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #b81c07;
  }
  .rs-btn-default.rs-btn-red:active,
  .rs-btn-default.rs-btn-red.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-default.rs-btn-red {
    background-image: none;
  }
  .rs-btn-default.rs-btn-red.rs-btn-disabled:hover,
  .rs-btn-default.rs-btn-red.rs-btn-disabled:focus,
  .rs-btn-default.rs-btn-red.rs-btn-disabled.focus {
    background: #f44336;
  }
  .rs-btn-default.rs-btn-red.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-default.rs-btn-red.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-default.rs-btn-orange {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #ff9800;
  }
  .rs-btn-default.rs-btn-orange.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #f59700;
  }
  .rs-btn-default.rs-btn-orange.rs-btn-loading:hover,
  .rs-btn-default.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-default.rs-btn-orange.rs-btn-loading:focus,
  .rs-btn-default.rs-btn-orange.rs-btn-loading:active,
  .rs-btn-default.rs-btn-orange.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #ff9800;
  }
  .rs-btn-default.rs-btn-orange:focus,
  .rs-btn-default.rs-btn-orange.rs-btn-focus {
    color: #fff;
    background-color: #f59700;
  }
  .rs-btn-default.rs-btn-orange:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-orange.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #e08e00;
  }
  .rs-btn-default.rs-btn-orange:hover {
    color: #fff;
  }
  .rs-btn-default.rs-btn-orange:not(.rs-btn-disabled):hover {
    background-color: #f59700;
  }
  .rs-btn-default.rs-btn-orange:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #e08e00;
  }
  .rs-btn-default.rs-btn-orange:active,
  .rs-btn-default.rs-btn-orange.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange {
    color: #fff;
    background-color: #e08e00;
  }
  .rs-btn-default.rs-btn-orange:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-orange.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c27e00;
  }
  .rs-btn-default.rs-btn-orange:active:hover,
  .rs-btn-default.rs-btn-orange.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange:hover,
  .rs-btn-default.rs-btn-orange:active:focus,
  .rs-btn-default.rs-btn-orange.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange:focus,
  .rs-btn-default.rs-btn-orange:active.focus,
  .rs-btn-default.rs-btn-orange.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange.focus {
    color: #fff;
    background-color: #e08e00;
  }
  .rs-btn-default.rs-btn-orange:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-orange.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-orange:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-orange.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-orange:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-orange.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c27e00;
  }
  .rs-btn-default.rs-btn-orange:active,
  .rs-btn-default.rs-btn-orange.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-default.rs-btn-orange {
    background-image: none;
  }
  .rs-btn-default.rs-btn-orange.rs-btn-disabled:hover,
  .rs-btn-default.rs-btn-orange.rs-btn-disabled:focus,
  .rs-btn-default.rs-btn-orange.rs-btn-disabled.focus {
    background: #ff9800;
  }
  .rs-btn-default.rs-btn-orange.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-default.rs-btn-orange.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-default.rs-btn-yellow {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #ffca28;
  }
  .rs-btn-default.rs-btn-yellow.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #f5c31d;
  }
  .rs-btn-default.rs-btn-yellow.rs-btn-loading:hover,
  .rs-btn-default.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-default.rs-btn-yellow.rs-btn-loading:focus,
  .rs-btn-default.rs-btn-yellow.rs-btn-loading:active,
  .rs-btn-default.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #ffca28;
  }
  .rs-btn-default.rs-btn-yellow:focus,
  .rs-btn-default.rs-btn-yellow.rs-btn-focus {
    color: #fff;
    background-color: #f5c31d;
  }
  .rs-btn-default.rs-btn-yellow:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-yellow.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #e0b412;
  }
  .rs-btn-default.rs-btn-yellow:hover {
    color: #fff;
  }
  .rs-btn-default.rs-btn-yellow:not(.rs-btn-disabled):hover {
    background-color: #f5c31d;
  }
  .rs-btn-default.rs-btn-yellow:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #e0b412;
  }
  .rs-btn-default.rs-btn-yellow:active,
  .rs-btn-default.rs-btn-yellow.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow {
    color: #fff;
    background-color: #e0b412;
  }
  .rs-btn-default.rs-btn-yellow:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-yellow.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c29d08;
  }
  .rs-btn-default.rs-btn-yellow:active:hover,
  .rs-btn-default.rs-btn-yellow.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow:hover,
  .rs-btn-default.rs-btn-yellow:active:focus,
  .rs-btn-default.rs-btn-yellow.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow:focus,
  .rs-btn-default.rs-btn-yellow:active.focus,
  .rs-btn-default.rs-btn-yellow.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow.focus {
    color: #fff;
    background-color: #e0b412;
  }
  .rs-btn-default.rs-btn-yellow:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-yellow.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-yellow:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-yellow.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-yellow:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-yellow.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c29d08;
  }
  .rs-btn-default.rs-btn-yellow:active,
  .rs-btn-default.rs-btn-yellow.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-default.rs-btn-yellow {
    background-image: none;
  }
  .rs-btn-default.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-btn-default.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-btn-default.rs-btn-yellow.rs-btn-disabled.focus {
    background: #ffca28;
  }
  .rs-btn-default.rs-btn-yellow.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-default.rs-btn-yellow.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-default.rs-btn-green {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #4caf50;
  }
  .rs-btn-default.rs-btn-green.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #37ab3c;
  }
  .rs-btn-default.rs-btn-green.rs-btn-loading:hover,
  .rs-btn-default.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-default.rs-btn-green.rs-btn-loading:focus,
  .rs-btn-default.rs-btn-green.rs-btn-loading:active,
  .rs-btn-default.rs-btn-green.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #4caf50;
  }
  .rs-btn-default.rs-btn-green:focus,
  .rs-btn-default.rs-btn-green.rs-btn-focus {
    color: #fff;
    background-color: #37ab3c;
  }
  .rs-btn-default.rs-btn-green:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-green.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #22a12a;
  }
  .rs-btn-default.rs-btn-green:hover {
    color: #fff;
  }
  .rs-btn-default.rs-btn-green:not(.rs-btn-disabled):hover {
    background-color: #37ab3c;
  }
  .rs-btn-default.rs-btn-green:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #22a12a;
  }
  .rs-btn-default.rs-btn-green:active,
  .rs-btn-default.rs-btn-green.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green {
    color: #fff;
    background-color: #22a12a;
  }
  .rs-btn-default.rs-btn-green:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-green.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0f9119;
  }
  .rs-btn-default.rs-btn-green:active:hover,
  .rs-btn-default.rs-btn-green.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green:hover,
  .rs-btn-default.rs-btn-green:active:focus,
  .rs-btn-default.rs-btn-green.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green:focus,
  .rs-btn-default.rs-btn-green:active.focus,
  .rs-btn-default.rs-btn-green.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green.focus {
    color: #fff;
    background-color: #22a12a;
  }
  .rs-btn-default.rs-btn-green:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-green.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-green:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-green.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-green:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-green.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0f9119;
  }
  .rs-btn-default.rs-btn-green:active,
  .rs-btn-default.rs-btn-green.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-default.rs-btn-green {
    background-image: none;
  }
  .rs-btn-default.rs-btn-green.rs-btn-disabled:hover,
  .rs-btn-default.rs-btn-green.rs-btn-disabled:focus,
  .rs-btn-default.rs-btn-green.rs-btn-disabled.focus {
    background: #4caf50;
  }
  .rs-btn-default.rs-btn-green.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-default.rs-btn-green.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-default.rs-btn-cyan {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #00bcd4;
  }
  .rs-btn-default.rs-btn-cyan.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #00b1cc;
  }
  .rs-btn-default.rs-btn-cyan.rs-btn-loading:hover,
  .rs-btn-default.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-default.rs-btn-cyan.rs-btn-loading:focus,
  .rs-btn-default.rs-btn-cyan.rs-btn-loading:active,
  .rs-btn-default.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #00bcd4;
  }
  .rs-btn-default.rs-btn-cyan:focus,
  .rs-btn-default.rs-btn-cyan.rs-btn-focus {
    color: #fff;
    background-color: #00b1cc;
  }
  .rs-btn-default.rs-btn-cyan:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-cyan.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #00a0bd;
  }
  .rs-btn-default.rs-btn-cyan:hover {
    color: #fff;
  }
  .rs-btn-default.rs-btn-cyan:not(.rs-btn-disabled):hover {
    background-color: #00b1cc;
  }
  .rs-btn-default.rs-btn-cyan:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #00a0bd;
  }
  .rs-btn-default.rs-btn-cyan:active,
  .rs-btn-default.rs-btn-cyan.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan {
    color: #fff;
    background-color: #00a0bd;
  }
  .rs-btn-default.rs-btn-cyan:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-cyan.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #008aa6;
  }
  .rs-btn-default.rs-btn-cyan:active:hover,
  .rs-btn-default.rs-btn-cyan.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan:hover,
  .rs-btn-default.rs-btn-cyan:active:focus,
  .rs-btn-default.rs-btn-cyan.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan:focus,
  .rs-btn-default.rs-btn-cyan:active.focus,
  .rs-btn-default.rs-btn-cyan.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan.focus {
    color: #fff;
    background-color: #00a0bd;
  }
  .rs-btn-default.rs-btn-cyan:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-cyan.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-cyan:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-cyan.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-cyan:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-cyan.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #008aa6;
  }
  .rs-btn-default.rs-btn-cyan:active,
  .rs-btn-default.rs-btn-cyan.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-default.rs-btn-cyan {
    background-image: none;
  }
  .rs-btn-default.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-btn-default.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-btn-default.rs-btn-cyan.rs-btn-disabled.focus {
    background: #00bcd4;
  }
  .rs-btn-default.rs-btn-cyan.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-default.rs-btn-cyan.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-default.rs-btn-blue {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #2196f3;
  }
  .rs-btn-default.rs-btn-blue.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #1787e8;
  }
  .rs-btn-default.rs-btn-blue.rs-btn-loading:hover,
  .rs-btn-default.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-default.rs-btn-blue.rs-btn-loading:focus,
  .rs-btn-default.rs-btn-blue.rs-btn-loading:active,
  .rs-btn-default.rs-btn-blue.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #2196f3;
  }
  .rs-btn-default.rs-btn-blue:focus,
  .rs-btn-default.rs-btn-blue.rs-btn-focus {
    color: #fff;
    background-color: #1787e8;
  }
  .rs-btn-default.rs-btn-blue:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-blue.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0d73d4;
  }
  .rs-btn-default.rs-btn-blue:hover {
    color: #fff;
  }
  .rs-btn-default.rs-btn-blue:not(.rs-btn-disabled):hover {
    background-color: #1787e8;
  }
  .rs-btn-default.rs-btn-blue:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0d73d4;
  }
  .rs-btn-default.rs-btn-blue:active,
  .rs-btn-default.rs-btn-blue.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue {
    color: #fff;
    background-color: #0d73d4;
  }
  .rs-btn-default.rs-btn-blue:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-blue.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #045cb5;
  }
  .rs-btn-default.rs-btn-blue:active:hover,
  .rs-btn-default.rs-btn-blue.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue:hover,
  .rs-btn-default.rs-btn-blue:active:focus,
  .rs-btn-default.rs-btn-blue.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue:focus,
  .rs-btn-default.rs-btn-blue:active.focus,
  .rs-btn-default.rs-btn-blue.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue.focus {
    color: #fff;
    background-color: #0d73d4;
  }
  .rs-btn-default.rs-btn-blue:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-blue.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-blue:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-blue.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-blue:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-blue.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #045cb5;
  }
  .rs-btn-default.rs-btn-blue:active,
  .rs-btn-default.rs-btn-blue.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-default.rs-btn-blue {
    background-image: none;
  }
  .rs-btn-default.rs-btn-blue.rs-btn-disabled:hover,
  .rs-btn-default.rs-btn-blue.rs-btn-disabled:focus,
  .rs-btn-default.rs-btn-blue.rs-btn-disabled.focus {
    background: #2196f3;
  }
  .rs-btn-default.rs-btn-blue.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-default.rs-btn-blue.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-default.rs-btn-violet {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #673ab7;
  }
  .rs-btn-default.rs-btn-violet.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #5f2bb3;
  }
  .rs-btn-default.rs-btn-violet.rs-btn-loading:hover,
  .rs-btn-default.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-default.rs-btn-violet.rs-btn-loading:focus,
  .rs-btn-default.rs-btn-violet.rs-btn-loading:active,
  .rs-btn-default.rs-btn-violet.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #673ab7;
  }
  .rs-btn-default.rs-btn-violet:focus,
  .rs-btn-default.rs-btn-violet.rs-btn-focus {
    color: #fff;
    background-color: #5f2bb3;
  }
  .rs-btn-default.rs-btn-violet:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-violet.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #531ba8;
  }
  .rs-btn-default.rs-btn-violet:hover {
    color: #fff;
  }
  .rs-btn-default.rs-btn-violet:not(.rs-btn-disabled):hover {
    background-color: #5f2bb3;
  }
  .rs-btn-default.rs-btn-violet:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #531ba8;
  }
  .rs-btn-default.rs-btn-violet:active,
  .rs-btn-default.rs-btn-violet.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet {
    color: #fff;
    background-color: #531ba8;
  }
  .rs-btn-default.rs-btn-violet:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-violet.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #470c99;
  }
  .rs-btn-default.rs-btn-violet:active:hover,
  .rs-btn-default.rs-btn-violet.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet:hover,
  .rs-btn-default.rs-btn-violet:active:focus,
  .rs-btn-default.rs-btn-violet.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet:focus,
  .rs-btn-default.rs-btn-violet:active.focus,
  .rs-btn-default.rs-btn-violet.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet.focus {
    color: #fff;
    background-color: #531ba8;
  }
  .rs-btn-default.rs-btn-violet:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-violet.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-violet:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-violet.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-violet:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-violet.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #470c99;
  }
  .rs-btn-default.rs-btn-violet:active,
  .rs-btn-default.rs-btn-violet.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-default.rs-btn-violet {
    background-image: none;
  }
  .rs-btn-default.rs-btn-violet.rs-btn-disabled:hover,
  .rs-btn-default.rs-btn-violet.rs-btn-disabled:focus,
  .rs-btn-default.rs-btn-violet.rs-btn-disabled.focus {
    background: #673ab7;
  }
  .rs-btn-default.rs-btn-violet.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-default.rs-btn-violet.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-primary {
    /* stylelint-disable-next-line */
    color: #ffffff;
    background-color: #3498ff;
  }
  .rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #2589f5;
  }
  .rs-btn-primary.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-loading:hover:active {
    color: #ffffff;
    background-color: #3498ff;
  }
  .rs-btn-primary:focus,
  .rs-btn-primary.rs-btn-focus {
    color: #ffffff;
    background-color: #2589f5;
  }
  .rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #1675e0;
  }
  .rs-btn-primary:hover {
    color: #ffffff;
  }
  .rs-btn-primary:not(.rs-btn-disabled):hover {
    background-color: #2589f5;
  }
  .rs-btn-primary:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #1675e0;
  }
  .rs-btn-primary:active,
  .rs-btn-primary.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary {
    color: #ffffff;
    background-color: #1675e0;
  }
  .rs-btn-primary:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0a5dc2;
  }
  .rs-btn-primary:active:hover,
  .rs-btn-primary.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary:hover,
  .rs-btn-primary:active:focus,
  .rs-btn-primary.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary:focus,
  .rs-btn-primary:active.focus,
  .rs-btn-primary.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.focus {
    color: #ffffff;
    background-color: #1675e0;
  }
  .rs-btn-primary:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0a5dc2;
  }
  .rs-btn-primary:active,
  .rs-btn-primary.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-primary {
    background-image: none;
  }
  .rs-btn-primary.rs-btn-disabled:hover,
  .rs-btn-primary.rs-btn-disabled:focus,
  .rs-btn-primary.rs-btn-disabled.focus {
    background: #3498ff;
  }
  .rs-btn-primary.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #3498ff;
  }
  .rs-btn-primary,
  .rs-btn-primary > .rs-icon {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
  }
  .rs-btn-primary.rs-btn-red {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #f44336;
  }
  .rs-btn-primary.rs-btn-red.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #eb3626;
  }
  .rs-btn-primary.rs-btn-red.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-red.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-red.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-red.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #f44336;
  }
  .rs-btn-primary.rs-btn-red:focus,
  .rs-btn-primary.rs-btn-red.rs-btn-focus {
    color: #fff;
    background-color: #eb3626;
  }
  .rs-btn-primary.rs-btn-red:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-red.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #d62915;
  }
  .rs-btn-primary.rs-btn-red:hover {
    color: #fff;
  }
  .rs-btn-primary.rs-btn-red:not(.rs-btn-disabled):hover {
    background-color: #eb3626;
  }
  .rs-btn-primary.rs-btn-red:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #d62915;
  }
  .rs-btn-primary.rs-btn-red:active,
  .rs-btn-primary.rs-btn-red.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red {
    color: #fff;
    background-color: #d62915;
  }
  .rs-btn-primary.rs-btn-red:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-red.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #b81c07;
  }
  .rs-btn-primary.rs-btn-red:active:hover,
  .rs-btn-primary.rs-btn-red.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red:hover,
  .rs-btn-primary.rs-btn-red:active:focus,
  .rs-btn-primary.rs-btn-red.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red:focus,
  .rs-btn-primary.rs-btn-red:active.focus,
  .rs-btn-primary.rs-btn-red.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red.focus {
    color: #fff;
    background-color: #d62915;
  }
  .rs-btn-primary.rs-btn-red:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-red.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-red:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-red.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-red:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-red.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #b81c07;
  }
  .rs-btn-primary.rs-btn-red:active,
  .rs-btn-primary.rs-btn-red.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-red {
    background-image: none;
  }
  .rs-btn-primary.rs-btn-red.rs-btn-disabled:hover,
  .rs-btn-primary.rs-btn-red.rs-btn-disabled:focus,
  .rs-btn-primary.rs-btn-red.rs-btn-disabled.focus {
    background: #f44336;
  }
  .rs-btn-primary.rs-btn-red.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-primary.rs-btn-red.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-primary.rs-btn-orange {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #ff9800;
  }
  .rs-btn-primary.rs-btn-orange.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #f59700;
  }
  .rs-btn-primary.rs-btn-orange.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-orange.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-orange.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-orange.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #ff9800;
  }
  .rs-btn-primary.rs-btn-orange:focus,
  .rs-btn-primary.rs-btn-orange.rs-btn-focus {
    color: #fff;
    background-color: #f59700;
  }
  .rs-btn-primary.rs-btn-orange:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-orange.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #e08e00;
  }
  .rs-btn-primary.rs-btn-orange:hover {
    color: #fff;
  }
  .rs-btn-primary.rs-btn-orange:not(.rs-btn-disabled):hover {
    background-color: #f59700;
  }
  .rs-btn-primary.rs-btn-orange:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #e08e00;
  }
  .rs-btn-primary.rs-btn-orange:active,
  .rs-btn-primary.rs-btn-orange.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange {
    color: #fff;
    background-color: #e08e00;
  }
  .rs-btn-primary.rs-btn-orange:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-orange.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c27e00;
  }
  .rs-btn-primary.rs-btn-orange:active:hover,
  .rs-btn-primary.rs-btn-orange.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange:hover,
  .rs-btn-primary.rs-btn-orange:active:focus,
  .rs-btn-primary.rs-btn-orange.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange:focus,
  .rs-btn-primary.rs-btn-orange:active.focus,
  .rs-btn-primary.rs-btn-orange.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange.focus {
    color: #fff;
    background-color: #e08e00;
  }
  .rs-btn-primary.rs-btn-orange:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-orange.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-orange:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-orange.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-orange:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-orange.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c27e00;
  }
  .rs-btn-primary.rs-btn-orange:active,
  .rs-btn-primary.rs-btn-orange.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-orange {
    background-image: none;
  }
  .rs-btn-primary.rs-btn-orange.rs-btn-disabled:hover,
  .rs-btn-primary.rs-btn-orange.rs-btn-disabled:focus,
  .rs-btn-primary.rs-btn-orange.rs-btn-disabled.focus {
    background: #ff9800;
  }
  .rs-btn-primary.rs-btn-orange.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-primary.rs-btn-orange.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-primary.rs-btn-yellow {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #ffca28;
  }
  .rs-btn-primary.rs-btn-yellow.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #f5c31d;
  }
  .rs-btn-primary.rs-btn-yellow.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-yellow.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-yellow.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #ffca28;
  }
  .rs-btn-primary.rs-btn-yellow:focus,
  .rs-btn-primary.rs-btn-yellow.rs-btn-focus {
    color: #fff;
    background-color: #f5c31d;
  }
  .rs-btn-primary.rs-btn-yellow:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-yellow.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #e0b412;
  }
  .rs-btn-primary.rs-btn-yellow:hover {
    color: #fff;
  }
  .rs-btn-primary.rs-btn-yellow:not(.rs-btn-disabled):hover {
    background-color: #f5c31d;
  }
  .rs-btn-primary.rs-btn-yellow:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #e0b412;
  }
  .rs-btn-primary.rs-btn-yellow:active,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow {
    color: #fff;
    background-color: #e0b412;
  }
  .rs-btn-primary.rs-btn-yellow:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c29d08;
  }
  .rs-btn-primary.rs-btn-yellow:active:hover,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow:hover,
  .rs-btn-primary.rs-btn-yellow:active:focus,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow:focus,
  .rs-btn-primary.rs-btn-yellow:active.focus,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow.focus {
    color: #fff;
    background-color: #e0b412;
  }
  .rs-btn-primary.rs-btn-yellow:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-yellow:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-yellow:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c29d08;
  }
  .rs-btn-primary.rs-btn-yellow:active,
  .rs-btn-primary.rs-btn-yellow.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-yellow {
    background-image: none;
  }
  .rs-btn-primary.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-btn-primary.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-btn-primary.rs-btn-yellow.rs-btn-disabled.focus {
    background: #ffca28;
  }
  .rs-btn-primary.rs-btn-yellow.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-primary.rs-btn-yellow.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-primary.rs-btn-green {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #4caf50;
  }
  .rs-btn-primary.rs-btn-green.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #37ab3c;
  }
  .rs-btn-primary.rs-btn-green.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-green.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-green.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-green.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #4caf50;
  }
  .rs-btn-primary.rs-btn-green:focus,
  .rs-btn-primary.rs-btn-green.rs-btn-focus {
    color: #fff;
    background-color: #37ab3c;
  }
  .rs-btn-primary.rs-btn-green:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-green.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #22a12a;
  }
  .rs-btn-primary.rs-btn-green:hover {
    color: #fff;
  }
  .rs-btn-primary.rs-btn-green:not(.rs-btn-disabled):hover {
    background-color: #37ab3c;
  }
  .rs-btn-primary.rs-btn-green:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #22a12a;
  }
  .rs-btn-primary.rs-btn-green:active,
  .rs-btn-primary.rs-btn-green.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green {
    color: #fff;
    background-color: #22a12a;
  }
  .rs-btn-primary.rs-btn-green:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-green.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0f9119;
  }
  .rs-btn-primary.rs-btn-green:active:hover,
  .rs-btn-primary.rs-btn-green.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green:hover,
  .rs-btn-primary.rs-btn-green:active:focus,
  .rs-btn-primary.rs-btn-green.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green:focus,
  .rs-btn-primary.rs-btn-green:active.focus,
  .rs-btn-primary.rs-btn-green.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green.focus {
    color: #fff;
    background-color: #22a12a;
  }
  .rs-btn-primary.rs-btn-green:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-green.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-green:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-green.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-green:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-green.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0f9119;
  }
  .rs-btn-primary.rs-btn-green:active,
  .rs-btn-primary.rs-btn-green.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-green {
    background-image: none;
  }
  .rs-btn-primary.rs-btn-green.rs-btn-disabled:hover,
  .rs-btn-primary.rs-btn-green.rs-btn-disabled:focus,
  .rs-btn-primary.rs-btn-green.rs-btn-disabled.focus {
    background: #4caf50;
  }
  .rs-btn-primary.rs-btn-green.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-primary.rs-btn-green.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-primary.rs-btn-cyan {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #00bcd4;
  }
  .rs-btn-primary.rs-btn-cyan.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #00b1cc;
  }
  .rs-btn-primary.rs-btn-cyan.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-cyan.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-cyan.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #00bcd4;
  }
  .rs-btn-primary.rs-btn-cyan:focus,
  .rs-btn-primary.rs-btn-cyan.rs-btn-focus {
    color: #fff;
    background-color: #00b1cc;
  }
  .rs-btn-primary.rs-btn-cyan:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-cyan.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #00a0bd;
  }
  .rs-btn-primary.rs-btn-cyan:hover {
    color: #fff;
  }
  .rs-btn-primary.rs-btn-cyan:not(.rs-btn-disabled):hover {
    background-color: #00b1cc;
  }
  .rs-btn-primary.rs-btn-cyan:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #00a0bd;
  }
  .rs-btn-primary.rs-btn-cyan:active,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan {
    color: #fff;
    background-color: #00a0bd;
  }
  .rs-btn-primary.rs-btn-cyan:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #008aa6;
  }
  .rs-btn-primary.rs-btn-cyan:active:hover,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan:hover,
  .rs-btn-primary.rs-btn-cyan:active:focus,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan:focus,
  .rs-btn-primary.rs-btn-cyan:active.focus,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan.focus {
    color: #fff;
    background-color: #00a0bd;
  }
  .rs-btn-primary.rs-btn-cyan:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-cyan:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-cyan:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #008aa6;
  }
  .rs-btn-primary.rs-btn-cyan:active,
  .rs-btn-primary.rs-btn-cyan.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-cyan {
    background-image: none;
  }
  .rs-btn-primary.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-btn-primary.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-btn-primary.rs-btn-cyan.rs-btn-disabled.focus {
    background: #00bcd4;
  }
  .rs-btn-primary.rs-btn-cyan.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-primary.rs-btn-cyan.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-primary.rs-btn-blue {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #2196f3;
  }
  .rs-btn-primary.rs-btn-blue.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #1787e8;
  }
  .rs-btn-primary.rs-btn-blue.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-blue.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-blue.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-blue.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #2196f3;
  }
  .rs-btn-primary.rs-btn-blue:focus,
  .rs-btn-primary.rs-btn-blue.rs-btn-focus {
    color: #fff;
    background-color: #1787e8;
  }
  .rs-btn-primary.rs-btn-blue:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-blue.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0d73d4;
  }
  .rs-btn-primary.rs-btn-blue:hover {
    color: #fff;
  }
  .rs-btn-primary.rs-btn-blue:not(.rs-btn-disabled):hover {
    background-color: #1787e8;
  }
  .rs-btn-primary.rs-btn-blue:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0d73d4;
  }
  .rs-btn-primary.rs-btn-blue:active,
  .rs-btn-primary.rs-btn-blue.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue {
    color: #fff;
    background-color: #0d73d4;
  }
  .rs-btn-primary.rs-btn-blue:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-blue.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #045cb5;
  }
  .rs-btn-primary.rs-btn-blue:active:hover,
  .rs-btn-primary.rs-btn-blue.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue:hover,
  .rs-btn-primary.rs-btn-blue:active:focus,
  .rs-btn-primary.rs-btn-blue.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue:focus,
  .rs-btn-primary.rs-btn-blue:active.focus,
  .rs-btn-primary.rs-btn-blue.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue.focus {
    color: #fff;
    background-color: #0d73d4;
  }
  .rs-btn-primary.rs-btn-blue:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-blue.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-blue:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-blue.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-blue:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-blue.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #045cb5;
  }
  .rs-btn-primary.rs-btn-blue:active,
  .rs-btn-primary.rs-btn-blue.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-blue {
    background-image: none;
  }
  .rs-btn-primary.rs-btn-blue.rs-btn-disabled:hover,
  .rs-btn-primary.rs-btn-blue.rs-btn-disabled:focus,
  .rs-btn-primary.rs-btn-blue.rs-btn-disabled.focus {
    background: #2196f3;
  }
  .rs-btn-primary.rs-btn-blue.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-primary.rs-btn-blue.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-primary.rs-btn-violet {
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #673ab7;
  }
  .rs-btn-primary.rs-btn-violet.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #5f2bb3;
  }
  .rs-btn-primary.rs-btn-violet.rs-btn-loading:hover,
  .rs-btn-primary.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-primary.rs-btn-violet.rs-btn-loading:focus,
  .rs-btn-primary.rs-btn-violet.rs-btn-loading:active,
  .rs-btn-primary.rs-btn-violet.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #673ab7;
  }
  .rs-btn-primary.rs-btn-violet:focus,
  .rs-btn-primary.rs-btn-violet.rs-btn-focus {
    color: #fff;
    background-color: #5f2bb3;
  }
  .rs-btn-primary.rs-btn-violet:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-violet.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #531ba8;
  }
  .rs-btn-primary.rs-btn-violet:hover {
    color: #fff;
  }
  .rs-btn-primary.rs-btn-violet:not(.rs-btn-disabled):hover {
    background-color: #5f2bb3;
  }
  .rs-btn-primary.rs-btn-violet:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #531ba8;
  }
  .rs-btn-primary.rs-btn-violet:active,
  .rs-btn-primary.rs-btn-violet.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet {
    color: #fff;
    background-color: #531ba8;
  }
  .rs-btn-primary.rs-btn-violet:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-violet.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #470c99;
  }
  .rs-btn-primary.rs-btn-violet:active:hover,
  .rs-btn-primary.rs-btn-violet.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet:hover,
  .rs-btn-primary.rs-btn-violet:active:focus,
  .rs-btn-primary.rs-btn-violet.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet:focus,
  .rs-btn-primary.rs-btn-violet:active.focus,
  .rs-btn-primary.rs-btn-violet.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet.focus {
    color: #fff;
    background-color: #531ba8;
  }
  .rs-btn-primary.rs-btn-violet:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-violet.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-violet:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-violet.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-violet:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-primary.rs-btn-violet.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #470c99;
  }
  .rs-btn-primary.rs-btn-violet:active,
  .rs-btn-primary.rs-btn-violet.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-btn-primary.rs-btn-violet {
    background-image: none;
  }
  .rs-btn-primary.rs-btn-violet.rs-btn-disabled:hover,
  .rs-btn-primary.rs-btn-violet.rs-btn-disabled:focus,
  .rs-btn-primary.rs-btn-violet.rs-btn-disabled.focus {
    background: #673ab7;
  }
  .rs-btn-primary.rs-btn-violet.rs-btn-default.rs-btn-disabled,
  .rs-dropdown-disabled .rs-btn-primary.rs-btn-violet.rs-btn-default {
    opacity: 0.3;
  }
  .rs-btn-link {
    -webkit-transition: color 0.2s linear;
    transition: color 0.2s linear;
    color: #1675e0;
    font-weight: normal;
    border-radius: 0;
  }
  .rs-btn-link.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-loading:active,
  .rs-btn-link.rs-btn-loading:hover:active {
    color: #1675e0;
    background-color: transparent;
  }
  .rs-btn-link,
  .rs-btn-link:active,
  .rs-btn-link.rs-btn-active {
    background-color: transparent;
  }
  .rs-btn-link,
  .rs-btn-link:hover,
  .rs-btn-link:focus,
  .rs-btn-link:active {
    border-color: transparent;
  }
  .rs-btn-link:hover,
  .rs-btn-link:focus {
    color: #0a5dc2;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-btn-link:active,
  .rs-btn-link.rs-btn-active {
    color: #004299;
  }
  .rs-btn-link:active:hover,
  .rs-btn-link.rs-btn-active:hover,
  .rs-btn-link:active:focus,
  .rs-btn-link.rs-btn-active:focus,
  .rs-btn-link:active.focus,
  .rs-btn-link.rs-btn-active.focus {
    color: #004299;
  }
  .rs-btn-link.rs-btn-disabled:hover,
  .rs-btn-link.rs-btn-disabled:focus {
    color: #1675e0;
    text-decoration: none;
  }
  .rs-btn-link.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-loading:active,
  .rs-btn-link.rs-btn-loading:hover:active {
    color: #3498ff;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-red {
    color: #bf2008;
    font-weight: normal;
    border-radius: 0;
  }
  .rs-btn-link.rs-btn-red.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-red.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-red.rs-btn-loading:active,
  .rs-btn-link.rs-btn-red.rs-btn-loading:hover:active {
    color: #bf2008;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-red,
  .rs-btn-link.rs-btn-red:active,
  .rs-btn-link.rs-btn-red.rs-btn-active {
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-red,
  .rs-btn-link.rs-btn-red:hover,
  .rs-btn-link.rs-btn-red:focus,
  .rs-btn-link.rs-btn-red:active {
    border-color: transparent;
  }
  .rs-btn-link.rs-btn-red:hover,
  .rs-btn-link.rs-btn-red:focus {
    color: #a81b02;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-red:active,
  .rs-btn-link.rs-btn-red.rs-btn-active {
    color: #8a1700;
  }
  .rs-btn-link.rs-btn-red:active:hover,
  .rs-btn-link.rs-btn-red.rs-btn-active:hover,
  .rs-btn-link.rs-btn-red:active:focus,
  .rs-btn-link.rs-btn-red.rs-btn-active:focus,
  .rs-btn-link.rs-btn-red:active.focus,
  .rs-btn-link.rs-btn-red.rs-btn-active.focus {
    color: #8a1700;
  }
  .rs-btn-link.rs-btn-red.rs-btn-disabled:hover,
  .rs-btn-link.rs-btn-red.rs-btn-disabled:focus {
    color: #bf2008;
    text-decoration: none;
  }
  .rs-btn-link.rs-btn-orange {
    color: #c98600;
    font-weight: normal;
    border-radius: 0;
  }
  .rs-btn-link.rs-btn-orange.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-orange.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-orange.rs-btn-loading:active,
  .rs-btn-link.rs-btn-orange.rs-btn-loading:hover:active {
    color: #c98600;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-orange,
  .rs-btn-link.rs-btn-orange:active,
  .rs-btn-link.rs-btn-orange.rs-btn-active {
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-orange,
  .rs-btn-link.rs-btn-orange:hover,
  .rs-btn-link.rs-btn-orange:focus,
  .rs-btn-link.rs-btn-orange:active {
    border-color: transparent;
  }
  .rs-btn-link.rs-btn-orange:hover,
  .rs-btn-link.rs-btn-orange:focus {
    color: #b37a00;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-orange:active,
  .rs-btn-link.rs-btn-orange.rs-btn-active {
    color: #946800;
  }
  .rs-btn-link.rs-btn-orange:active:hover,
  .rs-btn-link.rs-btn-orange.rs-btn-active:hover,
  .rs-btn-link.rs-btn-orange:active:focus,
  .rs-btn-link.rs-btn-orange.rs-btn-active:focus,
  .rs-btn-link.rs-btn-orange:active.focus,
  .rs-btn-link.rs-btn-orange.rs-btn-active.focus {
    color: #946800;
  }
  .rs-btn-link.rs-btn-orange.rs-btn-disabled:hover,
  .rs-btn-link.rs-btn-orange.rs-btn-disabled:focus {
    color: #c98600;
    text-decoration: none;
  }
  .rs-btn-link.rs-btn-yellow {
    color: #c9a608;
    font-weight: normal;
    border-radius: 0;
  }
  .rs-btn-link.rs-btn-yellow.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-yellow.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-yellow.rs-btn-loading:active,
  .rs-btn-link.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #c9a608;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-yellow,
  .rs-btn-link.rs-btn-yellow:active,
  .rs-btn-link.rs-btn-yellow.rs-btn-active {
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-yellow,
  .rs-btn-link.rs-btn-yellow:hover,
  .rs-btn-link.rs-btn-yellow:focus,
  .rs-btn-link.rs-btn-yellow:active {
    border-color: transparent;
  }
  .rs-btn-link.rs-btn-yellow:hover,
  .rs-btn-link.rs-btn-yellow:focus {
    color: #b39504;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-yellow:active,
  .rs-btn-link.rs-btn-yellow.rs-btn-active {
    color: #947e00;
  }
  .rs-btn-link.rs-btn-yellow:active:hover,
  .rs-btn-link.rs-btn-yellow.rs-btn-active:hover,
  .rs-btn-link.rs-btn-yellow:active:focus,
  .rs-btn-link.rs-btn-yellow.rs-btn-active:focus,
  .rs-btn-link.rs-btn-yellow:active.focus,
  .rs-btn-link.rs-btn-yellow.rs-btn-active.focus {
    color: #947e00;
  }
  .rs-btn-link.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-btn-link.rs-btn-yellow.rs-btn-disabled:focus {
    color: #c9a608;
    text-decoration: none;
  }
  .rs-btn-link.rs-btn-green {
    color: #10911d;
    font-weight: normal;
    border-radius: 0;
  }
  .rs-btn-link.rs-btn-green.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-green.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-green.rs-btn-loading:active,
  .rs-btn-link.rs-btn-green.rs-btn-loading:hover:active {
    color: #10911d;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-green,
  .rs-btn-link.rs-btn-green:active,
  .rs-btn-link.rs-btn-green.rs-btn-active {
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-green,
  .rs-btn-link.rs-btn-green:hover,
  .rs-btn-link.rs-btn-green:focus,
  .rs-btn-link.rs-btn-green:active {
    border-color: transparent;
  }
  .rs-btn-link.rs-btn-green:hover,
  .rs-btn-link.rs-btn-green:focus {
    color: #088216;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-green:active,
  .rs-btn-link.rs-btn-green.rs-btn-active {
    color: #016e10;
  }
  .rs-btn-link.rs-btn-green:active:hover,
  .rs-btn-link.rs-btn-green.rs-btn-active:hover,
  .rs-btn-link.rs-btn-green:active:focus,
  .rs-btn-link.rs-btn-green.rs-btn-active:focus,
  .rs-btn-link.rs-btn-green:active.focus,
  .rs-btn-link.rs-btn-green.rs-btn-active.focus {
    color: #016e10;
  }
  .rs-btn-link.rs-btn-green.rs-btn-disabled:hover,
  .rs-btn-link.rs-btn-green.rs-btn-disabled:focus {
    color: #10911d;
    text-decoration: none;
  }
  .rs-btn-link.rs-btn-cyan {
    color: #0087a6;
    font-weight: normal;
    border-radius: 0;
  }
  .rs-btn-link.rs-btn-cyan.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-cyan.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-cyan.rs-btn-loading:active,
  .rs-btn-link.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #0087a6;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-cyan,
  .rs-btn-link.rs-btn-cyan:active,
  .rs-btn-link.rs-btn-cyan.rs-btn-active {
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-cyan,
  .rs-btn-link.rs-btn-cyan:hover,
  .rs-btn-link.rs-btn-cyan:focus,
  .rs-btn-link.rs-btn-cyan:active {
    border-color: transparent;
  }
  .rs-btn-link.rs-btn-cyan:hover,
  .rs-btn-link.rs-btn-cyan:focus {
    color: #00728f;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-cyan:active,
  .rs-btn-link.rs-btn-cyan.rs-btn-active {
    color: #005870;
  }
  .rs-btn-link.rs-btn-cyan:active:hover,
  .rs-btn-link.rs-btn-cyan.rs-btn-active:hover,
  .rs-btn-link.rs-btn-cyan:active:focus,
  .rs-btn-link.rs-btn-cyan.rs-btn-active:focus,
  .rs-btn-link.rs-btn-cyan:active.focus,
  .rs-btn-link.rs-btn-cyan.rs-btn-active.focus {
    color: #005870;
  }
  .rs-btn-link.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-btn-link.rs-btn-cyan.rs-btn-disabled:focus {
    color: #0087a6;
    text-decoration: none;
  }
  .rs-btn-link.rs-btn-blue {
    color: #045dbd;
    font-weight: normal;
    border-radius: 0;
  }
  .rs-btn-link.rs-btn-blue.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-blue.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-blue.rs-btn-loading:active,
  .rs-btn-link.rs-btn-blue.rs-btn-loading:hover:active {
    color: #045dbd;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-blue,
  .rs-btn-link.rs-btn-blue:active,
  .rs-btn-link.rs-btn-blue.rs-btn-active {
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-blue,
  .rs-btn-link.rs-btn-blue:hover,
  .rs-btn-link.rs-btn-blue:focus,
  .rs-btn-link.rs-btn-blue:active {
    border-color: transparent;
  }
  .rs-btn-link.rs-btn-blue:hover,
  .rs-btn-link.rs-btn-blue:focus {
    color: #004da6;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-blue:active,
  .rs-btn-link.rs-btn-blue.rs-btn-active {
    color: #003d87;
  }
  .rs-btn-link.rs-btn-blue:active:hover,
  .rs-btn-link.rs-btn-blue.rs-btn-active:hover,
  .rs-btn-link.rs-btn-blue:active:focus,
  .rs-btn-link.rs-btn-blue.rs-btn-active:focus,
  .rs-btn-link.rs-btn-blue:active.focus,
  .rs-btn-link.rs-btn-blue.rs-btn-active.focus {
    color: #003d87;
  }
  .rs-btn-link.rs-btn-blue.rs-btn-disabled:hover,
  .rs-btn-link.rs-btn-blue.rs-btn-disabled:focus {
    color: #045dbd;
    text-decoration: none;
  }
  .rs-btn-link.rs-btn-violet {
    color: #490c99;
    font-weight: normal;
    border-radius: 0;
  }
  .rs-btn-link.rs-btn-violet.rs-btn-loading:hover,
  .rs-btn-link.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-link.rs-btn-violet.rs-btn-loading:focus,
  .rs-btn-link.rs-btn-violet.rs-btn-loading:active,
  .rs-btn-link.rs-btn-violet.rs-btn-loading:hover:active {
    color: #490c99;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-violet,
  .rs-btn-link.rs-btn-violet:active,
  .rs-btn-link.rs-btn-violet.rs-btn-active {
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-violet,
  .rs-btn-link.rs-btn-violet:hover,
  .rs-btn-link.rs-btn-violet:focus,
  .rs-btn-link.rs-btn-violet:active {
    border-color: transparent;
  }
  .rs-btn-link.rs-btn-violet:hover,
  .rs-btn-link.rs-btn-violet:focus {
    color: #41068a;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-btn-link.rs-btn-violet:active,
  .rs-btn-link.rs-btn-violet.rs-btn-active {
    color: #370075;
  }
  .rs-btn-link.rs-btn-violet:active:hover,
  .rs-btn-link.rs-btn-violet.rs-btn-active:hover,
  .rs-btn-link.rs-btn-violet:active:focus,
  .rs-btn-link.rs-btn-violet.rs-btn-active:focus,
  .rs-btn-link.rs-btn-violet:active.focus,
  .rs-btn-link.rs-btn-violet.rs-btn-active.focus {
    color: #370075;
  }
  .rs-btn-link.rs-btn-violet.rs-btn-disabled:hover,
  .rs-btn-link.rs-btn-violet.rs-btn-disabled:focus {
    color: #490c99;
    text-decoration: none;
  }
  .rs-btn-subtle {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle:focus,
  .rs-btn-subtle.rs-btn-focus,
  .rs-btn-subtle:hover {
    color: #fff;
    background: #f7f7fa;
  }
  .rs-btn-subtle:active,
  .rs-btn-subtle.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle {
    color: #fff;
    background-color: #b2b1f0;
    background-image: none;
  }
  .rs-btn-subtle:active:hover,
  .rs-btn-subtle.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover,
  .rs-btn-subtle:active:focus,
  .rs-btn-subtle.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus,
  .rs-btn-subtle:active.focus,
  .rs-btn-subtle.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
    color: #fff;
    background-color: #b2b1f0;
  }
  .rs-btn-subtle.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-disabled.focus,
  .rs-btn-subtle.rs-btn-disabled:active,
  .rs-btn-subtle.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-subtle.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-disabled:active {
    opacity: 1;
  }
  .rs-btn-subtle:focus,
  .rs-btn-subtle.rs-btn-focus,
  .rs-btn-subtle:hover {
    color: #575757;
  }
  .rs-btn-subtle:active,
  .rs-btn-subtle.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle {
    color: #272c36;
    background-color: #e5e5ea;
    background-image: none;
  }
  .rs-btn-subtle:active:hover,
  .rs-btn-subtle.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover,
  .rs-btn-subtle:active:focus,
  .rs-btn-subtle.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus,
  .rs-btn-subtle:active.focus,
  .rs-btn-subtle.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
    color: #272c36;
    background-color: #e5e5ea;
  }
  .rs-btn-subtle.rs-btn-red {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-red.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-red.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-red.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-red.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-red:focus,
  .rs-btn-subtle.rs-btn-red.rs-btn-focus,
  .rs-btn-subtle.rs-btn-red:hover {
    color: #fff;
    background: #f44336;
  }
  .rs-btn-subtle.rs-btn-red:active,
  .rs-btn-subtle.rs-btn-red.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-red {
    color: #fff;
    background-color: #eb3626;
    background-image: none;
  }
  .rs-btn-subtle.rs-btn-red:active:hover,
  .rs-btn-subtle.rs-btn-red.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-red:hover,
  .rs-btn-subtle.rs-btn-red:active:focus,
  .rs-btn-subtle.rs-btn-red.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-red:focus,
  .rs-btn-subtle.rs-btn-red:active.focus,
  .rs-btn-subtle.rs-btn-red.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-red.focus {
    color: #fff;
    background-color: #eb3626;
  }
  .rs-btn-subtle.rs-btn-red.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-red.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-red.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-red.rs-btn-disabled.focus,
  .rs-btn-subtle.rs-btn-red.rs-btn-disabled:active,
  .rs-btn-subtle.rs-btn-red.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-subtle.rs-btn-orange {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-orange.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-orange.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-orange.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-orange.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-orange:focus,
  .rs-btn-subtle.rs-btn-orange.rs-btn-focus,
  .rs-btn-subtle.rs-btn-orange:hover {
    color: #fff;
    background: #ff9800;
  }
  .rs-btn-subtle.rs-btn-orange:active,
  .rs-btn-subtle.rs-btn-orange.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-orange {
    color: #fff;
    background-color: #f59700;
    background-image: none;
  }
  .rs-btn-subtle.rs-btn-orange:active:hover,
  .rs-btn-subtle.rs-btn-orange.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-orange:hover,
  .rs-btn-subtle.rs-btn-orange:active:focus,
  .rs-btn-subtle.rs-btn-orange.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-orange:focus,
  .rs-btn-subtle.rs-btn-orange:active.focus,
  .rs-btn-subtle.rs-btn-orange.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-orange.focus {
    color: #fff;
    background-color: #f59700;
  }
  .rs-btn-subtle.rs-btn-orange.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-orange.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-orange.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-orange.rs-btn-disabled.focus,
  .rs-btn-subtle.rs-btn-orange.rs-btn-disabled:active,
  .rs-btn-subtle.rs-btn-orange.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-subtle.rs-btn-yellow {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-yellow.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-yellow:focus,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-focus,
  .rs-btn-subtle.rs-btn-yellow:hover {
    color: #fff;
    background: #ffca28;
  }
  .rs-btn-subtle.rs-btn-yellow:active,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-yellow {
    color: #fff;
    background-color: #f5c31d;
    background-image: none;
  }
  .rs-btn-subtle.rs-btn-yellow:active:hover,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-yellow:hover,
  .rs-btn-subtle.rs-btn-yellow:active:focus,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-yellow:focus,
  .rs-btn-subtle.rs-btn-yellow:active.focus,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-yellow.focus {
    color: #fff;
    background-color: #f5c31d;
  }
  .rs-btn-subtle.rs-btn-yellow.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-disabled:active,
  .rs-btn-subtle.rs-btn-yellow.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-subtle.rs-btn-green {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-green.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-green.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-green.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-green.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-green:focus,
  .rs-btn-subtle.rs-btn-green.rs-btn-focus,
  .rs-btn-subtle.rs-btn-green:hover {
    color: #fff;
    background: #4caf50;
  }
  .rs-btn-subtle.rs-btn-green:active,
  .rs-btn-subtle.rs-btn-green.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-green {
    color: #fff;
    background-color: #37ab3c;
    background-image: none;
  }
  .rs-btn-subtle.rs-btn-green:active:hover,
  .rs-btn-subtle.rs-btn-green.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-green:hover,
  .rs-btn-subtle.rs-btn-green:active:focus,
  .rs-btn-subtle.rs-btn-green.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-green:focus,
  .rs-btn-subtle.rs-btn-green:active.focus,
  .rs-btn-subtle.rs-btn-green.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-green.focus {
    color: #fff;
    background-color: #37ab3c;
  }
  .rs-btn-subtle.rs-btn-green.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-green.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-green.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-green.rs-btn-disabled.focus,
  .rs-btn-subtle.rs-btn-green.rs-btn-disabled:active,
  .rs-btn-subtle.rs-btn-green.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-subtle.rs-btn-cyan {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-cyan.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-cyan:focus,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-focus,
  .rs-btn-subtle.rs-btn-cyan:hover {
    color: #fff;
    background: #00bcd4;
  }
  .rs-btn-subtle.rs-btn-cyan:active,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-cyan {
    color: #fff;
    background-color: #00b1cc;
    background-image: none;
  }
  .rs-btn-subtle.rs-btn-cyan:active:hover,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-cyan:hover,
  .rs-btn-subtle.rs-btn-cyan:active:focus,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-cyan:focus,
  .rs-btn-subtle.rs-btn-cyan:active.focus,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-cyan.focus {
    color: #fff;
    background-color: #00b1cc;
  }
  .rs-btn-subtle.rs-btn-cyan.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-disabled:active,
  .rs-btn-subtle.rs-btn-cyan.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-subtle.rs-btn-blue {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-blue.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-blue.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-blue.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-blue.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-blue:focus,
  .rs-btn-subtle.rs-btn-blue.rs-btn-focus,
  .rs-btn-subtle.rs-btn-blue:hover {
    color: #fff;
    background: #2196f3;
  }
  .rs-btn-subtle.rs-btn-blue:active,
  .rs-btn-subtle.rs-btn-blue.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-blue {
    color: #fff;
    background-color: #1787e8;
    background-image: none;
  }
  .rs-btn-subtle.rs-btn-blue:active:hover,
  .rs-btn-subtle.rs-btn-blue.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-blue:hover,
  .rs-btn-subtle.rs-btn-blue:active:focus,
  .rs-btn-subtle.rs-btn-blue.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-blue:focus,
  .rs-btn-subtle.rs-btn-blue:active.focus,
  .rs-btn-subtle.rs-btn-blue.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-blue.focus {
    color: #fff;
    background-color: #1787e8;
  }
  .rs-btn-subtle.rs-btn-blue.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-blue.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-blue.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-blue.rs-btn-disabled.focus,
  .rs-btn-subtle.rs-btn-blue.rs-btn-disabled:active,
  .rs-btn-subtle.rs-btn-blue.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-subtle.rs-btn-violet {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-violet.rs-btn-loading:hover,
  .rs-btn-subtle.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-subtle.rs-btn-violet.rs-btn-loading:focus,
  .rs-btn-subtle.rs-btn-violet.rs-btn-loading:active,
  .rs-btn-subtle.rs-btn-violet.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-btn-subtle.rs-btn-violet:focus,
  .rs-btn-subtle.rs-btn-violet.rs-btn-focus,
  .rs-btn-subtle.rs-btn-violet:hover {
    color: #fff;
    background: #673ab7;
  }
  .rs-btn-subtle.rs-btn-violet:active,
  .rs-btn-subtle.rs-btn-violet.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-violet {
    color: #fff;
    background-color: #5f2bb3;
    background-image: none;
  }
  .rs-btn-subtle.rs-btn-violet:active:hover,
  .rs-btn-subtle.rs-btn-violet.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-violet:hover,
  .rs-btn-subtle.rs-btn-violet:active:focus,
  .rs-btn-subtle.rs-btn-violet.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-violet:focus,
  .rs-btn-subtle.rs-btn-violet:active.focus,
  .rs-btn-subtle.rs-btn-violet.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-subtle.rs-btn-violet.focus {
    color: #fff;
    background-color: #5f2bb3;
  }
  .rs-btn-subtle.rs-btn-violet.rs-btn-disabled,
  .rs-btn-subtle.rs-btn-violet.rs-btn-disabled:hover,
  .rs-btn-subtle.rs-btn-violet.rs-btn-disabled:focus,
  .rs-btn-subtle.rs-btn-violet.rs-btn-disabled.focus,
  .rs-btn-subtle.rs-btn-violet.rs-btn-disabled:active,
  .rs-btn-subtle.rs-btn-violet.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-btn-ghost {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    color: #1675e0;
    border: 1px solid #1675e0;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-loading:hover,
  .rs-btn-ghost.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-ghost.rs-btn-loading:focus,
  .rs-btn-ghost.rs-btn-loading:active,
  .rs-btn-ghost.rs-btn-loading:hover:active {
    color: #1675e0;
    background-color: transparent;
  }
  .rs-btn-ghost:focus,
  .rs-btn-ghost.rs-btn-focus {
    color: #0a5dc2;
    border-color: #0a5dc2;
  }
  .rs-btn-ghost:hover {
    color: #0a5dc2;
    border-color: #0a5dc2;
  }
  .rs-btn-ghost:active,
  .rs-btn-ghost.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost {
    color: #004299;
    border-color: #004299;
    background-image: none;
  }
  .rs-btn-ghost:active:hover,
  .rs-btn-ghost.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost:hover,
  .rs-btn-ghost:active:focus,
  .rs-btn-ghost.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost:focus,
  .rs-btn-ghost:active.rs-btn-focus,
  .rs-btn-ghost.rs-btn-active.rs-btn-focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-focus {
    color: #004299;
    border-color: #004299;
  }
  .rs-btn-ghost.rs-btn-disabled:hover,
  .rs-btn-ghost.rs-btn-disabled:focus,
  .rs-btn-ghost.rs-btn-disabled.focus {
    background: none;
  }
  .rs-btn-ghost.rs-btn-red {
    color: #bf2008;
    border: 1px solid #bf2008;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-red.rs-btn-loading:hover,
  .rs-btn-ghost.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-ghost.rs-btn-red.rs-btn-loading:focus,
  .rs-btn-ghost.rs-btn-red.rs-btn-loading:active,
  .rs-btn-ghost.rs-btn-red.rs-btn-loading:hover:active {
    color: #bf2008;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-red:focus,
  .rs-btn-ghost.rs-btn-red.rs-btn-focus {
    color: #a81b02;
    border-color: #a81b02;
  }
  .rs-btn-ghost.rs-btn-red:hover {
    color: #a81b02;
    border-color: #a81b02;
  }
  .rs-btn-ghost.rs-btn-red:active,
  .rs-btn-ghost.rs-btn-red.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-red {
    color: #8a1700;
    border-color: #8a1700;
    background-image: none;
  }
  .rs-btn-ghost.rs-btn-red:active:hover,
  .rs-btn-ghost.rs-btn-red.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-red:hover,
  .rs-btn-ghost.rs-btn-red:active:focus,
  .rs-btn-ghost.rs-btn-red.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-red:focus,
  .rs-btn-ghost.rs-btn-red:active.rs-btn-focus,
  .rs-btn-ghost.rs-btn-red.rs-btn-active.rs-btn-focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-red.rs-btn-focus {
    color: #8a1700;
    border-color: #8a1700;
  }
  .rs-btn-ghost.rs-btn-red.rs-btn-disabled:hover,
  .rs-btn-ghost.rs-btn-red.rs-btn-disabled:focus,
  .rs-btn-ghost.rs-btn-red.rs-btn-disabled.focus {
    background: none;
  }
  .rs-btn-ghost.rs-btn-orange {
    color: #c98600;
    border: 1px solid #c98600;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-orange.rs-btn-loading:hover,
  .rs-btn-ghost.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-ghost.rs-btn-orange.rs-btn-loading:focus,
  .rs-btn-ghost.rs-btn-orange.rs-btn-loading:active,
  .rs-btn-ghost.rs-btn-orange.rs-btn-loading:hover:active {
    color: #c98600;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-orange:focus,
  .rs-btn-ghost.rs-btn-orange.rs-btn-focus {
    color: #b37a00;
    border-color: #b37a00;
  }
  .rs-btn-ghost.rs-btn-orange:hover {
    color: #b37a00;
    border-color: #b37a00;
  }
  .rs-btn-ghost.rs-btn-orange:active,
  .rs-btn-ghost.rs-btn-orange.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-orange {
    color: #946800;
    border-color: #946800;
    background-image: none;
  }
  .rs-btn-ghost.rs-btn-orange:active:hover,
  .rs-btn-ghost.rs-btn-orange.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-orange:hover,
  .rs-btn-ghost.rs-btn-orange:active:focus,
  .rs-btn-ghost.rs-btn-orange.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-orange:focus,
  .rs-btn-ghost.rs-btn-orange:active.rs-btn-focus,
  .rs-btn-ghost.rs-btn-orange.rs-btn-active.rs-btn-focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-orange.rs-btn-focus {
    color: #946800;
    border-color: #946800;
  }
  .rs-btn-ghost.rs-btn-orange.rs-btn-disabled:hover,
  .rs-btn-ghost.rs-btn-orange.rs-btn-disabled:focus,
  .rs-btn-ghost.rs-btn-orange.rs-btn-disabled.focus {
    background: none;
  }
  .rs-btn-ghost.rs-btn-yellow {
    color: #c9a608;
    border: 1px solid #c9a608;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-yellow.rs-btn-loading:hover,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-loading:focus,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-loading:active,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #c9a608;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-yellow:focus,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-focus {
    color: #b39504;
    border-color: #b39504;
  }
  .rs-btn-ghost.rs-btn-yellow:hover {
    color: #b39504;
    border-color: #b39504;
  }
  .rs-btn-ghost.rs-btn-yellow:active,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-yellow {
    color: #947e00;
    border-color: #947e00;
    background-image: none;
  }
  .rs-btn-ghost.rs-btn-yellow:active:hover,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-yellow:hover,
  .rs-btn-ghost.rs-btn-yellow:active:focus,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-yellow:focus,
  .rs-btn-ghost.rs-btn-yellow:active.rs-btn-focus,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-active.rs-btn-focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-yellow.rs-btn-focus {
    color: #947e00;
    border-color: #947e00;
  }
  .rs-btn-ghost.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-btn-ghost.rs-btn-yellow.rs-btn-disabled.focus {
    background: none;
  }
  .rs-btn-ghost.rs-btn-green {
    color: #10911d;
    border: 1px solid #10911d;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-green.rs-btn-loading:hover,
  .rs-btn-ghost.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-ghost.rs-btn-green.rs-btn-loading:focus,
  .rs-btn-ghost.rs-btn-green.rs-btn-loading:active,
  .rs-btn-ghost.rs-btn-green.rs-btn-loading:hover:active {
    color: #10911d;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-green:focus,
  .rs-btn-ghost.rs-btn-green.rs-btn-focus {
    color: #088216;
    border-color: #088216;
  }
  .rs-btn-ghost.rs-btn-green:hover {
    color: #088216;
    border-color: #088216;
  }
  .rs-btn-ghost.rs-btn-green:active,
  .rs-btn-ghost.rs-btn-green.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-green {
    color: #016e10;
    border-color: #016e10;
    background-image: none;
  }
  .rs-btn-ghost.rs-btn-green:active:hover,
  .rs-btn-ghost.rs-btn-green.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-green:hover,
  .rs-btn-ghost.rs-btn-green:active:focus,
  .rs-btn-ghost.rs-btn-green.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-green:focus,
  .rs-btn-ghost.rs-btn-green:active.rs-btn-focus,
  .rs-btn-ghost.rs-btn-green.rs-btn-active.rs-btn-focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-green.rs-btn-focus {
    color: #016e10;
    border-color: #016e10;
  }
  .rs-btn-ghost.rs-btn-green.rs-btn-disabled:hover,
  .rs-btn-ghost.rs-btn-green.rs-btn-disabled:focus,
  .rs-btn-ghost.rs-btn-green.rs-btn-disabled.focus {
    background: none;
  }
  .rs-btn-ghost.rs-btn-cyan {
    color: #0087a6;
    border: 1px solid #0087a6;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-cyan.rs-btn-loading:hover,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-loading:focus,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-loading:active,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #0087a6;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-cyan:focus,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-focus {
    color: #00728f;
    border-color: #00728f;
  }
  .rs-btn-ghost.rs-btn-cyan:hover {
    color: #00728f;
    border-color: #00728f;
  }
  .rs-btn-ghost.rs-btn-cyan:active,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-cyan {
    color: #005870;
    border-color: #005870;
    background-image: none;
  }
  .rs-btn-ghost.rs-btn-cyan:active:hover,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-cyan:hover,
  .rs-btn-ghost.rs-btn-cyan:active:focus,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-cyan:focus,
  .rs-btn-ghost.rs-btn-cyan:active.rs-btn-focus,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-active.rs-btn-focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-cyan.rs-btn-focus {
    color: #005870;
    border-color: #005870;
  }
  .rs-btn-ghost.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-btn-ghost.rs-btn-cyan.rs-btn-disabled.focus {
    background: none;
  }
  .rs-btn-ghost.rs-btn-blue {
    color: #045dbd;
    border: 1px solid #045dbd;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-blue.rs-btn-loading:hover,
  .rs-btn-ghost.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-ghost.rs-btn-blue.rs-btn-loading:focus,
  .rs-btn-ghost.rs-btn-blue.rs-btn-loading:active,
  .rs-btn-ghost.rs-btn-blue.rs-btn-loading:hover:active {
    color: #045dbd;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-blue:focus,
  .rs-btn-ghost.rs-btn-blue.rs-btn-focus {
    color: #004da6;
    border-color: #004da6;
  }
  .rs-btn-ghost.rs-btn-blue:hover {
    color: #004da6;
    border-color: #004da6;
  }
  .rs-btn-ghost.rs-btn-blue:active,
  .rs-btn-ghost.rs-btn-blue.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-blue {
    color: #003d87;
    border-color: #003d87;
    background-image: none;
  }
  .rs-btn-ghost.rs-btn-blue:active:hover,
  .rs-btn-ghost.rs-btn-blue.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-blue:hover,
  .rs-btn-ghost.rs-btn-blue:active:focus,
  .rs-btn-ghost.rs-btn-blue.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-blue:focus,
  .rs-btn-ghost.rs-btn-blue:active.rs-btn-focus,
  .rs-btn-ghost.rs-btn-blue.rs-btn-active.rs-btn-focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-blue.rs-btn-focus {
    color: #003d87;
    border-color: #003d87;
  }
  .rs-btn-ghost.rs-btn-blue.rs-btn-disabled:hover,
  .rs-btn-ghost.rs-btn-blue.rs-btn-disabled:focus,
  .rs-btn-ghost.rs-btn-blue.rs-btn-disabled.focus {
    background: none;
  }
  .rs-btn-ghost.rs-btn-violet {
    color: #490c99;
    border: 1px solid #490c99;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-violet.rs-btn-loading:hover,
  .rs-btn-ghost.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-btn-ghost.rs-btn-violet.rs-btn-loading:focus,
  .rs-btn-ghost.rs-btn-violet.rs-btn-loading:active,
  .rs-btn-ghost.rs-btn-violet.rs-btn-loading:hover:active {
    color: #490c99;
    background-color: transparent;
  }
  .rs-btn-ghost.rs-btn-violet:focus,
  .rs-btn-ghost.rs-btn-violet.rs-btn-focus {
    color: #41068a;
    border-color: #41068a;
  }
  .rs-btn-ghost.rs-btn-violet:hover {
    color: #41068a;
    border-color: #41068a;
  }
  .rs-btn-ghost.rs-btn-violet:active,
  .rs-btn-ghost.rs-btn-violet.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-violet {
    color: #370075;
    border-color: #370075;
    background-image: none;
  }
  .rs-btn-ghost.rs-btn-violet:active:hover,
  .rs-btn-ghost.rs-btn-violet.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-violet:hover,
  .rs-btn-ghost.rs-btn-violet:active:focus,
  .rs-btn-ghost.rs-btn-violet.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-violet:focus,
  .rs-btn-ghost.rs-btn-violet:active.rs-btn-focus,
  .rs-btn-ghost.rs-btn-violet.rs-btn-active.rs-btn-focus,
  .rs-open > .rs-dropdown-toggle.rs-btn-ghost.rs-btn-violet.rs-btn-focus {
    color: #370075;
    border-color: #370075;
  }
  .rs-btn-ghost.rs-btn-violet.rs-btn-disabled:hover,
  .rs-btn-ghost.rs-btn-violet.rs-btn-disabled:focus,
  .rs-btn-ghost.rs-btn-violet.rs-btn-disabled.focus {
    background: none;
  }
  .rs-btn-lg {
    /* stylelint-disable */
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.375;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-btn-lg.rs-btn-ghost {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .rs-btn-lg.rs-btn-icon {
    padding-left: 26px;
    height: 42px;
    position: relative;
  }
  .rs-btn-ghost.rs-btn-lg.rs-btn-icon {
    padding-left: 24px;
  }
  .rs-btn-lg.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 52px;
  }
  .rs-btn-lg.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 52px;
  }
  .rs-btn-lg.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-btn-lg.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 42px;
    height: 42px;
    padding: 10px 0;
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-btn-ghost.rs-btn-lg.rs-btn-icon > .rs-icon {
    width: 40px;
    height: 40px;
    padding: 9px 0;
  }
  .rs-btn-lg.rs-btn-icon > .rs-icon > svg {
    height: 16px;
    vertical-align: middle;
  }
  .rs-btn-lg.rs-btn-icon-circle {
    width: 42px;
    padding: 10px;
  }
  .rs-btn-sm {
    /* stylelint-disable */
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-btn-sm.rs-btn-ghost {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .rs-btn-sm.rs-btn-icon {
    padding-left: 20px;
    height: 30px;
    position: relative;
  }
  .rs-btn-ghost.rs-btn-sm.rs-btn-icon {
    padding-left: 18px;
  }
  .rs-btn-sm.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 35px;
  }
  .rs-btn-sm.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 35px;
  }
  .rs-btn-sm.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-btn-sm.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 30px;
    height: 30px;
    padding: 5px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-btn-sm.rs-btn-icon > .rs-icon {
    width: 28px;
    height: 28px;
    padding: 4px 0;
  }
  .rs-btn-sm.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-btn-sm.rs-btn-icon-circle {
    width: 30px;
    padding: 5px;
  }
  .rs-btn-xs {
    /* stylelint-disable */
    padding: 2px 8px;
    font-size: 12px;
    line-height: 1.66666667;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-btn-xs.rs-btn-ghost {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .rs-btn-xs.rs-btn-icon {
    padding-left: 16px;
    height: 24px;
    position: relative;
  }
  .rs-btn-ghost.rs-btn-xs.rs-btn-icon {
    padding-left: 14px;
  }
  .rs-btn-xs.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 26px;
  }
  .rs-btn-xs.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 26px;
  }
  .rs-btn-xs.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-btn-xs.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 24px;
    height: 24px;
    padding: 2px 0;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-btn-ghost.rs-btn-xs.rs-btn-icon > .rs-icon {
    width: 22px;
    height: 22px;
    padding: 1px 0;
  }
  .rs-btn-xs.rs-btn-icon > .rs-icon > svg {
    height: 12px;
    vertical-align: middle;
  }
  .rs-btn-xs.rs-btn-icon-circle {
    width: 24px;
    padding: 2px;
  }
  .rs-btn-block {
    display: block;
    width: 100%;
  }
  .rs-btn-block + .rs-btn-block {
    margin-top: 5px;
  }
  .rs-btn-group,
  .rs-btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }
  .rs-btn-group > .rs-btn,
  .rs-btn-group-vertical > .rs-btn {
    position: relative;
    float: left;
  }
  .rs-btn-group > .rs-btn.rs-btn-ghost:hover,
  .rs-btn-group-vertical > .rs-btn.rs-btn-ghost:hover,
  .rs-btn-group > .rs-btn.rs-btn-ghost:focus,
  .rs-btn-group-vertical > .rs-btn.rs-btn-ghost:focus,
  .rs-btn-group > .rs-btn.rs-btn-ghost:active,
  .rs-btn-group-vertical > .rs-btn.rs-btn-ghost:active {
    z-index: 2;
  }
  .rs-btn-group-lg .rs-btn {
    /* stylelint-disable */
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.375;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-btn-group-lg .rs-btn.rs-btn-ghost {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .rs-btn-group-lg .rs-btn.rs-btn-icon {
    padding-left: 26px;
    height: 42px;
    position: relative;
  }
  .rs-btn-ghost.rs-btn-group-lg .rs-btn.rs-btn-icon {
    padding-left: 24px;
  }
  .rs-btn-group-lg .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 52px;
  }
  .rs-btn-group-lg .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 52px;
  }
  .rs-btn-group-lg .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-btn-group-lg .rs-btn.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 42px;
    height: 42px;
    padding: 10px 0;
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-btn-ghost.rs-btn-group-lg .rs-btn.rs-btn-icon > .rs-icon {
    width: 40px;
    height: 40px;
    padding: 9px 0;
  }
  .rs-btn-group-lg .rs-btn.rs-btn-icon > .rs-icon > svg {
    height: 16px;
    vertical-align: middle;
  }
  .rs-btn-group-lg .rs-btn.rs-btn-icon-circle {
    width: 42px;
    padding: 10px;
  }
  .rs-btn-group-sm .rs-btn {
    /* stylelint-disable */
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-btn-group-sm .rs-btn.rs-btn-ghost {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .rs-btn-group-sm .rs-btn.rs-btn-icon {
    padding-left: 20px;
    height: 30px;
    position: relative;
  }
  .rs-btn-ghost.rs-btn-group-sm .rs-btn.rs-btn-icon {
    padding-left: 18px;
  }
  .rs-btn-group-sm .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 35px;
  }
  .rs-btn-group-sm .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 35px;
  }
  .rs-btn-group-sm .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-btn-group-sm .rs-btn.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 30px;
    height: 30px;
    padding: 5px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-btn-group-sm .rs-btn.rs-btn-icon > .rs-icon {
    width: 28px;
    height: 28px;
    padding: 4px 0;
  }
  .rs-btn-group-sm .rs-btn.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-btn-group-sm .rs-btn.rs-btn-icon-circle {
    width: 30px;
    padding: 5px;
  }
  .rs-btn-group-xs .rs-btn {
    /* stylelint-disable */
    padding: 2px 8px;
    font-size: 12px;
    line-height: 1.66666667;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-btn-group-xs .rs-btn.rs-btn-ghost {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .rs-btn-group-xs .rs-btn.rs-btn-icon {
    padding-left: 16px;
    height: 24px;
    position: relative;
  }
  .rs-btn-ghost.rs-btn-group-xs .rs-btn.rs-btn-icon {
    padding-left: 14px;
  }
  .rs-btn-group-xs .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 26px;
  }
  .rs-btn-group-xs .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 26px;
  }
  .rs-btn-group-xs .rs-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-btn-group-xs .rs-btn.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 24px;
    height: 24px;
    padding: 2px 0;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-btn-ghost.rs-btn-group-xs .rs-btn.rs-btn-icon > .rs-icon {
    width: 22px;
    height: 22px;
    padding: 1px 0;
  }
  .rs-btn-group-xs .rs-btn.rs-btn-icon > .rs-icon > svg {
    height: 12px;
    vertical-align: middle;
  }
  .rs-btn-group-xs .rs-btn.rs-btn-icon-circle {
    width: 24px;
    padding: 2px;
  }
  .rs-btn-group > .rs-btn,
  .rs-btn-group > .rs-dropdown > .rs-dropdown-toggle > .rs-btn {
    border-radius: 0;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:first-child,
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-dropdown:first-child > .rs-dropdown-toggle > .rs-btn {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:last-child,
  .rs-btn-group:not(.rs-btn-group-vertical) > .rs-dropdown:last-child > .rs-dropdown-toggle > .rs-btn {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-btn-group.rs-btn-group-vertical > .rs-btn:first-child,
  .rs-btn-group.rs-btn-group-vertical > .rs-dropdown:first-child > .rs-dropdown-toggle > .rs-btn {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }
  .rs-btn-group.rs-btn-group-vertical > .rs-btn:last-child,
  .rs-btn-group.rs-btn-group-vertical > .rs-dropdown:last-child > .rs-dropdown-toggle > .rs-btn {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
  .rs-btn-group.rs-btn-group-vertical > .rs-dropdown,
  .rs-btn-group.rs-btn-group-vertical > .rs-dropdown-dropup {
    display: block;
  }
  .rs-btn-group .rs-btn + .rs-btn,
  .rs-btn-group .rs-btn + .rs-btn-group,
  .rs-btn-group .rs-btn-group + .rs-btn,
  .rs-btn-group .rs-btn-group + .rs-btn-group {
    margin-left: -1px;
  }
  .rs-btn-group > .rs-btn-group {
    float: left;
  }
  .rs-btn-group > .rs-btn-group:not(:first-child):not(:last-child) > .rs-btn {
    border-radius: 0;
  }
  .rs-btn-group > .rs-btn-group:first-child:not(:last-child) > .rs-btn:last-child,
  .rs-btn-group > .rs-btn-group:first-child:not(:last-child) > .rs-dropdown-toggle {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .rs-btn-group > .rs-btn-group:last-child:not(:first-child) > .rs-btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  .rs-btn-group .dropdown-toggle:active,
  .rs-btn-group.open .dropdown-toggle {
    outline: 0;
  }
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn {
    z-index: 15;
  }
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn:active,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn:focus,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn:hover {
    background: white;
    padding-bottom: 9px;
  }
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn:hover {
    padding-bottom: 9px;
  }
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-lg,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-lg:active,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-lg:focus,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-lg:hover {
    padding-bottom: 11px;
  }
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-sm,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-sm:active,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-sm:focus,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-sm:hover {
    padding-bottom: 6px;
  }
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-xs,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-xs:active,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-xs:focus,
  .rs-btn-group.rs-open .rs-dropdown-toggle.rs-btn.rs-btn-xs:hover {
    padding-bottom: 3px;
  }
  .rs-btn-group.rs-open .rs-dropdown-menu {
    z-index: 10;
  }
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn {
    z-index: 15;
  }
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn:active,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn:focus,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn:hover {
    background: white;
    padding-top: 9px;
    padding-bottom: 8px;
  }
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn:hover {
    padding-top: 9px;
    padding-bottom: 8px;
  }
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.btn-lg,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.btn-lg:active,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.btn-lg:focus,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.btn-lg:hover {
    padding-top: 11px;
    padding-bottom: 10px;
  }
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.rs-btn-sm,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.rs-btn-sm:active,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.rs-btn-sm:focus,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.rs-btn-sm:hover {
    padding-top: 6px;
    padding-bottom: 5px;
  }
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.rs-btn-xs,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.rs-btn-xs:active,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.rs-btn-xs:focus,
  .rs-btn-group.rs-open.rs-dropup .rs-dropdown-toggle.rs-btn.rs-btn-xs:hover {
    padding-top: 3px;
    padding-bottom: 2px;
  }
  .rs-btn-group > .rs-btn + .rs-dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px;
  }
  .rs-btn-group > .rs-btn-lg + .rs-dropdown-toggle {
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-btn .rs-caret {
    margin-left: 0;
  }
  .rs-btn-group-vertical > .rs-btn,
  .rs-btn-group-vertical > .rs-btn-group,
  .rs-btn-group-vertical > .rs-btn-group > .rs-btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
  }
  .rs-btn-group-vertical > .rs-btn-group::before,
  .rs-btn-group-vertical > .rs-btn-group::after {
    content: ' ';
    display: table;
  }
  .rs-btn-group-vertical > .rs-btn-group::after {
    clear: both;
  }
  .rs-btn-group-vertical > .rs-btn-group::before,
  .rs-btn-group-vertical > .rs-btn-group::after {
    content: ' ';
    display: table;
  }
  .rs-btn-group-vertical > .rs-btn-group::after {
    clear: both;
  }
  .rs-btn-group-vertical > .rs-btn-group > .rs-btn {
    float: none;
  }
  .rs-btn-group-vertical > .rs-btn + .rs-btn,
  .rs-btn-group-vertical > .rs-btn + .rs-btn-group,
  .rs-btn-group-vertical > .rs-btn-group + .rs-btn,
  .rs-btn-group-vertical > .rs-btn-group + .rs-btn-group {
    margin-top: -1px;
    margin-left: 0;
  }
  .rs-btn-group-justified {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
  }
  .rs-btn-group-justified > .rs-btn,
  .rs-btn-group-justified > .rs-btn-group {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 1%;
            flex: 1 1 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-btn-group-justified > .rs-btn-group .rs-btn {
    width: 100%;
  }
  .rs-btn-group-justified > .rs-btn-group .rs-dropdown-menu {
    left: auto;
  }
  .rs-btn-group-justified > .rs-btn-ghost {
    border-left-width: 0;
    border-right-width: 0;
    position: relative;
  }
  .rs-btn-group-justified > .rs-btn-ghost::before,
  .rs-btn-group-justified > .rs-btn-ghost::after {
    background-color: #3498ff;
  }
  .rs-btn-group-justified > .rs-btn-ghost:focus::before,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-focus::before,
  .rs-btn-group-justified > .rs-btn-ghost:focus::after,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-focus::after {
    background-color: #2589f5;
  }
  .rs-btn-group-justified > .rs-btn-ghost:hover::before,
  .rs-btn-group-justified > .rs-btn-ghost:hover::after {
    background-color: #2589f5;
  }
  .rs-btn-group-justified > .rs-btn-ghost:active::before,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-active::before,
  .rs-btn-group-justified > .rs-btn-ghost:active::after,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-active::after {
    background-color: #1675e0;
  }
  .rs-btn-group-justified > .rs-btn-ghost:active:hover::before,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-active:hover::before,
  .rs-btn-group-justified > .rs-btn-ghost:active:focus::before,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-active:focus::before,
  .rs-btn-group-justified > .rs-btn-ghost:active.rs-btn-focus::before,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-active.rs-btn-focus::before,
  .rs-btn-group-justified > .rs-btn-ghost:active:hover::after,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-active:hover::after,
  .rs-btn-group-justified > .rs-btn-ghost:active:focus::after,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-active:focus::after,
  .rs-btn-group-justified > .rs-btn-ghost:active.rs-btn-focus::after,
  .rs-btn-group-justified > .rs-btn-ghost.rs-btn-active.rs-btn-focus::after {
    background-color: #1675e0;
  }
  .rs-btn-group-justified > .rs-btn-ghost::before,
  .rs-btn-group-justified > .rs-btn-ghost::after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    background-color: #3498ff;
    top: -1px;
    bottom: -1px;
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
  }
  .rs-btn-group-justified > .rs-btn-ghost::before {
    left: 0;
  }
  .rs-btn-group-justified > .rs-btn-ghost::after {
    right: 0;
  }
  .rs-btn-group-justified > .rs-btn-ghost:first-child {
    border-left-width: 1px;
  }
  .rs-btn-group-justified > .rs-btn-ghost:first-child::before {
    display: none;
  }
  .rs-btn-group-justified > .rs-btn-ghost:last-child {
    border-right-width: 1px;
  }
  .rs-btn-group-justified > .rs-btn-ghost:last-child::after {
    display: none;
  }
  .rs-btn-group-primary.btn-group .btn.disabled:hover {
    background-color: #3498ff;
  }
  .rs-btn-group-primary.btn-group .btn:hover {
    background-color: #1a8bff;
  }
  .rs-btn-group-primary.btn-group .dropdown-menu > li > a:focus,
  .rs-btn-group-primary.btn-group .dropdown-menu > li > a:hover {
    background: #f2faff;
  }
  .rs-btn-group-primary.btn-group .dropdown-menu > .active > a,
  .rs-btn-group-primary.btn-group .dropdown-menu > .active > a:focus,
  .rs-btn-group-primary.btn-group .dropdown-menu > .active > a:hover {
    background-color: #017eff;
  }
  .rs-btn-group-primary.btn-group.open .dropdown-toggle.btn,
  .rs-btn-group-primary.btn-group.open .dropdown-toggle.btn:active,
  .rs-btn-group-primary.btn-group.open .dropdown-toggle.btn:focus,
  .rs-btn-group-primary.btn-group.open .dropdown-toggle.btn:hover {
    color: #017eff;
  }
  .rs-btn-group-link.btn-group .btn.disabled:hover {
    background-color: #3498ff;
  }
  .rs-btn-group-link.btn-group .btn:hover {
    background-color: #1a8bff;
  }
  .rs-btn-group-link.btn-group .dropdown-menu > li > a:focus,
  .rs-btn-group-link.btn-group .dropdown-menu > li > a:hover {
    background: #f2faff;
  }
  .rs-btn-group-link.btn-group .dropdown-menu > .active > a,
  .rs-btn-group-link.btn-group .dropdown-menu > .active > a:focus,
  .rs-btn-group-link.btn-group .dropdown-menu > .active > a:hover {
    background-color: #017eff;
  }
  .rs-btn-group-link.btn-group.open .dropdown-toggle.btn,
  .rs-btn-group-link.btn-group.open .dropdown-toggle.btn:active,
  .rs-btn-group-link.btn-group.open .dropdown-toggle.btn:focus,
  .rs-btn-group-link.btn-group.open .dropdown-toggle.btn:hover {
    color: #017eff;
  }
  .rs-btn-group-link.rs-btn-group .rs-btn.disabled:hover {
    background-color: white;
    color: #f2faff;
  }
  .rs-btn-group-link.rs-btn-group .rs-btn-link:focus,
  .rs-btn-group-link.rs-btn-group .rs-btn-link:hover {
    color: white;
  }
  .rs-btn-toolbar {
    line-height: 0;
  }
  .rs-btn-toolbar > :not(:first-child):not(.rs-btn-block) {
    margin-left: 5px;
  }
  .rs-calendar {
    background-color: #fff;
    padding-top: 12px;
    position: relative;
    min-height: 266px;
    overflow: hidden;
  }
  .rs-calendar-bordered .rs-calendar-table {
    border: 1px solid #f2f2f5;
    border-radius: 6px;
  }
  .rs-calendar-bordered .rs-calendar-table-row:not(:last-child) .rs-calendar-table-cell,
  .rs-calendar-bordered .rs-calendar-table-header-row .rs-calendar-table-cell {
    border-bottom: 1px solid #f2f2f5;
  }
  .rs-calendar-bordered .rs-calendar-month-dropdown {
    border: 1px solid #e5e5ea;
    border-radius: 6px;
  }
  .rs-calendar-panel .rs-calendar-header {
    width: 100%;
    padding-bottom: 6px;
  }
  .rs-calendar-panel .rs-calendar-header::before,
  .rs-calendar-panel .rs-calendar-header::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-panel .rs-calendar-header::after {
    clear: both;
  }
  .rs-calendar-panel .rs-calendar-header::before,
  .rs-calendar-panel .rs-calendar-header::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-panel .rs-calendar-header::after {
    clear: both;
  }
  .rs-calendar-panel .rs-calendar-header .rs-calendar-btn-today {
    float: right;
  }
  .rs-calendar-panel .rs-calendar-header-forward,
  .rs-calendar-panel .rs-calendar-header-title,
  .rs-calendar-panel .rs-calendar-header-backward {
    /* stylelint-disable */
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-calendar-panel .rs-calendar-header-forward.rs-btn-ghost,
  .rs-calendar-panel .rs-calendar-header-title.rs-btn-ghost,
  .rs-calendar-panel .rs-calendar-header-backward.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon,
  .rs-calendar-panel .rs-calendar-header-title.rs-btn-icon,
  .rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon,
  .rs-btn-ghost.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon,
  .rs-btn-ghost.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
  .rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
  .rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
  .rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
  .rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
  .rs-calendar-panel .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
  .rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon,
  .rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon,
  .rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon,
  .rs-btn-ghost.rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon,
  .rs-btn-ghost.rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon > .rs-icon > svg,
  .rs-calendar-panel .rs-calendar-header-title.rs-btn-icon > .rs-icon > svg,
  .rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-calendar-panel .rs-calendar-header-forward.rs-btn-icon-circle,
  .rs-calendar-panel .rs-calendar-header-title.rs-btn-icon-circle,
  .rs-calendar-panel .rs-calendar-header-backward.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-calendar-panel .rs-calendar-header-forward,
  .rs-calendar-panel .rs-calendar-header-backward {
    width: 31px;
    float: none !important;
  }
  .rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-forward,
  .rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-backward {
    display: inline-block;
    visibility: hidden;
    pointer-events: none;
  }
  .rs-calendar-panel .rs-calendar-header-month-toolbar {
    width: auto !important;
  }
  .rs-calendar-panel.rs-calendar-show-month-dropdown .rs-calendar-header-month-toolbar {
    padding-left: 0;
    padding-right: 0;
  }
  .rs-calendar-panel .rs-calendar-show-month-dropdown {
    padding-left: 0;
    padding-right: 0;
  }
  .rs-calendar-panel .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    color: #fff;
    width: 20px;
    height: 20px;
    background-color: #3498ff;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .rs-calendar-panel .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border-color: transparent;
  }
  .rs-calendar-panel .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: transparent;
    color: inherit;
    border: 1px solid #3498ff;
    padding: 4px;
  }
  .rs-calendar-panel .rs-calendar-table-cell-day {
    margin-top: 3px;
    display: block;
    text-align: center;
  }
  .rs-calendar-panel .rs-calendar-month-dropdown {
    margin-left: 12px;
    margin-right: 12px;
    top: 54px;
    width: calc(100% - 24px);
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-month-dropdown-scroll {
    height: 644px;
  }
  .rs-calendar-panel.rs-calendar-compact .rs-calendar-month-dropdown-scroll {
    height: 344px;
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    width: 100%;
    height: 100px;
    overflow: hidden;
  }
  .rs-calendar-panel:not(.rs-calendar-compact) .rs-calendar-table-cell {
    vertical-align: top;
  }
  .rs-calendar-panel .rs-calendar-table-cell {
    padding: 0 1px;
    line-height: 0;
  }
  .rs-calendar-panel .rs-calendar-table-cell-content {
    width: 100%;
    border-radius: 0;
  }
  .rs-calendar-panel .rs-calendar-table-row:last-child :first-child .rs-calendar-table-cell-content {
    border-bottom-left-radius: 6px;
  }
  .rs-calendar-panel .rs-calendar-table-row:last-child :last-child .rs-calendar-table-cell-content {
    border-bottom-right-radius: 6px;
  }
  .rs-calendar-panel.rs-calendar-compact .rs-calendar-table-row:not(.rs-calendar-table-header-row) .rs-calendar-table-cell-content {
    height: 50px;
  }
  .rs-calendar-show-month-dropdown .rs-calendar-header-month-toolbar {
    padding-left: 24px;
    padding-right: 24px;
  }
  .rs-calendar-show-month-dropdown .rs-calendar-header-backward,
  .rs-calendar-show-month-dropdown .rs-calendar-header-forward {
    display: none;
  }
  .rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
    color: #1675e0;
    background: transparent;
  }
  .rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error {
    color: #f44336;
  }
  .rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:focus,
  .rs-calendar-show-month-dropdown .rs-calendar-header-title-date.rs-calendar-header-error:hover:active {
    color: #fff !important;
  }
  .rs-calendar-show-time-dropdown .rs-calendar-header-title-time {
    color: #1675e0;
    background: transparent;
  }
  .rs-calendar-show-time-dropdown .rs-calendar-header-title-time.rs-calendar-header-error {
    color: #f44336;
  }
  .rs-calendar-show-time-dropdown .rs-calendar-header-title-time.rs-calendar-header-error:hover:active {
    color: #fff;
  }
  .rs-calendar-table-cell-selected,
  .rs-calendar-table-cell-in-range {
    position: relative;
  }
  .rs-calendar-table-cell-selected::before,
  .rs-calendar-table-cell-in-range::before {
    content: '';
    display: block;
    width: 100%;
    margin-top: 3px;
    height: 24px;
    position: absolute;
    z-index: 0;
    top: 0;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
    z-index: 1;
    position: relative;
  }
  .rs-calendar-table-cell-in-range::before {
    background-color: rgba(204, 233, 255, 0.5);
  }
  .rs-calendar-month-dropdown-scroll div:focus {
    outline: none;
  }
  .rs-calendar-header {
    width: 280px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-calendar-header::before,
  .rs-calendar-header::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-header::after {
    clear: both;
  }
  .rs-calendar-header::before,
  .rs-calendar-header::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-header::after {
    clear: both;
  }
  .rs-calendar-header-month-toolbar,
  .rs-calendar-header-time-toolbar {
    display: inline-block;
  }
  .rs-calendar-header-month-toolbar {
    float: left;
  }
  .rs-calendar-header-time-toolbar {
    float: right;
  }
  .rs-calendar-header-title,
  .rs-calendar-header-meridian,
  .rs-calendar-header-backward,
  .rs-calendar-header-forward {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* stylelint-disable */
    padding: 8px 12px;
    /* stylelint-enable */
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    color: #8e8e93;
    background-color: transparent;
    /* stylelint-disable */
    padding: 2px 8px;
    font-size: 12px;
    line-height: 1.66666667;
    border-radius: 6px;
    /* stylelint-enable */
    padding: 2px 2px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-calendar-header-title.rs-btn-ghost,
  .rs-calendar-header-meridian.rs-btn-ghost,
  .rs-calendar-header-backward.rs-btn-ghost,
  .rs-calendar-header-forward.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-calendar-header-title.rs-btn-icon,
  .rs-calendar-header-meridian.rs-btn-icon,
  .rs-calendar-header-backward.rs-btn-icon,
  .rs-calendar-header-forward.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-calendar-header-title.rs-btn-icon,
  .rs-btn-ghost.rs-calendar-header-meridian.rs-btn-icon,
  .rs-btn-ghost.rs-calendar-header-backward.rs-btn-icon,
  .rs-btn-ghost.rs-calendar-header-forward.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
  .rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
  .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
  .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
  .rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
  .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
  .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-calendar-header-title.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
  .rs-calendar-header-meridian.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
  .rs-calendar-header-backward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
  .rs-calendar-header-forward.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-calendar-header-title.rs-btn-icon > .rs-icon,
  .rs-calendar-header-meridian.rs-btn-icon > .rs-icon,
  .rs-calendar-header-backward.rs-btn-icon > .rs-icon,
  .rs-calendar-header-forward.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-calendar-header-title.rs-btn-icon > .rs-icon,
  .rs-btn-ghost.rs-calendar-header-meridian.rs-btn-icon > .rs-icon,
  .rs-btn-ghost.rs-calendar-header-backward.rs-btn-icon > .rs-icon,
  .rs-btn-ghost.rs-calendar-header-forward.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-calendar-header-title.rs-btn-icon > .rs-icon > svg,
  .rs-calendar-header-meridian.rs-btn-icon > .rs-icon > svg,
  .rs-calendar-header-backward.rs-btn-icon > .rs-icon > svg,
  .rs-calendar-header-forward.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-calendar-header-title.rs-btn-icon-circle,
  .rs-calendar-header-meridian.rs-btn-icon-circle,
  .rs-calendar-header-backward.rs-btn-icon-circle,
  .rs-calendar-header-forward.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-calendar-header-title:hover,
  .rs-calendar-header-meridian:hover,
  .rs-calendar-header-backward:hover,
  .rs-calendar-header-forward:hover,
  .rs-calendar-header-title:focus,
  .rs-calendar-header-meridian:focus,
  .rs-calendar-header-backward:focus,
  .rs-calendar-header-forward:focus {
    color: #575757;
    text-decoration: none;
  }
  .rs-calendar-header-title:active,
  .rs-calendar-header-meridian:active,
  .rs-calendar-header-backward:active,
  .rs-calendar-header-forward:active,
  .rs-calendar-header-title.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-active {
    outline: 0;
    background-image: none;
  }
  .rs-calendar-header-title.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title:focus,
  .rs-calendar-header-meridian:focus,
  .rs-calendar-header-backward:focus,
  .rs-calendar-header-forward:focus,
  .rs-calendar-header-title.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-focus,
  .rs-calendar-header-title:hover,
  .rs-calendar-header-meridian:hover,
  .rs-calendar-header-backward:hover,
  .rs-calendar-header-forward:hover {
    color: #fff;
    background: #f7f7fa;
  }
  .rs-calendar-header-title:active,
  .rs-calendar-header-meridian:active,
  .rs-calendar-header-backward:active,
  .rs-calendar-header-forward:active,
  .rs-calendar-header-title.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward {
    color: #fff;
    background-color: #b2b1f0;
    background-image: none;
  }
  .rs-calendar-header-title:active:hover,
  .rs-calendar-header-meridian:active:hover,
  .rs-calendar-header-backward:active:hover,
  .rs-calendar-header-forward:active:hover,
  .rs-calendar-header-title.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:hover,
  .rs-calendar-header-title:active:focus,
  .rs-calendar-header-meridian:active:focus,
  .rs-calendar-header-backward:active:focus,
  .rs-calendar-header-forward:active:focus,
  .rs-calendar-header-title.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:focus,
  .rs-calendar-header-title:active.focus,
  .rs-calendar-header-meridian:active.focus,
  .rs-calendar-header-backward:active.focus,
  .rs-calendar-header-forward:active.focus,
  .rs-calendar-header-title.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.focus {
    color: #fff;
    background-color: #b2b1f0;
  }
  .rs-calendar-header-title.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-disabled.focus,
  .rs-calendar-header-meridian.rs-btn-disabled.focus,
  .rs-calendar-header-backward.rs-btn-disabled.focus,
  .rs-calendar-header-forward.rs-btn-disabled.focus,
  .rs-calendar-header-title.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-disabled:active,
  .rs-calendar-header-title.rs-btn-disabled:hover:active,
  .rs-calendar-header-meridian.rs-btn-disabled:hover:active,
  .rs-calendar-header-backward.rs-btn-disabled:hover:active,
  .rs-calendar-header-forward.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-title.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-disabled:active {
    opacity: 1;
  }
  .rs-calendar-header-title:focus,
  .rs-calendar-header-meridian:focus,
  .rs-calendar-header-backward:focus,
  .rs-calendar-header-forward:focus,
  .rs-calendar-header-title.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-focus,
  .rs-calendar-header-title:hover,
  .rs-calendar-header-meridian:hover,
  .rs-calendar-header-backward:hover,
  .rs-calendar-header-forward:hover {
    color: #575757;
  }
  .rs-calendar-header-title:active,
  .rs-calendar-header-meridian:active,
  .rs-calendar-header-backward:active,
  .rs-calendar-header-forward:active,
  .rs-calendar-header-title.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward {
    color: #272c36;
    background-color: #e5e5ea;
    background-image: none;
  }
  .rs-calendar-header-title:active:hover,
  .rs-calendar-header-meridian:active:hover,
  .rs-calendar-header-backward:active:hover,
  .rs-calendar-header-forward:active:hover,
  .rs-calendar-header-title.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:hover,
  .rs-calendar-header-title:active:focus,
  .rs-calendar-header-meridian:active:focus,
  .rs-calendar-header-backward:active:focus,
  .rs-calendar-header-forward:active:focus,
  .rs-calendar-header-title.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward:focus,
  .rs-calendar-header-title:active.focus,
  .rs-calendar-header-meridian:active.focus,
  .rs-calendar-header-backward:active.focus,
  .rs-calendar-header-forward:active.focus,
  .rs-calendar-header-title.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.focus {
    color: #272c36;
    background-color: #e5e5ea;
  }
  .rs-calendar-header-title.rs-btn-red,
  .rs-calendar-header-meridian.rs-btn-red,
  .rs-calendar-header-backward.rs-btn-red,
  .rs-calendar-header-forward.rs-btn-red {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-red.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-red.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-red.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-red.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-red:focus,
  .rs-calendar-header-meridian.rs-btn-red:focus,
  .rs-calendar-header-backward.rs-btn-red:focus,
  .rs-calendar-header-forward.rs-btn-red:focus,
  .rs-calendar-header-title.rs-btn-red.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-focus,
  .rs-calendar-header-title.rs-btn-red:hover,
  .rs-calendar-header-meridian.rs-btn-red:hover,
  .rs-calendar-header-backward.rs-btn-red:hover,
  .rs-calendar-header-forward.rs-btn-red:hover {
    color: #fff;
    background: #f44336;
  }
  .rs-calendar-header-title.rs-btn-red:active,
  .rs-calendar-header-meridian.rs-btn-red:active,
  .rs-calendar-header-backward.rs-btn-red:active,
  .rs-calendar-header-forward.rs-btn-red:active,
  .rs-calendar-header-title.rs-btn-red.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-red,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-red,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-red,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-red {
    color: #fff;
    background-color: #eb3626;
    background-image: none;
  }
  .rs-calendar-header-title.rs-btn-red:active:hover,
  .rs-calendar-header-meridian.rs-btn-red:active:hover,
  .rs-calendar-header-backward.rs-btn-red:active:hover,
  .rs-calendar-header-forward.rs-btn-red:active:hover,
  .rs-calendar-header-title.rs-btn-red.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-red:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-red:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-red:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-red:hover,
  .rs-calendar-header-title.rs-btn-red:active:focus,
  .rs-calendar-header-meridian.rs-btn-red:active:focus,
  .rs-calendar-header-backward.rs-btn-red:active:focus,
  .rs-calendar-header-forward.rs-btn-red:active:focus,
  .rs-calendar-header-title.rs-btn-red.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-red:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-red:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-red:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-red:focus,
  .rs-calendar-header-title.rs-btn-red:active.focus,
  .rs-calendar-header-meridian.rs-btn-red:active.focus,
  .rs-calendar-header-backward.rs-btn-red:active.focus,
  .rs-calendar-header-forward.rs-btn-red:active.focus,
  .rs-calendar-header-title.rs-btn-red.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-red.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-red.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-red.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-red.focus {
    color: #fff;
    background-color: #eb3626;
  }
  .rs-calendar-header-title.rs-btn-red.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-red.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-red.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-red.rs-btn-disabled.focus,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-disabled.focus,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-disabled.focus,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-disabled.focus,
  .rs-calendar-header-title.rs-btn-red.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-disabled:active,
  .rs-calendar-header-title.rs-btn-red.rs-btn-disabled:hover:active,
  .rs-calendar-header-meridian.rs-btn-red.rs-btn-disabled:hover:active,
  .rs-calendar-header-backward.rs-btn-red.rs-btn-disabled:hover:active,
  .rs-calendar-header-forward.rs-btn-red.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-title.rs-btn-orange,
  .rs-calendar-header-meridian.rs-btn-orange,
  .rs-calendar-header-backward.rs-btn-orange,
  .rs-calendar-header-forward.rs-btn-orange {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-orange.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-orange:focus,
  .rs-calendar-header-meridian.rs-btn-orange:focus,
  .rs-calendar-header-backward.rs-btn-orange:focus,
  .rs-calendar-header-forward.rs-btn-orange:focus,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-focus,
  .rs-calendar-header-title.rs-btn-orange:hover,
  .rs-calendar-header-meridian.rs-btn-orange:hover,
  .rs-calendar-header-backward.rs-btn-orange:hover,
  .rs-calendar-header-forward.rs-btn-orange:hover {
    color: #fff;
    background: #ff9800;
  }
  .rs-calendar-header-title.rs-btn-orange:active,
  .rs-calendar-header-meridian.rs-btn-orange:active,
  .rs-calendar-header-backward.rs-btn-orange:active,
  .rs-calendar-header-forward.rs-btn-orange:active,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-orange,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-orange,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-orange,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-orange {
    color: #fff;
    background-color: #f59700;
    background-image: none;
  }
  .rs-calendar-header-title.rs-btn-orange:active:hover,
  .rs-calendar-header-meridian.rs-btn-orange:active:hover,
  .rs-calendar-header-backward.rs-btn-orange:active:hover,
  .rs-calendar-header-forward.rs-btn-orange:active:hover,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-orange:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-orange:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-orange:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-orange:hover,
  .rs-calendar-header-title.rs-btn-orange:active:focus,
  .rs-calendar-header-meridian.rs-btn-orange:active:focus,
  .rs-calendar-header-backward.rs-btn-orange:active:focus,
  .rs-calendar-header-forward.rs-btn-orange:active:focus,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-orange:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-orange:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-orange:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-orange:focus,
  .rs-calendar-header-title.rs-btn-orange:active.focus,
  .rs-calendar-header-meridian.rs-btn-orange:active.focus,
  .rs-calendar-header-backward.rs-btn-orange:active.focus,
  .rs-calendar-header-forward.rs-btn-orange:active.focus,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-orange.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-orange.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-orange.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-orange.focus {
    color: #fff;
    background-color: #f59700;
  }
  .rs-calendar-header-title.rs-btn-orange.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-disabled.focus,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-disabled.focus,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-disabled.focus,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-disabled.focus,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-disabled:active,
  .rs-calendar-header-title.rs-btn-orange.rs-btn-disabled:hover:active,
  .rs-calendar-header-meridian.rs-btn-orange.rs-btn-disabled:hover:active,
  .rs-calendar-header-backward.rs-btn-orange.rs-btn-disabled:hover:active,
  .rs-calendar-header-forward.rs-btn-orange.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-title.rs-btn-yellow,
  .rs-calendar-header-meridian.rs-btn-yellow,
  .rs-calendar-header-backward.rs-btn-yellow,
  .rs-calendar-header-forward.rs-btn-yellow {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-yellow:focus,
  .rs-calendar-header-meridian.rs-btn-yellow:focus,
  .rs-calendar-header-backward.rs-btn-yellow:focus,
  .rs-calendar-header-forward.rs-btn-yellow:focus,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-focus,
  .rs-calendar-header-title.rs-btn-yellow:hover,
  .rs-calendar-header-meridian.rs-btn-yellow:hover,
  .rs-calendar-header-backward.rs-btn-yellow:hover,
  .rs-calendar-header-forward.rs-btn-yellow:hover {
    color: #fff;
    background: #ffca28;
  }
  .rs-calendar-header-title.rs-btn-yellow:active,
  .rs-calendar-header-meridian.rs-btn-yellow:active,
  .rs-calendar-header-backward.rs-btn-yellow:active,
  .rs-calendar-header-forward.rs-btn-yellow:active,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-yellow,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-yellow,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-yellow,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-yellow {
    color: #fff;
    background-color: #f5c31d;
    background-image: none;
  }
  .rs-calendar-header-title.rs-btn-yellow:active:hover,
  .rs-calendar-header-meridian.rs-btn-yellow:active:hover,
  .rs-calendar-header-backward.rs-btn-yellow:active:hover,
  .rs-calendar-header-forward.rs-btn-yellow:active:hover,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-yellow:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-yellow:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-yellow:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-yellow:hover,
  .rs-calendar-header-title.rs-btn-yellow:active:focus,
  .rs-calendar-header-meridian.rs-btn-yellow:active:focus,
  .rs-calendar-header-backward.rs-btn-yellow:active:focus,
  .rs-calendar-header-forward.rs-btn-yellow:active:focus,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-yellow:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-yellow:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-yellow:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-yellow:focus,
  .rs-calendar-header-title.rs-btn-yellow:active.focus,
  .rs-calendar-header-meridian.rs-btn-yellow:active.focus,
  .rs-calendar-header-backward.rs-btn-yellow:active.focus,
  .rs-calendar-header-forward.rs-btn-yellow:active.focus,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-yellow.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-yellow.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-yellow.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-yellow.focus {
    color: #fff;
    background-color: #f5c31d;
  }
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-disabled:active,
  .rs-calendar-header-title.rs-btn-yellow.rs-btn-disabled:hover:active,
  .rs-calendar-header-meridian.rs-btn-yellow.rs-btn-disabled:hover:active,
  .rs-calendar-header-backward.rs-btn-yellow.rs-btn-disabled:hover:active,
  .rs-calendar-header-forward.rs-btn-yellow.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-title.rs-btn-green,
  .rs-calendar-header-meridian.rs-btn-green,
  .rs-calendar-header-backward.rs-btn-green,
  .rs-calendar-header-forward.rs-btn-green {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-green.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-green.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-green.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-green.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-green:focus,
  .rs-calendar-header-meridian.rs-btn-green:focus,
  .rs-calendar-header-backward.rs-btn-green:focus,
  .rs-calendar-header-forward.rs-btn-green:focus,
  .rs-calendar-header-title.rs-btn-green.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-focus,
  .rs-calendar-header-title.rs-btn-green:hover,
  .rs-calendar-header-meridian.rs-btn-green:hover,
  .rs-calendar-header-backward.rs-btn-green:hover,
  .rs-calendar-header-forward.rs-btn-green:hover {
    color: #fff;
    background: #4caf50;
  }
  .rs-calendar-header-title.rs-btn-green:active,
  .rs-calendar-header-meridian.rs-btn-green:active,
  .rs-calendar-header-backward.rs-btn-green:active,
  .rs-calendar-header-forward.rs-btn-green:active,
  .rs-calendar-header-title.rs-btn-green.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-green,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-green,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-green,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-green {
    color: #fff;
    background-color: #37ab3c;
    background-image: none;
  }
  .rs-calendar-header-title.rs-btn-green:active:hover,
  .rs-calendar-header-meridian.rs-btn-green:active:hover,
  .rs-calendar-header-backward.rs-btn-green:active:hover,
  .rs-calendar-header-forward.rs-btn-green:active:hover,
  .rs-calendar-header-title.rs-btn-green.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-green:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-green:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-green:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-green:hover,
  .rs-calendar-header-title.rs-btn-green:active:focus,
  .rs-calendar-header-meridian.rs-btn-green:active:focus,
  .rs-calendar-header-backward.rs-btn-green:active:focus,
  .rs-calendar-header-forward.rs-btn-green:active:focus,
  .rs-calendar-header-title.rs-btn-green.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-green:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-green:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-green:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-green:focus,
  .rs-calendar-header-title.rs-btn-green:active.focus,
  .rs-calendar-header-meridian.rs-btn-green:active.focus,
  .rs-calendar-header-backward.rs-btn-green:active.focus,
  .rs-calendar-header-forward.rs-btn-green:active.focus,
  .rs-calendar-header-title.rs-btn-green.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-green.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-green.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-green.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-green.focus {
    color: #fff;
    background-color: #37ab3c;
  }
  .rs-calendar-header-title.rs-btn-green.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-green.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-green.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-green.rs-btn-disabled.focus,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-disabled.focus,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-disabled.focus,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-disabled.focus,
  .rs-calendar-header-title.rs-btn-green.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-disabled:active,
  .rs-calendar-header-title.rs-btn-green.rs-btn-disabled:hover:active,
  .rs-calendar-header-meridian.rs-btn-green.rs-btn-disabled:hover:active,
  .rs-calendar-header-backward.rs-btn-green.rs-btn-disabled:hover:active,
  .rs-calendar-header-forward.rs-btn-green.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-title.rs-btn-cyan,
  .rs-calendar-header-meridian.rs-btn-cyan,
  .rs-calendar-header-backward.rs-btn-cyan,
  .rs-calendar-header-forward.rs-btn-cyan {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-cyan:focus,
  .rs-calendar-header-meridian.rs-btn-cyan:focus,
  .rs-calendar-header-backward.rs-btn-cyan:focus,
  .rs-calendar-header-forward.rs-btn-cyan:focus,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-focus,
  .rs-calendar-header-title.rs-btn-cyan:hover,
  .rs-calendar-header-meridian.rs-btn-cyan:hover,
  .rs-calendar-header-backward.rs-btn-cyan:hover,
  .rs-calendar-header-forward.rs-btn-cyan:hover {
    color: #fff;
    background: #00bcd4;
  }
  .rs-calendar-header-title.rs-btn-cyan:active,
  .rs-calendar-header-meridian.rs-btn-cyan:active,
  .rs-calendar-header-backward.rs-btn-cyan:active,
  .rs-calendar-header-forward.rs-btn-cyan:active,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-cyan,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-cyan,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-cyan,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-cyan {
    color: #fff;
    background-color: #00b1cc;
    background-image: none;
  }
  .rs-calendar-header-title.rs-btn-cyan:active:hover,
  .rs-calendar-header-meridian.rs-btn-cyan:active:hover,
  .rs-calendar-header-backward.rs-btn-cyan:active:hover,
  .rs-calendar-header-forward.rs-btn-cyan:active:hover,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-cyan:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-cyan:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-cyan:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-cyan:hover,
  .rs-calendar-header-title.rs-btn-cyan:active:focus,
  .rs-calendar-header-meridian.rs-btn-cyan:active:focus,
  .rs-calendar-header-backward.rs-btn-cyan:active:focus,
  .rs-calendar-header-forward.rs-btn-cyan:active:focus,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-cyan:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-cyan:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-cyan:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-cyan:focus,
  .rs-calendar-header-title.rs-btn-cyan:active.focus,
  .rs-calendar-header-meridian.rs-btn-cyan:active.focus,
  .rs-calendar-header-backward.rs-btn-cyan:active.focus,
  .rs-calendar-header-forward.rs-btn-cyan:active.focus,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-cyan.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-cyan.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-cyan.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-cyan.focus {
    color: #fff;
    background-color: #00b1cc;
  }
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-disabled:active,
  .rs-calendar-header-title.rs-btn-cyan.rs-btn-disabled:hover:active,
  .rs-calendar-header-meridian.rs-btn-cyan.rs-btn-disabled:hover:active,
  .rs-calendar-header-backward.rs-btn-cyan.rs-btn-disabled:hover:active,
  .rs-calendar-header-forward.rs-btn-cyan.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-title.rs-btn-blue,
  .rs-calendar-header-meridian.rs-btn-blue,
  .rs-calendar-header-backward.rs-btn-blue,
  .rs-calendar-header-forward.rs-btn-blue {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-blue.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-blue:focus,
  .rs-calendar-header-meridian.rs-btn-blue:focus,
  .rs-calendar-header-backward.rs-btn-blue:focus,
  .rs-calendar-header-forward.rs-btn-blue:focus,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-focus,
  .rs-calendar-header-title.rs-btn-blue:hover,
  .rs-calendar-header-meridian.rs-btn-blue:hover,
  .rs-calendar-header-backward.rs-btn-blue:hover,
  .rs-calendar-header-forward.rs-btn-blue:hover {
    color: #fff;
    background: #2196f3;
  }
  .rs-calendar-header-title.rs-btn-blue:active,
  .rs-calendar-header-meridian.rs-btn-blue:active,
  .rs-calendar-header-backward.rs-btn-blue:active,
  .rs-calendar-header-forward.rs-btn-blue:active,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-blue,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-blue,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-blue,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-blue {
    color: #fff;
    background-color: #1787e8;
    background-image: none;
  }
  .rs-calendar-header-title.rs-btn-blue:active:hover,
  .rs-calendar-header-meridian.rs-btn-blue:active:hover,
  .rs-calendar-header-backward.rs-btn-blue:active:hover,
  .rs-calendar-header-forward.rs-btn-blue:active:hover,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-blue:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-blue:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-blue:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-blue:hover,
  .rs-calendar-header-title.rs-btn-blue:active:focus,
  .rs-calendar-header-meridian.rs-btn-blue:active:focus,
  .rs-calendar-header-backward.rs-btn-blue:active:focus,
  .rs-calendar-header-forward.rs-btn-blue:active:focus,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-blue:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-blue:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-blue:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-blue:focus,
  .rs-calendar-header-title.rs-btn-blue:active.focus,
  .rs-calendar-header-meridian.rs-btn-blue:active.focus,
  .rs-calendar-header-backward.rs-btn-blue:active.focus,
  .rs-calendar-header-forward.rs-btn-blue:active.focus,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-blue.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-blue.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-blue.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-blue.focus {
    color: #fff;
    background-color: #1787e8;
  }
  .rs-calendar-header-title.rs-btn-blue.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-disabled.focus,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled.focus,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled.focus,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled.focus,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled:active,
  .rs-calendar-header-title.rs-btn-blue.rs-btn-disabled:hover:active,
  .rs-calendar-header-meridian.rs-btn-blue.rs-btn-disabled:hover:active,
  .rs-calendar-header-backward.rs-btn-blue.rs-btn-disabled:hover:active,
  .rs-calendar-header-forward.rs-btn-blue.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-title.rs-btn-violet,
  .rs-calendar-header-meridian.rs-btn-violet,
  .rs-calendar-header-backward.rs-btn-violet,
  .rs-calendar-header-forward.rs-btn-violet {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-violet.rs-btn-loading:hover,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-loading:hover,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-loading:hover,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-loading:hover,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-loading:focus,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-loading:focus,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-loading:focus,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-loading:focus,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-loading:active,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-loading:active,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-loading:active,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-loading:active,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-loading:hover:active,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-loading:hover:active,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-loading:hover:active,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-title.rs-btn-violet:focus,
  .rs-calendar-header-meridian.rs-btn-violet:focus,
  .rs-calendar-header-backward.rs-btn-violet:focus,
  .rs-calendar-header-forward.rs-btn-violet:focus,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-focus,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-focus,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-focus,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-focus,
  .rs-calendar-header-title.rs-btn-violet:hover,
  .rs-calendar-header-meridian.rs-btn-violet:hover,
  .rs-calendar-header-backward.rs-btn-violet:hover,
  .rs-calendar-header-forward.rs-btn-violet:hover {
    color: #fff;
    background: #673ab7;
  }
  .rs-calendar-header-title.rs-btn-violet:active,
  .rs-calendar-header-meridian.rs-btn-violet:active,
  .rs-calendar-header-backward.rs-btn-violet:active,
  .rs-calendar-header-forward.rs-btn-violet:active,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-active,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-active,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-active,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-violet,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-violet,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-violet,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-violet {
    color: #fff;
    background-color: #5f2bb3;
    background-image: none;
  }
  .rs-calendar-header-title.rs-btn-violet:active:hover,
  .rs-calendar-header-meridian.rs-btn-violet:active:hover,
  .rs-calendar-header-backward.rs-btn-violet:active:hover,
  .rs-calendar-header-forward.rs-btn-violet:active:hover,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-active:hover,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-active:hover,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-active:hover,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-violet:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-violet:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-violet:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-violet:hover,
  .rs-calendar-header-title.rs-btn-violet:active:focus,
  .rs-calendar-header-meridian.rs-btn-violet:active:focus,
  .rs-calendar-header-backward.rs-btn-violet:active:focus,
  .rs-calendar-header-forward.rs-btn-violet:active:focus,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-active:focus,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-active:focus,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-active:focus,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-violet:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-violet:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-violet:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-violet:focus,
  .rs-calendar-header-title.rs-btn-violet:active.focus,
  .rs-calendar-header-meridian.rs-btn-violet:active.focus,
  .rs-calendar-header-backward.rs-btn-violet:active.focus,
  .rs-calendar-header-forward.rs-btn-violet:active.focus,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-active.focus,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-active.focus,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-active.focus,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-title.rs-btn-violet.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-meridian.rs-btn-violet.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-backward.rs-btn-violet.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-forward.rs-btn-violet.focus {
    color: #fff;
    background-color: #5f2bb3;
  }
  .rs-calendar-header-title.rs-btn-violet.rs-btn-disabled,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-disabled,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-disabled,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-disabled,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-disabled:hover,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-disabled:hover,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-disabled:hover,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-disabled:hover,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-disabled:focus,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-disabled:focus,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-disabled:focus,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-disabled:focus,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-disabled.focus,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-disabled.focus,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-disabled.focus,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-disabled.focus,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-disabled:active,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-disabled:active,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-disabled:active,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-disabled:active,
  .rs-calendar-header-title.rs-btn-violet.rs-btn-disabled:hover:active,
  .rs-calendar-header-meridian.rs-btn-violet.rs-btn-disabled:hover:active,
  .rs-calendar-header-backward.rs-btn-violet.rs-btn-disabled:hover:active,
  .rs-calendar-header-forward.rs-btn-violet.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-title {
    margin: 0 0;
  }
  .rs-calendar-header-meridian {
    line-height: 12px;
    border: 1px solid #e5e5ea;
    font-size: 12px;
    padding: 2px 12px 2px 2px;
    margin-left: 4px;
  }
  .rs-calendar-header-meridian::after {
    -webkit-transform: scale(0.7, 0.9);
            transform: scale(0.7, 0.9);
    position: absolute;
    font-family: 'rsuite-icon-font';
    content: '\f0dc';
  }
  .rs-calendar-header-error {
    color: #8e8e93;
    background-color: transparent;
    color: #f44336;
  }
  .rs-calendar-header-error.rs-btn-loading:hover,
  .rs-calendar-header-error.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-calendar-header-error.rs-btn-loading:focus,
  .rs-calendar-header-error.rs-btn-loading:active,
  .rs-calendar-header-error.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-calendar-header-error:focus,
  .rs-calendar-header-error.rs-btn-focus,
  .rs-calendar-header-error:hover {
    color: #fff;
    background: #f44336;
  }
  .rs-calendar-header-error:active,
  .rs-calendar-header-error.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-error {
    color: #fff;
    background-color: #eb3626;
    background-image: none;
  }
  .rs-calendar-header-error:active:hover,
  .rs-calendar-header-error.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-error:hover,
  .rs-calendar-header-error:active:focus,
  .rs-calendar-header-error.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-error:focus,
  .rs-calendar-header-error:active.focus,
  .rs-calendar-header-error.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-calendar-header-error.focus {
    color: #fff;
    background-color: #eb3626;
  }
  .rs-calendar-header-error.rs-btn-disabled,
  .rs-calendar-header-error.rs-btn-disabled:hover,
  .rs-calendar-header-error.rs-btn-disabled:focus,
  .rs-calendar-header-error.rs-btn-disabled.focus,
  .rs-calendar-header-error.rs-btn-disabled:active,
  .rs-calendar-header-error.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-calendar-header-btn-disabled,
  .rs-calendar-header-btn-disabled:hover,
  .rs-calendar-header-btn-disabled:hover:focus {
    cursor: not-allowed;
    opacity: 0.3;
    background: none;
  }
  .rs-calendar-header-btn-disabled::after {
    display: none !important;
  }
  .rs-calendar-header-backward,
  .rs-calendar-header-forward {
    font-size: 0;
    width: 24px;
  }
  .rs-calendar-header-backward::before,
  .rs-calendar-header-forward::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    font-size: 14px;
  }
  .rs-calendar-header-backward::before {
    content: '\ea0a';
  }
  .rs-calendar-header-forward::before {
    content: '\ea0c';
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
    display: block;
    text-align: center;
    width: 100%;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
    clear: both;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::before,
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar::after {
    clear: both;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-backward {
    float: left;
  }
  .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-forward {
    float: right;
  }
  .rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
    margin: 0 auto;
    text-align: center;
  }
  .rs-calendar-header-has-time:not(.rs-calendar-header-has-month) .rs-calendar-header-time-toolbar {
    float: none;
    display: inline-block;
  }
  .rs-calendar-header-has-month.rs-calendar-header-has-time {
    margin: 0 auto;
  }
  /* rtl:begin:ignore */
  /* stylelint-disable-next-line */
  [dir='rtl'] .rs-calendar-header-backward,
  [dir='rtl'] .rs-calendar-header-forward {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  /* rtl:end:ignore */
  .rs-calendar-view {
    padding: 4px 12px 12px;
  }
  .rs-calendar-table {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .rs-calendar-table-row {
    display: table-row;
  }
  .rs-calendar-table-row:nth-child(2) .rs-calendar-table-cell-week-number {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-calendar-table-row:last-child .rs-calendar-table-cell-week-number {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .rs-calendar-table-cell {
    display: table-cell;
    width: 1%;
    padding: 1px;
    text-align: center;
    vertical-align: middle;
  }
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-un-same-month .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
    color: #c5c6c7;
  }
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover {
    background: none;
    text-decoration: line-through;
    cursor: not-allowed;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    color: #ffffff;
    border-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content,
  .rs-calendar-table-cell-disabled.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    opacity: 0.3;
  }
  .rs-calendar-table-cell-content {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px;
    cursor: pointer;
    border-radius: 6px;
  }
  .rs-calendar-table-cell-content:hover {
    background-color: #f2faff;
  }
  .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    border: 1px solid #1675e0;
    padding: 4px;
  }
  .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    color: #ffffff;
    border-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    color: #8e8e93;
    font-size: 12px;
    line-height: 1.66666667;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .rs-calendar-table-header-row .rs-calendar-table-cell-content,
  .rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
  .rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
  .rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
    background: none;
    cursor: auto;
  }
  .rs-calendar-table-cell-week-number {
    display: table-cell;
    width: 1%;
    padding: 1px;
    text-align: center;
    vertical-align: middle;
    color: #8e8e93;
    background: #f7f7fa;
    font-size: 12px;
  }
  .rs-calendar-month-dropdown {
    display: none;
    position: absolute;
    top: 40px;
    border-top: 1px solid #e5e5ea;
    margin-top: 4px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    z-index: 1;
  }
  .rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
    display: block;
  }
  .rs-calendar-show-month-dropdown .rs-calendar-month-dropdown-content {
    -webkit-animation: 0.3s linear slideDown;
            animation: 0.3s linear slideDown;
  }
  .rs-calendar-month-dropdown-scroll {
    height: 230px;
  }
  .rs-calendar-month-dropdown-row {
    position: relative;
    padding-left: 52px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .rs-calendar-month-dropdown-row:not(:last-child) {
    border-bottom: 1px dotted #e5e5ea;
  }
  .rs-calendar-month-dropdown-year {
    position: absolute;
    top: calc(50% - 0.5em);
    left: 12px;
  }
  .rs-calendar-month-dropdown-year-active {
    color: #1675e0;
  }
  .rs-calendar-month-dropdown-list {
    display: block;
  }
  .rs-calendar-month-dropdown-list::before,
  .rs-calendar-month-dropdown-list::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-month-dropdown-list::after {
    clear: both;
  }
  .rs-calendar-month-dropdown-list::before,
  .rs-calendar-month-dropdown-list::after {
    content: ' ';
    display: table;
  }
  .rs-calendar-month-dropdown-list::after {
    clear: both;
  }
  .rs-calendar-month-dropdown-cell {
    display: inline-block;
    float: left;
    width: calc((100% - 1px * 12 ) / 6);
    margin: 1px;
    text-align: center;
    vertical-align: middle;
    width: calc((100% - 1px * 12 - 1px) / 6)\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-calendar-month-dropdown-cell {
      width: calc((100% - 1px * 12 - 1px) / 6);
    }
  }
  @supports (-ms-ime-align: auto) {
    .rs-calendar-month-dropdown-cell {
      width: calc((100% - 1px * 12 - 1px) / 6);
    }
  }
  .rs-calendar-month-dropdown-cell-content {
    display: inline-block;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 5px;
    cursor: pointer;
    border-radius: 6px;
  }
  .rs-calendar-month-dropdown-cell-content:hover {
    background-color: #f2faff;
  }
  .rs-calendar-month-dropdown-cell:not(.rs-calendar-month-dropdown-cell-active).disabled .rs-calendar-month-dropdown-cell-content {
    color: #c5c6c7;
    background: none;
    text-decoration: line-through;
    cursor: not-allowed;
  }
  .rs-calendar-month-dropdown-cell-active.disabled .rs-calendar-month-dropdown-cell-content {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    color: #ffffff;
    border-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-calendar-time-dropdown {
    display: none;
    position: absolute;
    top: 40px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    z-index: 1;
  }
  .rs-calendar-show-time-dropdown .rs-calendar-time-dropdown {
    display: block;
  }
  .rs-calendar-show-time-dropdown .rs-calendar-time-dropdown-content {
    -webkit-animation: 0.3s linear slideDown;
            animation: 0.3s linear slideDown;
  }
  .rs-calendar-time-dropdown-row {
    display: table;
    width: 100%;
  }
  .rs-calendar-time-dropdown-column {
    display: table-cell;
    width: 1%;
  }
  .rs-calendar-time-dropdown-column > ul,
  .rs-calendar-time-dropdown-column > ul > li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-calendar-time-dropdown-column > ul {
    height: 230px;
    overflow-y: auto;
    padding-bottom: 200px;
  }
  .rs-calendar-time-dropdown-column-title {
    width: 100%;
    background-color: #f7f7fa;
    color: #8e8e93;
    text-align: center;
    font-size: 12px;
    line-height: 1.66666667;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .rs-calendar-time-dropdown-cell {
    font-size: 14px;
    line-height: 20px;
    padding: 5px;
    display: block;
    color: #575757;
    text-align: center;
    cursor: pointer;
    -webkit-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
  }
  .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell,
  .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell:hover {
    background-color: #3498ff;
    color: #fff;
  }
  .rs-calendar-time-dropdown-cell,
  .rs-calendar-time-dropdown-cell:hover,
  .rs-calendar-time-dropdown-cell:focus {
    text-decoration: none;
  }
  .rs-calendar-time-dropdown-cell:hover {
    color: #575757;
    background-color: #f2faff;
  }
  .rs-calendar-time-dropdown-cell-disabled,
  .rs-calendar-time-dropdown-cell-disabled:hover,
  .rs-calendar-time-dropdown-cell-disabled:focus {
    color: #c5c6c7;
    background: none;
    cursor: not-allowed;
    text-decoration: line-through;
  }
  .rs-calendar-time-dropdown-cell-active.rs-calendar-time-dropdown-cell-disabled.rs-calendar-time-dropdown-cell {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-carousel {
    position: relative;
    height: 400px;
    overflow: hidden;
    background: #8e8e93;
  }
  .rs-carousel-content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .rs-carousel-slider {
    position: relative;
    left: 0;
    height: 100%;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    will-change: transform;
  }
  .rs-carousel-slider-item {
    background: #8e8e93;
    float: left;
    height: 100%;
    width: 100%;
  }
  .rs-carousel-slider-after {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background: #8e8e93;
    top: 0;
    -webkit-animation: moveLeftHalf 0.3s ease forwards;
            animation: moveLeftHalf 0.3s ease forwards;
  }
  .rs-carousel-slider-after-vertical {
    -webkit-animation: moveLeftHalf-vertical 0.3s ease forwards;
            animation: moveLeftHalf-vertical 0.3s ease forwards;
  }
  .rs-carousel-toolbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
  }
  .rs-carousel-toolbar > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-carousel-label-wrapper {
    margin: 3px;
  }
  .rs-carousel-label-wrapper input {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0;
  }
  .rs-carousel-label-wrapper input:checked ~ label {
    background: #3498ff;
  }
  .rs-carousel-label {
    cursor: pointer;
    display: block;
    background: rgba(255, 255, 255, 0.4);
    -webkit-transition: background 0.3s linear;
    transition: background 0.3s linear;
    -webkit-transition-property: background, width, height;
    transition-property: background, width, height;
    position: relative;
  }
  .rs-carousel-label::after {
    content: '';
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
  }
  .rs-carousel-label:hover {
    background: #fff;
  }
  .rs-carousel-shape-dot .rs-carousel-label {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .rs-carousel-shape-bar .rs-carousel-label {
    border-radius: 2px;
  }
  .rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label,
  .rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label {
    width: 18px;
    height: 4px;
  }
  .rs-carousel-placement-top.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
  .rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
    width: 28px;
  }
  .rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label,
  .rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label {
    width: 4px;
    height: 18px;
  }
  .rs-carousel-placement-left.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label,
  .rs-carousel-placement-right.rs-carousel-shape-bar .rs-carousel-label-wrapper input:checked ~ label {
    height: 28px;
  }
  .rs-carousel-placement-top .rs-carousel-toolbar,
  .rs-carousel-placement-bottom .rs-carousel-toolbar {
    left: 0;
    width: 100%;
  }
  .rs-carousel-placement-top .rs-carousel-toolbar > ul,
  .rs-carousel-placement-bottom .rs-carousel-toolbar > ul {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-carousel-placement-top .rs-carousel-toolbar {
    top: 17px;
  }
  .rs-carousel-placement-bottom .rs-carousel-toolbar {
    bottom: 17px;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar,
  .rs-carousel-placement-right .rs-carousel-toolbar {
    top: 0;
    width: 1.2vw;
    height: 100%;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar > ul,
  .rs-carousel-placement-right .rs-carousel-toolbar > ul {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-carousel-placement-left .rs-carousel-toolbar {
    left: 17px;
  }
  .rs-carousel-placement-right .rs-carousel-toolbar {
    right: 17px;
  }
  .rs-picker-cascader-menu-items > div::before,
  .rs-picker-cascader-menu-items > div::after {
    content: ' ';
    display: table;
  }
  .rs-picker-cascader-menu-items > div::after {
    clear: both;
  }
  .rs-picker-cascader-menu-items > div::before,
  .rs-picker-cascader-menu-items > div::after {
    content: ' ';
    display: table;
  }
  .rs-picker-cascader-menu-items > div::after {
    clear: both;
  }
  .rs-picker-cascader-search-panel {
    max-height: 300px;
    overflow: auto;
  }
  .rs-picker-cascader-row {
    cursor: pointer;
    padding-left: 12px;
    padding-right: 12px;
  }
  .rs-picker-cascader-row:hover {
    color: #575757;
    background-color: #f2faff;
  }
  .rs-picker-cascader-row-disabled {
    cursor: not-allowed;
  }
  .rs-picker-cascader-row-disabled,
  .rs-picker-cascader-row-disabled:hover {
    color: #c5c6c7;
  }
  .rs-picker-cascader-row-disabled strong {
    opacity: 0.7;
  }
  .rs-picker-cascader-col {
    padding: 8px 0;
    display: inline-block;
  }
  .rs-picker-cascader-col::after {
    content: '\00a0/\00a0';
  }
  .rs-picker-cascader-col:last-child::after {
    display: none;
  }
  .rs-picker-cascader-col strong {
    color: #3498ff;
  }
  .rs-picker-cascader-menu-column {
    float: left;
    overflow-y: auto;
  }
  .rs-picker-cascader-menu-items {
    padding: 6px 0;
  }
  .rs-picker-cascader-menu-items ul,
  .rs-picker-cascader-menu-items li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .rs-picker-cascader-menu-item {
    position: relative;
    display: block;
    padding: 8px 28px 8px 12px;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    cursor: pointer;
    text-decoration: none;
    color: #575757;
    word-break: break-word;
  }
  .rs-picker-cascader-menu-items-has-children .rs-picker-cascader-menu-item {
    padding-right: 32px;
  }
  .rs-picker-cascader-menu-item:not(.rs-picker-cascader-menu-item-disabled):hover,
  .rs-picker-cascader-menu-item:not(.rs-picker-cascader-menu-item-disabled):focus,
  .rs-picker-cascader-menu-item.rs-picker-select-menu-item-focus {
    color: #575757;
    background-color: #f2faff;
    text-decoration: none;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active {
    font-weight: bold;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus:hover,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active:hover,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus:focus,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active:focus,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus:hover:focus,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active:hover:focus {
    background-color: #f2faff;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-focus .rs-picker-cascader-menu-caret,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active .rs-picker-cascader-menu-caret {
    color: #575757;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active:hover,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active:focus,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active:hover:focus {
    color: #1675e0;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-disabled {
    color: #c5c6c7;
    text-decoration: none;
    cursor: not-allowed;
  }
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active.rs-picker-cascader-menu-item-disabled,
  .rs-picker-cascader-menu-item.rs-picker-cascader-menu-item-active.rs-picker-cascader-menu-item-disabled:hover {
    color: #a6d7ff;
  }
  .rs-picker-cascader-menu-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    font-weight: normal;
  }
  .rs-picker-cascader-menu-caret::before {
    font-family: 'rsuite-icon-font' !important;
  }
  .rs-picker-cascader-menu-caret::before {
    content: '\ea0c' /* rtl: "\ea0a" */;
  }
  .rs-checkbox {
    position: relative;
    display: block;
  }
  .rs-checkbox-disabled label {
    cursor: not-allowed;
  }
  .rs-checkbox label {
    line-height: 1.14285714;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
  .rs-checkbox .rs-checkbox-inner::before {
    border-color: #3498ff;
  }
  .rs-checkbox.rs-checkbox-disabled label {
    cursor: not-allowed;
  }
  .rs-checkbox-disabled > .rs-checkbox-checker > label {
    color: #c5c6c7;
  }
  .rs-checkbox-inline {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    margin-top: 0;
    margin-left: 10px;
  }
  .rs-checkbox-checker {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 36px;
    min-height: 36px;
    line-height: 1;
    position: relative;
  }
  /* rtl:begin:ignore */
  .rs-checkbox-wrapper::before,
  .rs-checkbox-wrapper::after,
  .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-wrapper .rs-checkbox-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner::after {
    opacity: 0;
    -webkit-transform: rotate(45deg) scale(0);
            transform: rotate(45deg) scale(0);
    -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
    border: solid #fff;
    width: 6px;
    height: 9px;
    margin-top: 2px;
    margin-left: 5px;
    opacity: 1;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after {
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg) scale(1);
            transform: rotate(45deg) scale(1);
  }
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after {
    border-width: 0 0 2px;
    -webkit-transform: rotate(0deg) scale(1);
            transform: rotate(0deg) scale(1);
    width: 10px;
    margin-top: 0;
    margin-left: 3px;
  }
  /* rtl:end:ignore */
  /* stylelint-disable-next-line */
  .rs-checkbox-wrapper {
    position: absolute;
    width: 16px;
    height: 16px;
    display: inline-block;
    left: 10px;
    top: 10px;
  }
  .rs-checkbox-wrapper [type='checkbox'] {
    width: 0;
    height: 0;
    opacity: 0;
  }
  .rs-checkbox-wrapper::before,
  .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-wrapper .rs-checkbox-inner::after {
    width: 16px;
    height: 16px;
  }
  .rs-checkbox-wrapper::before {
    border: 1px solid #3498ff;
    background-color: transparent;
    border-radius: 3px;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper::before {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
    visibility: visible;
  }
  .rs-checkbox-wrapper::after {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
  .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 3px;
    -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  }
  .rs-checkbox-checker:hover .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #3498ff;
  }
  .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-checkbox-disabled:not(.rs-checkbox-checked):not(.rs-checkbox-indeterminate) .rs-checkbox-wrapper .rs-checkbox-inner::before {
    border-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-checkbox-disabled.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before,
  .rs-checkbox-disabled.rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::before {
    opacity: 0.3;
  }
  .rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
    -webkit-box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.4);
            box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.4);
  }
  :hover.rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
    -webkit-box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.4);
            box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.4);
  }
  .rs-checkbox-checked .rs-checkbox-wrapper [type='checkbox']:focus ~ .rs-checkbox-inner::before {
    -webkit-box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.4);
            box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.4);
  }
  .rs-checkbox-group-inline {
    margin-left: -10px;
  }
  .rs-picker-check-menu-items {
    margin-bottom: 6px;
    overflow-y: auto;
  }
  .rs-picker-check-menu-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-picker-check-menu-items .rs-picker-check-menu-group:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid #e5e5ea;
    padding-top: 6px;
  }
  .rs-picker-check-menu-items .rs-picker-check-menu-group-title {
    padding: 8px 12px;
    padding-right: 32px;
    position: relative;
    cursor: default;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    color: #272c36;
  }
  .rs-picker-check-menu-items .rs-picker-check-menu-group-title:hover {
    background-color: #f2faff;
  }
  .rs-picker-check-menu-items .rs-picker-check-menu-group-title .rs-picker-check-menu-group-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .rs-picker-check-menu-items .rs-picker-check-menu-group-title .rs-picker-check-menu-group-caret::before {
    content: '\F0D7';
    font-family: 'rsuite-icon-font';
  }
  .rs-picker-check-menu-items .rs-picker-check-menu-group.folded .rs-picker-check-menu-group-caret {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .rs-picker-check-menu-items .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: bold;
  }
  .rs-check-tree {
    background-color: #fff;
    max-height: 360px;
    height: 100%;
    overflow-y: auto;
    /* stylelint-disable-next-line */
  }
  .rs-check-tree .ReactVirtualized__Grid.ReactVirtualized__List:focus {
    outline: none;
  }
  .rs-check-tree-node {
    position: relative;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item {
    display: inline-block;
  }
  .rs-picker-menu .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item {
    display: block;
  }
  .rs-check-tree-node > .rs-check-tree-node-label:focus .rs-check-item .rs-checkbox-checker > label {
    color: #575757;
    background-color: #f2faff;
  }
  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: bold;
  }
  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label {
    text-align: left;
    position: relative;
    margin: 0;
    padding: 8px 12px;
    padding-left: 58px;
  }
  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label::before {
    content: '';
    position: absolute;
    width: 46px;
    height: 100%;
    background-color: #fff;
    top: 0;
    margin-left: -58px;
  }
  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
    left: 20px;
  }
  .without-children .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label {
    padding-left: 34px;
  }
  .without-children .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label::before {
    width: 28px;
    margin-left: -36px;
  }
  .without-children .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
    left: 0;
  }
  .rs-check-tree-node-all-uncheckable > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label {
    padding-left: 22px;
  }
  .rs-check-tree-node-all-uncheckable > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label::before {
    width: 14px;
    margin-left: 0;
    left: 0;
  }
  .rs-check-tree-node-expand-icon-wrapper {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
    display: inline-block;
    padding: 8px 0;
    padding-right: 8px;
    height: 36px;
    font-size: 14px;
    line-height: 1.42857143;
    -webkit-transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-8px) translateY(-10px) */;
            transform: rotate(-90deg) /* rtl: rotate(90deg) translateX(-8px) translateY(-10px) */;
    -webkit-transform-origin: 3.5px 16px;
            transform-origin: 3.5px 16px;
    margin-left: -2px;
    margin-top: 2px;
    font-family: 'rsuite-icon-font';
    font-style: normal;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon::before {
    content: '\F0D7';
  }
  .rs-check-tree-open > .rs-check-tree-node .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expand-icon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-expanded {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }
  .rs-check-tree-node-expand-icon-wrapper > .rs-check-tree-node-custom-icon {
    width: 14px;
    line-height: 36px;
    vertical-align: middle;
  }
  .rs-check-tree-node-children > .rs-check-tree-children {
    display: none;
  }
  .rs-check-tree-open.rs-check-tree-node-children > .rs-check-tree-children {
    display: block;
  }
  .rs-picker-menu.rs-check-tree-menu {
    padding-top: 12px;
  }
  .rs-picker-menu.rs-check-tree-menu .rs-picker-search-bar {
    padding-top: 0;
  }
  .rs-picker-menu .rs-check-tree {
    padding: 0 12px 12px 0;
  }
  .rs-picker-menu .rs-check-tree-node > .rs-check-tree-node-label .rs-check-tree-node-text-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
    vertical-align: top;
  }
  /* rtl:begin:ignore */
  [dir='rtl'] .rs-check-tree-node-expand-icon-wrapper {
    right: 0;
    padding-right: inherit;
  }
  /* stylelint-disable-next-line */
  .rs-check-tree {
    /* stylelint-disable-next-line */
  }
  .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label::before {
    /* stylelint-disable-next-line */
  }
  [dir='rtl'] .ReactVirtualized__Grid__innerScrollContainer .rs-check-tree-node > .rs-check-tree-node-label .rs-check-item .rs-checkbox-checker > label::before {
    padding-right: inherit;
    right: 46px;
  }
  /* rtl:end:ignore */
  .rs-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }
  .rs-container-has-sidebar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .rs-content {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-control-label {
    margin-bottom: 4px;
  }
  .rs-picker-date.rs-picker-default .rs-picker-toggle-caret,
  .rs-picker-daterange.rs-picker-default .rs-picker-toggle-caret,
  .rs-picker-date.rs-picker-default .rs-picker-toggle-clean,
  .rs-picker-daterange.rs-picker-default .rs-picker-toggle-clean {
    top: 7px;
  }
  .rs-picker-toolbar {
    padding: 12px;
    border-top: 1px solid #e5e5ea;
  }
  .rs-picker-toolbar::before,
  .rs-picker-toolbar::after {
    content: ' ';
    display: table;
  }
  .rs-picker-toolbar::after {
    clear: both;
  }
  .rs-picker-toolbar::before,
  .rs-picker-toolbar::after {
    content: ' ';
    display: table;
  }
  .rs-picker-toolbar::after {
    clear: both;
  }
  .rs-picker-toolbar-ranges {
    display: inline-block;
    margin-left: -10px;
    margin-top: 5px;
  }
  .rs-picker-toolbar-right {
    float: right;
  }
  .rs-picker-toolbar-option {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* stylelint-disable */
    padding: 8px 12px;
    /* stylelint-enable */
    /* stylelint-disable */
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
    color: #085cc9;
    font-weight: normal;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .rs-picker-toolbar-option.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-toolbar-option.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-picker-toolbar-option.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-picker-toolbar-option.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-picker-toolbar-option.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-picker-toolbar-option.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-picker-toolbar-option.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-picker-toolbar-option.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-picker-toolbar-option.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-picker-toolbar-option.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-picker-toolbar-option:hover,
  .rs-picker-toolbar-option:focus {
    color: #575757;
    text-decoration: none;
  }
  .rs-picker-toolbar-option:active,
  .rs-picker-toolbar-option.rs-btn-active {
    outline: 0;
    background-image: none;
  }
  .rs-picker-toolbar-option.rs-btn-loading:hover,
  .rs-picker-toolbar-option.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-toolbar-option.rs-btn-loading:focus,
  .rs-picker-toolbar-option.rs-btn-loading:active,
  .rs-picker-toolbar-option.rs-btn-loading:hover:active {
    color: #085cc9;
    background-color: transparent;
  }
  .rs-picker-toolbar-option,
  .rs-picker-toolbar-option:active,
  .rs-picker-toolbar-option.rs-btn-active {
    background-color: transparent;
  }
  .rs-picker-toolbar-option,
  .rs-picker-toolbar-option:hover,
  .rs-picker-toolbar-option:focus,
  .rs-picker-toolbar-option:active {
    border-color: transparent;
  }
  .rs-picker-toolbar-option:hover,
  .rs-picker-toolbar-option:focus {
    color: #024bb3;
    text-decoration: underline;
    background-color: transparent;
  }
  .rs-picker-toolbar-option:active,
  .rs-picker-toolbar-option.rs-btn-active {
    color: #003b94;
  }
  .rs-picker-toolbar-option:active:hover,
  .rs-picker-toolbar-option.rs-btn-active:hover,
  .rs-picker-toolbar-option:active:focus,
  .rs-picker-toolbar-option.rs-btn-active:focus,
  .rs-picker-toolbar-option:active.focus,
  .rs-picker-toolbar-option.rs-btn-active.focus {
    color: #003b94;
  }
  .rs-picker-toolbar-option.rs-btn-disabled:hover,
  .rs-picker-toolbar-option.rs-btn-disabled:focus {
    color: #085cc9;
    text-decoration: none;
  }
  .rs-picker-toolbar-option:not(:last-child) {
    position: relative;
  }
  .rs-picker-toolbar-option:not(:last-child)::before {
    content: '';
    width: 1px;
    height: 20px;
    background-color: #e5e5ea;
    position: absolute;
    top: 0;
    right: 0;
  }
  .rs-picker-toolbar-option-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-picker-toolbar-option-disabled,
  .rs-picker-toolbar-option-disabled:hover,
  .rs-picker-toolbar-option-disabled:focus,
  .rs-picker-toolbar-option-disabled:active {
    text-decoration: none;
  }
  .rs-picker-toolbar-right-btn-ok {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* stylelint-disable */
    padding: 8px 12px;
    /* stylelint-enable */
    /* stylelint-disable */
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
    /* stylelint-disable-next-line */
    color: #fff;
    background-color: #3498ff;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-picker-toolbar-right-btn-ok.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-picker-toolbar-right-btn-ok.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-picker-toolbar-right-btn-ok:hover,
  .rs-picker-toolbar-right-btn-ok:focus {
    color: #575757;
    text-decoration: none;
  }
  .rs-picker-toolbar-right-btn-ok:active,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active {
    outline: 0;
    background-image: none;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #2589f5;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-loading:hover,
  .rs-picker-toolbar-right-btn-ok.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-toolbar-right-btn-ok.rs-btn-loading:focus,
  .rs-picker-toolbar-right-btn-ok.rs-btn-loading:active,
  .rs-picker-toolbar-right-btn-ok.rs-btn-loading:hover:active {
    color: #fff;
    background-color: #3498ff;
  }
  .rs-picker-toolbar-right-btn-ok:focus,
  .rs-picker-toolbar-right-btn-ok.rs-btn-focus {
    color: #fff;
    background-color: #2589f5;
  }
  .rs-picker-toolbar-right-btn-ok:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-picker-toolbar-right-btn-ok.rs-btn-focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #1675e0;
  }
  .rs-picker-toolbar-right-btn-ok:hover {
    color: #fff;
  }
  .rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover {
    background-color: #2589f5;
  }
  .rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #1675e0;
  }
  .rs-picker-toolbar-right-btn-ok:active,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok {
    color: #fff;
    background-color: #1675e0;
  }
  .rs-picker-toolbar-right-btn-ok:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0a5dc2;
  }
  .rs-picker-toolbar-right-btn-ok:active:hover,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:hover,
  .rs-picker-toolbar-right-btn-ok:active:focus,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:focus,
  .rs-picker-toolbar-right-btn-ok:active.focus,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok.focus {
    color: #fff;
    background-color: #1675e0;
  }
  .rs-picker-toolbar-right-btn-ok:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-picker-toolbar-right-btn-ok:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-picker-toolbar-right-btn-ok:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #0a5dc2;
  }
  .rs-picker-toolbar-right-btn-ok:active,
  .rs-picker-toolbar-right-btn-ok.rs-btn-active,
  .open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok {
    background-image: none;
  }
  .rs-picker-toolbar-right-btn-ok.rs-btn-disabled:hover,
  .rs-picker-toolbar-right-btn-ok.rs-btn-disabled:focus,
  .rs-picker-toolbar-right-btn-ok.rs-btn-disabled.focus {
    background: #3498ff;
  }
  .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled,
  .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover,
  .rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover:active {
    background-color: #3498ff;
  }
  .rs-picker-date .rs-picker-toggle-caret::before {
    content: '\f073' !important;
    line-height: 17px;
  }
  .rs-picker-date-only-time .rs-picker-toggle-caret::before {
    content: '\f017' !important;
  }
  .rs-picker-date-inline {
    height: 299px;
  }
  .rs-picker-date-inline .rs-calendar {
    height: 286px;
  }
  .rs-picker-menu .rs-picker-toolbar {
    max-width: 280px;
  }
  .rs-picker-menu .rs-calendar {
    width: 280px;
    display: block;
    margin: 0 auto;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    width: 30px;
    height: 30px;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    height: 24px;
    padding-top: 0;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
    height: 227px;
  }
  .rs-picker-menu .rs-calendar .rs-calendar-time-dropdown-column > ul {
    height: 214px;
    padding-bottom: 184px;
  }
  .rs-picker-daterange .rs-picker-toggle-caret::before {
    content: '\f073' !important;
    line-height: 17px;
  }
  .rs-picker-daterange-menu .rs-calendar {
    display: inline-block;
    width: 255px;
    height: 278px;
    padding-bottom: 12px;
  }
  .rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 1px solid #e5e5ea;
  }
  .rs-picker-daterange-menu .rs-calendar-header {
    width: 100%;
    text-align: center;
  }
  .rs-picker-daterange-menu .rs-calendar-header-month-toolbar {
    float: none;
  }
  .rs-picker-daterange-menu .rs-calendar-month-dropdown {
    z-index: 1;
  }
  .rs-picker-daterange-menu .rs-calendar-month-dropdown-list {
    width: 185px;
  }
  .rs-picker-daterange-menu .rs-picker-toolbar {
    margin-top: 4px;
    max-width: 510px;
  }
  .rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar .rs-picker-toolbar {
    max-width: 255px;
  }
  .rs-picker-daterange-menu .rs-picker-daterange-calendar-single .rs-calendar {
    border: 0;
    display: block;
    margin: auto;
  }
  .rs-picker-daterange-header {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-bottom: 1px solid #e5e5ea;
  }
  .rs-picker-daterange-calendar-group {
    height: 274px;
    min-width: 510px;
  }
  .rs-divider {
    background-color: #e5e5ea;
  }
  .rs-divider-vertical {
    display: inline-block;
    height: 1em;
    width: 1px;
    vertical-align: middle;
    margin: 0 12px;
  }
  .rs-divider-horizontal {
    height: 1px;
    margin: 24px 0;
  }
  .rs-divider-horizontal.rs-divider-with-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: transparent;
    margin: 30px 0;
    display: table\9;
    text-align: center\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-divider-horizontal.rs-divider-with-text {
      text-align: center\9;
    }
  }
  .rs-divider-horizontal.rs-divider-with-text::before,
  .rs-divider-horizontal.rs-divider-with-text::after {
    content: '';
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    border-top: 1px solid #e5e5ea;
    position: relative\9;
    display: table-cell\9;
    top: 50%\9;
    width: 50%\9;
    -webkit-transform: translateY(50%)\9;
            transform: translateY(50%)\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-divider-horizontal.rs-divider-with-text::before,
    .rs-divider-horizontal.rs-divider-with-text::after {
      position: relative\9;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-divider-horizontal.rs-divider-with-text::before,
    .rs-divider-horizontal.rs-divider-with-text::after {
      top: 50%\9;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-divider-horizontal.rs-divider-with-text::before,
    .rs-divider-horizontal.rs-divider-with-text::after {
      width: 50%;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-divider-horizontal.rs-divider-with-text::before,
    .rs-divider-horizontal.rs-divider-with-text::after {
      -webkit-transform: translateY(50%)\9;
              transform: translateY(50%)\9;
    }
  }
  .rs-divider-inner-text {
    padding: 0 12px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-drawer-wrapper {
    position: fixed;
    z-index: 1050;
    top: 0;
  }
  .rs-drawer {
    display: none;
    overflow: hidden;
    position: fixed;
    z-index: 1050;
    outline: 0;
  }
  .rs-drawer-open.rs-drawer-has-backdrop {
    overflow: hidden;
  }
  .rs-drawer-left,
  .rs-drawer-right {
    top: 0;
    height: 100%;
  }
  .rs-drawer-left.rs-drawer-lg,
  .rs-drawer-right.rs-drawer-lg {
    width: 968px;
  }
  .rs-drawer-left.rs-drawer-md,
  .rs-drawer-right.rs-drawer-md {
    width: 800px;
  }
  .rs-drawer-left.rs-drawer-sm,
  .rs-drawer-right.rs-drawer-sm {
    width: 600px;
  }
  .rs-drawer-left.rs-drawer-xs,
  .rs-drawer-right.rs-drawer-xs {
    width: 400px;
  }
  .rs-drawer-top,
  .rs-drawer-bottom {
    width: 100%;
  }
  .rs-drawer-top.rs-drawer-lg,
  .rs-drawer-bottom.rs-drawer-lg {
    height: 568px;
  }
  .rs-drawer-top.rs-drawer-md,
  .rs-drawer-bottom.rs-drawer-md {
    height: 480px;
  }
  .rs-drawer-top.rs-drawer-sm,
  .rs-drawer-bottom.rs-drawer-sm {
    height: 400px;
  }
  .rs-drawer-top.rs-drawer-xs,
  .rs-drawer-bottom.rs-drawer-xs {
    height: 290px;
  }
  .rs-drawer-full.rs-drawer-top,
  .rs-drawer-full.rs-drawer-bottom {
    height: calc(100% - 60px);
  }
  .rs-drawer-full.rs-drawer-top .rs-drawer-content,
  .rs-drawer-full.rs-drawer-bottom .rs-drawer-content {
    height: 100%;
  }
  .rs-drawer-full.rs-drawer-left,
  .rs-drawer-full.rs-drawer-right {
    width: calc(100% - 60px);
  }
  .rs-drawer-right {
    right: 0;
  }
  .rs-drawer-left {
    left: 0;
  }
  .rs-drawer-top {
    top: 0;
  }
  .rs-drawer-bottom {
    bottom: 0;
  }
  .rs-drawer-open .rs-drawer {
    overflow: visible;
  }
  .rs-drawer-dialog {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .rs-drawer-content {
    position: absolute;
    background-color: #fff;
    outline: 0;
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  }
  .rs-drawer-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    background-color: #272c36;
  }
  .rs-drawer-backdrop.fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }
  .rs-drawer-backdrop.in {
    opacity: 0.3;
  }
  .rs-drawer-body {
    position: relative;
    margin: 30px 20px;
    overflow: auto;
  }
  .rs-drawer-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  .rs-drawer-header {
    padding-right: 20px;
    position: relative;
    margin: 20px 20px 0;
  }
  .rs-drawer-header::before,
  .rs-drawer-header::after {
    content: ' ';
    display: table;
  }
  .rs-drawer-header::after {
    clear: both;
  }
  .rs-drawer-header::before,
  .rs-drawer-header::after {
    content: ' ';
    display: table;
  }
  .rs-drawer-header::after {
    clear: both;
  }
  .rs-drawer-header .rs-drawer-title {
    font-size: 16px;
    line-height: 1.25;
    display: block;
    color: #575757;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-drawer-header .rs-drawer-header-close {
    outline: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    line-height: 1.66666667;
    color: #575757;
    width: 20px;
    padding: 0 4px;
    background: none;
    border-style: none;
  }
  .rs-drawer-header .rs-drawer-header-close::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-drawer-header .rs-drawer-header-close [aria-hidden] {
    display: none;
  }
  .rs-drawer-header .rs-title {
    margin: 0;
    color: #3498ff;
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-drawer-footer {
    text-align: right;
    border-top: none;
    margin: 0 20px 20px;
  }
  .rs-drawer-footer::before,
  .rs-drawer-footer::after {
    content: ' ';
    display: table;
  }
  .rs-drawer-footer::after {
    clear: both;
  }
  .rs-drawer-footer::before,
  .rs-drawer-footer::after {
    content: ' ';
    display: table;
  }
  .rs-drawer-footer::after {
    clear: both;
  }
  .rs-drawer-footer .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }
  .rs-drawer-footer .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-drawer-footer .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-dropdown-toggle-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    font-weight: normal;
  }
  .rs-dropdown-toggle-caret::before {
    font-family: 'rsuite-icon-font' !important;
  }
  .rs-dropdown-placement-left-end .rs-dropdown-toggle-caret::before,
  .rs-dropdown-placement-left-start .rs-dropdown-toggle-caret::before {
    content: '\ea0a' /* rtl: "\ea0c" */;
  }
  .rs-dropdown-placement-right-end .rs-dropdown-toggle-caret::before,
  .rs-dropdown-placement-right-start .rs-dropdown-toggle-caret::before {
    content: '\ea0c' /* rtl: "\ea0a" */;
  }
  .rs-dropdown-placement-top-start .rs-dropdown-toggle-caret::before,
  .rs-dropdown-placement-top-end .rs-dropdown-toggle-caret::before {
    content: '\ea0e';
  }
  .rs-dropdown-placement-bottom-start .rs-dropdown-toggle-caret::before,
  .rs-dropdown-placement-bottom-end .rs-dropdown-toggle-caret::before {
    content: '\ea08';
  }
  .rs-dropdown {
    position: relative;
    display: inline-block;
    font-size: 0;
    vertical-align: middle;
  }
  .rs-dropdown .rs-btn > .rs-icon {
    margin-right: 6px;
  }
  .rs-dropdown .rs-btn > .rs-dropdown-toggle-caret {
    margin-left: 4px;
  }
  .rs-dropdown .rs-dropdown-menu {
    position: absolute;
    z-index: 6;
    display: none;
    float: left;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn {
    cursor: not-allowed !important;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:focus,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-subtle:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:focus,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-primary:active {
    opacity: 0.3;
    background-color: #3498ff;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:focus,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-link:active {
    opacity: 0.3;
    text-decoration: none;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:focus,
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn.rs-btn-ghost:active {
    opacity: 0.3;
  }
  .rs-dropdown-disabled .rs-dropdown-toggle.rs-btn .rs-ripple-pond {
    display: none !important;
  }
  .rs-popover-content .rs-dropdown-item-submenu:not(.rs-dropdown-item-open) > .rs-dropdown-item-content > .rs-dropdown-menu {
    display: none;
  }
  .rs-dropdown-toggle.rs-btn-lg {
    padding-right: 36px !important;
  }
  .rs-dropdown-toggle.rs-btn-lg .rs-dropdown-toggle-caret {
    top: 10px;
    right: 16px;
  }
  .rs-dropdown-toggle.rs-btn-sm {
    padding-right: 30px !important;
  }
  .rs-dropdown-toggle.rs-btn-sm .rs-dropdown-toggle-caret {
    top: 5px;
    right: 10px;
  }
  .rs-dropdown-toggle.rs-btn-xs {
    padding-right: 28px !important;
  }
  .rs-dropdown-toggle.rs-btn-xs .rs-dropdown-toggle-caret {
    top: 2px;
    right: 8px;
  }
  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    outline: none;
    cursor: pointer;
  }
  .rs-dropdown-toggle-custom-title,
  .rs-dropdown-toggle.rs-btn-custom-title {
    padding: 0 !important;
  }
  .rs-dropdown-no-caret .rs-dropdown-toggle {
    padding-right: 12px;
  }
  .rs-dropdown-menu {
    margin: 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border-radius: 6px;
    padding: 6px 0;
  }
  .rs-dropdown-menu .rs-dropdown-item-divider {
    height: 1px;
    margin: 6px 0;
    overflow: hidden;
    background-color: #e5e5ea;
  }
  .rs-dropdown-menu .rs-dropdown-item-content {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #575757;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
  }
  .rs-dropdown-menu .rs-dropdown-item-content > .rs-icon {
    width: 14px;
    text-align: center;
    margin-right: 6px;
  }
  .rs-dropdown-menu a.rs-dropdown-item-content,
  .rs-dropdown-menu a.rs-dropdown-item-content:hover,
  .rs-dropdown-menu a.rs-dropdown-item-content:focus,
  .rs-dropdown-menu a.rs-dropdown-item-content:active {
    text-decoration: none;
  }
  .rs-dropdown-item-submenu {
    position: relative;
  }
  .rs-dropdown-item-submenu > .rs-dropdown-item-content {
    position: relative;
  }
  .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    display: inline-block;
  }
  .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon {
    position: absolute;
    width: 5px;
    line-height: 1.42857143;
    font-size: 14px;
  }
  .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu {
    position: absolute;
    top: 0;
  }
  .rs-dropdown-item-submenu.rs-dropdown-menu-pull-right > .rs-dropdown-item-content {
    padding-right: 27px;
  }
  .rs-dropdown-item-submenu.rs-dropdown-menu-pull-right > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon {
    right: 12px;
  }
  .rs-dropdown-item-submenu.rs-dropdown-menu-pull-right > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon::before {
    content: '\ea0c' /* rtl: "\ea0a" */;
  }
  .rs-dropdown-item-submenu.rs-dropdown-menu-pull-left > .rs-dropdown-item-content {
    padding-left: 27px;
  }
  .rs-dropdown-item-submenu.rs-dropdown-menu-pull-left > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon {
    left: 12px;
  }
  .rs-dropdown-item-submenu.rs-dropdown-menu-pull-left > .rs-dropdown-item-content > .rs-dropdown-menu-toggle .rs-dropdown-menu-toggle-icon::before {
    content: '\ea0a' /* rtl: "\ea0c" */;
  }
  .rs-dropdown-item-submenu.rs-dropdown-menu-pull-right > .rs-dropdown-item-content > .rs-dropdown-menu {
    left: 100%;
  }
  .rs-dropdown-item-submenu.rs-dropdown-menu-pull-left > .rs-dropdown-item-content > .rs-dropdown-menu {
    right: 100%;
  }
  .rs-dropdown-item-submenu.rs-dropdown-item-open > .rs-dropdown-item-content > .rs-dropdown-menu {
    display: table;
    -webkit-animation-name: slideUpIn;
            animation-name: slideUpIn;
    -webkit-animation-name: none\9;
            animation-name: none\9;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:hover,
  .rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:focus {
    text-decoration: none;
    color: #575757;
    background-color: #f2faff;
  }
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content {
    font-weight: bold;
    outline: 0;
  }
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content:hover,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:hover,
  .rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content:focus,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:focus {
    text-decoration: none;
    background-color: rgba(242, 250, 255, 0.5);
  }
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:hover,
  .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content:focus {
    color: #1675e0;
  }
  .rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content {
    opacity: 0.3;
  }
  .rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content,
  .rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content:hover,
  .rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content:focus {
    color: #575757;
  }
  .rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content:hover,
  .rs-dropdown-menu > .rs-dropdown-item-disabled > .rs-dropdown-item-content:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;
    cursor: not-allowed;
  }
  .rs-dropdown-open > .rs-dropdown-menu {
    display: block;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu {
    bottom: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu {
    right: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu {
    left: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
    top: 100%;
  }
  .rs-dropdown-open.rs-dropdown-placement-top-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-bottom-start > .rs-dropdown-menu {
    left: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-top-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-bottom-end > .rs-dropdown-menu {
    right: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-left-start > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-right-start > .rs-dropdown-menu {
    top: 0;
  }
  .rs-dropdown-open.rs-dropdown-placement-right-end > .rs-dropdown-menu,
  .rs-dropdown-open.rs-dropdown-placement-left-end > .rs-dropdown-menu {
    bottom: 0;
  }
  .rs-dropdown-open > a {
    outline: 0;
  }
  .rs-dropdown-header {
    display: block;
    padding: 8px 12px;
    line-height: 1.42857143;
    color: #a6a6a6;
    border-bottom: 1px solid #e5e5ea;
    white-space: nowrap;
  }
  .rs-form-control-wrapper .rs-error-message {
    position: absolute;
    padding: 4px 8px;
    background-color: #fff;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    -webkit-filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
            filter: drop-shadow(0 0 6px rgba(0,0,0,.1));
    z-index: 5;
    color: #f44336;
    font-size: 12px;
    display: none;
    white-space: nowrap;
  }
  .rs-form-control-wrapper .rs-error-message-wrapper {
    position: absolute;
  }
  .rs-form-control-wrapper .rs-error-message-show {
    display: block;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  .rs-form-control-wrapper .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-arrow::after {
    content: '';
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end {
    bottom: -1px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start .rs-error-message,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end .rs-error-message {
    top: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start .rs-error-message-show,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end .rs-error-message-show {
    -webkit-animation-name: errorMessageSlideDownIn;
            animation-name: errorMessageSlideDownIn;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end .rs-error-message-arrow::after {
    border-width: 0 6px 6px;
    border-bottom-color: #e5e5ea;
    top: -6px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end .rs-error-message-arrow::after {
    top: -5px;
    border-bottom-color: #fff;
  }
  .rs-form-control-wrapper .rs-error-message-placement-top-start,
  .rs-form-control-wrapper .rs-error-message-placement-top-end {
    top: -1px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-top-start .rs-error-message,
  .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message {
    bottom: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-top-start .rs-error-message-show,
  .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message-show {
    -webkit-animation-name: errorMessageSlideUpIn;
            animation-name: errorMessageSlideUpIn;
  }
  .rs-form-control-wrapper .rs-error-message-placement-top-start .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-top-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message-arrow::after {
    border-width: 6px 6px 0;
    border-top-color: #e5e5ea;
    bottom: -6px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-top-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message-arrow::after {
    bottom: -5px;
    border-top-color: #fff;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start,
  .rs-form-control-wrapper .rs-error-message-placement-top-start {
    left: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start .rs-error-message,
  .rs-form-control-wrapper .rs-error-message-placement-top-start .rs-error-message {
    left: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-top-start .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-top-start .rs-error-message-arrow::after {
    left: 10px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end,
  .rs-form-control-wrapper .rs-error-message-placement-top-end {
    right: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end .rs-error-message,
  .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message {
    right: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-bottom-end .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-top-end .rs-error-message-arrow::after {
    right: 10px;
  }
  /* rtl:begin:ignore */
  /* stylelint-disable-next-line */
  .rs-form-control-wrapper .rs-error-message-placement-left-start,
  .rs-form-control-wrapper .rs-error-message-placement-left-end {
    left: -1px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-start .rs-error-message,
  .rs-form-control-wrapper .rs-error-message-placement-left-end .rs-error-message {
    right: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-start .rs-error-message-show,
  .rs-form-control-wrapper .rs-error-message-placement-left-end .rs-error-message-show {
    -webkit-animation-name: errorMessageSlideRightIn;
            animation-name: errorMessageSlideRightIn;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-start .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-left-end .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-left-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-left-end .rs-error-message-arrow::after {
    border-width: 6px 0 6px 6px;
    border-left-color: #e5e5ea;
    right: -6px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-left-end .rs-error-message-arrow::after {
    right: -5px;
    border-left-color: #fff;
  }
  .rs-form-control-wrapper .rs-error-message-placement-right-start,
  .rs-form-control-wrapper .rs-error-message-placement-right-end {
    right: -1px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-right-start .rs-error-message,
  .rs-form-control-wrapper .rs-error-message-placement-right-end .rs-error-message {
    left: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-right-start .rs-error-message-show,
  .rs-form-control-wrapper .rs-error-message-placement-right-end .rs-error-message-show {
    -webkit-animation-name: errorMessageSlideLeftIn;
            animation-name: errorMessageSlideLeftIn;
  }
  .rs-form-control-wrapper .rs-error-message-placement-right-start .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-right-end .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-right-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-right-end .rs-error-message-arrow::after {
    border-width: 6px 6px 6px 0;
    border-right-color: #e5e5ea;
    left: -6px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-right-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-right-end .rs-error-message-arrow::after {
    left: -5px;
    border-right-color: #fff;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-start,
  .rs-form-control-wrapper .rs-error-message-placement-right-start {
    top: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-start .rs-error-message,
  .rs-form-control-wrapper .rs-error-message-placement-right-start .rs-error-message {
    top: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-start .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-right-start .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-left-start .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-right-start .rs-error-message-arrow::after {
    top: 4px;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-end,
  .rs-form-control-wrapper .rs-error-message-placement-right-end {
    bottom: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-end .rs-error-message,
  .rs-form-control-wrapper .rs-error-message-placement-right-end .rs-error-message {
    bottom: 0;
  }
  .rs-form-control-wrapper .rs-error-message-placement-left-end .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-right-end .rs-error-message-arrow::before,
  .rs-form-control-wrapper .rs-error-message-placement-left-end .rs-error-message-arrow::after,
  .rs-form-control-wrapper .rs-error-message-placement-right-end .rs-error-message-arrow::after {
    bottom: 4px;
  }
  /* rtl:end:ignore */
  .rs-flex-box-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .rs-flex-box-grid-item {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-flex-box-grid-top {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .rs-flex-box-grid-middle {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .rs-flex-box-grid-bottom {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .rs-flex-box-grid-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .rs-flex-box-grid-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .rs-flex-box-grid-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .rs-flex-box-grid-space-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .rs-flex-box-grid-space-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .rs-flex-box-grid-item-24 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 100%;
  }
  .rs-flex-box-grid-item-23 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 95.83333333%;
  }
  .rs-flex-box-grid-item-22 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 91.66666667%;
  }
  .rs-flex-box-grid-item-21 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 87.5%;
  }
  .rs-flex-box-grid-item-20 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 83.33333333%;
  }
  .rs-flex-box-grid-item-19 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 79.16666667%;
  }
  .rs-flex-box-grid-item-18 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 75%;
  }
  .rs-flex-box-grid-item-17 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 70.83333333%;
  }
  .rs-flex-box-grid-item-16 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 66.66666667%;
  }
  .rs-flex-box-grid-item-15 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 62.5%;
  }
  .rs-flex-box-grid-item-14 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 58.33333333%;
  }
  .rs-flex-box-grid-item-13 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 54.16666667%;
  }
  .rs-flex-box-grid-item-12 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 50%;
  }
  .rs-flex-box-grid-item-11 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 45.83333333%;
  }
  .rs-flex-box-grid-item-10 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 41.66666667%;
  }
  .rs-flex-box-grid-item-9 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 37.5%;
  }
  .rs-flex-box-grid-item-8 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 33.33333333%;
  }
  .rs-flex-box-grid-item-7 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 29.16666667%;
  }
  .rs-flex-box-grid-item-6 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 25%;
  }
  .rs-flex-box-grid-item-5 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 20.83333333%;
  }
  .rs-flex-box-grid-item-4 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 16.66666667%;
  }
  .rs-flex-box-grid-item-3 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 12.5%;
  }
  .rs-flex-box-grid-item-2 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 8.33333333%;
  }
  .rs-flex-box-grid-item-1 {
    position: relative;
    display: block;
    min-height: 1px;
    width: 4.16666667%;
  }
  .rs-flex-box-grid-item-order-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .rs-flex-box-grid-item-order-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .rs-flex-box-grid-item-order-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .rs-flex-box-grid-item-order-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .rs-flex-box-grid-item-order-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .rs-flex-box-grid-item-order-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .rs-flex-box-grid-item-order-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .rs-flex-box-grid-item-order-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .rs-flex-box-grid-item-order-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .rs-flex-box-grid-item-order-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .rs-flex-box-grid-item-order-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .rs-flex-box-grid-item-order-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .rs-flex-box-grid-item-order-13 {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .rs-flex-box-grid-item-order-14 {
    -webkit-box-ordinal-group: 15;
        -ms-flex-order: 14;
            order: 14;
  }
  .rs-flex-box-grid-item-order-15 {
    -webkit-box-ordinal-group: 16;
        -ms-flex-order: 15;
            order: 15;
  }
  .rs-flex-box-grid-item-order-16 {
    -webkit-box-ordinal-group: 17;
        -ms-flex-order: 16;
            order: 16;
  }
  .rs-flex-box-grid-item-order-17 {
    -webkit-box-ordinal-group: 18;
        -ms-flex-order: 17;
            order: 17;
  }
  .rs-flex-box-grid-item-order-18 {
    -webkit-box-ordinal-group: 19;
        -ms-flex-order: 18;
            order: 18;
  }
  .rs-flex-box-grid-item-order-19 {
    -webkit-box-ordinal-group: 20;
        -ms-flex-order: 19;
            order: 19;
  }
  .rs-flex-box-grid-item-order-20 {
    -webkit-box-ordinal-group: 21;
        -ms-flex-order: 20;
            order: 20;
  }
  .rs-flex-box-grid-item-order-21 {
    -webkit-box-ordinal-group: 22;
        -ms-flex-order: 21;
            order: 21;
  }
  .rs-flex-box-grid-item-order-22 {
    -webkit-box-ordinal-group: 23;
        -ms-flex-order: 22;
            order: 22;
  }
  .rs-flex-box-grid-item-order-23 {
    -webkit-box-ordinal-group: 24;
        -ms-flex-order: 23;
            order: 23;
  }
  .rs-flex-box-grid-item-order-24 {
    -webkit-box-ordinal-group: 25;
        -ms-flex-order: 24;
            order: 24;
  }
  .rs-footer {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-form-inline > *,
  .rs-form-inline > .rs-btn {
    margin-right: 30px;
    margin-bottom: 24px;
    vertical-align: top;
  }
  .rs-form-inline .rs-control-label {
    vertical-align: top;
    margin-bottom: auto;
    margin-top: 8px;
    display: inline-block;
  }
  .rs-form-fluid .rs-form-control-wrapper {
    width: 100%;
  }
  .rs-form-fluid .rs-form-control-wrapper > .rs-input-number,
  .rs-form-fluid .rs-form-control-wrapper > .rs-input {
    width: 100%;
  }
  .rs-form-control-wrapper {
    position: relative;
  }
  .rs-form-control-wrapper > .rs-input-number,
  .rs-form-control-wrapper > .rs-input {
    width: 300px;
  }
  .rs-form-control-wrapper.read-only,
  .rs-form-control-wrapper.plaintext {
    pointer-events: none;
    -ms-touch-action: none;
        touch-action: none;
  }
  .rs-form-vertical .rs-form-group .rs-input-group {
    width: 300px;
  }
  .rs-form-vertical .rs-form-group .rs-form-control-wrapper {
    display: inline-block;
    max-width: 100%;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper {
    float: left;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper + .rs-help-block {
    clear: both;
  }
  .rs-form-horizontal .rs-form-group .rs-form-control-wrapper + .rs-help-block:not(.rs-help-block-tooltip) {
    margin-left: 182px;
  }
  .rs-form-inline .rs-form-group .rs-form-control-wrapper {
    display: inline-block;
    margin-left: 12px;
  }
  .rs-form-inline .rs-form-group .rs-sr-only + .rs-form-control-wrapper {
    margin-left: 0;
  }
  .rs-form-control-wrapper.plaintext {
    display: block !important;
    max-width: 100%;
  }
  .rs-form-control-wrapper.plaintext + .rs-help-block {
    display: none;
  }
  .rs-form-control-wrapper.plaintext .rs-form-control-default-value {
    padding: 8px 0;
  }
  .rs-form-control-wrapper.plaintext > .rs-input {
    border: 1px solid rgba(255, 255, 255, 0);
    padding-left: 0;
    padding-right: 0;
    width: auto;
  }
  .rs-form-control-wrapper.plaintext > .rs-checkbox-group .rs-checkbox-checker {
    padding-left: 0;
    display: inline-block;
  }
  .rs-form-control-wrapper.plaintext > .rs-checkbox-group .rs-checkbox-wrapper,
  .rs-form-control-wrapper.plaintext > .rs-checkbox-group .rs-checkbox:not(.rs-checkbox-checked) {
    display: none;
  }
  .rs-form-control-wrapper.plaintext > .rs-checkbox-group .rs-checkbox {
    position: relative;
    display: inline-block;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  .rs-form-control-wrapper.plaintext > .rs-checkbox-group-inline {
    margin-left: 0;
  }
  .rs-form-control-wrapper.plaintext > .rs-checkbox-group .rs-checkbox.rs-checkbox-checked ~ .rs-checkbox.rs-checkbox-checked::before {
    content: ',\00a0';
  }
  .rs-form-control-wrapper.plaintext > .rs-radio-group .rs-radio-checker {
    padding-left: 0;
  }
  .rs-form-control-wrapper.plaintext > .rs-radio-group .rs-radio-wrapper,
  .rs-form-control-wrapper.plaintext > .rs-radio-group .rs-radio:not(.rs-radio-checked) {
    display: none;
  }
  .rs-form-control-wrapper.plaintext > .rs-slider {
    margin: 0 !important;
    width: auto !important;
  }
  .rs-form-control-wrapper.plaintext > .rs-slider .rs-tooltip {
    display: inline;
    position: static;
    opacity: 1;
  }
  .rs-form-control-wrapper.plaintext > .rs-slider .rs-slider-bar,
  .rs-form-control-wrapper.plaintext > .rs-slider .rs-slider-handle::before,
  .rs-form-control-wrapper.plaintext > .rs-slider .rs-tooltip-arrow {
    display: none;
  }
  .rs-form-control-wrapper.plaintext > .rs-slider .rs-slider-handle {
    position: static;
  }
  .rs-form-control-wrapper.plaintext > .rs-slider .rs-tooltip-inner {
    display: inline-block;
    background: transparent;
    color: #575757;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 8px 0;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-default .rs-picker-toggle {
    border: 1px solid rgba(255, 255, 255, 0);
    padding-left: 0;
    padding-right: 0;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-default .rs-picker-toggle,
  .rs-form-control-wrapper.plaintext > .rs-picker-default .rs-picker-toggle-value {
    color: #575757;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-default .rs-picker-toggle .rs-picker-value-list,
  .rs-form-control-wrapper.plaintext > .rs-picker-default .rs-picker-toggle-value .rs-picker-value-list {
    overflow: visible;
    white-space: normal;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-default .rs-picker-toggle-clean,
  .rs-form-control-wrapper.plaintext > .rs-picker-default .rs-picker-toggle-caret,
  .rs-form-control-wrapper.plaintext > .rs-picker-default .rs-picker-value-count {
    display: none;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-input {
    display: block;
    border: 1px solid rgba(255, 255, 255, 0);
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-input .rs-picker-toggle-clean,
  .rs-form-control-wrapper.plaintext > .rs-picker-input .rs-picker-toggle-caret {
    display: none;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-input .rs-picker-tag-wrapper {
    margin-left: -10px;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-input .rs-tag {
    padding-right: 6px;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-input .rs-tag .rs-tag-icon-close {
    display: none;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-input .rs-picker-toggle {
    padding-left: 0;
    padding-right: 12px;
  }
  .rs-form-control-wrapper.plaintext > .rs-picker-input .rs-picker-toggle .rs-picker-toggle-value {
    color: #575757;
  }
  .rs-form-control-wrapper.plaintext .rs-picker-toggle {
    -webkit-user-select: auto;
       -moz-user-select: auto;
        -ms-user-select: auto;
            user-select: auto;
  }
  .rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
    margin-bottom: 24px;
  }
  .rs-form-group .rs-input {
    display: inline-block;
  }
  .rs-form-group textarea.rs-input {
    vertical-align: bottom;
  }
  .rs-form-vertical .rs-form-group .rs-control-label {
    display: block;
  }
  .rs-form-vertical .rs-form-group .rs-help-block:not(.rs-help-block-tooltip) {
    padding-top: 4px;
  }
  .rs-form-horizontal .rs-form-group::before,
  .rs-form-horizontal .rs-form-group::after {
    content: ' ';
    display: table;
  }
  .rs-form-horizontal .rs-form-group::after {
    clear: both;
  }
  .rs-form-horizontal .rs-form-group::before,
  .rs-form-horizontal .rs-form-group::after {
    content: ' ';
    display: table;
  }
  .rs-form-horizontal .rs-form-group::after {
    clear: both;
  }
  .rs-form-horizontal .rs-form-group .rs-control-label {
    float: left;
    display: inline-block;
    min-height: 20px;
    line-height: 1.42857143;
    font-size: 14px;
    padding: 8px 0;
    width: 170px;
    margin-right: 12px;
    text-align: right;
  }
  .rs-form-horizontal .rs-form-group .rs-btn-toolbar {
    padding-left: 182px;
  }
  .rs-form-inline .rs-form-group {
    display: inline-block;
  }
  @media (max-width: 479px) {
    .make-hidden .rs-hidden-xs {
      display: none;
    }
  }
  @media (min-width: 480px) and (max-width: 991px) {
    .make-hidden .rs-hidden-sm {
      display: none;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .make-hidden .rs-hidden-md {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .make-hidden .rs-hidden-lg {
      display: none;
    }
  }
  .rs-grid-container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rs-grid-container::before,
  .rs-grid-container::after {
    content: ' ';
    display: table;
  }
  .rs-grid-container::after {
    clear: both;
  }
  .rs-grid-container::before,
  .rs-grid-container::after {
    content: ' ';
    display: table;
  }
  .rs-grid-container::after {
    clear: both;
  }
  @media (min-width: 480px) {
    .rs-grid-container {
      width: 490px;
    }
  }
  @media (min-width: 992px) {
    .rs-grid-container {
      width: 950px;
    }
  }
  @media (min-width: 1200px) {
    .rs-grid-container {
      width: 1150px;
    }
  }
  .rs-grid-container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rs-grid-container-fluid::before,
  .rs-grid-container-fluid::after {
    content: ' ';
    display: table;
  }
  .rs-grid-container-fluid::after {
    clear: both;
  }
  .rs-grid-container-fluid::before,
  .rs-grid-container-fluid::after {
    content: ' ';
    display: table;
  }
  .rs-grid-container-fluid::after {
    clear: both;
  }
  .rs-col-xs-1,
  .rs-col-sm-1,
  .rs-col-md-1,
  .rs-col-lg-1,
  .rs-col-xs-2,
  .rs-col-sm-2,
  .rs-col-md-2,
  .rs-col-lg-2,
  .rs-col-xs-3,
  .rs-col-sm-3,
  .rs-col-md-3,
  .rs-col-lg-3,
  .rs-col-xs-4,
  .rs-col-sm-4,
  .rs-col-md-4,
  .rs-col-lg-4,
  .rs-col-xs-5,
  .rs-col-sm-5,
  .rs-col-md-5,
  .rs-col-lg-5,
  .rs-col-xs-6,
  .rs-col-sm-6,
  .rs-col-md-6,
  .rs-col-lg-6,
  .rs-col-xs-7,
  .rs-col-sm-7,
  .rs-col-md-7,
  .rs-col-lg-7,
  .rs-col-xs-8,
  .rs-col-sm-8,
  .rs-col-md-8,
  .rs-col-lg-8,
  .rs-col-xs-9,
  .rs-col-sm-9,
  .rs-col-md-9,
  .rs-col-lg-9,
  .rs-col-xs-10,
  .rs-col-sm-10,
  .rs-col-md-10,
  .rs-col-lg-10,
  .rs-col-xs-11,
  .rs-col-sm-11,
  .rs-col-md-11,
  .rs-col-lg-11,
  .rs-col-xs-12,
  .rs-col-sm-12,
  .rs-col-md-12,
  .rs-col-lg-12,
  .rs-col-xs-13,
  .rs-col-sm-13,
  .rs-col-md-13,
  .rs-col-lg-13,
  .rs-col-xs-14,
  .rs-col-sm-14,
  .rs-col-md-14,
  .rs-col-lg-14,
  .rs-col-xs-15,
  .rs-col-sm-15,
  .rs-col-md-15,
  .rs-col-lg-15,
  .rs-col-xs-16,
  .rs-col-sm-16,
  .rs-col-md-16,
  .rs-col-lg-16,
  .rs-col-xs-17,
  .rs-col-sm-17,
  .rs-col-md-17,
  .rs-col-lg-17,
  .rs-col-xs-18,
  .rs-col-sm-18,
  .rs-col-md-18,
  .rs-col-lg-18,
  .rs-col-xs-19,
  .rs-col-sm-19,
  .rs-col-md-19,
  .rs-col-lg-19,
  .rs-col-xs-20,
  .rs-col-sm-20,
  .rs-col-md-20,
  .rs-col-lg-20,
  .rs-col-xs-21,
  .rs-col-sm-21,
  .rs-col-md-21,
  .rs-col-lg-21,
  .rs-col-xs-22,
  .rs-col-sm-22,
  .rs-col-md-22,
  .rs-col-lg-22,
  .rs-col-xs-23,
  .rs-col-sm-23,
  .rs-col-md-23,
  .rs-col-lg-23,
  .rs-col-xs-24,
  .rs-col-sm-24,
  .rs-col-md-24,
  .rs-col-lg-24 {
    position: relative;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .rs-col-xs-1,
  .rs-col-xs-2,
  .rs-col-xs-3,
  .rs-col-xs-4,
  .rs-col-xs-5,
  .rs-col-xs-6,
  .rs-col-xs-7,
  .rs-col-xs-8,
  .rs-col-xs-9,
  .rs-col-xs-10,
  .rs-col-xs-11,
  .rs-col-xs-12,
  .rs-col-xs-13,
  .rs-col-xs-14,
  .rs-col-xs-15,
  .rs-col-xs-16,
  .rs-col-xs-17,
  .rs-col-xs-18,
  .rs-col-xs-19,
  .rs-col-xs-20,
  .rs-col-xs-21,
  .rs-col-xs-22,
  .rs-col-xs-23,
  .rs-col-xs-24 {
    float: left;
  }
  .rs-col-xs-24 {
    width: 100%;
  }
  .rs-col-xs-23 {
    width: 95.83333333%;
  }
  .rs-col-xs-22 {
    width: 91.66666667%;
  }
  .rs-col-xs-21 {
    width: 87.5%;
  }
  .rs-col-xs-20 {
    width: 83.33333333%;
  }
  .rs-col-xs-19 {
    width: 79.16666667%;
  }
  .rs-col-xs-18 {
    width: 75%;
  }
  .rs-col-xs-17 {
    width: 70.83333333%;
  }
  .rs-col-xs-16 {
    width: 66.66666667%;
  }
  .rs-col-xs-15 {
    width: 62.5%;
  }
  .rs-col-xs-14 {
    width: 58.33333333%;
  }
  .rs-col-xs-13 {
    width: 54.16666667%;
  }
  .rs-col-xs-12 {
    width: 50%;
  }
  .rs-col-xs-11 {
    width: 45.83333333%;
  }
  .rs-col-xs-10 {
    width: 41.66666667%;
  }
  .rs-col-xs-9 {
    width: 37.5%;
  }
  .rs-col-xs-8 {
    width: 33.33333333%;
  }
  .rs-col-xs-7 {
    width: 29.16666667%;
  }
  .rs-col-xs-6 {
    width: 25%;
  }
  .rs-col-xs-5 {
    width: 20.83333333%;
  }
  .rs-col-xs-4 {
    width: 16.66666667%;
  }
  .rs-col-xs-3 {
    width: 12.5%;
  }
  .rs-col-xs-2 {
    width: 8.33333333%;
  }
  .rs-col-xs-1 {
    width: 4.16666667%;
  }
  .rs-col-xs-pull-24 {
    right: 100%;
  }
  .rs-col-xs-pull-23 {
    right: 95.83333333%;
  }
  .rs-col-xs-pull-22 {
    right: 91.66666667%;
  }
  .rs-col-xs-pull-21 {
    right: 87.5%;
  }
  .rs-col-xs-pull-20 {
    right: 83.33333333%;
  }
  .rs-col-xs-pull-19 {
    right: 79.16666667%;
  }
  .rs-col-xs-pull-18 {
    right: 75%;
  }
  .rs-col-xs-pull-17 {
    right: 70.83333333%;
  }
  .rs-col-xs-pull-16 {
    right: 66.66666667%;
  }
  .rs-col-xs-pull-15 {
    right: 62.5%;
  }
  .rs-col-xs-pull-14 {
    right: 58.33333333%;
  }
  .rs-col-xs-pull-13 {
    right: 54.16666667%;
  }
  .rs-col-xs-pull-12 {
    right: 50%;
  }
  .rs-col-xs-pull-11 {
    right: 45.83333333%;
  }
  .rs-col-xs-pull-10 {
    right: 41.66666667%;
  }
  .rs-col-xs-pull-9 {
    right: 37.5%;
  }
  .rs-col-xs-pull-8 {
    right: 33.33333333%;
  }
  .rs-col-xs-pull-7 {
    right: 29.16666667%;
  }
  .rs-col-xs-pull-6 {
    right: 25%;
  }
  .rs-col-xs-pull-5 {
    right: 20.83333333%;
  }
  .rs-col-xs-pull-4 {
    right: 16.66666667%;
  }
  .rs-col-xs-pull-3 {
    right: 12.5%;
  }
  .rs-col-xs-pull-2 {
    right: 8.33333333%;
  }
  .rs-col-xs-pull-1 {
    right: 4.16666667%;
  }
  .rs-col-xs-pull-0 {
    right: auto;
  }
  .rs-col-xs-push-24 {
    left: 100%;
  }
  .rs-col-xs-push-23 {
    left: 95.83333333%;
  }
  .rs-col-xs-push-22 {
    left: 91.66666667%;
  }
  .rs-col-xs-push-21 {
    left: 87.5%;
  }
  .rs-col-xs-push-20 {
    left: 83.33333333%;
  }
  .rs-col-xs-push-19 {
    left: 79.16666667%;
  }
  .rs-col-xs-push-18 {
    left: 75%;
  }
  .rs-col-xs-push-17 {
    left: 70.83333333%;
  }
  .rs-col-xs-push-16 {
    left: 66.66666667%;
  }
  .rs-col-xs-push-15 {
    left: 62.5%;
  }
  .rs-col-xs-push-14 {
    left: 58.33333333%;
  }
  .rs-col-xs-push-13 {
    left: 54.16666667%;
  }
  .rs-col-xs-push-12 {
    left: 50%;
  }
  .rs-col-xs-push-11 {
    left: 45.83333333%;
  }
  .rs-col-xs-push-10 {
    left: 41.66666667%;
  }
  .rs-col-xs-push-9 {
    left: 37.5%;
  }
  .rs-col-xs-push-8 {
    left: 33.33333333%;
  }
  .rs-col-xs-push-7 {
    left: 29.16666667%;
  }
  .rs-col-xs-push-6 {
    left: 25%;
  }
  .rs-col-xs-push-5 {
    left: 20.83333333%;
  }
  .rs-col-xs-push-4 {
    left: 16.66666667%;
  }
  .rs-col-xs-push-3 {
    left: 12.5%;
  }
  .rs-col-xs-push-2 {
    left: 8.33333333%;
  }
  .rs-col-xs-push-1 {
    left: 4.16666667%;
  }
  .rs-col-xs-push-0 {
    left: auto;
  }
  .rs-col-xs-offset-24 {
    margin-left: 100%;
  }
  .rs-col-xs-offset-23 {
    margin-left: 95.83333333%;
  }
  .rs-col-xs-offset-22 {
    margin-left: 91.66666667%;
  }
  .rs-col-xs-offset-21 {
    margin-left: 87.5%;
  }
  .rs-col-xs-offset-20 {
    margin-left: 83.33333333%;
  }
  .rs-col-xs-offset-19 {
    margin-left: 79.16666667%;
  }
  .rs-col-xs-offset-18 {
    margin-left: 75%;
  }
  .rs-col-xs-offset-17 {
    margin-left: 70.83333333%;
  }
  .rs-col-xs-offset-16 {
    margin-left: 66.66666667%;
  }
  .rs-col-xs-offset-15 {
    margin-left: 62.5%;
  }
  .rs-col-xs-offset-14 {
    margin-left: 58.33333333%;
  }
  .rs-col-xs-offset-13 {
    margin-left: 54.16666667%;
  }
  .rs-col-xs-offset-12 {
    margin-left: 50%;
  }
  .rs-col-xs-offset-11 {
    margin-left: 45.83333333%;
  }
  .rs-col-xs-offset-10 {
    margin-left: 41.66666667%;
  }
  .rs-col-xs-offset-9 {
    margin-left: 37.5%;
  }
  .rs-col-xs-offset-8 {
    margin-left: 33.33333333%;
  }
  .rs-col-xs-offset-7 {
    margin-left: 29.16666667%;
  }
  .rs-col-xs-offset-6 {
    margin-left: 25%;
  }
  .rs-col-xs-offset-5 {
    margin-left: 20.83333333%;
  }
  .rs-col-xs-offset-4 {
    margin-left: 16.66666667%;
  }
  .rs-col-xs-offset-3 {
    margin-left: 12.5%;
  }
  .rs-col-xs-offset-2 {
    margin-left: 8.33333333%;
  }
  .rs-col-xs-offset-1 {
    margin-left: 4.16666667%;
  }
  .rs-col-xs-offset-0 {
    margin-left: 0%;
  }
  @media (min-width: 480px) {
    .rs-col-sm-1,
    .rs-col-sm-2,
    .rs-col-sm-3,
    .rs-col-sm-4,
    .rs-col-sm-5,
    .rs-col-sm-6,
    .rs-col-sm-7,
    .rs-col-sm-8,
    .rs-col-sm-9,
    .rs-col-sm-10,
    .rs-col-sm-11,
    .rs-col-sm-12,
    .rs-col-sm-13,
    .rs-col-sm-14,
    .rs-col-sm-15,
    .rs-col-sm-16,
    .rs-col-sm-17,
    .rs-col-sm-18,
    .rs-col-sm-19,
    .rs-col-sm-20,
    .rs-col-sm-21,
    .rs-col-sm-22,
    .rs-col-sm-23,
    .rs-col-sm-24 {
      float: left;
    }
    .rs-col-sm-24 {
      width: 100%;
    }
    .rs-col-sm-23 {
      width: 95.83333333%;
    }
    .rs-col-sm-22 {
      width: 91.66666667%;
    }
    .rs-col-sm-21 {
      width: 87.5%;
    }
    .rs-col-sm-20 {
      width: 83.33333333%;
    }
    .rs-col-sm-19 {
      width: 79.16666667%;
    }
    .rs-col-sm-18 {
      width: 75%;
    }
    .rs-col-sm-17 {
      width: 70.83333333%;
    }
    .rs-col-sm-16 {
      width: 66.66666667%;
    }
    .rs-col-sm-15 {
      width: 62.5%;
    }
    .rs-col-sm-14 {
      width: 58.33333333%;
    }
    .rs-col-sm-13 {
      width: 54.16666667%;
    }
    .rs-col-sm-12 {
      width: 50%;
    }
    .rs-col-sm-11 {
      width: 45.83333333%;
    }
    .rs-col-sm-10 {
      width: 41.66666667%;
    }
    .rs-col-sm-9 {
      width: 37.5%;
    }
    .rs-col-sm-8 {
      width: 33.33333333%;
    }
    .rs-col-sm-7 {
      width: 29.16666667%;
    }
    .rs-col-sm-6 {
      width: 25%;
    }
    .rs-col-sm-5 {
      width: 20.83333333%;
    }
    .rs-col-sm-4 {
      width: 16.66666667%;
    }
    .rs-col-sm-3 {
      width: 12.5%;
    }
    .rs-col-sm-2 {
      width: 8.33333333%;
    }
    .rs-col-sm-1 {
      width: 4.16666667%;
    }
    .rs-col-sm-pull-24 {
      right: 100%;
    }
    .rs-col-sm-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-sm-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-sm-pull-21 {
      right: 87.5%;
    }
    .rs-col-sm-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-sm-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-sm-pull-18 {
      right: 75%;
    }
    .rs-col-sm-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-sm-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-sm-pull-15 {
      right: 62.5%;
    }
    .rs-col-sm-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-sm-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-sm-pull-12 {
      right: 50%;
    }
    .rs-col-sm-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-sm-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-sm-pull-9 {
      right: 37.5%;
    }
    .rs-col-sm-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-sm-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-sm-pull-6 {
      right: 25%;
    }
    .rs-col-sm-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-sm-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-sm-pull-3 {
      right: 12.5%;
    }
    .rs-col-sm-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-sm-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-sm-pull-0 {
      right: auto;
    }
    .rs-col-sm-push-24 {
      left: 100%;
    }
    .rs-col-sm-push-23 {
      left: 95.83333333%;
    }
    .rs-col-sm-push-22 {
      left: 91.66666667%;
    }
    .rs-col-sm-push-21 {
      left: 87.5%;
    }
    .rs-col-sm-push-20 {
      left: 83.33333333%;
    }
    .rs-col-sm-push-19 {
      left: 79.16666667%;
    }
    .rs-col-sm-push-18 {
      left: 75%;
    }
    .rs-col-sm-push-17 {
      left: 70.83333333%;
    }
    .rs-col-sm-push-16 {
      left: 66.66666667%;
    }
    .rs-col-sm-push-15 {
      left: 62.5%;
    }
    .rs-col-sm-push-14 {
      left: 58.33333333%;
    }
    .rs-col-sm-push-13 {
      left: 54.16666667%;
    }
    .rs-col-sm-push-12 {
      left: 50%;
    }
    .rs-col-sm-push-11 {
      left: 45.83333333%;
    }
    .rs-col-sm-push-10 {
      left: 41.66666667%;
    }
    .rs-col-sm-push-9 {
      left: 37.5%;
    }
    .rs-col-sm-push-8 {
      left: 33.33333333%;
    }
    .rs-col-sm-push-7 {
      left: 29.16666667%;
    }
    .rs-col-sm-push-6 {
      left: 25%;
    }
    .rs-col-sm-push-5 {
      left: 20.83333333%;
    }
    .rs-col-sm-push-4 {
      left: 16.66666667%;
    }
    .rs-col-sm-push-3 {
      left: 12.5%;
    }
    .rs-col-sm-push-2 {
      left: 8.33333333%;
    }
    .rs-col-sm-push-1 {
      left: 4.16666667%;
    }
    .rs-col-sm-push-0 {
      left: auto;
    }
    .rs-col-sm-offset-24 {
      margin-left: 100%;
    }
    .rs-col-sm-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-sm-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-sm-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-sm-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-sm-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-sm-offset-18 {
      margin-left: 75%;
    }
    .rs-col-sm-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-sm-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-sm-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-sm-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-sm-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-sm-offset-12 {
      margin-left: 50%;
    }
    .rs-col-sm-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-sm-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-sm-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-sm-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-sm-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-sm-offset-6 {
      margin-left: 25%;
    }
    .rs-col-sm-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-sm-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-sm-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-sm-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-sm-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-sm-offset-0 {
      margin-left: 0%;
    }
  }
  @media (min-width: 992px) {
    .rs-col-md-1,
    .rs-col-md-2,
    .rs-col-md-3,
    .rs-col-md-4,
    .rs-col-md-5,
    .rs-col-md-6,
    .rs-col-md-7,
    .rs-col-md-8,
    .rs-col-md-9,
    .rs-col-md-10,
    .rs-col-md-11,
    .rs-col-md-12,
    .rs-col-md-13,
    .rs-col-md-14,
    .rs-col-md-15,
    .rs-col-md-16,
    .rs-col-md-17,
    .rs-col-md-18,
    .rs-col-md-19,
    .rs-col-md-20,
    .rs-col-md-21,
    .rs-col-md-22,
    .rs-col-md-23,
    .rs-col-md-24 {
      float: left;
    }
    .rs-col-md-24 {
      width: 100%;
    }
    .rs-col-md-23 {
      width: 95.83333333%;
    }
    .rs-col-md-22 {
      width: 91.66666667%;
    }
    .rs-col-md-21 {
      width: 87.5%;
    }
    .rs-col-md-20 {
      width: 83.33333333%;
    }
    .rs-col-md-19 {
      width: 79.16666667%;
    }
    .rs-col-md-18 {
      width: 75%;
    }
    .rs-col-md-17 {
      width: 70.83333333%;
    }
    .rs-col-md-16 {
      width: 66.66666667%;
    }
    .rs-col-md-15 {
      width: 62.5%;
    }
    .rs-col-md-14 {
      width: 58.33333333%;
    }
    .rs-col-md-13 {
      width: 54.16666667%;
    }
    .rs-col-md-12 {
      width: 50%;
    }
    .rs-col-md-11 {
      width: 45.83333333%;
    }
    .rs-col-md-10 {
      width: 41.66666667%;
    }
    .rs-col-md-9 {
      width: 37.5%;
    }
    .rs-col-md-8 {
      width: 33.33333333%;
    }
    .rs-col-md-7 {
      width: 29.16666667%;
    }
    .rs-col-md-6 {
      width: 25%;
    }
    .rs-col-md-5 {
      width: 20.83333333%;
    }
    .rs-col-md-4 {
      width: 16.66666667%;
    }
    .rs-col-md-3 {
      width: 12.5%;
    }
    .rs-col-md-2 {
      width: 8.33333333%;
    }
    .rs-col-md-1 {
      width: 4.16666667%;
    }
    .rs-col-md-pull-24 {
      right: 100%;
    }
    .rs-col-md-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-md-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-md-pull-21 {
      right: 87.5%;
    }
    .rs-col-md-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-md-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-md-pull-18 {
      right: 75%;
    }
    .rs-col-md-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-md-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-md-pull-15 {
      right: 62.5%;
    }
    .rs-col-md-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-md-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-md-pull-12 {
      right: 50%;
    }
    .rs-col-md-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-md-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-md-pull-9 {
      right: 37.5%;
    }
    .rs-col-md-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-md-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-md-pull-6 {
      right: 25%;
    }
    .rs-col-md-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-md-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-md-pull-3 {
      right: 12.5%;
    }
    .rs-col-md-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-md-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-md-pull-0 {
      right: auto;
    }
    .rs-col-md-push-24 {
      left: 100%;
    }
    .rs-col-md-push-23 {
      left: 95.83333333%;
    }
    .rs-col-md-push-22 {
      left: 91.66666667%;
    }
    .rs-col-md-push-21 {
      left: 87.5%;
    }
    .rs-col-md-push-20 {
      left: 83.33333333%;
    }
    .rs-col-md-push-19 {
      left: 79.16666667%;
    }
    .rs-col-md-push-18 {
      left: 75%;
    }
    .rs-col-md-push-17 {
      left: 70.83333333%;
    }
    .rs-col-md-push-16 {
      left: 66.66666667%;
    }
    .rs-col-md-push-15 {
      left: 62.5%;
    }
    .rs-col-md-push-14 {
      left: 58.33333333%;
    }
    .rs-col-md-push-13 {
      left: 54.16666667%;
    }
    .rs-col-md-push-12 {
      left: 50%;
    }
    .rs-col-md-push-11 {
      left: 45.83333333%;
    }
    .rs-col-md-push-10 {
      left: 41.66666667%;
    }
    .rs-col-md-push-9 {
      left: 37.5%;
    }
    .rs-col-md-push-8 {
      left: 33.33333333%;
    }
    .rs-col-md-push-7 {
      left: 29.16666667%;
    }
    .rs-col-md-push-6 {
      left: 25%;
    }
    .rs-col-md-push-5 {
      left: 20.83333333%;
    }
    .rs-col-md-push-4 {
      left: 16.66666667%;
    }
    .rs-col-md-push-3 {
      left: 12.5%;
    }
    .rs-col-md-push-2 {
      left: 8.33333333%;
    }
    .rs-col-md-push-1 {
      left: 4.16666667%;
    }
    .rs-col-md-push-0 {
      left: auto;
    }
    .rs-col-md-offset-24 {
      margin-left: 100%;
    }
    .rs-col-md-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-md-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-md-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-md-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-md-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-md-offset-18 {
      margin-left: 75%;
    }
    .rs-col-md-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-md-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-md-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-md-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-md-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-md-offset-12 {
      margin-left: 50%;
    }
    .rs-col-md-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-md-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-md-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-md-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-md-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-md-offset-6 {
      margin-left: 25%;
    }
    .rs-col-md-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-md-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-md-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-md-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-md-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-md-offset-0 {
      margin-left: 0%;
    }
  }
  @media (min-width: 1200px) {
    .rs-col-lg-1,
    .rs-col-lg-2,
    .rs-col-lg-3,
    .rs-col-lg-4,
    .rs-col-lg-5,
    .rs-col-lg-6,
    .rs-col-lg-7,
    .rs-col-lg-8,
    .rs-col-lg-9,
    .rs-col-lg-10,
    .rs-col-lg-11,
    .rs-col-lg-12,
    .rs-col-lg-13,
    .rs-col-lg-14,
    .rs-col-lg-15,
    .rs-col-lg-16,
    .rs-col-lg-17,
    .rs-col-lg-18,
    .rs-col-lg-19,
    .rs-col-lg-20,
    .rs-col-lg-21,
    .rs-col-lg-22,
    .rs-col-lg-23,
    .rs-col-lg-24 {
      float: left;
    }
    .rs-col-lg-24 {
      width: 100%;
    }
    .rs-col-lg-23 {
      width: 95.83333333%;
    }
    .rs-col-lg-22 {
      width: 91.66666667%;
    }
    .rs-col-lg-21 {
      width: 87.5%;
    }
    .rs-col-lg-20 {
      width: 83.33333333%;
    }
    .rs-col-lg-19 {
      width: 79.16666667%;
    }
    .rs-col-lg-18 {
      width: 75%;
    }
    .rs-col-lg-17 {
      width: 70.83333333%;
    }
    .rs-col-lg-16 {
      width: 66.66666667%;
    }
    .rs-col-lg-15 {
      width: 62.5%;
    }
    .rs-col-lg-14 {
      width: 58.33333333%;
    }
    .rs-col-lg-13 {
      width: 54.16666667%;
    }
    .rs-col-lg-12 {
      width: 50%;
    }
    .rs-col-lg-11 {
      width: 45.83333333%;
    }
    .rs-col-lg-10 {
      width: 41.66666667%;
    }
    .rs-col-lg-9 {
      width: 37.5%;
    }
    .rs-col-lg-8 {
      width: 33.33333333%;
    }
    .rs-col-lg-7 {
      width: 29.16666667%;
    }
    .rs-col-lg-6 {
      width: 25%;
    }
    .rs-col-lg-5 {
      width: 20.83333333%;
    }
    .rs-col-lg-4 {
      width: 16.66666667%;
    }
    .rs-col-lg-3 {
      width: 12.5%;
    }
    .rs-col-lg-2 {
      width: 8.33333333%;
    }
    .rs-col-lg-1 {
      width: 4.16666667%;
    }
    .rs-col-lg-pull-24 {
      right: 100%;
    }
    .rs-col-lg-pull-23 {
      right: 95.83333333%;
    }
    .rs-col-lg-pull-22 {
      right: 91.66666667%;
    }
    .rs-col-lg-pull-21 {
      right: 87.5%;
    }
    .rs-col-lg-pull-20 {
      right: 83.33333333%;
    }
    .rs-col-lg-pull-19 {
      right: 79.16666667%;
    }
    .rs-col-lg-pull-18 {
      right: 75%;
    }
    .rs-col-lg-pull-17 {
      right: 70.83333333%;
    }
    .rs-col-lg-pull-16 {
      right: 66.66666667%;
    }
    .rs-col-lg-pull-15 {
      right: 62.5%;
    }
    .rs-col-lg-pull-14 {
      right: 58.33333333%;
    }
    .rs-col-lg-pull-13 {
      right: 54.16666667%;
    }
    .rs-col-lg-pull-12 {
      right: 50%;
    }
    .rs-col-lg-pull-11 {
      right: 45.83333333%;
    }
    .rs-col-lg-pull-10 {
      right: 41.66666667%;
    }
    .rs-col-lg-pull-9 {
      right: 37.5%;
    }
    .rs-col-lg-pull-8 {
      right: 33.33333333%;
    }
    .rs-col-lg-pull-7 {
      right: 29.16666667%;
    }
    .rs-col-lg-pull-6 {
      right: 25%;
    }
    .rs-col-lg-pull-5 {
      right: 20.83333333%;
    }
    .rs-col-lg-pull-4 {
      right: 16.66666667%;
    }
    .rs-col-lg-pull-3 {
      right: 12.5%;
    }
    .rs-col-lg-pull-2 {
      right: 8.33333333%;
    }
    .rs-col-lg-pull-1 {
      right: 4.16666667%;
    }
    .rs-col-lg-pull-0 {
      right: auto;
    }
    .rs-col-lg-push-24 {
      left: 100%;
    }
    .rs-col-lg-push-23 {
      left: 95.83333333%;
    }
    .rs-col-lg-push-22 {
      left: 91.66666667%;
    }
    .rs-col-lg-push-21 {
      left: 87.5%;
    }
    .rs-col-lg-push-20 {
      left: 83.33333333%;
    }
    .rs-col-lg-push-19 {
      left: 79.16666667%;
    }
    .rs-col-lg-push-18 {
      left: 75%;
    }
    .rs-col-lg-push-17 {
      left: 70.83333333%;
    }
    .rs-col-lg-push-16 {
      left: 66.66666667%;
    }
    .rs-col-lg-push-15 {
      left: 62.5%;
    }
    .rs-col-lg-push-14 {
      left: 58.33333333%;
    }
    .rs-col-lg-push-13 {
      left: 54.16666667%;
    }
    .rs-col-lg-push-12 {
      left: 50%;
    }
    .rs-col-lg-push-11 {
      left: 45.83333333%;
    }
    .rs-col-lg-push-10 {
      left: 41.66666667%;
    }
    .rs-col-lg-push-9 {
      left: 37.5%;
    }
    .rs-col-lg-push-8 {
      left: 33.33333333%;
    }
    .rs-col-lg-push-7 {
      left: 29.16666667%;
    }
    .rs-col-lg-push-6 {
      left: 25%;
    }
    .rs-col-lg-push-5 {
      left: 20.83333333%;
    }
    .rs-col-lg-push-4 {
      left: 16.66666667%;
    }
    .rs-col-lg-push-3 {
      left: 12.5%;
    }
    .rs-col-lg-push-2 {
      left: 8.33333333%;
    }
    .rs-col-lg-push-1 {
      left: 4.16666667%;
    }
    .rs-col-lg-push-0 {
      left: auto;
    }
    .rs-col-lg-offset-24 {
      margin-left: 100%;
    }
    .rs-col-lg-offset-23 {
      margin-left: 95.83333333%;
    }
    .rs-col-lg-offset-22 {
      margin-left: 91.66666667%;
    }
    .rs-col-lg-offset-21 {
      margin-left: 87.5%;
    }
    .rs-col-lg-offset-20 {
      margin-left: 83.33333333%;
    }
    .rs-col-lg-offset-19 {
      margin-left: 79.16666667%;
    }
    .rs-col-lg-offset-18 {
      margin-left: 75%;
    }
    .rs-col-lg-offset-17 {
      margin-left: 70.83333333%;
    }
    .rs-col-lg-offset-16 {
      margin-left: 66.66666667%;
    }
    .rs-col-lg-offset-15 {
      margin-left: 62.5%;
    }
    .rs-col-lg-offset-14 {
      margin-left: 58.33333333%;
    }
    .rs-col-lg-offset-13 {
      margin-left: 54.16666667%;
    }
    .rs-col-lg-offset-12 {
      margin-left: 50%;
    }
    .rs-col-lg-offset-11 {
      margin-left: 45.83333333%;
    }
    .rs-col-lg-offset-10 {
      margin-left: 41.66666667%;
    }
    .rs-col-lg-offset-9 {
      margin-left: 37.5%;
    }
    .rs-col-lg-offset-8 {
      margin-left: 33.33333333%;
    }
    .rs-col-lg-offset-7 {
      margin-left: 29.16666667%;
    }
    .rs-col-lg-offset-6 {
      margin-left: 25%;
    }
    .rs-col-lg-offset-5 {
      margin-left: 20.83333333%;
    }
    .rs-col-lg-offset-4 {
      margin-left: 16.66666667%;
    }
    .rs-col-lg-offset-3 {
      margin-left: 12.5%;
    }
    .rs-col-lg-offset-2 {
      margin-left: 8.33333333%;
    }
    .rs-col-lg-offset-1 {
      margin-left: 4.16666667%;
    }
    .rs-col-lg-offset-0 {
      margin-left: 0%;
    }
  }
  @media (max-width: 479px) {
    .rs-hidden-xs {
      display: none;
    }
  }
  @media (min-width: 480px) and (max-width: 991px) {
    .rs-hidden-sm {
      display: none;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .rs-hidden-md {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .rs-hidden-lg {
      display: none;
    }
  }
  .rs-header {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-help-block {
    display: block;
    color: #8e8e93;
    min-height: 20px;
    line-height: 1.42857143;
    font-size: 14px;
  }
  .rs-help-block-tooltip {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
    margin-top: 8px;
  }
  .rs-help-block-tooltip .rs-icon::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\ea18';
  }
  .rs-icon {
    font-family: 'rsuite-icon-font' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    display: inline-block;
    text-transform: none;
    font-size: 14px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .rs-icon > svg {
    height: 14px;
  }
  .rs-icon-size-lg {
    font-size: 1.33333333em;
  }
  .rs-icon-size-lg > svg {
    vertical-align: middle;
    height: 18px;
    width: 18px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-icon-size-lg > svg {
      width: 18px;
    }
  }
  .rs-icon-size-1x,
  .rs-icon-size-1x.rs-icon {
    height: 14px;
    font-size: 14px;
  }
  .rs-icon-size-1x > svg,
  .rs-icon-size-1x.rs-icon > svg {
    height: 14px;
    width: 14px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-icon-size-1x > svg,
    .rs-icon-size-1x.rs-icon > svg {
      width: 14px;
    }
  }
  .rs-icon-size-2x,
  .rs-icon-size-2x.rs-icon {
    height: 28px;
    font-size: 28px;
  }
  .rs-icon-size-2x > svg,
  .rs-icon-size-2x.rs-icon > svg {
    height: 28px;
    width: 28px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-icon-size-2x > svg,
    .rs-icon-size-2x.rs-icon > svg {
      width: 28px;
    }
  }
  .rs-icon-size-3x,
  .rs-icon-size-3x.rs-icon {
    height: 42px;
    font-size: 42px;
  }
  .rs-icon-size-3x > svg,
  .rs-icon-size-3x.rs-icon > svg {
    height: 42px;
    width: 42px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-icon-size-3x > svg,
    .rs-icon-size-3x.rs-icon > svg {
      width: 42px;
    }
  }
  .rs-icon-size-4x,
  .rs-icon-size-4x.rs-icon {
    height: 56px;
    font-size: 56px;
  }
  .rs-icon-size-4x > svg,
  .rs-icon-size-4x.rs-icon > svg {
    height: 56px;
    width: 56px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-icon-size-4x > svg,
    .rs-icon-size-4x.rs-icon > svg {
      width: 56px;
    }
  }
  .rs-icon-size-5x,
  .rs-icon-size-5x.rs-icon {
    height: 70px;
    font-size: 70px;
  }
  .rs-icon-size-5x > svg,
  .rs-icon-size-5x.rs-icon > svg {
    height: 70px;
    width: 70px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-icon-size-5x > svg,
    .rs-icon-size-5x.rs-icon > svg {
      width: 70px;
    }
  }
  .rs-icon-spin {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  .rs-icon-pulse {
    -webkit-animation: icon-spin 1s infinite steps(8);
            animation: icon-spin 1s infinite steps(8);
  }
  .rs-icon-flip-horizontal {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
  .rs-icon-flip-vertical {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
  }
  .rs-icon-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2em;
  }
  .rs-icon-stack-size-lg {
    font-size: 1.33333333em;
  }
  .rs-icon-stack-size-1x {
    font-size: 1em;
  }
  .rs-icon-stack-size-2x {
    font-size: 2em;
  }
  .rs-icon-stack-size-3x {
    font-size: 3em;
  }
  .rs-icon-stack-size-4x {
    font-size: 4em;
  }
  .rs-icon-stack-size-5x {
    font-size: 5em;
  }
  .rs-icon-stack-1x,
  .rs-icon-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }
  .rs-icon-stack-1x {
    font-size: 1em;
    line-height: inherit;
  }
  .rs-icon-stack-2x {
    font-size: 2em;
  }
  .rs-icon-inverse {
    color: #fff;
  }
  .rs-icon-fw {
    width: 2.14285714em;
    text-align: center;
  }
  .rs-icon-500px::before {
    content: '\f26e';
  }
  .rs-icon-address-book::before {
    content: '\f2b9';
  }
  .rs-icon-address-book-o::before {
    content: '\f2ba';
  }
  .rs-icon-adjust::before {
    content: '\f042';
  }
  .rs-icon-adn::before {
    content: '\f170';
  }
  .rs-icon-align-center::before {
    content: '\f037';
  }
  .rs-icon-align-justify::before {
    content: '\f039';
  }
  .rs-icon-align-left::before {
    content: '\f036' /* rtl: "\f038" */;
  }
  .rs-icon-align-right::before {
    content: '\f038' /* rtl: "\f036" */;
  }
  .rs-icon-alipay::before {
    content: '\ea06';
  }
  .rs-icon-amazon::before {
    content: '\f270';
  }
  .rs-icon-ambulance::before {
    content: '\f0f9';
  }
  .rs-icon-anchor::before {
    content: '\f13d';
  }
  .rs-icon-android::before {
    content: '\f17b';
  }
  .rs-icon-android2::before {
    content: '\e900';
  }
  .rs-icon-angellist::before {
    content: '\f209';
  }
  .rs-icon-angle-double-down::before {
    content: '\f103';
  }
  .rs-icon-angle-double-left::before {
    content: '\f100' /* rtl: "\f101" */;
  }
  .rs-icon-angle-double-right::before {
    content: '\f101' /* rtl: "\f100" */;
  }
  .rs-icon-angle-double-up::before {
    content: '\f102';
  }
  .rs-icon-angle-down::before {
    content: '\f107';
  }
  .rs-icon-angle-left::before {
    content: '\f104' /* rtl: "\f105" */;
  }
  .rs-icon-angle-right::before {
    content: '\f105' /* rtl: "\f104" */;
  }
  .rs-icon-angle-up::before {
    content: '\f106';
  }
  .rs-icon-apple::before {
    content: '\f179';
  }
  .rs-icon-archive::before {
    content: '\f187';
  }
  .rs-icon-area-chart::before {
    content: '\f1fe';
  }
  .rs-icon-arrow-circle-down::before {
    content: '\f0ab';
  }
  .rs-icon-arrow-circle-left::before {
    content: '\f0a8' /* rtl: "\f0a9" */;
  }
  .rs-icon-arrow-circle-o-down::before {
    content: '\f01a';
  }
  .rs-icon-arrow-circle-o-left::before {
    content: '\f190' /* rtl: "\f18e" */;
  }
  .rs-icon-arrow-circle-o-right::before {
    content: '\f18e' /* rtl: "\f190" */;
  }
  .rs-icon-arrow-circle-o-up::before {
    content: '\f01b';
  }
  .rs-icon-arrow-circle-right::before {
    content: '\f0a9' /* rtl: "\f0a8" */;
  }
  .rs-icon-arrow-circle-up::before {
    content: '\f0aa';
  }
  .rs-icon-arrow-down::before {
    content: '\ea09';
  }
  .rs-icon-arrow-down-line::before {
    content: '\ea08';
  }
  .rs-icon-arrow-down2::before {
    content: '\e901';
  }
  .rs-icon-arrow-left::before {
    content: '\f060' /* rtl: "\f061" */;
  }
  .rs-icon-arrow-left-line::before {
    content: '\ea0a' /* rtl: "\ea0c" */;
  }
  .rs-icon-arrow-right::before {
    content: '\f061' /* rtl: "\f060" */;
  }
  .rs-icon-arrow-right-line::before {
    content: '\ea0c' /* rtl: "\ea0a" */;
  }
  .rs-icon-arrow-up::before {
    content: '\f062';
  }
  .rs-icon-arrow-up-line::before {
    content: '\ea0e';
  }
  .rs-icon-arrow-up2::before {
    content: '\e902';
  }
  .rs-icon-arrows::before {
    content: '\f047';
  }
  .rs-icon-arrows-alt::before {
    content: '\f0b2';
  }
  .rs-icon-arrows-h::before {
    content: '\f07e';
  }
  .rs-icon-arrows-v::before {
    content: '\f07d';
  }
  .rs-icon-asl-interpreting::before {
    content: '\f2a3';
  }
  .rs-icon-assistive-listening-systems::before {
    content: '\f2a2';
  }
  .rs-icon-asterisk::before {
    content: '\f069';
  }
  .rs-icon-at::before {
    content: '\f1fa';
  }
  .rs-icon-attachment::before {
    content: '\ea10';
  }
  .rs-icon-attribution::before {
    content: '\e903';
  }
  .rs-icon-audio-description::before {
    content: '\f29e';
  }
  .rs-icon-avatar::before {
    content: '\e904';
  }
  .rs-icon-back-arrow::before {
    content: '\ea12';
  }
  .rs-icon-backward::before {
    content: '\f04a';
  }
  .rs-icon-balance-scale::before {
    content: '\f24e';
  }
  .rs-icon-ban::before {
    content: '\f05e';
  }
  .rs-icon-bandcamp::before {
    content: '\f2d5';
  }
  .rs-icon-bank::before {
    content: '\f19c';
  }
  .rs-icon-bar-chart::before {
    content: '\f080';
  }
  .rs-icon-bar-chart-ranking::before {
    content: '\ea04';
  }
  .rs-icon-barcode::before {
    content: '\f02a';
  }
  .rs-icon-bars::before {
    content: '\f0c9';
  }
  .rs-icon-battery::before {
    content: '\f240';
  }
  .rs-icon-battery-0::before {
    content: '\f244';
  }
  .rs-icon-battery-1::before {
    content: '\f243';
  }
  .rs-icon-battery-2::before {
    content: '\f242';
  }
  .rs-icon-battery-3::before {
    content: '\f241';
  }
  .rs-icon-bed::before {
    content: '\f236';
  }
  .rs-icon-beer::before {
    content: '\f0fc';
  }
  .rs-icon-behance::before {
    content: '\f1b4';
  }
  .rs-icon-behance-square::before {
    content: '\f1b5';
  }
  .rs-icon-bell::before {
    content: '\f0f3';
  }
  .rs-icon-bell-o::before {
    content: '\f0a2';
  }
  .rs-icon-bell-slash::before {
    content: '\f1f6';
  }
  .rs-icon-bell-slash-o::before {
    content: '\f1f7';
  }
  .rs-icon-bicycle::before {
    content: '\f206';
  }
  .rs-icon-binoculars::before {
    content: '\f1e5';
  }
  .rs-icon-birthday-cake::before {
    content: '\f1fd';
  }
  .rs-icon-bitbucket::before {
    content: '\f171';
  }
  .rs-icon-bitbucket-square::before {
    content: '\f172';
  }
  .rs-icon-black-tie::before {
    content: '\f27e';
  }
  .rs-icon-blind::before {
    content: '\f29d';
  }
  .rs-icon-bluetooth::before {
    content: '\f293';
  }
  .rs-icon-bluetooth-b::before {
    content: '\f294';
  }
  .rs-icon-bold::before {
    content: '\f032';
  }
  .rs-icon-bolt::before {
    content: '\f0e7';
  }
  .rs-icon-bomb::before {
    content: '\f1e2';
  }
  .rs-icon-book::before {
    content: '\f02d';
  }
  .rs-icon-book2::before {
    content: '\e905';
  }
  .rs-icon-bookmark::before {
    content: '\f02e';
  }
  .rs-icon-bookmark-o::before {
    content: '\f097';
  }
  .rs-icon-braille::before {
    content: '\f2a1';
  }
  .rs-icon-briefcase::before {
    content: '\f0b1';
  }
  .rs-icon-btc::before {
    content: '\f15a';
  }
  .rs-icon-btn-off::before {
    content: '\e906';
  }
  .rs-icon-btn-on::before {
    content: '\e907';
  }
  .rs-icon-bug::before {
    content: '\f188';
  }
  .rs-icon-building::before {
    content: '\f1ad';
  }
  .rs-icon-building-o::before {
    content: '\f0f7';
  }
  .rs-icon-building2::before {
    content: '\e908';
  }
  .rs-icon-bullhorn::before {
    content: '\f0a1';
  }
  .rs-icon-bullseye::before {
    content: '\f140';
  }
  .rs-icon-bus::before {
    content: '\f207';
  }
  .rs-icon-buysellads::before {
    content: '\f20d';
  }
  .rs-icon-cab::before {
    content: '\f1ba';
  }
  .rs-icon-calculator::before {
    content: '\f1ec';
  }
  .rs-icon-calendar::before {
    content: '\f073';
  }
  .rs-icon-calendar-check-o::before {
    content: '\f274';
  }
  .rs-icon-calendar-minus-o::before {
    content: '\f272';
  }
  .rs-icon-calendar-o::before {
    content: '\f133';
  }
  .rs-icon-calendar-plus-o::before {
    content: '\f271';
  }
  .rs-icon-calendar-times-o::before {
    content: '\f273';
  }
  .rs-icon-camera::before {
    content: '\f030';
  }
  .rs-icon-camera-retro::before {
    content: '\f083';
  }
  .rs-icon-car::before {
    content: '\f1b9';
  }
  .rs-icon-caret-down::before {
    content: '\f0d7';
  }
  .rs-icon-caret-left::before {
    content: '\f0d9' /* rtl: "\f0da" */;
  }
  .rs-icon-caret-right::before {
    content: '\f0da' /* rtl: "\f0d9" */;
  }
  .rs-icon-caret-up::before {
    content: '\f0d8';
  }
  .rs-icon-cart-arrow-down::before {
    content: '\f218';
  }
  .rs-icon-cart-plus::before {
    content: '\f217';
  }
  .rs-icon-cc::before {
    content: '\f20a';
  }
  .rs-icon-cc-amex::before {
    content: '\f1f3';
  }
  .rs-icon-cc-diners-club::before {
    content: '\f24c';
  }
  .rs-icon-cc-discover::before {
    content: '\f1f2';
  }
  .rs-icon-cc-jcb::before {
    content: '\f24b';
  }
  .rs-icon-cc-mastercard::before {
    content: '\f1f1';
  }
  .rs-icon-cc-paypal::before {
    content: '\f1f4';
  }
  .rs-icon-cc-stripe::before {
    content: '\f1f5';
  }
  .rs-icon-cc-visa::before {
    content: '\f1f0';
  }
  .rs-icon-certificate::before {
    content: '\f0a3';
  }
  .rs-icon-character-area::before {
    content: '\ea13';
  }
  .rs-icon-character-authorize::before {
    content: '\ea1e';
  }
  .rs-icon-charts::before {
    content: '\e90a';
  }
  .rs-icon-charts-line::before {
    content: '\e909';
  }
  .rs-icon-check::before {
    content: '\f00c';
  }
  .rs-icon-check-circle::before {
    content: '\ea11';
  }
  .rs-icon-check-circle-o::before {
    content: '\f05d';
  }
  .rs-icon-check-square::before {
    content: '\f14a';
  }
  .rs-icon-check-square-o::before {
    content: '\f046';
  }
  .rs-icon-check2::before {
    content: '\e90b';
  }
  .rs-icon-chevron-circle-down::before {
    content: '\f13a';
  }
  .rs-icon-chevron-circle-left::before {
    content: '\f137' /* rtl: "\f138" */;
  }
  .rs-icon-chevron-circle-right::before {
    content: '\f138' /* rtl: "\f137" */;
  }
  .rs-icon-chevron-circle-up::before {
    content: '\f139';
  }
  .rs-icon-chevron-down::before {
    content: '\f078';
  }
  .rs-icon-chevron-left::before {
    content: '\f053' /* rtl: "\f054" */;
  }
  .rs-icon-chevron-right::before {
    content: '\f054' /* rtl: "\f053" */;
  }
  .rs-icon-chevron-up::before {
    content: '\f077';
  }
  .rs-icon-child::before {
    content: '\f1ae';
  }
  .rs-icon-chrome::before {
    content: '\f268';
  }
  .rs-icon-circle::before {
    content: '\f111';
  }
  .rs-icon-circle-o::before {
    content: '\f10c';
  }
  .rs-icon-circle-o-notch::before {
    content: '\f1ce';
  }
  .rs-icon-circle-thin::before {
    content: '\f1db';
  }
  .rs-icon-clock-o::before {
    content: '\f017';
  }
  .rs-icon-clone::before {
    content: '\f24d';
  }
  .rs-icon-close::before {
    content: '\f00d';
  }
  .rs-icon-close-circle::before {
    content: '\e90c';
  }
  .rs-icon-cloud::before {
    content: '\f0c2';
  }
  .rs-icon-cloud-download::before {
    content: '\f0ed';
  }
  .rs-icon-cloud-upload::before {
    content: '\f0ee';
  }
  .rs-icon-cny::before {
    content: '\f157';
  }
  .rs-icon-code::before {
    content: '\f121';
  }
  .rs-icon-code-fork::before {
    content: '\f126';
  }
  .rs-icon-codepen::before {
    content: '\f1cb';
  }
  .rs-icon-codiepie::before {
    content: '\f284';
  }
  .rs-icon-coffee::before {
    content: '\f0f4';
  }
  .rs-icon-cog::before {
    content: '\f013';
  }
  .rs-icon-cogs::before {
    content: '\f085';
  }
  .rs-icon-coincide::before {
    content: '\e90e';
  }
  .rs-icon-collasped::before {
    content: '\ea15';
  }
  .rs-icon-collasped-o::before {
    content: '\ea14';
  }
  .rs-icon-columns::before {
    content: '\f0db';
  }
  .rs-icon-comment::before {
    content: '\f075';
  }
  .rs-icon-comment-o::before {
    content: '\f0e5';
  }
  .rs-icon-commenting::before {
    content: '\f27a';
  }
  .rs-icon-commenting-o::before {
    content: '\f27b';
  }
  .rs-icon-comments::before {
    content: '\f086';
  }
  .rs-icon-comments-o::before {
    content: '\f0e6';
  }
  .rs-icon-compass::before {
    content: '\f14e';
  }
  .rs-icon-compress::before {
    content: '\f066';
  }
  .rs-icon-connectdevelop::before {
    content: '\f20e';
  }
  .rs-icon-contao::before {
    content: '\f26d';
  }
  .rs-icon-copy::before {
    content: '\f0c5';
  }
  .rs-icon-copy-o::before {
    content: '\ea16';
  }
  .rs-icon-copyright::before {
    content: '\f1f9';
  }
  .rs-icon-creative::before {
    content: '\ea26';
  }
  .rs-icon-creative-commons::before {
    content: '\f25e';
  }
  .rs-icon-credit-card::before {
    content: '\f09d';
  }
  .rs-icon-credit-card-alt::before {
    content: '\f283';
  }
  .rs-icon-crop::before {
    content: '\f125';
  }
  .rs-icon-crosshairs::before {
    content: '\f05b';
  }
  .rs-icon-css3::before {
    content: '\f13c';
  }
  .rs-icon-cube::before {
    content: '\f1b2';
  }
  .rs-icon-cubes::before {
    content: '\f1b3';
  }
  .rs-icon-cut::before {
    content: '\f0c4';
  }
  .rs-icon-cutlery::before {
    content: '\f0f5';
  }
  .rs-icon-dashboard::before {
    content: '\f0e4';
  }
  .rs-icon-dashcube::before {
    content: '\f210';
  }
  .rs-icon-data-authorize::before {
    content: '\ea28';
  }
  .rs-icon-data-decrease::before {
    content: '\e90f';
  }
  .rs-icon-data-increase::before {
    content: '\e910';
  }
  .rs-icon-database::before {
    content: '\f1c0';
  }
  .rs-icon-deaf::before {
    content: '\f2a4';
  }
  .rs-icon-dedent::before {
    content: '\f03b';
  }
  .rs-icon-delicious::before {
    content: '\f1a5';
  }
  .rs-icon-desktop::before {
    content: '\f108';
  }
  .rs-icon-detail::before {
    content: '\ea29';
  }
  .rs-icon-deviantart::before {
    content: '\f1bd';
  }
  .rs-icon-diamond::before {
    content: '\f219';
  }
  .rs-icon-digg::before {
    content: '\f1a6';
  }
  .rs-icon-dot-circle-o::before {
    content: '\f192';
  }
  .rs-icon-down::before {
    content: '\e911';
  }
  .rs-icon-download::before {
    content: '\f019';
  }
  .rs-icon-download2::before {
    content: '\e912';
  }
  .rs-icon-dribbble::before {
    content: '\f17d';
  }
  .rs-icon-dropbox::before {
    content: '\f16b';
  }
  .rs-icon-drupal::before {
    content: '\f1a9';
  }
  .rs-icon-edge::before {
    content: '\f282';
  }
  .rs-icon-edit::before {
    content: '\f044';
  }
  .rs-icon-edit2::before {
    content: '\e913';
  }
  .rs-icon-eercast::before {
    content: '\f2da';
  }
  .rs-icon-eject::before {
    content: '\f052';
  }
  .rs-icon-ellipsis-h::before {
    content: '\f141';
  }
  .rs-icon-ellipsis-v::before {
    content: '\f142';
  }
  .rs-icon-envelope::before {
    content: '\f0e0';
  }
  .rs-icon-envelope-o::before {
    content: '\f003';
  }
  .rs-icon-envelope-open::before {
    content: '\f2b6';
  }
  .rs-icon-envelope-open-o::before {
    content: '\f2b7';
  }
  .rs-icon-envelope-square::before {
    content: '\f199';
  }
  .rs-icon-envira::before {
    content: '\f299';
  }
  .rs-icon-eraser::before {
    content: '\f12d';
  }
  .rs-icon-etsy::before {
    content: '\f2d7';
  }
  .rs-icon-eur::before {
    content: '\f153';
  }
  .rs-icon-exchange::before {
    content: '\f0ec';
  }
  .rs-icon-exclamation::before {
    content: '\f12a';
  }
  .rs-icon-exclamation-circle::before {
    content: '\f06a';
  }
  .rs-icon-exclamation-circle2::before {
    content: '\e914';
  }
  .rs-icon-exclamation-triangle::before {
    content: '\f071';
  }
  .rs-icon-exit::before {
    content: '\e915';
  }
  .rs-icon-expand::before {
    content: '\f065';
  }
  .rs-icon-expand-o::before {
    content: '\ea17';
  }
  .rs-icon-expeditedssl::before {
    content: '\f23e';
  }
  .rs-icon-explore::before {
    content: '\ea2b';
  }
  .rs-icon-export::before {
    content: '\ea2c';
  }
  .rs-icon-external-link::before {
    content: '\f08e';
  }
  .rs-icon-external-link-square::before {
    content: '\f14c';
  }
  .rs-icon-eye::before {
    content: '\f06e';
  }
  .rs-icon-eye-slash::before {
    content: '\f070';
  }
  .rs-icon-eyedropper::before {
    content: '\f1fb';
  }
  .rs-icon-fa::before {
    content: '\f2b4';
  }
  .rs-icon-facebook::before {
    content: '\f09a';
  }
  .rs-icon-facebook-official::before {
    content: '\f230';
  }
  .rs-icon-facebook-square::before {
    content: '\f082';
  }
  .rs-icon-fast-backward::before {
    content: '\f049';
  }
  .rs-icon-fast-forward::before {
    content: '\f050';
  }
  .rs-icon-fax::before {
    content: '\f1ac';
  }
  .rs-icon-female::before {
    content: '\f182';
  }
  .rs-icon-fighter-jet::before {
    content: '\f0fb';
  }
  .rs-icon-file::before {
    content: '\f15b';
  }
  .rs-icon-file-audio-o::before {
    content: '\f1c7';
  }
  .rs-icon-file-code-o::before {
    content: '\f1c9';
  }
  .rs-icon-file-download::before {
    content: '\ea2d';
  }
  .rs-icon-file-excel-o::before {
    content: '\f1c3';
  }
  .rs-icon-file-image-o::before {
    content: '\f1c5';
  }
  .rs-icon-file-movie-o::before {
    content: '\f1c8';
  }
  .rs-icon-file-o::before {
    content: '\f016';
  }
  .rs-icon-file-pdf-o::before {
    content: '\f1c1';
  }
  .rs-icon-file-powerpoint-o::before {
    content: '\f1c4';
  }
  .rs-icon-file-text::before {
    content: '\f15c';
  }
  .rs-icon-file-text-o::before {
    content: '\f0f6';
  }
  .rs-icon-file-upload::before {
    content: '\ea2e';
  }
  .rs-icon-file-word-o::before {
    content: '\f1c2';
  }
  .rs-icon-file-zip-o::before {
    content: '\f1c6';
  }
  .rs-icon-film::before {
    content: '\f008';
  }
  .rs-icon-filter::before {
    content: '\f0b0';
  }
  .rs-icon-fire::before {
    content: '\f06d';
  }
  .rs-icon-fire-extinguisher::before {
    content: '\f134';
  }
  .rs-icon-firefox::before {
    content: '\f269';
  }
  .rs-icon-first-order::before {
    content: '\f2b0';
  }
  .rs-icon-flag::before {
    content: '\f024';
  }
  .rs-icon-flag-checkered::before {
    content: '\f11e';
  }
  .rs-icon-flag-o::before {
    content: '\f11d';
  }
  .rs-icon-flask::before {
    content: '\f0c3';
  }
  .rs-icon-flickr::before {
    content: '\f16e';
  }
  .rs-icon-flow::before {
    content: '\e916';
  }
  .rs-icon-folder::before {
    content: '\f07b';
  }
  .rs-icon-folder-o::before {
    content: '\f114';
  }
  .rs-icon-folder-open::before {
    content: '\f07c';
  }
  .rs-icon-folder-open-o::before {
    content: '\f115';
  }
  .rs-icon-font::before {
    content: '\f031';
  }
  .rs-icon-fonticons::before {
    content: '\f280';
  }
  .rs-icon-fort-awesome::before {
    content: '\f286';
  }
  .rs-icon-forumbee::before {
    content: '\f211';
  }
  .rs-icon-forward::before {
    content: '\f04e';
  }
  .rs-icon-foursquare::before {
    content: '\f180';
  }
  .rs-icon-frame::before {
    content: '\e917';
  }
  .rs-icon-free-code-camp::before {
    content: '\f2c5';
  }
  .rs-icon-frown-o::before {
    content: '\f119';
  }
  .rs-icon-futbol-o::before {
    content: '\f1e3';
  }
  .rs-icon-gamepad::before {
    content: '\f11b';
  }
  .rs-icon-gavel::before {
    content: '\f0e3';
  }
  .rs-icon-gbp::before {
    content: '\f154';
  }
  .rs-icon-ge::before {
    content: '\f1d1';
  }
  .rs-icon-gear::before {
    content: '\ea2f';
  }
  .rs-icon-gear-circle::before {
    content: '\e918';
  }
  .rs-icon-gear2::before {
    content: '\e919';
  }
  .rs-icon-gears2::before {
    content: '\e91a';
  }
  .rs-icon-genderless::before {
    content: '\f22d';
  }
  .rs-icon-get-pocket::before {
    content: '\f265';
  }
  .rs-icon-gg::before {
    content: '\f260';
  }
  .rs-icon-gg-circle::before {
    content: '\f261';
  }
  .rs-icon-gift::before {
    content: '\f06b';
  }
  .rs-icon-git::before {
    content: '\f1d3';
  }
  .rs-icon-git-square::before {
    content: '\f1d2';
  }
  .rs-icon-github::before {
    content: '\f09b';
  }
  .rs-icon-github-alt::before {
    content: '\f113';
  }
  .rs-icon-github-square::before {
    content: '\f092';
  }
  .rs-icon-gitlab::before {
    content: '\f296';
  }
  .rs-icon-gittip::before {
    content: '\f184';
  }
  .rs-icon-glass::before {
    content: '\f000';
  }
  .rs-icon-glide::before {
    content: '\f2a5';
  }
  .rs-icon-glide-g::before {
    content: '\f2a6';
  }
  .rs-icon-globe::before {
    content: '\f0ac';
  }
  .rs-icon-globe2::before {
    content: '\e91b';
  }
  .rs-icon-good::before {
    content: '\e91c';
  }
  .rs-icon-google::before {
    content: '\f1a0';
  }
  .rs-icon-google-plus::before {
    content: '\f0d5';
  }
  .rs-icon-google-plus-circle::before {
    content: '\f2b3';
  }
  .rs-icon-google-plus-square::before {
    content: '\f0d4';
  }
  .rs-icon-google-wallet::before {
    content: '\f1ee';
  }
  .rs-icon-grav::before {
    content: '\f2d6';
  }
  .rs-icon-group::before {
    content: '\f0c0';
  }
  .rs-icon-h-square::before {
    content: '\f0fd';
  }
  .rs-icon-hand-grab-o::before {
    content: '\f255';
  }
  .rs-icon-hand-lizard-o::before {
    content: '\f258';
  }
  .rs-icon-hand-o-down::before {
    content: '\f0a7';
  }
  .rs-icon-hand-o-left::before {
    content: '\f0a5' /* rtl: "\f0a4" */;
  }
  .rs-icon-hand-o-right::before {
    content: '\f0a4' /* rtl: "\f0a5" */;
  }
  .rs-icon-hand-o-up::before {
    content: '\f0a6';
  }
  .rs-icon-hand-peace-o::before {
    content: '\f25b';
  }
  .rs-icon-hand-pointer-o::before {
    content: '\f25a';
  }
  .rs-icon-hand-scissors-o::before {
    content: '\f257';
  }
  .rs-icon-hand-spock-o::before {
    content: '\f259';
  }
  .rs-icon-hand-stop-o::before {
    content: '\f256';
  }
  .rs-icon-handshake-o::before {
    content: '\f2b5';
  }
  .rs-icon-hashtag::before {
    content: '\f292';
  }
  .rs-icon-hdd-o::before {
    content: '\f0a0';
  }
  .rs-icon-header::before {
    content: '\f1dc';
  }
  .rs-icon-headphones::before {
    content: '\f025';
  }
  .rs-icon-heart::before {
    content: '\f004';
  }
  .rs-icon-heart-o::before {
    content: '\f08a';
  }
  .rs-icon-heartbeat::before {
    content: '\f21e';
  }
  .rs-icon-help-o::before {
    content: '\ea18';
  }
  .rs-icon-history::before {
    content: '\f1da';
  }
  .rs-icon-home::before {
    content: '\f015';
  }
  .rs-icon-hospital-o::before {
    content: '\f0f8';
  }
  .rs-icon-hourglass::before {
    content: '\f254';
  }
  .rs-icon-hourglass-1::before {
    content: '\f251';
  }
  .rs-icon-hourglass-2::before {
    content: '\f252';
  }
  .rs-icon-hourglass-3::before {
    content: '\f253';
  }
  .rs-icon-hourglass-o::before {
    content: '\f250';
  }
  .rs-icon-houzz::before {
    content: '\f27c';
  }
  .rs-icon-html5::before {
    content: '\f13b';
  }
  .rs-icon-i-cursor::before {
    content: '\f246';
  }
  .rs-icon-id-badge::before {
    content: '\f2c1';
  }
  .rs-icon-id-card::before {
    content: '\f2c2';
  }
  .rs-icon-id-card-o::before {
    content: '\f2c3';
  }
  .rs-icon-id-info::before {
    content: '\ea0d';
  }
  .rs-icon-id-mapping::before {
    content: '\ea30';
  }
  .rs-icon-ils::before {
    content: '\f20b';
  }
  .rs-icon-image::before {
    content: '\f03e';
  }
  .rs-icon-imdb::before {
    content: '\f2d8';
  }
  .rs-icon-import::before {
    content: '\ea31';
  }
  .rs-icon-inbox::before {
    content: '\f01c';
  }
  .rs-icon-indent::before {
    content: '\f03c';
  }
  .rs-icon-industry::before {
    content: '\f275';
  }
  .rs-icon-info::before {
    content: '\ea19';
  }
  .rs-icon-info-circle::before {
    content: '\f05a';
  }
  .rs-icon-inr::before {
    content: '\f156';
  }
  .rs-icon-instagram::before {
    content: '\f16d';
  }
  .rs-icon-internet-explorer::before {
    content: '\f26b';
  }
  .rs-icon-intersex::before {
    content: '\f224';
  }
  .rs-icon-ios::before {
    content: '\e91e';
  }
  .rs-icon-ioxhost::before {
    content: '\f208';
  }
  .rs-icon-italic::before {
    content: '\f033';
  }
  .rs-icon-joomla::before {
    content: '\f1aa';
  }
  .rs-icon-jsfiddle::before {
    content: '\f1cc';
  }
  .rs-icon-key::before {
    content: '\f084';
  }
  .rs-icon-keyboard-o::before {
    content: '\f11c';
  }
  .rs-icon-krw::before {
    content: '\f159';
  }
  .rs-icon-language::before {
    content: '\f1ab';
  }
  .rs-icon-laptop::before {
    content: '\f109';
  }
  .rs-icon-lastfm::before {
    content: '\f202';
  }
  .rs-icon-lastfm-square::before {
    content: '\f203';
  }
  .rs-icon-leaf::before {
    content: '\f06c';
  }
  .rs-icon-leanpub::before {
    content: '\f212';
  }
  .rs-icon-left::before {
    content: '\e91f' /* rtl: "\e932" */;
  }
  .rs-icon-lemon-o::before {
    content: '\f094';
  }
  .rs-icon-level-down::before {
    content: '\f149';
  }
  .rs-icon-level-up::before {
    content: '\f148';
  }
  .rs-icon-lightbulb-o::before {
    content: '\f0eb';
  }
  .rs-icon-line-chart::before {
    content: '\f201';
  }
  .rs-icon-link::before {
    content: '\f0c1';
  }
  .rs-icon-linkedin::before {
    content: '\f0e1';
  }
  .rs-icon-linkedin-square::before {
    content: '\f08c';
  }
  .rs-icon-linode::before {
    content: '\f2b8';
  }
  .rs-icon-linux::before {
    content: '\f17c';
  }
  .rs-icon-list::before {
    content: '\f03a';
  }
  .rs-icon-list-alt::before {
    content: '\f022';
  }
  .rs-icon-list-ol::before {
    content: '\f0cb';
  }
  .rs-icon-list-ul::before {
    content: '\f0ca';
  }
  .rs-icon-location-arrow::before {
    content: '\f124';
  }
  .rs-icon-lock::before {
    content: '\f023';
  }
  .rs-icon-logo-ads::before {
    content: '\e920';
  }
  .rs-icon-logo-analytics::before {
    content: '\e921';
  }
  .rs-icon-logo-dmp::before {
    content: '\e922';
  }
  .rs-icon-logo-mobile::before {
    content: '\e923';
  }
  .rs-icon-logo-shop::before {
    content: '\e924';
  }
  .rs-icon-logo-survey::before {
    content: '\e925';
  }
  .rs-icon-logo-video::before {
    content: '\e926';
  }
  .rs-icon-long-arrow-down::before {
    content: '\f175';
  }
  .rs-icon-long-arrow-left::before {
    content: '\f177' /* rtl: "\f178" */;
  }
  .rs-icon-long-arrow-right::before {
    content: '\f178' /* rtl: "\f177" */;
  }
  .rs-icon-long-arrow-up::before {
    content: '\f176';
  }
  .rs-icon-low-vision::before {
    content: '\f2a8';
  }
  .rs-icon-magic::before {
    content: '\f0d0';
  }
  .rs-icon-magic2::before {
    content: '\e927';
  }
  .rs-icon-magnet::before {
    content: '\f076';
  }
  .rs-icon-male::before {
    content: '\f183';
  }
  .rs-icon-map::before {
    content: '\f279';
  }
  .rs-icon-map-marker::before {
    content: '\f041';
  }
  .rs-icon-map-o::before {
    content: '\f278';
  }
  .rs-icon-map-pin::before {
    content: '\f276';
  }
  .rs-icon-map-signs::before {
    content: '\f277';
  }
  .rs-icon-mars::before {
    content: '\f222';
  }
  .rs-icon-mars-double::before {
    content: '\f227';
  }
  .rs-icon-mars-stroke::before {
    content: '\f229';
  }
  .rs-icon-mars-stroke-h::before {
    content: '\f22b';
  }
  .rs-icon-mars-stroke-v::before {
    content: '\f22a';
  }
  .rs-icon-maxcdn::before {
    content: '\f136';
  }
  .rs-icon-meanpath::before {
    content: '\f20c';
  }
  .rs-icon-medium::before {
    content: '\f23a';
  }
  .rs-icon-medkit::before {
    content: '\f0fa';
  }
  .rs-icon-meetup::before {
    content: '\f2e0';
  }
  .rs-icon-meh-o::before {
    content: '\f11a';
  }
  .rs-icon-mercury::before {
    content: '\f223';
  }
  .rs-icon-microchip::before {
    content: '\f2db';
  }
  .rs-icon-microphone::before {
    content: '\f130';
  }
  .rs-icon-microphone-slash::before {
    content: '\f131';
  }
  .rs-icon-minus::before {
    content: '\f068';
  }
  .rs-icon-minus-circle::before {
    content: '\f056';
  }
  .rs-icon-minus-square::before {
    content: '\f146';
  }
  .rs-icon-minus-square-o::before {
    content: '\f147';
  }
  .rs-icon-mixcloud::before {
    content: '\f289';
  }
  .rs-icon-mobile::before {
    content: '\f10b';
  }
  .rs-icon-modx::before {
    content: '\f285';
  }
  .rs-icon-money::before {
    content: '\f0d6';
  }
  .rs-icon-moon-o::before {
    content: '\f186';
  }
  .rs-icon-more::before {
    content: '\ea0b';
  }
  .rs-icon-mortar-board::before {
    content: '\f19d';
  }
  .rs-icon-motorcycle::before {
    content: '\f21c';
  }
  .rs-icon-mouse-pointer::before {
    content: '\f245';
  }
  .rs-icon-multiple-lines-chart::before {
    content: '\ea05';
  }
  .rs-icon-music::before {
    content: '\f001';
  }
  .rs-icon-neuter::before {
    content: '\f22c';
  }
  .rs-icon-newspaper-o::before {
    content: '\f1ea';
  }
  .rs-icon-object-group::before {
    content: '\f247';
  }
  .rs-icon-object-ungroup::before {
    content: '\f248';
  }
  .rs-icon-odnoklassniki::before {
    content: '\f263';
  }
  .rs-icon-odnoklassniki-square::before {
    content: '\f264';
  }
  .rs-icon-off::before {
    content: '\ea34';
  }
  .rs-icon-ok-circle::before {
    content: '\e928';
  }
  .rs-icon-opencart::before {
    content: '\f23d';
  }
  .rs-icon-openid::before {
    content: '\f19b';
  }
  .rs-icon-opera::before {
    content: '\f26a';
  }
  .rs-icon-optin-monster::before {
    content: '\f23c';
  }
  .rs-icon-order-form::before {
    content: '\e929';
  }
  .rs-icon-page-end::before {
    content: '\ea1a';
  }
  .rs-icon-page-next::before {
    content: '\ea1b';
  }
  .rs-icon-page-previous::before {
    content: '\ea1c';
  }
  .rs-icon-page-top::before {
    content: '\ea1d';
  }
  .rs-icon-pagelines::before {
    content: '\f18c';
  }
  .rs-icon-paint-brush::before {
    content: '\f1fc';
  }
  .rs-icon-paperclip::before {
    content: '\f0c6';
  }
  .rs-icon-paragraph::before {
    content: '\f1dd';
  }
  .rs-icon-paste::before {
    content: '\f0ea';
  }
  .rs-icon-pause::before {
    content: '\f04c';
  }
  .rs-icon-pause-circle::before {
    content: '\f28b';
  }
  .rs-icon-pause-circle-o::before {
    content: '\f28c';
  }
  .rs-icon-paw::before {
    content: '\f1b0';
  }
  .rs-icon-paypal::before {
    content: '\f1ed';
  }
  .rs-icon-pc::before {
    content: '\e92a';
  }
  .rs-icon-pencil::before {
    content: '\f040';
  }
  .rs-icon-pencil-square::before {
    content: '\f14b';
  }
  .rs-icon-people-group::before {
    content: '\e92b';
  }
  .rs-icon-peoples::before {
    content: '\ea36';
  }
  .rs-icon-peoples-map::before {
    content: '\ea35';
  }
  .rs-icon-percent::before {
    content: '\f295';
  }
  .rs-icon-phone::before {
    content: '\f095';
  }
  .rs-icon-phone-square::before {
    content: '\f098';
  }
  .rs-icon-pie-chart::before {
    content: '\f200';
  }
  .rs-icon-pied-piper::before {
    content: '\f2ae';
  }
  .rs-icon-pied-piper-alt::before {
    content: '\f1a8';
  }
  .rs-icon-pied-piper-pp::before {
    content: '\f1a7';
  }
  .rs-icon-pinterest::before {
    content: '\f0d2';
  }
  .rs-icon-pinterest-p::before {
    content: '\f231';
  }
  .rs-icon-pinterest-square::before {
    content: '\f0d3';
  }
  .rs-icon-plane::before {
    content: '\f072';
  }
  .rs-icon-play::before {
    content: '\f04b';
  }
  .rs-icon-play-circle::before {
    content: '\f144';
  }
  .rs-icon-play-circle-o::before {
    content: '\f01d';
  }
  .rs-icon-play2::before {
    content: '\e92c';
  }
  .rs-icon-plug::before {
    content: '\f1e6';
  }
  .rs-icon-plus::before {
    content: '\f067';
  }
  .rs-icon-plus-circle::before {
    content: '\f055';
  }
  .rs-icon-plus-square::before {
    content: '\f0fe';
  }
  .rs-icon-plus-square-o::before {
    content: '\f196';
  }
  .rs-icon-podcast::before {
    content: '\f2ce';
  }
  .rs-icon-power-off::before {
    content: '\f011';
  }
  .rs-icon-print::before {
    content: '\f02f';
  }
  .rs-icon-product-hunt::before {
    content: '\f288';
  }
  .rs-icon-profile::before {
    content: '\e92d';
  }
  .rs-icon-project::before {
    content: '\ea39';
  }
  .rs-icon-public-opinion::before {
    content: '\ea3a';
  }
  .rs-icon-puzzle-piece::before {
    content: '\f12e';
  }
  .rs-icon-qq::before {
    content: '\f1d6';
  }
  .rs-icon-qrcode::before {
    content: '\f029';
  }
  .rs-icon-question::before {
    content: '\f128';
  }
  .rs-icon-question-circle::before {
    content: '\f059';
  }
  .rs-icon-question-circle2::before {
    content: '\e92e';
  }
  .rs-icon-question2::before {
    content: '\e92f';
  }
  .rs-icon-quora::before {
    content: '\f2c4';
  }
  .rs-icon-quote-left::before {
    content: '\f10d';
  }
  .rs-icon-quote-right::before {
    content: '\f10e';
  }
  .rs-icon-ra::before {
    content: '\f1d0';
  }
  .rs-icon-random::before {
    content: '\f074';
  }
  .rs-icon-rate::before {
    content: '\ea3b';
  }
  .rs-icon-ravelry::before {
    content: '\f2d9';
  }
  .rs-icon-realtime::before {
    content: '\ea3c';
  }
  .rs-icon-recycle::before {
    content: '\f1b8';
  }
  .rs-icon-reddit::before {
    content: '\f1a1';
  }
  .rs-icon-reddit-alien::before {
    content: '\f281';
  }
  .rs-icon-reddit-square::before {
    content: '\f1a2';
  }
  .rs-icon-refresh::before {
    content: '\f021';
  }
  .rs-icon-refresh2::before {
    content: '\e930';
  }
  .rs-icon-registered::before {
    content: '\f25d';
  }
  .rs-icon-related-map::before {
    content: '\ea3d';
  }
  .rs-icon-reload::before {
    content: '\ea1f';
  }
  .rs-icon-remind::before {
    content: '\ea20';
  }
  .rs-icon-renren::before {
    content: '\f18b';
  }
  .rs-icon-repeat::before {
    content: '\f01e';
  }
  .rs-icon-reply::before {
    content: '\f112';
  }
  .rs-icon-reply-all::before {
    content: '\f122';
  }
  .rs-icon-retention::before {
    content: '\e931';
  }
  .rs-icon-retweet::before {
    content: '\f079';
  }
  .rs-icon-right::before {
    content: '\e932' /* rtl: "\e91f" */;
  }
  .rs-icon-road::before {
    content: '\f018';
  }
  .rs-icon-rocket::before {
    content: '\f135';
  }
  .rs-icon-rss::before {
    content: '\f09e';
  }
  .rs-icon-rss-square::before {
    content: '\f143';
  }
  .rs-icon-rub::before {
    content: '\f158';
  }
  .rs-icon-s15::before {
    content: '\f2cd';
  }
  .rs-icon-safari::before {
    content: '\f267';
  }
  .rs-icon-sales::before {
    content: '\ea37';
  }
  .rs-icon-growth::before {
    content: '\ea37';
  }
  .rs-icon-save::before {
    content: '\f0c7';
  }
  .rs-icon-scribd::before {
    content: '\f28a';
  }
  .rs-icon-search::before {
    content: '\f002';
  }
  .rs-icon-search-minus::before {
    content: '\f010';
  }
  .rs-icon-search-peoples::before {
    content: '\ea3f';
  }
  .rs-icon-search-plus::before {
    content: '\f00e';
  }
  .rs-icon-sellsy::before {
    content: '\f213';
  }
  .rs-icon-send::before {
    content: '\f1d8';
  }
  .rs-icon-send-o::before {
    content: '\f1d9';
  }
  .rs-icon-sequence::before {
    content: '\ea24';
  }
  .rs-icon-sequence-down::before {
    content: '\ea22';
  }
  .rs-icon-sequence-up::before {
    content: '\ea23';
  }
  .rs-icon-server::before {
    content: '\f233';
  }
  .rs-icon-setting::before {
    content: '\ea0f';
  }
  .rs-icon-shapes::before {
    content: '\e933';
  }
  .rs-icon-share::before {
    content: '\f064';
  }
  .rs-icon-share-alt::before {
    content: '\f1e0';
  }
  .rs-icon-share-alt-square::before {
    content: '\f1e1';
  }
  .rs-icon-share-square::before {
    content: '\f14d';
  }
  .rs-icon-share-square-o::before {
    content: '\f045';
  }
  .rs-icon-share2::before {
    content: '\e934';
  }
  .rs-icon-shield::before {
    content: '\f132';
  }
  .rs-icon-ship::before {
    content: '\f21a';
  }
  .rs-icon-shirtsinbulk::before {
    content: '\f214';
  }
  .rs-icon-shopping-bag::before {
    content: '\f290';
  }
  .rs-icon-shopping-basket::before {
    content: '\f291';
  }
  .rs-icon-shopping-cart::before {
    content: '\f07a';
  }
  .rs-icon-shower::before {
    content: '\f2cc';
  }
  .rs-icon-sign-in::before {
    content: '\f090';
  }
  .rs-icon-sign-out::before {
    content: '\f08b';
  }
  .rs-icon-signal::before {
    content: '\f012';
  }
  .rs-icon-signing::before {
    content: '\f2a7';
  }
  .rs-icon-simplybuilt::before {
    content: '\f215';
  }
  .rs-icon-sitemap::before {
    content: '\f0e8';
  }
  .rs-icon-skyatlas::before {
    content: '\f216';
  }
  .rs-icon-skype::before {
    content: '\f17e';
  }
  .rs-icon-slack::before {
    content: '\f198';
  }
  .rs-icon-sliders::before {
    content: '\f1de';
  }
  .rs-icon-slideshare::before {
    content: '\f1e7';
  }
  .rs-icon-smile-o::before {
    content: '\f118';
  }
  .rs-icon-snapchat::before {
    content: '\f2ab';
  }
  .rs-icon-snapchat-ghost::before {
    content: '\f2ac';
  }
  .rs-icon-snapchat-square::before {
    content: '\f2ad';
  }
  .rs-icon-snowflake-o::before {
    content: '\f2dc';
  }
  .rs-icon-sort::before {
    content: '\f0dc';
  }
  .rs-icon-sort-alpha-asc::before {
    content: '\f15d';
  }
  .rs-icon-sort-alpha-desc::before {
    content: '\f15e';
  }
  .rs-icon-sort-amount-asc::before {
    content: '\f160';
  }
  .rs-icon-sort-amount-desc::before {
    content: '\f161';
  }
  .rs-icon-sort-desc::before {
    content: '\f0dd';
  }
  .rs-icon-sort-numeric-asc::before {
    content: '\f162';
  }
  .rs-icon-sort-numeric-desc::before {
    content: '\f163';
  }
  .rs-icon-sort-up::before {
    content: '\f0de';
  }
  .rs-icon-soundcloud::before {
    content: '\f1be';
  }
  .rs-icon-space-shuttle::before {
    content: '\f197';
  }
  .rs-icon-speaker::before {
    content: '\ea41';
  }
  .rs-icon-spinner::before {
    content: '\f110';
  }
  .rs-icon-spoon::before {
    content: '\f1b1';
  }
  .rs-icon-spotify::before {
    content: '\f1bc';
  }
  .rs-icon-square::before {
    content: '\f0c8';
  }
  .rs-icon-square-o::before {
    content: '\f096';
  }
  .rs-icon-squares::before {
    content: '\e935';
  }
  .rs-icon-stack-exchange::before {
    content: '\f18d';
  }
  .rs-icon-stack-overflow::before {
    content: '\f16c';
  }
  .rs-icon-star::before {
    content: '\f005';
  }
  .rs-icon-star-half::before {
    content: '\f089';
  }
  .rs-icon-star-half-o::before {
    content: '\f123';
  }
  .rs-icon-star-o::before {
    content: '\f006';
  }
  .rs-icon-steam::before {
    content: '\f1b6';
  }
  .rs-icon-steam-square::before {
    content: '\f1b7';
  }
  .rs-icon-step-backward::before {
    content: '\f048';
  }
  .rs-icon-step-forward::before {
    content: '\f051';
  }
  .rs-icon-stethoscope::before {
    content: '\f0f1';
  }
  .rs-icon-sticky-note::before {
    content: '\f249';
  }
  .rs-icon-sticky-note-o::before {
    content: '\f24a';
  }
  .rs-icon-stop::before {
    content: '\f04d';
  }
  .rs-icon-stop-circle::before {
    content: '\f28d';
  }
  .rs-icon-stop-circle-o::before {
    content: '\f28e';
  }
  .rs-icon-stop2::before {
    content: '\e936';
  }
  .rs-icon-street-view::before {
    content: '\f21d';
  }
  .rs-icon-strikethrough::before {
    content: '\f0cc';
  }
  .rs-icon-stumbleupon::before {
    content: '\f1a4';
  }
  .rs-icon-stumbleupon-circle::before {
    content: '\f1a3';
  }
  .rs-icon-subscript::before {
    content: '\f12c';
  }
  .rs-icon-subway::before {
    content: '\f239';
  }
  .rs-icon-suitcase::before {
    content: '\f0f2';
  }
  .rs-icon-sun-o::before {
    content: '\f185';
  }
  .rs-icon-superpowers::before {
    content: '\f2dd';
  }
  .rs-icon-superscript::before {
    content: '\f12b';
  }
  .rs-icon-support::before {
    content: '\f1cd';
  }
  .rs-icon-table::before {
    content: '\f0ce';
  }
  .rs-icon-tablet::before {
    content: '\f10a';
  }
  .rs-icon-tag::before {
    content: '\f02b';
  }
  .rs-icon-tag-area::before {
    content: '\ea42';
  }
  .rs-icon-tag-authorize::before {
    content: '\ea43';
  }
  .rs-icon-tag-unauthorize::before {
    content: '\ea44';
  }
  .rs-icon-tags::before {
    content: '\f02c';
  }
  .rs-icon-target::before {
    content: '\ea03';
  }
  .rs-icon-task::before {
    content: '\ea46';
  }
  .rs-icon-tasks::before {
    content: '\f0ae';
  }
  .rs-icon-telegram::before {
    content: '\f2c6';
  }
  .rs-icon-tencent-weibo::before {
    content: '\f1d5';
  }
  .rs-icon-terminal::before {
    content: '\f120';
  }
  .rs-icon-terminal-line::before {
    content: '\e937';
  }
  .rs-icon-text-height::before {
    content: '\f034';
  }
  .rs-icon-text-width::before {
    content: '\f035';
  }
  .rs-icon-th::before {
    content: '\f00a';
  }
  .rs-icon-th-large::before {
    content: '\f009';
  }
  .rs-icon-th-list::before {
    content: '\f00b';
  }
  .rs-icon-th2::before {
    content: '\e938';
  }
  .rs-icon-themeisle::before {
    content: '\f2b2';
  }
  .rs-icon-thermometer::before {
    content: '\f2c7';
  }
  .rs-icon-thermometer-0::before {
    content: '\f2cb';
  }
  .rs-icon-thermometer-1::before {
    content: '\f2ca';
  }
  .rs-icon-thermometer-2::before {
    content: '\f2c9';
  }
  .rs-icon-thermometer-3::before {
    content: '\f2c8';
  }
  .rs-icon-thumb-tack::before {
    content: '\f08d';
  }
  .rs-icon-thumbs-down::before {
    content: '\f165';
  }
  .rs-icon-thumbs-o-down::before {
    content: '\f088';
  }
  .rs-icon-thumbs-o-up::before {
    content: '\f087';
  }
  .rs-icon-thumbs-up::before {
    content: '\f164';
  }
  .rs-icon-ticket::before {
    content: '\f145';
  }
  .rs-icon-times-circle::before {
    content: '\f057';
  }
  .rs-icon-times-circle-o::before {
    content: '\f05c';
  }
  .rs-icon-tint::before {
    content: '\f043';
  }
  .rs-icon-tmall::before {
    content: '\ea07';
  }
  .rs-icon-toggle-down::before {
    content: '\f150';
  }
  .rs-icon-toggle-left::before {
    content: '\f191' /* rtl: "\f152" */;
  }
  .rs-icon-toggle-off::before {
    content: '\f204';
  }
  .rs-icon-toggle-on::before {
    content: '\f205';
  }
  .rs-icon-toggle-right::before {
    content: '\f152' /* rtl: "\f191" */;
  }
  .rs-icon-toggle-up::before {
    content: '\f151';
  }
  .rs-icon-trademark::before {
    content: '\f25c';
  }
  .rs-icon-train::before {
    content: '\f238';
  }
  .rs-icon-transgender-alt::before {
    content: '\f225';
  }
  .rs-icon-trash::before {
    content: '\f1f8';
  }
  .rs-icon-trash-o::before {
    content: '\f014';
  }
  .rs-icon-trash2::before {
    content: '\e939';
  }
  .rs-icon-tree::before {
    content: '\f1bb';
  }
  .rs-icon-tree-close::before {
    content: '\e93a';
  }
  .rs-icon-tree-open::before {
    content: '\e93b';
  }
  .rs-icon-trello::before {
    content: '\f181';
  }
  .rs-icon-trend::before {
    content: '\ea47';
  }
  .rs-icon-tripadvisor::before {
    content: '\f262';
  }
  .rs-icon-trophy::before {
    content: '\f091';
  }
  .rs-icon-truck::before {
    content: '\f0d1';
  }
  .rs-icon-try::before {
    content: '\f195';
  }
  .rs-icon-tty::before {
    content: '\f1e4';
  }
  .rs-icon-tumblr::before {
    content: '\f173';
  }
  .rs-icon-tumblr-square::before {
    content: '\f174';
  }
  .rs-icon-tv::before {
    content: '\f26c';
  }
  .rs-icon-twinkle-star::before {
    content: '\e93c';
  }
  .rs-icon-twitch::before {
    content: '\f1e8';
  }
  .rs-icon-twitter::before {
    content: '\f099';
  }
  .rs-icon-twitter-square::before {
    content: '\f081';
  }
  .rs-icon-umbrella::before {
    content: '\f0e9';
  }
  .rs-icon-underline::before {
    content: '\f0cd';
  }
  .rs-icon-undo::before {
    content: '\f0e2';
  }
  .rs-icon-universal-access::before {
    content: '\f29a';
  }
  .rs-icon-unlink::before {
    content: '\f127';
  }
  .rs-icon-unlock::before {
    content: '\f09c';
  }
  .rs-icon-unlock-alt::before {
    content: '\f13e';
  }
  .rs-icon-up::before {
    content: '\e93d';
  }
  .rs-icon-upload::before {
    content: '\f093';
  }
  .rs-icon-upload2::before {
    content: '\e93e';
  }
  .rs-icon-usb::before {
    content: '\f287';
  }
  .rs-icon-usd::before {
    content: '\f155';
  }
  .rs-icon-user::before {
    content: '\f007';
  }
  .rs-icon-user-analysis::before {
    content: '\e93f';
  }
  .rs-icon-user-circle::before {
    content: '\f2bd';
  }
  .rs-icon-user-circle-o::before {
    content: '\f2be';
  }
  .rs-icon-user-info::before {
    content: '\ea48';
  }
  .rs-icon-user-md::before {
    content: '\f0f0';
  }
  .rs-icon-user-o::before {
    content: '\f2c0';
  }
  .rs-icon-user-plus::before {
    content: '\f234';
  }
  .rs-icon-user-secret::before {
    content: '\f21b';
  }
  .rs-icon-user-times::before {
    content: '\f235';
  }
  .rs-icon-vcard::before {
    content: '\f2bb';
  }
  .rs-icon-vcard-o::before {
    content: '\f2bc';
  }
  .rs-icon-venus::before {
    content: '\f221';
  }
  .rs-icon-venus-double::before {
    content: '\f226';
  }
  .rs-icon-venus-mars::before {
    content: '\f228';
  }
  .rs-icon-viacoin::before {
    content: '\f237';
  }
  .rs-icon-viadeo::before {
    content: '\f2a9';
  }
  .rs-icon-viadeo-square::before {
    content: '\f2aa';
  }
  .rs-icon-video-camera::before {
    content: '\f03d';
  }
  .rs-icon-views-authorize::before {
    content: '\ea49';
  }
  .rs-icon-views-unauthorize::before {
    content: '\ea4a';
  }
  .rs-icon-vimeo::before {
    content: '\f27d';
  }
  .rs-icon-vimeo-square::before {
    content: '\f194';
  }
  .rs-icon-vine::before {
    content: '\f1ca';
  }
  .rs-icon-vk::before {
    content: '\f189';
  }
  .rs-icon-volume-control-phone::before {
    content: '\f2a0';
  }
  .rs-icon-volume-down::before {
    content: '\f027';
  }
  .rs-icon-volume-off::before {
    content: '\f026';
  }
  .rs-icon-volume-up::before {
    content: '\f028';
  }
  .rs-icon-warning::before {
    content: '\ea25';
  }
  .rs-icon-weapp::before {
    content: '\ea01';
  }
  .rs-icon-web::before {
    content: '\ea02';
  }
  .rs-icon-wechat::before {
    content: '\f1d7';
  }
  .rs-icon-weibo::before {
    content: '\f18a';
  }
  .rs-icon-whatsapp::before {
    content: '\f232';
  }
  .rs-icon-wheelchair::before {
    content: '\f193';
  }
  .rs-icon-wheelchair-alt::before {
    content: '\f29b';
  }
  .rs-icon-wifi::before {
    content: '\f1eb';
  }
  .rs-icon-wikipedia-w::before {
    content: '\f266';
  }
  .rs-icon-window-close::before {
    content: '\f2d3';
  }
  .rs-icon-window-close-o::before {
    content: '\f2d4';
  }
  .rs-icon-window-maximize::before {
    content: '\f2d0';
  }
  .rs-icon-window-minimize::before {
    content: '\f2d1';
  }
  .rs-icon-window-restore::before {
    content: '\f2d2';
  }
  .rs-icon-windows::before {
    content: '\f17a';
  }
  .rs-icon-wordpress::before {
    content: '\f19a';
  }
  .rs-icon-wpbeginner::before {
    content: '\f297';
  }
  .rs-icon-wpexplorer::before {
    content: '\f2de';
  }
  .rs-icon-wpforms::before {
    content: '\f298';
  }
  .rs-icon-wrench::before {
    content: '\f0ad';
  }
  .rs-icon-xing::before {
    content: '\f168';
  }
  .rs-icon-xing-square::before {
    content: '\f169';
  }
  .rs-icon-yahoo::before {
    content: '\f19e';
  }
  .rs-icon-yc::before {
    content: '\f23b';
  }
  .rs-icon-yc-square::before {
    content: '\f1d4';
  }
  .rs-icon-yelp::before {
    content: '\f1e9';
  }
  .rs-icon-yoast::before {
    content: '\f2b1';
  }
  .rs-icon-youtube::before {
    content: '\f167';
  }
  .rs-icon-youtube-play::before {
    content: '\f16a';
  }
  .rs-icon-youtube-square::before {
    content: '\f166';
  }
  .rs-btn-default.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #f2f2f5;
  }
  .rs-btn-default:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #d9d9d9;
  }
  .rs-btn-default:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #d9d9d9;
  }
  .rs-btn-default:active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-active.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c5c6c7;
  }
  .rs-btn-default:active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-active:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default:hover.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default:active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-active:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default:focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default:active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-btn-default.rs-btn-active.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon,
  .rs-open > .rs-dropdown-toggle.rs-btn-default.focus.rs-btn-icon.rs-btn-icon-with-text > .rs-icon {
    background: #c5c6c7;
  }
  .rs-btn-icon:not(.rs-btn-icon-with-text) .icon {
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-btn-icon:not(.rs-btn-icon-with-text).btn-xs {
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-icon-circle {
    border-radius: 50% !important;
    width: 36px;
  }
  .rs-input {
    display: block;
    width: 100%;
    color: #575757;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e5ea;
    -webkit-transition: border-color ease-in-out 0.3s;
    transition: border-color ease-in-out 0.3s;
    border-radius: 6px;
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 36px\9;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input {
      height: 36px;
    }
  }
  textarea.rs-input {
    height: auto;
  }
  .rs-input::-webkit-input-placeholder {
    color: #8e8e93;
  }
  .rs-input::-moz-placeholder {
    color: #8e8e93;
  }
  .rs-input::-ms-input-placeholder {
    color: #8e8e93;
  }
  .rs-input::placeholder {
    color: #8e8e93;
  }
  .rs-input:focus,
  .rs-input:hover {
    border-color: #1675e0;
    outline: 0;
  }
  .rs-input[disabled] {
    background-color: #f7f7fa;
    color: #c5c6c7;
    cursor: not-allowed;
  }
  .rs-input[disabled]:hover {
    border-color: #e5e5ea;
  }
  textarea.rs-input {
    resize: none;
    min-width: 300px;
    min-height: 76px;
    max-width: 100%;
    max-height: 200px;
  }
  .rs-input-xs {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    height: 24px\9;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-xs {
      height: 24px;
    }
  }
  textarea.rs-input-xs {
    height: auto;
  }
  .rs-input-lg {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    height: 42px\9;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-lg {
      height: 42px;
    }
  }
  textarea.rs-input-lg {
    height: auto;
  }
  .rs-input-sm {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 30px\9;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-sm {
      height: 30px;
    }
  }
  textarea.rs-input-sm {
    height: auto;
  }
  .rs-input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 6px;
    -webkit-transition: border-color ease-in-out 0.3s;
    transition: border-color ease-in-out 0.3s;
    width: 100%;
  }
  .rs-input-group-disabled,
  .rs-input-group-disabled .rs-input,
  .rs-input-group-disabled .rs-input-group-addon,
  .rs-input-group-disabled .rs-input-group-btn {
    background-color: #f7f7fa;
    color: #c5c6c7;
    cursor: not-allowed;
  }
  .rs-input-group:not(.rs-input-group-inside) {
    border: 1px solid #e5e5ea;
  }
  .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled):hover,
  .rs-input-group:not(.rs-input-group-inside):not(.rs-input-group-disabled).rs-input-group-focus {
    border-color: #1675e0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number,
  .rs-input-group:not(.rs-input-group-inside) .rs-input-group-addon,
  .rs-input-group:not(.rs-input-group-inside) .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-input-group-btn,
  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date .rs-picker-toggle {
    border: none;
    border-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-btn-group-vertical {
    border-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-up {
    border-top-right-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:last-child) .rs-input-number-touchspin-down {
    border-bottom-right-radius: 0;
  }
  .rs-input-group:not(.rs-input-group-inside) .rs-input-number:not(:first-child) .rs-input {
    border-radius: 0 !important;
  }
  .rs-input-group:not(.rs-input-group-inside) > :first-child,
  .rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:first-child .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:first-child .rs-picker-toggle,
  .rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:first-child > .rs-input {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .rs-input-group:not(.rs-input-group-inside) > :last-child,
  .rs-input-group:not(.rs-input-group-inside) .rs-auto-complete:last-child .rs-input,
  .rs-input-group:not(.rs-input-group-inside) .rs-picker-date:last-child .rs-picker-toggle,
  .rs-input-group:not(.rs-input-group-inside) .rs-form-control-wrapper:last-child > .rs-input {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-input-group .rs-input,
  .rs-input-group .rs-auto-complete,
  .rs-input-group .rs-form-control-wrapper {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-input-group .rs-form-control-wrapper > .rs-input {
    width: 100%;
  }
  .rs-input-group .rs-input {
    position: relative;
    z-index: 2;
  }
  .rs-input-group .rs-input:focus {
    z-index: 3;
  }
  .rs-input-group input.rs-input ~ .rs-input-group-addon,
  .rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon {
    border-left: none;
    left: auto;
    right: 0;
  }
  .rs-input-group input.rs-input ~ .rs-input-group-addon:not(:last-child),
  .rs-input-group .rs-form-control-wrapper ~ .rs-input-group-addon:not(:last-child) {
    border-right: 0;
  }
  .rs-input-group.rs-input-group-inside {
    width: 100%;
    border-radius: 0;
  }
  .rs-input-group.rs-input-group-inside .rs-input {
    display: block;
    width: 100%;
    padding-right: 36px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    position: absolute;
    z-index: 4;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    color: #8e8e93;
    background-color: transparent;
    color: #575757;
    position: absolute;
    left: 1px;
    top: 1px;
    height: 34px;
    padding: 7px 11px;
    border-radius: 0 6px 6px 0;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:hover {
    color: #fff;
    background: #f7f7fa;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn {
    color: #fff;
    background-color: #b2b1f0;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.focus {
    color: #fff;
    background-color: #b2b1f0;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-disabled:active {
    opacity: 1;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:hover {
    color: #575757;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn {
    color: #272c36;
    background-color: #e5e5ea;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.focus {
    color: #272c36;
    background-color: #e5e5ea;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red:hover {
    color: #fff;
    background: #f44336;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red {
    color: #fff;
    background-color: #eb3626;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.focus {
    color: #fff;
    background-color: #eb3626;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-disabled.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-disabled:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-red.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange:hover {
    color: #fff;
    background: #ff9800;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange {
    color: #fff;
    background-color: #f59700;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.focus {
    color: #fff;
    background-color: #f59700;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-disabled.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-disabled:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-orange.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow:hover {
    color: #fff;
    background: #ffca28;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow {
    color: #fff;
    background-color: #f5c31d;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.focus {
    color: #fff;
    background-color: #f5c31d;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-disabled:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-yellow.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green:hover {
    color: #fff;
    background: #4caf50;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green {
    color: #fff;
    background-color: #37ab3c;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.focus {
    color: #fff;
    background-color: #37ab3c;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-disabled.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-disabled:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-green.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan:hover {
    color: #fff;
    background: #00bcd4;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan {
    color: #fff;
    background-color: #00b1cc;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.focus {
    color: #fff;
    background-color: #00b1cc;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-disabled:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-cyan.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue:hover {
    color: #fff;
    background: #2196f3;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue {
    color: #fff;
    background-color: #1787e8;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.focus {
    color: #fff;
    background-color: #1787e8;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-disabled.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-disabled:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-blue.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-loading:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-loading:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-loading:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet:hover {
    color: #fff;
    background: #673ab7;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet {
    color: #fff;
    background-color: #5f2bb3;
    background-image: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet:active:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet:active:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet:active.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.focus {
    color: #fff;
    background-color: #5f2bb3;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-disabled,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-disabled:hover,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-disabled:focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-disabled.focus,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-disabled:active,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn.rs-btn-violet.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:first-child {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn:last-child {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-addon {
    top: 0;
    background: none;
    border: none;
    padding: 11px 13px 8px 13px;
  }
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-auto-complete > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-auto-complete > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-addon ~ .rs-form-control-wrapper > input.rs-input,
  .rs-input-group.rs-input-group-inside .rs-input-group-btn ~ .rs-form-control-wrapper > input.rs-input {
    padding-left: 36px;
    padding-right: 12px;
  }
  .rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-addon,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-addon,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
    left: inherit;
    right: 0;
  }
  .rs-input-group.rs-input-group-inside input.rs-input ~ .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside .rs-auto-complete ~ .rs-input-group-btn,
  .rs-input-group.rs-input-group-inside .rs-form-control-wrapper ~ .rs-input-group-btn {
    left: inherit;
    right: 1px;
  }
  .rs-input-group-lg > .rs-input {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    height: 42px\9;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-lg > .rs-input {
      height: 42px;
    }
  }
  textarea.rs-input-group-lg > .rs-input {
    height: auto;
  }
  .rs-input-group-lg.rs-input-group-inside > .rs-input {
    padding-right: 46px;
  }
  .rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input {
    height: 40px;
  }
  .rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    height: 40px;
  }
  .rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-lg.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .rs-input-group-md > .rs-input {
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 36px\9;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-md > .rs-input {
      height: 36px;
    }
  }
  textarea.rs-input-group-md > .rs-input {
    height: auto;
  }
  .rs-input-group-md.rs-input-group-inside > .rs-input {
    padding-right: 36px;
  }
  .rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input {
    height: 34px;
  }
  .rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    height: 34px;
  }
  .rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-md.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .rs-input-group-sm > .rs-input {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 30px\9;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-sm > .rs-input {
      height: 30px;
    }
  }
  textarea.rs-input-group-sm > .rs-input {
    height: auto;
  }
  .rs-input-group-sm.rs-input-group-inside > .rs-input {
    padding-right: 30px;
  }
  .rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input {
    height: 28px;
  }
  .rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    height: 28px;
  }
  .rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group-sm.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .rs-input-group-xs > .rs-input {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    height: 24px\9;
    /* stylelint-enable */
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-xs > .rs-input {
      height: 24px;
    }
  }
  textarea.rs-input-group-xs > .rs-input {
    height: auto;
  }
  .rs-input-group-xs.rs-input-group-inside > .rs-input {
    padding-right: 26px;
  }
  .rs-input-group-xs.rs-input-group:not(.rs-input-group-inside) > .rs-input {
    height: 22px;
  }
  .rs-input-group-xs.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon {
    height: 22px;
  }
  .rs-input-group-xs.rs-input-group:not(.rs-input-group-inside) > .rs-input-group-addon > .rs-icon {
    font-size: inherit;
  }
  .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    height: 42px\9;
    /* stylelint-enable */
    width: 44px;
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-lg > .rs-input-group-addon {
      height: 42px;
    }
  }
  textarea.rs-input-group.rs-input-group-lg > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-md > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 36px\9;
    /* stylelint-enable */
    width: 36px;
    padding-left: 11px;
    padding-right: 11px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-md > .rs-input-group-addon {
      height: 36px;
    }
  }
  textarea.rs-input-group.rs-input-group-md > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 30px\9;
    /* stylelint-enable */
    width: 32px;
    padding-left: 9px;
    padding-right: 9px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-sm > .rs-input-group-addon {
      height: 30px;
    }
  }
  textarea.rs-input-group.rs-input-group-sm > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    height: 24px\9;
    /* stylelint-enable */
    width: 28px;
    padding-left: 7px;
    padding-right: 7px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group.rs-input-group-xs > .rs-input-group-addon {
      height: 24px;
    }
  }
  textarea.rs-input-group.rs-input-group-xs > .rs-input-group-addon {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.375;
    height: 42px\9;
    /* stylelint-enable */
    height: 40px;
    padding: 9px 15px;
    border-radius: 0 6px 6px 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
      height: 42px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-lg > .rs-input-group-btn > .rs-icon {
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    /* stylelint-disable */
    font-size: 14px;
    line-height: 1.42857143;
    height: 36px\9;
    /* stylelint-enable */
    height: 34px;
    padding: 7px 11px;
    border-radius: 0 6px 6px 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
      height: 36px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-md > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-md > .rs-input-group-btn > .rs-icon {
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 30px\9;
    /* stylelint-enable */
    height: 28px;
    padding: 4px 9px;
    border-radius: 0 6px 6px 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
      height: 30px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-sm > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    /* stylelint-disable */
    padding: 1px 11px;
    font-size: 12px;
    line-height: 1.66666667;
    height: 24px\9;
    /* stylelint-enable */
    height: 22px;
    padding: 1px 7px;
    border-radius: 0 6px 6px 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
      height: 24px;
    }
  }
  textarea.rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn {
    height: auto;
  }
  .rs-input-group-inside.rs-input-group-xs > .rs-input-group-btn > .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-input-group-addon:not(:first-child):not(:last-child),
  .rs-input-group-btn:not(:first-child):not(:last-child),
  .rs-input-group:not(.rs-input-group-inside) .rs-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .rs-input-group-addon,
  .rs-input-group-btn {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    vertical-align: middle;
    color: #575757;
  }
  .rs-input-group-addon {
    padding: 8px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #575757;
    text-align: center;
    background-color: #f7f7fa;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
  }
  .rs-input-group-addon.rs-input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 6px;
  }
  .rs-input-group-addon.rs-input-xs {
    padding: 8px 8px;
    font-size: 12px;
    border-radius: 6px;
  }
  .rs-input-group-addon.rs-input-lg {
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 6px;
  }
  .rs-input-group-addon input[type='radio'],
  .rs-input-group-addon input[type='checkbox'] {
    margin-top: 0;
  }
  .rs-input-group-btn {
    position: relative;
    line-height: 1;
    white-space: nowrap;
    border-radius: 0;
  }
  .rs-input-group-btn > .rs-btn {
    position: relative;
  }
  .rs-input-group-btn > .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-input-group-btn > .rs-btn:hover,
  .rs-input-group-btn > .rs-btn:focus,
  .rs-input-group-btn > .rs-btn:active {
    z-index: 2;
  }
  .rs-input-group-btn:first-child > .rs-btn,
  .rs-input-group-btn:first-child > .rs-btn-group {
    margin-right: -1px;
    border-right: medium none;
  }
  .rs-input-group-btn:last-child > .rs-btn,
  .rs-input-group-btn:last-child > .rs-btn-group {
    z-index: 2;
    margin-left: -1px;
  }
  .rs-input-number {
    background: #fff;
  }
  .rs-input-number-btn-group-vertical {
    position: relative;
    width: 20px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20px;
            flex: 0 0 20px;
    display: block;
  }
  .rs-input-number-btn-group-vertical > .rs-btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    position: relative;
    border: none;
  }
  .rs-input-number-touchspin-up,
  .rs-input-number-touchspin-down {
    position: relative;
    border-radius: 0;
    padding: 0;
    color: #8e8e93;
    height: 17px;
  }
  .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-number-touchspin-down > .rs-icon {
    height: 17px;
    line-height: 1.41666667;
  }
  .rs-input-group-lg .rs-input-number-touchspin-up,
  .rs-input-group-lg .rs-input-number-touchspin-down {
    height: 20px;
  }
  .rs-input-group-lg .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-group-lg .rs-input-number-touchspin-down > .rs-icon {
    height: 20px;
    line-height: 1.66666667;
  }
  .rs-input-group-sm .rs-input-number-touchspin-up,
  .rs-input-group-sm .rs-input-number-touchspin-down {
    height: 14px;
  }
  .rs-input-group-sm .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-group-sm .rs-input-number-touchspin-down > .rs-icon {
    height: 14px;
    line-height: 1.16666667;
  }
  .rs-input-group-xs .rs-input-number-touchspin-up,
  .rs-input-group-xs .rs-input-number-touchspin-down {
    height: 11px;
  }
  .rs-input-group-xs .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-group-xs .rs-input-number-touchspin-down > .rs-icon {
    height: 11px;
    line-height: 0.91666667;
  }
  .rs-input-number-touchspin-up > .rs-icon,
  .rs-input-number-touchspin-down > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  .rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-up {
    border-top-right-radius: 6px;
  }
  .rs-input-number-btn-group-vertical:last-child .rs-input-number-touchspin-down {
    border-bottom-right-radius: 6px;
  }
  .rs-input-number > .rs-input {
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
  }
  .rs-input-number input[type='number'] {
    -webkit-appearance: textfield;
       -moz-appearance: textfield;
            appearance: textfield;
  }
  .rs-input-number input[type='number']::-webkit-inner-spin-button {
    /* stylelint-disable */
    -webkit-appearance: none;
  }
  .rs-picker-input {
    position: relative;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    -webkit-transition: border-color ease-in-out 0.3s;
    transition: border-color ease-in-out 0.3s;
    background: #fff;
  }
  .rs-picker-input .rs-picker-toggle {
    border: none !important;
    background: transparent !important;
    height: 34px;
    cursor: text;
  }
  .rs-picker-input .rs-picker-toggle-clean,
  .rs-picker-input .rs-picker-toggle-caret {
    top: 7px;
  }
  .rs-picker-input .rs-picker-toggle .rs-ripple-pond {
    display: none;
  }
  .rs-picker-input:not(.rs-picker-disabled) .rs-picker-toggle {
    position: absolute !important;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-lg {
    height: 40px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-md {
    height: 34px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-sm {
    height: 28px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-xs {
    height: 22px;
  }
  .rs-picker-input .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
    padding-top: 0;
    padding-bottom: 0;
  }
  .rs-picker-input:not(.rs-picker-disabled):hover,
  .rs-picker-input.rs-picker-focused {
    border-color: #1675e0;
  }
  .rs-picker-tag-wrapper {
    padding-right: 32px;
  }
  .rs-picker-cleanable .rs-picker-tag-wrapper {
    padding-right: 44px;
  }
  .rs-picker-search {
    border: none;
    width: 100%;
  }
  .rs-picker-search-input {
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 36px\9;
    /* stylelint-enable */
    background: none;
    outline: none;
    border: none;
    width: 100%;
    position: relative;
    padding-right: 0;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-picker-search-input {
      height: 36px;
    }
  }
  textarea.rs-picker-search-input {
    height: auto;
  }
  .rs-picker-tag .rs-picker-search-input {
    font-size: 14px;
    line-height: 1.42857143;
    padding: 2px 12px 2px 11px;
  }
  .rs-picker-tag .rs-picker-search-input > input {
    background: none;
    outline: none;
    border: none;
    width: 100%;
  }
  .rs-picker-focused .rs-picker-search-input {
    z-index: 6;
  }
  .rs-list {
    position: relative;
    -webkit-box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
            box-shadow: 0 1px 0 #e5e5ea, 0 -1px 0 #e5e5ea;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .rs-list-bordered {
    border-radius: 6px;
    -webkit-box-shadow: 0 0 0 1px #e5e5ea;
            box-shadow: 0 0 0 1px #e5e5ea;
  }
  .rs-list-hover .rs-list-item:hover {
    background-color: #f2faff;
  }
  .rs-list-sortable .rs-list-item {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .rs-list-sortable .rs-list-item-disabled,
  .rs-list-sortable .rs-list-item-disabled:active {
    cursor: not-allowed;
  }
  .rs-list-item {
    position: relative;
  }
  .rs-list-item-bordered {
    padding-left: 20px;
    padding-right: 20px;
  }
  .rs-list-item-lg {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .rs-list-item-md {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .rs-list-item-sm {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  /* rtl:begin:ignore */
  /* stylelint-disable-next-line */
  .rs-list-item {
    background-color: #fff;
    -webkit-box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
            box-shadow: 0 -1px 0 #e5e5ea, 0 1px 0 #e5e5ea;
  }
  .rs-list-item-helper {
    position: absolute;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
            box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
    width: calc(100% - 2px);
    border-radius: 6px;
    top: 0;
    left: 0;
    z-index: 99;
    border: none;
    cursor: move;
  }
  .rs-modal-open .rs-list-item-helper {
    z-index: 1149;
  }
  .rs-drawer-open .rs-list-item-helper {
    z-index: 1149;
  }
  .rs-list-item-holder {
    background-color: #f2faff;
  }
  .rs-list-item-holder::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: calc(100% - 2 * 1px);
    height: calc(100% - 2 * 1px);
    border: 1px #3498ff dashed;
  }
  .rs-list-item-holder .rs-list-item-content {
    opacity: 0;
  }
  /* rtl:end:ignore */
  .rs-loader {
    height: 18px;
    display: inline-block;
  }
  .rs-loader::before,
  .rs-loader::after {
    content: ' ';
    display: table;
  }
  .rs-loader::after {
    clear: both;
  }
  .rs-loader::before,
  .rs-loader::after {
    content: ' ';
    display: table;
  }
  .rs-loader::after {
    clear: both;
  }
  .rs-loader .rs-loader-spin {
    width: 18px;
    height: 18px;
  }
  .rs-loader .rs-loader-spin::before,
  .rs-loader .rs-loader-spin::after {
    width: 18px;
    height: 18px;
  }
  .rs-loader .rs-loader-content {
    font-size: 12px;
    line-height: 1.5;
  }
  .rs-loader .rs-loader-vertical .rs-loader .rs-loader {
    height: 48px;
  }
  .rs-loader .rs-loader-vertical .rs-loader .rs-loader-content {
    line-height: 1.66666667;
  }
  .rs-loader-spin {
    position: relative;
    display: inline-block;
    float: left;
  }
  .rs-loader-spin::before,
  .rs-loader-spin::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-loader-spin::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-loader-spin::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-loader-content {
    float: left;
    display: inline-block;
    color: #575757;
  }
  .rs-loader-spin + .rs-loader-content {
    margin-left: 12px;
  }
  .rs-loader-xs .rs-loader-spin + .rs-loader-content {
    margin-left: 10px;
  }
  .rs-loader-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.75);
  }
  .rs-loader-vertical {
    width: auto;
    display: inline-block;
  }
  .rs-loader-vertical .rs-loader {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .rs-loader-vertical .rs-loader-spin {
    margin: 0 auto;
  }
  .rs-loader-vertical .rs-loader-content {
    line-height: 1.66666667;
    text-align: center;
  }
  .rs-loader-vertical .rs-loader-spin,
  .rs-loader-vertical .rs-loader-content {
    display: block;
    float: none;
  }
  .rs-loader-vertical .rs-loader-spin + .rs-loader-content {
    margin-left: 0;
    margin-top: 10px;
  }
  .rs-loader-inverse .rs-loader-content {
    color: #f7f7fa;
  }
  .rs-loader-inverse .rs-loader-backdrop {
    background: rgba(39, 44, 54, 0.83);
  }
  .rs-loader-inverse .rs-loader-spin::before {
    border-color: rgba(247, 247, 250, 0.3);
  }
  .rs-loader-inverse .rs-loader-spin::after {
    border-top-color: #fff;
  }
  .rs-loader-speed-fast .rs-loader-spin::after {
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
  }
  .rs-loader-speed-normal .rs-loader-spin::after {
    -webkit-animation-duration: 0.6s;
            animation-duration: 0.6s;
  }
  .rs-loader-speed-slow .rs-loader-spin::after {
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
  }
  .rs-loader-center,
  .rs-loader-backdrop-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
  }
  .rs-loader-center .rs-loader,
  .rs-loader-backdrop-wrapper .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
  }
  .rs-loader-center:not(.rs-loader-vertical) .rs-loader,
  .rs-loader-backdrop-wrapper:not(.rs-loader-vertical) .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-loader-center.rs-loader-vertical .rs-loader,
  .rs-loader-backdrop-wrapper.rs-loader-vertical .rs-loader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
  }
  .rs-loader-center .rs-loader-content,
  .rs-loader-backdrop-wrapper .rs-loader-content {
    z-index: 1;
  }
  .rs-loader-lg .rs-loader-spin {
    width: 64px;
    height: 64px;
  }
  .rs-loader-lg .rs-loader-spin::before,
  .rs-loader-lg .rs-loader-spin::after {
    width: 64px;
    height: 64px;
  }
  .rs-loader-lg .rs-loader-content {
    font-size: 16px;
    line-height: 4;
  }
  .rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader {
    height: 94px;
  }
  .rs-loader-lg .rs-loader-vertical .rs-loader-lg .rs-loader-content {
    line-height: 1.25;
  }
  .rs-loader-md .rs-loader-spin {
    width: 36px;
    height: 36px;
  }
  .rs-loader-md .rs-loader-spin::before,
  .rs-loader-md .rs-loader-spin::after {
    width: 36px;
    height: 36px;
  }
  .rs-loader-md .rs-loader-content {
    font-size: 14px;
    line-height: 2.57142857;
  }
  .rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader {
    height: 66px;
  }
  .rs-loader-md .rs-loader-vertical .rs-loader-md .rs-loader-content {
    line-height: 1.42857143;
  }
  .rs-loader-sm .rs-loader-spin {
    width: 18px;
    height: 18px;
  }
  .rs-loader-sm .rs-loader-spin::before,
  .rs-loader-sm .rs-loader-spin::after {
    width: 18px;
    height: 18px;
  }
  .rs-loader-sm .rs-loader-content {
    font-size: 14px;
    line-height: 1.28571429;
  }
  .rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader {
    height: 48px;
  }
  .rs-loader-sm .rs-loader-vertical .rs-loader-sm .rs-loader-content {
    line-height: 1.42857143;
  }
  .rs-loader-xs .rs-loader-spin {
    width: 16px;
    height: 16px;
  }
  .rs-loader-xs .rs-loader-spin::before,
  .rs-loader-xs .rs-loader-spin::after {
    width: 16px;
    height: 16px;
  }
  .rs-loader-xs .rs-loader-content {
    font-size: 12px;
    line-height: 1.33333333;
  }
  .rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader {
    height: 46px;
  }
  .rs-loader-xs .rs-loader-vertical .rs-loader-xs .rs-loader-content {
    line-height: 1.66666667;
  }
  .rs-loader-wrapper {
    display: inline-block;
    width: auto;
    line-height: 0;
  }
  .rs-message {
    border-radius: 6px;
    font-size: 14px;
    line-height: 1.42857143;
    position: relative;
  }
  .rs-message .rs-message-container {
    padding: 20px;
  }
  .rs-message:not(.rs-message-hiding) + .rs-message {
    margin-top: 10px;
  }
  .rs-message:not(.rs-message-hiding) + .rs-message.rs-message-hiding:last-child {
    -webkit-transition: margin-top 0.1s linear 0.3s;
    transition: margin-top 0.1s linear 0.3s;
    margin-top: 0;
  }
  .rs-message.rs-message-has-icon .rs-message-container {
    padding-left: 42px;
  }
  .rs-message.rs-message-has-title h5 {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-message.rs-message-has-title h5 + .rs-message-description {
    margin-top: 8px;
  }
  .rs-message.rs-message-has-icon.rs-message-has-title .rs-message-container {
    padding-left: 54px;
  }
  .rs-message.rs-message-has-icon.rs-message-has-title .rs-message-container .rs-message-icon-wrapper .rs-icon {
    font-size: 24px;
    line-height: 1;
  }
  .rs-message .rs-message-header {
    color: #272c36;
    line-height: 1.71428571;
  }
  .rs-message .rs-message-description {
    color: #575757;
  }
  .rs-message .rs-message-icon-wrapper {
    position: absolute;
    top: 20px;
    left: 20px;
    line-height: 1;
  }
  .rs-message .rs-message-icon-wrapper .rs-icon {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-message .rs-message-btn-close {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px 8px 0;
    font-size: 12px;
    border: none;
    outline: none !important;
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
  }
  .rs-message .rs-message-btn-close::before {
    content: '\f00d';
  }
  .rs-message .rs-message-btn-close [aria-hidden] {
    display: none;
  }
  .rs-message.rs-message-show {
    -webkit-animation: messageMoveIn 0.3s ease-in forwards;
            animation: messageMoveIn 0.3s ease-in forwards;
  }
  .rs-message.rs-message-hiding {
    -webkit-animation: messageMoveOut 0.3s ease-in forwards;
            animation: messageMoveOut 0.3s ease-in forwards;
  }
  .rs-message-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
  }
  .rs-message-success {
    background-color: #edfae1;
    color: #575757;
  }
  .rs-message-success .rs-message-icon-wrapper > .rs-icon,
  .rs-message-success .rs-message-btn-close {
    color: #4caf50;
  }
  .rs-message-info {
    background-color: #e9f5fe;
    color: #575757;
  }
  .rs-message-info .rs-message-icon-wrapper > .rs-icon,
  .rs-message-info .rs-message-btn-close {
    color: #2196f3;
  }
  .rs-message-warning {
    background-color: #fff9e6;
    color: #575757;
  }
  .rs-message-warning .rs-message-icon-wrapper > .rs-icon,
  .rs-message-warning .rs-message-btn-close {
    color: #ffb300;
  }
  .rs-message-error {
    background-color: #fde9ef;
    color: #575757;
  }
  .rs-message-error .rs-message-icon-wrapper > .rs-icon,
  .rs-message-error .rs-message-btn-close {
    color: #f44336;
  }
  .rs-modal-open {
    overflow: hidden;
  }
  .rs-modal-wrapper {
    position: fixed;
    overflow: auto;
    z-index: 1050;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .rs-modal {
    display: none;
    overflow: visible;
    outline: 0;
    margin: 30px auto 0 auto;
    position: relative;
    width: auto;
    z-index: 1050;
  }
  .rs-modal-lg {
    width: 968px;
  }
  .rs-modal-md {
    width: 800px;
  }
  .rs-modal-sm {
    width: 600px;
  }
  .rs-modal-xs {
    width: 400px;
  }
  .rs-modal-full {
    width: calc(100% - 120px);
  }
  .rs-modal-full .rs-modal-dialog {
    margin-left: 60px;
    margin-right: 60px;
  }
  .rs-modal-content {
    position: relative;
    background-color: #fff;
    border-radius: 6px;
    outline: 0;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    padding: 20px;
  }
  .rs-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #272c36;
  }
  .rs-modal-backdrop.fade {
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-in;
  }
  .rs-modal-backdrop.in {
    opacity: 0.3;
  }
  .rs-modal-header {
    padding-right: 20px;
  }
  .rs-modal-header::before,
  .rs-modal-header::after {
    content: ' ';
    display: table;
  }
  .rs-modal-header::after {
    clear: both;
  }
  .rs-modal-header::before,
  .rs-modal-header::after {
    content: ' ';
    display: table;
  }
  .rs-modal-header::after {
    clear: both;
  }
  .rs-modal-header .rs-modal-title {
    font-size: 16px;
    line-height: 1.25;
    display: block;
    color: #575757;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-modal-header .rs-modal-header-close {
    outline: 0;
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 12px;
    line-height: 1.66666667;
    color: #575757;
    width: 20px;
    padding: 0 4px;
    background: none;
    border-style: none;
  }
  .rs-modal-header .rs-modal-header-close::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-modal-header .rs-modal-header-close [aria-hidden] {
    display: none;
  }
  .rs-modal-header .title {
    margin: 0;
    color: #3498ff;
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-modal-body {
    position: relative;
    margin-top: 30px;
    padding-bottom: 30px;
  }
  .rs-modal-footer {
    text-align: right;
    border-top: none;
  }
  .rs-modal-footer::before,
  .rs-modal-footer::after {
    content: ' ';
    display: table;
  }
  .rs-modal-footer::after {
    clear: both;
  }
  .rs-modal-footer::before,
  .rs-modal-footer::after {
    content: ' ';
    display: table;
  }
  .rs-modal-footer::after {
    clear: both;
  }
  .rs-modal-footer .rs-btn + .rs-btn {
    margin-left: 10px;
    margin-bottom: 0;
  }
  .rs-modal-footer .rs-btn-group .rs-btn + .rs-btn {
    margin-left: -1px;
  }
  .rs-modal-footer .rs-btn-block + .rs-btn-block {
    margin-left: 0;
  }
  .rs-modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  .rs-picker-cascader-menu .rs-check-item .rs-checkbox-checker > label {
    padding-right: 26px;
  }
  .rs-picker-cascader-menu .rs-check-item.rs-check-item-focus .rs-checkbox-checker > label,
  .rs-picker-cascader-menu .rs-check-item.rs-checkbox-checked .rs-checkbox-checker > label {
    font-weight: bold;
  }
  .rs-picker-cascader-menu-column.rs-picker-cascader-menu-column-uncheckable .rs-check-item .rs-checkbox-checker > label {
    padding-left: 12px;
  }
  .rs-picker-multi-cascader-menu .rs-picker-cascader-col {
    padding: 0;
  }
  .rs-picker-multi-cascader-menu .rs-picker-cascader-row {
    padding-left: 0;
  }
  .rs-nav {
    position: relative;
  }
  .rs-nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content:hover,
  .rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content:focus {
    color: #575757;
  }
  .rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content:active {
    color: #575757;
  }
  .rs-nav-item.rs-nav-item-disabled {
    cursor: not-allowed;
  }
  .rs-nav-item.rs-nav-item-disabled > .rs-nav-item-content {
    cursor: not-allowed;
    color: #c5c6c7;
  }
  .rs-nav-item > .rs-nav-item-content {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    display: block;
    cursor: pointer;
    color: #8e8e93;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-nav-item > .rs-nav-item-content {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-disable-line property-no-vendor-prefix */
    }
  }
  .rs-nav-item > .rs-nav-item-content > .rs-icon {
    margin-right: 6px;
  }
  .rs-nav-item > a.rs-nav-item-content {
    outline: none;
  }
  .rs-nav-item > a.rs-nav-item-content,
  .rs-nav-item > a.rs-nav-item-content:hover,
  .rs-nav-item > a.rs-nav-item-content:focus,
  .rs-nav-item > a.rs-nav-item-content:active {
    text-decoration: none;
  }
  .rs-nav .rs-dropdown > .rs-dropdown-toggle {
    height: 36px;
  }
  .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-nav .rs-nav-item-active > .rs-nav-item-content {
    position: relative;
    z-index: 1;
  }
  .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-nav .rs-nav-item-active > .rs-nav-item-content,
  .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-nav .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
  .rs-nav .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active,
  .rs-nav .rs-nav-item-active > .rs-nav-item-content:active {
    color: #1675e0;
    background: transparent;
  }
  .rs-nav.rs-nav-horizontal {
    white-space: nowrap;
  }
  .rs-nav.rs-nav-horizontal.rs-nav-justified > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-nav.rs-nav-horizontal.rs-nav-justified .rs-nav-item,
  .rs-nav.rs-nav-horizontal.rs-nav-justified .rs-dropdown {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 1%;
            flex: 1 1 1%;
  }
  .rs-nav.rs-nav-horizontal.rs-nav-justified .rs-dropdown .rs-dropdown-toggle {
    width: 100%;
    text-align: left;
  }
  .rs-nav.rs-nav-horizontal .rs-dropdown,
  .rs-nav.rs-nav-horizontal .rs-nav-item {
    display: inline-block;
    vertical-align: top;
  }
  .rs-nav.rs-nav-horizontal .rs-nav-waterline {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .rs-nav-reversed.rs-nav.rs-nav-horizontal .rs-nav-waterline {
    bottom: auto;
    top: 0;
  }
  .rs-nav.rs-nav-vertical ul > .rs-dropdown:not(:first-child),
  .rs-nav.rs-nav-vertical ul > .rs-nav-item:not(:first-child) {
    margin-top: 6px;
  }
  .rs-nav.rs-nav-vertical .rs-dropdown {
    width: 100%;
  }
  .rs-nav.rs-nav-vertical .rs-dropdown > .rs-dropdown-toggle {
    width: 100%;
    text-align: left;
    z-index: 0;
  }
  .rs-nav.rs-nav-vertical .rs-nav-waterline {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
  }
  .rs-nav-reversed.rs-nav.rs-nav-vertical .rs-nav-waterline {
    right: auto;
  }
  .rs-nav-default .rs-nav-item,
  .rs-nav-tabs .rs-nav-item {
    overflow: hidden;
    position: relative;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-nav-default .rs-nav-item,
    .rs-nav-tabs .rs-nav-item {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-disable-line property-no-vendor-prefix */
    }
  }
  .rs-nav-default .rs-nav-item:not(.rs-nav-item-disabled) .rs-ripple,
  .rs-nav-tabs .rs-nav-item:not(.rs-nav-item-disabled) .rs-ripple {
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
  }
  .rs-nav-default .rs-nav-item:not(.rs-nav-item-disabled) .rs-ripple-rippling,
  .rs-nav-tabs .rs-nav-item:not(.rs-nav-item-disabled) .rs-ripple-rippling {
    -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  .rs-nav-default .rs-nav-item:not(.rs-nav-item-disabled) .rs-ripple-pond,
  .rs-nav-tabs .rs-nav-item:not(.rs-nav-item-disabled) .rs-ripple-pond {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .rs-nav-default .rs-nav-item .rs-ripple-pond {
    border-radius: inherit;
    overflow: hidden;
  }
  .rs-nav-tabs .rs-nav-item.rs-nav-item-active {
    z-index: 1;
  }
  .rs-nav-default .rs-nav-item > .rs-nav-item-content {
    border-radius: 6px;
  }
  .rs-nav-default .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:hover,
  .rs-nav-default .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:focus {
    background: #e5e5ea;
  }
  .rs-nav-default .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:active {
    background: #e5e5ea;
  }
  .rs-nav-tabs .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:hover,
  .rs-nav-tabs .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:focus {
    background: #e5e5ea;
  }
  .rs-nav-tabs .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:active {
    background: #e5e5ea;
  }
  .rs-nav-tabs .rs-nav-item.rs-nav-item-active > .rs-nav-item-content {
    padding: 7px 11px;
  }
  .rs-nav-tabs.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content {
    border-radius: 6px 6px 0 0;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content {
    border-radius: 0 0 6px 6px;
  }
  .rs-nav-tabs.rs-nav-horizontal .rs-nav-waterline {
    border-top: 1px solid #d9d9d9;
  }
  .rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active > .rs-nav-item-content {
    border: 1px solid #d9d9d9;
    border-bottom-color: #fff;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-horizontal .rs-nav-item.rs-nav-item-active > .rs-nav-item-content {
    border-bottom-color: #d9d9d9;
    border-top-color: #fff;
  }
  .rs-nav-tabs.rs-nav-vertical .rs-dropdown > .rs-dropdown-toggle,
  .rs-nav-tabs.rs-nav-vertical .rs-nav-item > .rs-nav-item-content {
    border-radius: 6px 0 0 6px;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical .rs-dropdown > .rs-dropdown-toggle,
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical .rs-nav-item > .rs-nav-item-content {
    border-radius: 0 6px 6px 0;
  }
  .rs-nav-tabs.rs-nav-vertical .rs-nav-waterline {
    width: 1px;
    background: #d9d9d9;
  }
  .rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active > .rs-nav-item-content {
    border: 1px solid #d9d9d9;
    border-right-color: #fff;
  }
  .rs-nav-reversed.rs-nav-tabs.rs-nav-vertical .rs-nav-item.rs-nav-item-active > .rs-nav-item-content {
    border-left-color: #fff;
    border-right-color: #d9d9d9;
  }
  .rs-nav-subtle.rs-nav-horizontal .rs-nav-waterline {
    border-top: 2px solid #f7f7fa;
  }
  .rs-nav-subtle.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content {
    position: relative;
  }
  .rs-nav-subtle.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    height: 2px;
    background-color: #1675e0;
    -webkit-transition: left 0.3s ease-out, right 0.3s ease-out;
    transition: left 0.3s ease-out, right 0.3s ease-out;
  }
  .rs-nav-reversed.rs-nav-subtle.rs-nav-horizontal .rs-nav-item > .rs-nav-item-content::before {
    bottom: auto;
    top: 0;
  }
  .rs-nav-subtle.rs-nav-horizontal .rs-nav-item.rs-nav-item-active > .rs-nav-item-content::before {
    left: 0;
    right: 0;
  }
  .rs-nav-subtle.rs-nav-vertical .rs-nav-waterline {
    width: 2px;
    background: #f7f7fa;
  }
  .rs-nav-subtle.rs-nav-vertical .rs-nav-item > .rs-nav-item-content {
    position: relative;
  }
  .rs-nav-subtle.rs-nav-vertical .rs-nav-item > .rs-nav-item-content::before {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 50%;
    width: 2px;
    background-color: #1675e0;
    -webkit-transition: top 0.3s ease-out, bottom 0.3s ease-out;
    transition: top 0.3s ease-out, bottom 0.3s ease-out;
  }
  .rs-nav-reversed.rs-nav-subtle.rs-nav-vertical .rs-nav-item > .rs-nav-item-content::before {
    right: auto;
    left: 0;
  }
  .rs-nav-subtle.rs-nav-vertical .rs-nav-item.rs-nav-item-active > .rs-nav-item-content::before {
    top: 0;
    bottom: 0;
  }
  .rs-navbar::before,
  .rs-navbar::after {
    content: ' ';
    display: table;
  }
  .rs-navbar::after {
    clear: both;
  }
  .rs-navbar::before,
  .rs-navbar::after {
    content: ' ';
    display: table;
  }
  .rs-navbar::after {
    clear: both;
  }
  .rs-navbar-header {
    float: left;
    display: inline-block;
    height: 56px;
  }
  .rs-navbar-nav {
    float: left;
  }
  .rs-navbar-nav.rs-navbar-right {
    float: right;
  }
  .rs-navbar-nav .rs-nav-item > .rs-nav-item-content {
    padding: 18px 16px;
    height: 56px;
  }
  .rs-navbar-nav .rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content,
  .rs-navbar-nav .rs-dropdown > .rs-dropdown-toggle {
    border-radius: 0;
  }
  .rs-navbar-nav .rs-dropdown > .rs-dropdown-toggle {
    padding: 18px 16px;
    padding-right: 36px;
    height: 56px;
  }
  .rs-navbar-nav .rs-dropdown-toggle-caret {
    top: 18px;
    right: 16px;
  }
  .rs-navbar.rs-navbar-default {
    background-color: #f7f7fa;
  }
  .rs-navbar.rs-navbar-default,
  .rs-navbar.rs-navbar-default .rs-navbar-header a {
    color: #575757;
  }
  .rs-navbar.rs-navbar-default .rs-dropdown .rs-dropdown-toggle,
  .rs-navbar.rs-navbar-default .rs-nav-item > .rs-nav-item-content,
  .rs-navbar.rs-navbar-default .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-default .rs-nav-item > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-default .rs-dropdown .rs-dropdown-toggle:focus,
  .rs-navbar.rs-navbar-default .rs-nav-item > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-default .rs-dropdown .rs-dropdown-toggle:active,
  .rs-navbar.rs-navbar-default .rs-nav-item > .rs-nav-item-content:active {
    color: #575757;
  }
  .rs-navbar.rs-navbar-default .rs-nav-item-active > .rs-nav-item-content,
  .rs-navbar.rs-navbar-default .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar.rs-navbar-default .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-default .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-default .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-default .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
  .rs-navbar.rs-navbar-default .rs-nav-item-active > .rs-nav-item-content:active,
  .rs-navbar.rs-navbar-default .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active {
    color: #1675e0;
    background-color: transparent;
  }
  .rs-navbar.rs-navbar-inverse {
    background-color: #3498ff;
  }
  .rs-navbar.rs-navbar-inverse,
  .rs-navbar.rs-navbar-inverse .rs-navbar-header a {
    color: #ffffff;
  }
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle,
  .rs-navbar.rs-navbar-inverse .rs-nav-item-active > .rs-nav-item-content,
  .rs-navbar.rs-navbar-inverse .rs-nav-item > .rs-nav-item-content,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-inverse .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-inverse .rs-nav-item > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:focus,
  .rs-navbar.rs-navbar-inverse .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-inverse .rs-nav-item > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-toggle:active,
  .rs-navbar.rs-navbar-inverse .rs-nav-item-active > .rs-nav-item-content:active,
  .rs-navbar.rs-navbar-inverse .rs-nav-item > .rs-nav-item-content:active {
    color: #ffffff;
  }
  .rs-navbar.rs-navbar-inverse .rs-nav-item-active > .rs-nav-item-content,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar.rs-navbar-inverse .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-inverse .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
  .rs-navbar.rs-navbar-inverse .rs-nav-item-active > .rs-nav-item-content:active,
  .rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active {
    background-color: #1675e0 !important;
  }
  .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: #2589f5;
  }
  .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:active,
  .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:active {
    background-color: #1675e0;
  }
  .rs-navbar.rs-navbar-subtle {
    background-color: #fff;
  }
  .rs-navbar.rs-navbar-subtle,
  .rs-navbar.rs-navbar-subtle .rs-navbar-header a {
    color: #7a7a7a;
  }
  .rs-navbar.rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle,
  .rs-navbar.rs-navbar-subtle .rs-nav-item > .rs-nav-item-content,
  .rs-navbar.rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-subtle .rs-nav-item > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:focus,
  .rs-navbar.rs-navbar-subtle .rs-nav-item > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-subtle .rs-dropdown .rs-dropdown-toggle:active,
  .rs-navbar.rs-navbar-subtle .rs-nav-item > .rs-nav-item-content:active {
    color: #7a7a7a;
  }
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: #f7f7fa;
    color: #575757;
  }
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content:active,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:active {
    background-color: rgba(255, 255, 255, 0);
    color: #1675e0;
  }
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    background-color: rgba(255, 255, 255, 0);
  }
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-nav-item-active > .rs-nav-item-content:active,
  .rs-navbar.rs-navbar-subtle .rs-navbar-nav .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active {
    color: #1675e0;
  }
  .rs-notification {
    position: fixed;
    z-index: 1080;
    pointer-events: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: right\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-notification {
      text-align: right\9;
    }
  }
  .rs-notification-item {
    pointer-events: auto;
    position: relative;
  }
  .rs-notification-content {
    display: table\9;
  }
  .rs-notification,
  .rs-notification-item-wrapper {
    max-width: 400px;
  }
  .rs-notification-item-wrapper:not(.rs-notification-fade-exited) + .rs-notification-item-wrapper {
    margin-top: 10px;
  }
  .rs-notification-item-content {
    background: #fff;
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
    padding: 20px;
    border-radius: 6px;
    max-width: 400px;
    display: table\9;
  }
  .rs-notification-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    text-align: left\9;
    color: #272c36;
    font-size: 16px;
    line-height: 1.5;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-notification-title {
      text-align: left\9;
    }
  }
  .rs-notification-title > p {
    margin: 0;
  }
  .rs-notification-title .rs-icon {
    font-size: 24px;
    width: 24px;
    line-height: 1;
    margin-right: 10px;
  }
  .rs-notification-title-with-icon {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-notification-description {
    color: #575757;
    display: table\9;
  }
  .rs-notification-title + .rs-notification-description {
    margin-top: 8px;
  }
  .rs-notification-item-close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 12px;
    line-height: 1;
    outline: none !important;
    color: #8e8e93;
    cursor: pointer;
  }
  .rs-notification-item-close-x::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-notification-fade-entered,
  .rs-notification-fade-leave-active {
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .rs-notification-fade-entered {
    -webkit-animation-duration: 0.4s;
            animation-duration: 0.4s;
    -webkit-animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
            animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
  }
  .rs-notification-fade-exited,
  .rs-notification-fade-entering {
    opacity: 0;
  }
  .rs-notification-fade-exited {
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-transform: scaleY(0.8);
            transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
  .rs-notification-item-wrapper {
    display: inline-block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
  }
  .rs-notification-bottom-start,
  .rs-notification-top-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    left: 24px;
  }
  .rs-notification-bottom-start .rs-notification-fade-entered,
  .rs-notification-top-start .rs-notification-fade-entered {
    -webkit-animation-name: notificationMoveInLeft;
            animation-name: notificationMoveInLeft;
  }
  .rs-notification-bottom-end,
  .rs-notification-top-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    right: 24px;
  }
  .rs-notification-bottom-end .rs-notification-fade-entered,
  .rs-notification-top-end .rs-notification-fade-entered {
    -webkit-animation-name: notificationMoveInRight;
            animation-name: notificationMoveInRight;
  }
  .rs-notification-fade-leave-active {
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
    -webkit-animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
            animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
    -webkit-animation-name: notificationMoveOut;
            animation-name: notificationMoveOut;
  }
  .rs-notification .rs-notification-success .rs-notification-item-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification .rs-notification-success .rs-notification-item-content .rs-icon {
    color: #4caf50;
  }
  .rs-notification .rs-notification-success .rs-notification-item-content .rs-icon::before {
    vertical-align: middle;
  }
  .rs-notification .rs-notification-info .rs-notification-item-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification .rs-notification-info .rs-notification-item-content .rs-icon {
    color: #2196f3;
  }
  .rs-notification .rs-notification-info .rs-notification-item-content .rs-icon::before {
    vertical-align: middle;
  }
  .rs-notification .rs-notification-warning .rs-notification-item-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification .rs-notification-warning .rs-notification-item-content .rs-icon {
    color: #ffb300;
  }
  .rs-notification .rs-notification-warning .rs-notification-item-content .rs-icon::before {
    vertical-align: middle;
  }
  .rs-notification .rs-notification-error .rs-notification-item-content .rs-notification-title + .rs-notification-description {
    margin-left: 34px;
  }
  .rs-notification .rs-notification-error .rs-notification-item-content .rs-icon {
    color: #f44336;
  }
  .rs-notification .rs-notification-error .rs-notification-item-content .rs-icon::before {
    vertical-align: middle;
  }
  .rs-pagination {
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
  }
  .rs-pagination > li {
    display: inline;
  }
  .rs-pagination > li.rs-pagination-btn-active > a {
    color: #1675e0;
    background-color: transparent;
  }
  .rs-pagination > li.rs-pagination-btn-active > a:hover,
  .rs-pagination > li.rs-pagination-btn-active > a:focus,
  .rs-pagination > li.rs-pagination-btn-active > a.focus {
    color: #1675e0;
    background-color: transparent;
  }
  .rs-pagination > li.rs-pagination-btn-active > a:active,
  .rs-pagination > li.rs-pagination-btn-active > a:active:hover,
  .rs-pagination > li.rs-pagination-btn-active > a:active:focus,
  .rs-pagination > li.rs-pagination-btn-active > a:active.focus {
    color: #1675e0;
    background-color: transparent;
  }
  .rs-pagination > li:not(.rs-pagination-btn-disabled) > a {
    -webkit-transition: 550ms, 1s, color 0.3s linear;
    transition: 550ms, 1s, color 0.3s linear;
    overflow: hidden;
    position: relative;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-pagination > li:not(.rs-pagination-btn-disabled) > a {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-disable-line property-no-vendor-prefix */
    }
  }
  .rs-pagination > li:not(.rs-pagination-btn-disabled) > a:not(.rs-btn-disabled) .rs-ripple {
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
  }
  .rs-pagination > li:not(.rs-pagination-btn-disabled) > a:not(.rs-btn-disabled) .rs-ripple-rippling {
    -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  .rs-pagination > li:not(.rs-pagination-btn-disabled) > a:not(.rs-btn-disabled) .rs-ripple-pond {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .rs-pagination > li > a {
    /* stylelint-disable */
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    color: #8e8e93;
    background-color: transparent;
    position: relative;
    float: left;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: 550ms, 1s, color 0.3s linear;
    transition: 550ms, 1s, color 0.3s linear;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-pagination > li > a.rs-btn-loading:hover,
  .rs-pagination > li > a.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-pagination > li > a.rs-btn-loading:focus,
  .rs-pagination > li > a.rs-btn-loading:active,
  .rs-pagination > li > a.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-pagination > li > a:focus,
  .rs-pagination > li > a.rs-btn-focus,
  .rs-pagination > li > a:hover {
    color: #fff;
    background: #f7f7fa;
  }
  .rs-pagination > li > a:active,
  .rs-pagination > li > a.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-pagination > li > a {
    color: #fff;
    background-color: #b2b1f0;
    background-image: none;
  }
  .rs-pagination > li > a:active:hover,
  .rs-pagination > li > a.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-pagination > li > a:hover,
  .rs-pagination > li > a:active:focus,
  .rs-pagination > li > a.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-pagination > li > a:focus,
  .rs-pagination > li > a:active.focus,
  .rs-pagination > li > a.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-pagination > li > a.focus {
    color: #fff;
    background-color: #b2b1f0;
  }
  .rs-pagination > li > a.rs-btn-disabled,
  .rs-pagination > li > a.rs-btn-disabled:hover,
  .rs-pagination > li > a.rs-btn-disabled:focus,
  .rs-pagination > li > a.rs-btn-disabled.focus,
  .rs-pagination > li > a.rs-btn-disabled:active,
  .rs-pagination > li > a.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-pagination > li > a:focus,
  .rs-pagination > li > a.focus,
  .rs-pagination > li > a:hover {
    color: #575757;
  }
  .rs-pagination > li > a:active {
    color: #575757;
    background-color: #e5e5ea;
    background-image: none;
  }
  .rs-pagination > li > a:active:hover,
  .rs-pagination > li > a:active:focus,
  .rs-pagination > li > a:active.focus {
    color: #272c36;
    background-color: #e5e5ea;
  }
  .rs-pagination > .rs-pagination-btn-disabled > a,
  .rs-pagination > .rs-pagination-btn-disabled > a:hover,
  .rs-pagination > .rs-pagination-btn-disabled > a:active,
  .rs-pagination > .rs-pagination-btn-disabled > a:focus {
    color: #8e8e93;
    opacity: 0.3;
    cursor: not-allowed;
    background: transparent;
  }
  .rs-pagination > .rs-pagination-btn-disabled > a:active:hover,
  .rs-pagination > .rs-pagination-btn-disabled > a:active:focus,
  .rs-pagination > .rs-pagination-btn-disabled > a:active.focus {
    color: #8e8e93;
    opacity: 0.3;
    cursor: not-allowed;
    background: transparent;
  }
  .rs-pagination-lg > li > a {
    /* stylelint-disable */
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.375;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-pagination-md > li > a {
    /* stylelint-disable */
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-pagination-sm > li > a {
    /* stylelint-disable */
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-pagination-xs > li > a {
    /* stylelint-disable */
    padding: 2px 8px;
    font-size: 12px;
    line-height: 1.66666667;
    border-radius: 6px;
    /* stylelint-enable */
  }
  /* rtl:begin:ignore */
  [dir='rtl'] .rs-pagination-btn [class*='rs-icon-page'] {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  /* rtl:end:ignore */
  .rs-panel {
    border-radius: 6px;
    overflow: hidden;
  }
  .rs-panel-bordered {
    border: 1px solid #e5e5ea;
  }
  .rs-panel-shaded {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06);
  }
  .rs-panel-heading,
  .rs-panel-body {
    padding: 20px;
  }
  .rs-panel-body-fill {
    padding: 0 !important;
  }
  .rs-panel-heading {
    color: #272c36;
    font-size: 16px;
    line-height: 1.25;
  }
  .rs-panel-title {
    margin: 0;
  }
  .rs-panel-title a {
    color: #272c36;
  }
  .rs-panel-title a:hover,
  .rs-panel-title a:focus,
  .rs-panel-title a:active {
    text-decoration: none;
  }
  .rs-panel-heading + .rs-panel-collapse .rs-panel-body,
  .rs-panel-heading + .rs-panel-body {
    padding-top: 10px;
  }
  .rs-panel-collapsible > .rs-panel-heading {
    cursor: pointer;
    -webkit-transition: background-color 0.3s linear, border-radius 0.3s linear;
    transition: background-color 0.3s linear, border-radius 0.3s linear;
    position: relative;
  }
  .rs-panel-collapsible > .rs-panel-heading::before {
    position: absolute;
    font-family: 'rsuite-icon-font';
    content: '\ea08';
    top: 20px;
    right: 20px;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .rs-panel-in > .rs-panel-heading::before {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .rs-panel-group {
    border-radius: 6px;
    overflow: hidden;
  }
  .rs-panel-group-bordered {
    border: 1px solid #e5e5ea;
  }
  .rs-panel-group > .rs-panel {
    border: none;
  }
  .rs-panel-group > .rs-panel + .rs-panel {
    position: relative;
  }
  .rs-panel-group > .rs-panel + .rs-panel::before {
    content: '';
    position: absolute;
    top: 0;
    border-top: 1px solid #e5e5ea;
    left: 20px;
    right: 20px;
  }
  .rs-picker-toggle-wrapper {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
  }
  .rs-picker-toggle.rs-btn .rs-ripple-pond {
    display: none !important;
  }
  .rs-picker-block {
    display: block;
  }
  .rs-picker-disabled {
    opacity: 0.3;
  }
  .rs-picker-toggle .rs-picker-toggle-placeholder {
    color: #8e8e93;
  }
  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #1675e0;
  }
  .rs-picker-none {
    padding: 6px 12px 12px;
    color: #8e8e93;
    cursor: default;
  }
  .rs-picker-countable .rs-picker-toggle-value {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-picker-value-list {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block\9;
    max-width: 100%\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-picker-value-list {
      max-width: 100%;
    }
  }
  .rs-picker-value-count {
    margin: 0 4px;
    background: #3498ff;
    color: #fff;
    border-radius: 10px;
    padding: 0 8px;
    line-height: 20px;
  }
  .rs-picker-value-separator {
    margin: 0 4px 0 0;
  }
  .rs-picker-toggle {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* stylelint-disable */
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-picker-toggle.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-toggle.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-picker-toggle.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-picker-toggle.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-picker-toggle.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-picker-toggle.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-picker-toggle.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-picker-toggle.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-picker-toggle:hover,
  .rs-picker-toggle:focus {
    color: #575757;
    text-decoration: none;
  }
  .rs-picker-toggle:active,
  .rs-picker-toggle.rs-btn-active {
    outline: 0;
    background-image: none;
  }
  .rs-picker-default .rs-picker-toggle {
    padding: 7px 11px;
  }
  .rs-picker-default .rs-btn,
  .rs-picker-default .rs-picker-toggle {
    -webkit-transition: border-color ease-in-out 0.3s;
    transition: border-color ease-in-out 0.3s;
  }
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
    border-color: #1675e0;
  }
  .rs-picker-subtle .rs-btn,
  .rs-picker-subtle .rs-picker-toggle {
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    color: #8e8e93;
    background-color: transparent;
    -webkit-transition: none;
    transition: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn:focus,
  .rs-picker-subtle .rs-picker-toggle:focus,
  .rs-picker-subtle .rs-btn.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-focus,
  .rs-picker-subtle .rs-btn:hover,
  .rs-picker-subtle .rs-picker-toggle:hover {
    color: #fff;
    background: #f7f7fa;
  }
  .rs-picker-subtle .rs-btn:active,
  .rs-picker-subtle .rs-picker-toggle:active,
  .rs-picker-subtle .rs-btn.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle {
    color: #fff;
    background-color: #b2b1f0;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn:active:hover,
  .rs-picker-subtle .rs-picker-toggle:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:hover,
  .rs-picker-subtle .rs-btn:active:focus,
  .rs-picker-subtle .rs-picker-toggle:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:focus,
  .rs-picker-subtle .rs-btn:active.focus,
  .rs-picker-subtle .rs-picker-toggle:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.focus {
    color: #fff;
    background-color: #b2b1f0;
  }
  .rs-picker-subtle .rs-btn.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-btn.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:active,
  .rs-picker-subtle .rs-btn.rs-btn-disabled:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-disabled:active {
    opacity: 1;
  }
  .rs-picker-subtle .rs-btn:focus,
  .rs-picker-subtle .rs-picker-toggle:focus,
  .rs-picker-subtle .rs-btn.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-focus,
  .rs-picker-subtle .rs-btn:hover,
  .rs-picker-subtle .rs-picker-toggle:hover {
    color: #575757;
  }
  .rs-picker-subtle .rs-btn:active,
  .rs-picker-subtle .rs-picker-toggle:active,
  .rs-picker-subtle .rs-btn.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle {
    color: #272c36;
    background-color: #e5e5ea;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn:active:hover,
  .rs-picker-subtle .rs-picker-toggle:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:hover,
  .rs-picker-subtle .rs-btn:active:focus,
  .rs-picker-subtle .rs-picker-toggle:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:focus,
  .rs-picker-subtle .rs-btn:active.focus,
  .rs-picker-subtle .rs-picker-toggle:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.focus {
    color: #272c36;
    background-color: #e5e5ea;
  }
  .rs-picker-subtle .rs-btn.rs-btn-red,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-red:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red:focus,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-focus,
  .rs-picker-subtle .rs-btn.rs-btn-red:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red:hover {
    color: #fff;
    background: #f44336;
  }
  .rs-picker-subtle .rs-btn.rs-btn-red:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red:active,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-red,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-red {
    color: #fff;
    background-color: #eb3626;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-red:active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-red:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-red:hover,
  .rs-picker-subtle .rs-btn.rs-btn-red:active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-red:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-red:focus,
  .rs-picker-subtle .rs-btn.rs-btn-red:active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-red.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-red.focus {
    color: #fff;
    background-color: #eb3626;
  }
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled:active,
  .rs-picker-subtle .rs-btn.rs-btn-red.rs-btn-disabled:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-red.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-orange,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-orange:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange:focus,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-focus,
  .rs-picker-subtle .rs-btn.rs-btn-orange:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange:hover {
    color: #fff;
    background: #ff9800;
  }
  .rs-picker-subtle .rs-btn.rs-btn-orange:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange:active,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-orange,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-orange {
    color: #fff;
    background-color: #f59700;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-orange:active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-orange:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:hover,
  .rs-picker-subtle .rs-btn.rs-btn-orange:active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-orange:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-orange:focus,
  .rs-picker-subtle .rs-btn.rs-btn-orange:active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-orange.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-orange.focus {
    color: #fff;
    background-color: #f59700;
  }
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled:active,
  .rs-picker-subtle .rs-btn.rs-btn-orange.rs-btn-disabled:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-orange.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-yellow,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-yellow:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:focus,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-focus,
  .rs-picker-subtle .rs-btn.rs-btn-yellow:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:hover {
    color: #fff;
    background: #ffca28;
  }
  .rs-picker-subtle .rs-btn.rs-btn-yellow:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:active,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-yellow,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow {
    color: #fff;
    background-color: #f5c31d;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-yellow:active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-yellow:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:hover,
  .rs-picker-subtle .rs-btn.rs-btn-yellow:active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-yellow:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:focus,
  .rs-picker-subtle .rs-btn.rs-btn-yellow:active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-yellow.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.focus {
    color: #fff;
    background-color: #f5c31d;
  }
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled:active,
  .rs-picker-subtle .rs-btn.rs-btn-yellow.rs-btn-disabled:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-yellow.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-green,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-green:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green:focus,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-focus,
  .rs-picker-subtle .rs-btn.rs-btn-green:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green:hover {
    color: #fff;
    background: #4caf50;
  }
  .rs-picker-subtle .rs-btn.rs-btn-green:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green:active,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-green,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-green {
    color: #fff;
    background-color: #37ab3c;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-green:active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-green:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-green:hover,
  .rs-picker-subtle .rs-btn.rs-btn-green:active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-green:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-green:focus,
  .rs-picker-subtle .rs-btn.rs-btn-green:active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-green.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-green.focus {
    color: #fff;
    background-color: #37ab3c;
  }
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled:active,
  .rs-picker-subtle .rs-btn.rs-btn-green.rs-btn-disabled:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-green.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-cyan,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-cyan:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:focus,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-focus,
  .rs-picker-subtle .rs-btn.rs-btn-cyan:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:hover {
    color: #fff;
    background: #00bcd4;
  }
  .rs-picker-subtle .rs-btn.rs-btn-cyan:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:active,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-cyan,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan {
    color: #fff;
    background-color: #00b1cc;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-cyan:active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-cyan:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:hover,
  .rs-picker-subtle .rs-btn.rs-btn-cyan:active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-cyan:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:focus,
  .rs-picker-subtle .rs-btn.rs-btn-cyan:active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-cyan.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.focus {
    color: #fff;
    background-color: #00b1cc;
  }
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled:active,
  .rs-picker-subtle .rs-btn.rs-btn-cyan.rs-btn-disabled:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-cyan.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-blue,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-blue:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue:focus,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-focus,
  .rs-picker-subtle .rs-btn.rs-btn-blue:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue:hover {
    color: #fff;
    background: #2196f3;
  }
  .rs-picker-subtle .rs-btn.rs-btn-blue:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue:active,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-blue,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-blue {
    color: #fff;
    background-color: #1787e8;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-blue:active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-blue:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:hover,
  .rs-picker-subtle .rs-btn.rs-btn-blue:active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-blue:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-blue:focus,
  .rs-picker-subtle .rs-btn.rs-btn-blue:active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-blue.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-blue.focus {
    color: #fff;
    background-color: #1787e8;
  }
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled:active,
  .rs-picker-subtle .rs-btn.rs-btn-blue.rs-btn-disabled:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-blue.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-violet,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:hover,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:focus,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:active,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-loading:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-picker-subtle .rs-btn.rs-btn-violet:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet:focus,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-focus,
  .rs-picker-subtle .rs-btn.rs-btn-violet:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet:hover {
    color: #fff;
    background: #673ab7;
  }
  .rs-picker-subtle .rs-btn.rs-btn-violet:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet:active,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-violet,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-violet {
    color: #fff;
    background-color: #5f2bb3;
    background-image: none;
  }
  .rs-picker-subtle .rs-btn.rs-btn-violet:active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet:active:hover,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-active:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-violet:hover,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:hover,
  .rs-picker-subtle .rs-btn.rs-btn-violet:active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet:active:focus,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-active:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-violet:focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-violet:focus,
  .rs-picker-subtle .rs-btn.rs-btn-violet:active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet:active.focus,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-active.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.rs-btn-violet.focus,
  .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.rs-btn-violet.focus {
    color: #fff;
    background-color: #5f2bb3;
  }
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled:hover,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled:focus,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled.focus,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled:active,
  .rs-picker-subtle .rs-btn.rs-btn-violet.rs-btn-disabled:hover:active,
  .rs-picker-subtle .rs-picker-toggle.rs-btn-violet.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-picker-subtle .rs-btn.active,
  .rs-picker-subtle .rs-picker-toggle.active {
    background-color: #f7f7fa;
    color: #575757;
  }
  /* stylelint-disable-next-line */
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn {
    padding-right: 32px;
  }
  .rs-picker-cleanable .rs-picker-toggle.rs-btn {
    padding-right: 44px;
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 8px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    top: 7px;
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    right: 12px;
  }
  .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    right: 30px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper {
    padding-bottom: 5px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 12px;
    margin-top: 5px;
    font-size: 14px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn,
  .rs-picker-daterange .rs-picker-toggle.rs-btn {
    padding-right: 36px;
  }
  .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn,
  .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn {
    padding-right: 42px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 8px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 7px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    right: 12px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
    right: 30px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-toggle.rs-btn-lg {
    /* stylelint-disable */
    padding: 10px 16px;
    font-size: 16px;
    line-height: 1.375;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-lg {
    padding-right: 36px;
  }
  .rs-picker-cleanable .rs-picker-toggle.rs-btn-lg {
    padding-right: 50px;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
    top: 10px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
    top: 9px;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
    right: 16px;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
    right: 32px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-left: 15px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 9px 16px;
    font-size: 16px;
    line-height: 1.375;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper {
    padding-bottom: 7px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 7px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 16px;
    margin-top: 7px;
    font-size: 16px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
    height: 20px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-lg {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-lg,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
    padding-right: 42px;
  }
  .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-lg,
  .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-lg {
    padding-right: 50px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
    top: 10px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
    top: 9px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
    right: 16px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
    right: 36px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-lg {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .rs-picker-toggle.rs-btn-lg .rs-picker-value-count {
    line-height: 22px;
  }
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-md {
    padding-right: 32px;
  }
  .rs-picker-cleanable .rs-picker-toggle.rs-btn-md {
    padding-right: 44px;
  }
  .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
    top: 8px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
    top: 7px;
  }
  .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
    right: 12px;
  }
  .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
    right: 30px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-left: 11px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 7px 12px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper {
    padding-bottom: 5px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 12px;
    margin-top: 5px;
    font-size: 14px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-md {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-md,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md {
    padding-right: 36px;
  }
  .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-md,
  .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-md {
    padding-right: 42px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
    top: 8px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
    top: 7px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-caret {
    right: 12px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-md .rs-picker-toggle-clean {
    right: 30px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-md {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-picker-toggle.rs-btn-sm {
    /* stylelint-disable */
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
  }
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-sm {
    padding-right: 30px;
  }
  .rs-picker-cleanable .rs-picker-toggle.rs-btn-sm {
    padding-right: 42px;
  }
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
    top: 5px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
    top: 4px;
  }
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
    right: 10px;
  }
  .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
    right: 28px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm {
    padding-left: 9px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper {
    padding-bottom: 2px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 2px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 10px;
    margin-top: 2px;
    font-size: 14px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-sm {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-sm,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
    padding-right: 34px;
  }
  .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-sm,
  .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-sm {
    padding-right: 40px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
    top: 4px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
    top: 3px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-caret {
    right: 10px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-sm .rs-picker-toggle-clean {
    right: 28px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-sm {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .rs-picker-toggle.rs-btn-xs {
    /* stylelint-disable */
    padding: 2px 8px;
    font-size: 12px;
    line-height: 1.66666667;
    border-radius: 6px;
    /* stylelint-enable */
    /* stylelint-disable */
    /* stylelint-enable */
  }
  .rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn-xs {
    padding-right: 28px;
  }
  .rs-picker-cleanable .rs-picker-toggle.rs-btn-xs {
    padding-right: 40px;
  }
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
    top: 2px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
    top: 1px;
  }
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
    right: 8px;
  }
  .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
    right: 26px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-left: 7px;
  }
  .rs-picker-input:not(.rs-picker-tag) .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding: 1px 8px;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
    padding-bottom: -1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: -1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-left: 8px;
    margin-top: -1px;
    font-size: 12px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input input {
    height: 18px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper {
    padding-bottom: 1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-tag {
    margin-top: 1px;
  }
  .rs-picker-input.rs-picker-tag .rs-picker-toggle.rs-btn-xs ~ .rs-picker-tag-wrapper .rs-picker-search-input {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-xs,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
    padding-right: 30px;
  }
  .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn-xs,
  .rs-picker-cleanable.rs-picker-daterange .rs-picker-toggle.rs-btn-xs {
    padding-right: 36px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
    top: 2px;
  }
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-subtle .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-subtle .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
    top: 1px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret {
    right: 8px;
  }
  .rs-picker-date .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
    right: 24px;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn-xs {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  /* stylelint-disable-next-line */
  .rs-picker-toggle {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-picker-default .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    outline: none;
    cursor: pointer;
    color: #575757;
    border: 1px solid #e5e5ea;
  }
  .rs-picker-default .rs-picker-toggle-custom-title {
    padding: 0 !important;
  }
  .rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;
  }
  .rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):hover,
  .rs-picker-toggle .rs-picker-default:not(.rs-picker-disabled):focus {
    border-color: #1675e0;
  }
  .rs-picker-subtle .rs-picker-toggle {
    position: relative;
    z-index: 5;
    padding-right: 32px;
    display: inline-block;
    outline: none;
    cursor: pointer;
  }
  .rs-picker-subtle .rs-picker-toggle-custom-title {
    padding: 0 !important;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle {
    cursor: not-allowed;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active {
    background: none;
  }
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:hover::after,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:focus::after,
  .rs-picker-subtle.rs-picker-disabled .rs-picker-toggle:active::after {
    display: none;
  }
  .rs-picker-toggle-value {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-picker-cleanable .rs-picker-toggle {
    padding-right: 44px;
  }
  .rs-picker-date.rs-picker-cleanable .rs-picker-toggle,
  .rs-picker-daterange.rs-picker-cleanable .rs-picker-toggle {
    padding-right: 52px;
  }
  .rs-picker-toggle-clean {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 30px;
    font-weight: normal;
    background: inherit;
    color: #a6a6a6;
    -webkit-transition: 0.2s color linear;
    transition: 0.2s color linear;
    cursor: pointer;
    text-align: center\9;
  }
  .rs-picker-toggle-clean::before {
    font-family: 'rsuite-icon-font' !important;
  }
  .dropdown-placement-left-end .rs-picker-toggle-clean::before,
  .dropdown-placement-left-start .rs-picker-toggle-clean::before {
    content: '\ea0a' /* rtl: "\ea0c" */;
  }
  .dropdown-placement-right-end .rs-picker-toggle-clean::before,
  .dropdown-placement-right-start .rs-picker-toggle-clean::before {
    content: '\ea0c' /* rtl: "\ea0a" */;
  }
  .dropdown-placement-top-start .rs-picker-toggle-clean::before,
  .dropdown-placement-top-end .rs-picker-toggle-clean::before {
    content: '\ea0e';
  }
  .dropdown-placement-bottom-start .rs-picker-toggle-clean::before,
  .dropdown-placement-bottom-end .rs-picker-toggle-clean::before {
    content: '\ea08';
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-picker-toggle-clean {
      text-align: center\9;
    }
  }
  .rs-picker-toggle-clean:hover {
    color: #f44336;
  }
  .rs-picker-date .rs-picker-toggle-clean,
  .rs-picker-daterange .rs-picker-toggle-clean {
    right: 38px;
  }
  .rs-picker-toggle-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    font-weight: normal;
    color: #8e8e93;
  }
  .rs-picker-toggle-caret::before {
    font-family: 'rsuite-icon-font' !important;
  }
  .rs-picker-placement-left-end .rs-picker-toggle-caret::before,
  .rs-picker-placement-left-start .rs-picker-toggle-caret::before {
    content: '\ea0a' /* rtl: "\ea0c" */;
  }
  .rs-picker-placement-right-end .rs-picker-toggle-caret::before,
  .rs-picker-placement-right-start .rs-picker-toggle-caret::before {
    content: '\ea0c' /* rtl: "\ea0a" */;
  }
  .rs-picker-placement-top-start .rs-picker-toggle-caret::before,
  .rs-picker-placement-top-end .rs-picker-toggle-caret::before {
    content: '\ea0e';
  }
  .rs-picker-placement-bottom-start .rs-picker-toggle-caret::before,
  .rs-picker-placement-bottom-end .rs-picker-toggle-caret::before {
    content: '\ea08';
  }
  .rs-picker-toggle-caret::before {
    content: '\ea08';
  }
  .rs-picker-menu {
    position: absolute;
    text-align: left;
    z-index: 7;
    border-radius: 6px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    -webkit-transition: none;
    transition: none;
    /* stylelint-disable-next-line */
  }
  .rs-modal-open .rs-picker-menu {
    z-index: 1055;
  }
  .rs-drawer-open .rs-picker-menu {
    z-index: 1055;
  }
  .rs-picker-menu.rs-picker-select-menu,
  .rs-picker-menu.rs-picker-check-menu {
    padding-top: 6px;
  }
  .rs-picker-menu .rs-picker-search-bar {
    position: relative;
    padding: 6px 12px 12px;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    display: block;
    width: 100%;
    color: #575757;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e5e5ea;
    -webkit-transition: border-color ease-in-out 0.3s;
    transition: border-color ease-in-out 0.3s;
    border-radius: 6px;
    /* stylelint-disable */
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    height: 36px\9;
    /* stylelint-enable */
    min-width: 200px;
    padding-right: 36px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
      height: 36px;
    }
  }
  textarea.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    height: auto;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-webkit-input-placeholder {
    color: #8e8e93;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-moz-placeholder {
    color: #8e8e93;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::-ms-input-placeholder {
    color: #8e8e93;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input::placeholder {
    color: #8e8e93;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:focus,
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input:hover {
    border-color: #1675e0;
    outline: 0;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input[disabled] {
    background-color: #f7f7fa;
    color: #c5c6c7;
    cursor: not-allowed;
  }
  .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input[disabled]:hover {
    border-color: #e5e5ea;
  }
  .rs-picker-menu .rs-picker-search-bar::after {
    content: '\f002';
    font-family: 'rsuite-icon-font';
    font-style: normal;
    position: absolute;
    width: 14px;
    color: #c5c6c7;
    font-size: 14px;
    line-height: 1.42857143;
    top: 14px;
    right: 24px;
  }
  .rs-tree-menu.rs-picker-menu .rs-picker-search-bar::after,
  .rs-check-tree-menu.rs-picker-menu .rs-picker-search-bar::after {
    top: 7px;
  }
  .rs-picker-menu .ReactVirtualized__Grid.ReactVirtualized__List:focus {
    outline: none;
  }
  .rs-picker-menu.rs-picker-inline {
    position: relative;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-picker-default .rs-btn,
  .rs-picker-input .rs-btn,
  .rs-picker-default .rs-picker-toggle,
  .rs-picker-input .rs-picker-toggle {
    background: #fff !important;
  }
  .rs-picker-default.rs-picker-disabled .rs-picker-toggle,
  .rs-picker-input.rs-picker-disabled .rs-picker-toggle,
  .rs-picker-default.rs-picker-disabled .rs-btn,
  .rs-picker-input.rs-picker-disabled .rs-btn {
    background: #f7f7fa !important;
  }
  .rs-check-item:not(.rs-checkbox-disabled):hover,
  .rs-check-item-focus {
    color: #575757;
    background-color: #f2faff;
  }
  .rs-check-item .rs-checkbox-checker {
    padding: 0;
  }
  .rs-check-item .rs-checkbox-checker > label {
    position: relative;
    cursor: pointer;
    display: block;
    padding: 8px 12px 8px 38px;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    line-height: 20px;
    /* stylelint-disable-next-line */
  }
  .ReactVirtualized__Grid .rs-check-item .rs-checkbox-checker > label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-checkbox-disabled.rs-check-item .rs-checkbox-checker > label {
    cursor: not-allowed;
  }
  .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
    left: 12px;
  }
  .grouped .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper,
  .rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label .rs-checkbox-wrapper {
    left: 26px;
  }
  .grouped .rs-check-item .rs-checkbox-checker > label,
  .rs-picker-check-menu-group-children .rs-check-item .rs-checkbox-checker > label {
    padding-left: 52px;
  }
  .rs-placeholder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .rs-placeholder-active.rs-placeholder-graph,
  .rs-placeholder-active .rs-placeholder-paragraph-graph-image,
  .rs-placeholder-active .rs-placeholder-grid-col > p,
  .rs-placeholder-active .rs-placeholder-paragraph-rows > p {
    background: linear-gradient(-45deg, #f2f2f5 25%, #e5e5ea 37%, #f2f2f5 63%);
    background-size: 400% 100% !important;
    -webkit-animation: placeholder-active 3s ease infinite;
            animation: placeholder-active 3s ease infinite;
  }
  .rs-placeholder-paragraph {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .rs-placeholder-paragraph-graph {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 2px;
    margin-right: 20px;
    background-color: #f2f2f5;
  }
  .rs-placeholder-paragraph-graph-circle {
    border-radius: 50%;
  }
  .rs-placeholder-paragraph-graph-image {
    width: 80px;
    height: 60px;
    position: relative;
    overflow: hidden;
  }
  .rs-placeholder-paragraph-graph-image::after {
    content: '';
    width: 0;
    height: 0;
    left: 10px;
    bottom: 10px;
    position: absolute;
    border-bottom: 36px solid #fff;
    border-left: 20px solid transparent;
    border-right: 30px solid transparent;
  }
  .rs-placeholder-paragraph-graph-image::before {
    content: '';
    width: 0;
    height: 0;
    left: 40px;
    bottom: 10px;
    position: absolute;
    border-bottom: 22px solid #fff;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
  }
  .rs-placeholder-paragraph-graph-image .rs-placeholder-paragraph-graph-inner {
    width: 12px;
    height: 12px;
    right: 18px;
    top: 10px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
  }
  .rs-placeholder-paragraph-rows {
    width: 100%;
  }
  .rs-placeholder-paragraph-rows > p {
    background-color: #f2f2f5;
  }
  .rs-placeholder-grid-col {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }
  .rs-placeholder-grid-col > p {
    background-color: #f2f2f5;
  }
  .rs-placeholder-grid-col:first-child {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .rs-placeholder-graph {
    display: inline-block;
    width: 100%;
    background-color: #f2f2f5;
  }
  .rs-popover {
    position: absolute;
    top: 0;
    left: 0 /* rtl:ignore */;
    z-index: 1060;
    display: none;
    padding: 12px;
    font-size: 14px;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    opacity: 0;
    -webkit-filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
            filter: drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12));
    -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12)\9;
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12)\9;
  }
  .rs-popover.fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  }
  .rs-popover.in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
  .rs-popover > .rs-popover-arrow {
    border-width: 6px;
  }
  .rs-popover > .rs-popover-arrow,
  .rs-popover > .rs-popover-arrow::after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rs-popover > .rs-popover-arrow::after {
    border-width: 6px;
    content: '';
  }
  .rs-popover-title {
    margin: 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-popover-content {
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-popover-title ~ .rs-popover-content {
    margin-top: 8px;
  }
  .rs-popover[class*='placement-top'] {
    margin-top: -6px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-top'].in {
    -webkit-transform: translate(0, -2px);
            transform: translate(0, -2px);
  }
  .rs-popover[class*='placement-top'] > .rs-popover-arrow {
    margin-left: -6px;
    border-bottom-width: 0;
    bottom: -6px;
  }
  .rs-popover[class*='placement-top'] > .rs-popover-arrow::after {
    bottom: 1px;
    margin-left: -6px;
    border-bottom-width: 0;
    border-top-color: #fff;
  }
  /* rtl:begin:ignore */
  .rs-popover[class*='placement-right'] {
    margin-left: 6px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-right'].in {
    -webkit-transform: translate(2px, 0);
            transform: translate(2px, 0);
  }
  .rs-popover[class*='placement-right'] > .rs-popover-arrow {
    left: -6px;
    margin-top: -6px;
    border-left-width: 0;
  }
  .rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
    left: 1px;
    bottom: -6px;
    border-left-width: 0;
    border-right-color: #fff;
  }
  .rs-popover[class*='placement-left'] {
    margin-left: -6px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-left'].in {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  .rs-popover[class*='placement-left'] > .rs-popover-arrow {
    right: -6px;
    margin-top: -6px;
    border-right-width: 0;
  }
  .rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
    right: 1px;
    border-right-width: 0;
    border-left-color: #fff;
    bottom: -6px;
  }
  /* rtl:end:ignore */
  .rs-popover[class*='placement-bottom'] {
    margin-top: 6px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-bottom'].in {
    -webkit-transform: translate(0, 2px);
            transform: translate(0, 2px);
  }
  .rs-popover[class*='placement-bottom'] > .rs-popover-arrow {
    margin-left: -6px;
    border-top-width: 0;
    top: -6px;
  }
  .rs-popover[class*='placement-bottom'] > .rs-popover-arrow::after {
    top: 1px;
    margin-left: -6px;
    border-top-width: 0;
    border-bottom-color: #fff;
  }
  .rs-popover.placement-bottom > .rs-popover-arrow,
  .rs-popover.placement-top > .rs-popover-arrow {
    left: 50%;
  }
  .rs-popover.placement-bottom-end > .rs-popover-arrow,
  .rs-popover.placement-top-end > .rs-popover-arrow {
    right: 4px;
  }
  .rs-popover.placement-left > .rs-popover-arrow,
  .rs-popover.placement-right > .rs-popover-arrow {
    top: 50%;
  }
  .rs-popover.placement-left-end > .rs-popover-arrow,
  .rs-popover.placement-right-end > .rs-popover-arrow {
    bottom: 4px;
  }
  .rs-popover-full {
    padding: 0;
  }
  .rs-popover-full .rs-popover-content {
    margin-top: 0;
  }
  .rs-popover.placement-top-start.rs-popover-full > .rs-popover-arrow,
  .rs-popover.placement-bottom-start.rs-popover-full > .rs-popover-arrow {
    margin-left: 6px;
  }
  .rs-popover.placement-right-start.rs-popover-full > .rs-popover-arrow,
  .rs-popover.placement-left-start.rs-popover-full > .rs-popover-arrow {
    margin-top: 6px;
  }
  .rs-progress-info {
    font-size: 0;
  }
  .rs-progress,
  .rs-progress-icon-success,
  .rs-progress-icon-fail,
  .rs-progress-info-status {
    font-size: 16px;
    line-height: 1.25;
  }
  .rs-progress-circle {
    position: relative;
    font-size: 0;
  }
  .rs-progress-circle-success .rs-progress-circle-info {
    color: #4caf50 !important;
  }
  .rs-progress-circle-success .rs-progress-stroke {
    stroke: #4caf50 !important;
  }
  .rs-progress-circle-fail .rs-progress-circle-info {
    color: #f44336 !important;
  }
  .rs-progress-circle-fail .rs-progress-stroke {
    stroke: #f44336 !important;
  }
  .rs-progress-circle-info {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    font-size: 16px;
    line-height: 2.25;
    height: 36px;
    color: #7a7a7a;
  }
  .rs-progress-circle .rs-progress-trail {
    stroke: #e5e5ea;
  }
  .rs-progress-circle .rs-progress-stroke {
    stroke: #3498ff;
    -webkit-transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
    transition: stroke-dashoffset 0.3s ease, stroke-dasharray 0.2s ease-in-out, stroke 0.3s;
  }
  .rs-progress-line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    padding: 8px 12px;
  }
  .rs-progress-line-success {
    color: #4caf50 !important;
  }
  .rs-progress-line-success .rs-progress-line-bg {
    background-color: #4caf50 !important;
  }
  .rs-progress-line-fail {
    color: #f44336 !important;
  }
  .rs-progress-line-fail .rs-progress-line-bg {
    background-color: #f44336 !important;
  }
  .rs-progress-line-active .rs-progress-line-bg {
    position: relative;
  }
  .rs-progress-line-active .rs-progress-line-bg::before {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 10px;
    -webkit-animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
            animation: progress-active 2s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  }
  .rs-progress-line .rs-progress-info {
    width: 50px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50px;
            flex: 0 0 50px;
    height: 20px;
    padding-left: 12px;
  }
  .rs-progress-line-outer {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    border-radius: 5px;
    overflow: hidden;
    vertical-align: middle;
  }
  .rs-progress-line-inner {
    background: #e5e5ea;
    border-radius: 5px;
  }
  .rs-progress-line-bg {
    background-color: #3498ff;
    border-radius: 5px;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
    -webkit-transition-property: width, color;
    transition-property: width, color;
  }
  .rs-progress-line-inner,
  .rs-progress-line-bg {
    height: 10px;
  }
  .rs-progress-line.rs-progress-line-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    width: auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-outer {
    width: auto;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-inner {
    min-height: 100px;
    width: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    height: 100%;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-info {
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
    padding-left: 0;
    width: auto;
  }
  .rs-progress-line.rs-progress-line-vertical .rs-progress-line-bg {
    width: 100%;
    -webkit-transition-property: height, color;
    transition-property: height, color;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    position: absolute;
    bottom: 0;
  }
  .rs-progress-line.rs-progress-line-vertical.rs-progress-line-active .rs-progress-line-bg::before {
    -webkit-animation-name: progress-active-vertical;
            animation-name: progress-active-vertical;
  }
  .rs-progress-icon-success,
  .rs-progress-icon-fail {
    display: inline-block;
    font-family: 'rsuite-icon-font';
  }
  .rs-progress-circle .rs-progress-icon-success,
  .rs-progress-circle .rs-progress-icon-fail {
    font-size: 36px;
    line-height: 36px;
  }
  .rs-progress-icon-success::before {
    content: '\f00c';
  }
  .rs-progress-icon-fail::before {
    content: '\f00d';
  }
  .rs-radio {
    position: relative;
    display: block;
  }
  .rs-radio-disabled label {
    cursor: not-allowed;
  }
  .rs-radio label {
    line-height: 1.14285714;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
  }
  .rs-radio .rs-radio-inner::before {
    border-color: #3498ff;
  }
  .rs-radio.rs-radio-disabled label {
    cursor: not-allowed;
  }
  .rs-radio-disabled > .rs-radio-checker > label {
    color: #c5c6c7;
  }
  .rs-radio-inline {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    margin-top: 0;
    margin-left: 10px;
  }
  .rs-radio-group-inline {
    display: inline-block;
    margin-left: -10px;
  }
  .rs-radio-group-picker {
    color: #575757;
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    margin-left: 0;
  }
  .rs-radio-group-picker .rs-radio-inline {
    margin-left: 0;
  }
  .rs-radio-group-picker .rs-radio-checker {
    padding: 0;
    min-height: auto;
  }
  .rs-radio-group-picker .rs-radio-wrapper {
    display: none;
  }
  .rs-radio-group-picker .rs-radio-checker > label {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* stylelint-disable */
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
    color: #8e8e93;
    -webkit-transition: color 0.3s linear;
    transition: color 0.3s linear;
    padding: 7px 10px;
  }
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-radio-group-picker .rs-radio-checker > label:hover,
  .rs-radio-group-picker .rs-radio-checker > label:focus {
    color: #575757;
    text-decoration: none;
  }
  .rs-radio-group-picker .rs-radio-checker > label:active,
  .rs-radio-group-picker .rs-radio-checker > label.rs-btn-active {
    outline: 0;
    background-image: none;
  }
  .rs-radio-group-picker .rs-radio-checker > label:hover,
  .rs-radio-group-picker .rs-radio-checker > label:active {
    color: #575757;
  }
  .rs-radio-group-picker .rs-radio-checked .rs-radio-checker > label {
    color: #1675e0;
  }
  .rs-radio-group-picker .rs-radio-disabled .rs-radio-checker > label {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-radio-group-picker .rs-radio-disabled:not(.rs-radio-checked) .rs-radio-checker > label {
    color: #8e8e93;
  }
  .rs-radio-checker {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 36px;
    min-height: 36px;
    line-height: 1;
    position: relative;
  }
  .rs-radio-wrapper {
    position: absolute;
    width: 16px;
    height: 16px;
    display: inline-block;
    left: 10px;
    top: 10px;
  }
  .rs-radio-wrapper [type='radio'] {
    opacity: 0;
  }
  .rs-radio-wrapper::before,
  .rs-radio-wrapper::after,
  .rs-radio-wrapper .rs-radio-inner::before,
  .rs-radio-wrapper .rs-radio-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }
  .rs-radio-wrapper::before,
  .rs-radio-wrapper .rs-radio-inner::before,
  .rs-radio-wrapper .rs-radio-inner::after {
    width: 16px;
    height: 16px;
  }
  .rs-radio-wrapper::before {
    border: 1px solid #3498ff;
    background-color: transparent;
    border-radius: 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    -webkit-transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: opacity 0.2s linear, -webkit-transform 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear;
    transition: transform 0.2s linear, opacity 0.2s linear, -webkit-transform 0.2s linear;
  }
  .rs-radio-checked .rs-radio-wrapper::before {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    opacity: 0;
    visibility: visible;
  }
  .rs-radio-wrapper::after {
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
  .rs-radio-wrapper .rs-radio-inner::before {
    border: 1px solid #d9d9d9;
    background-color: transparent;
    border-radius: 50%;
    -webkit-transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, -webkit-box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;
    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear, -webkit-box-shadow 0.2s linear;
  }
  .rs-radio-checker:hover .rs-radio-wrapper .rs-radio-inner::before {
    border-color: #3498ff;
  }
  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    border-color: #3498ff;
    background-color: #3498ff;
  }
  .rs-radio-disabled:not(.rs-radio-checked) .rs-radio-wrapper .rs-radio-inner::before {
    border-color: #f7f7fa;
    background-color: #f7f7fa;
  }
  .rs-radio-disabled.rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
    opacity: 0.3;
  }
  .rs-radio-wrapper .rs-radio-inner::after {
    width: 6px;
    height: 6px;
    background: #fff;
    margin-top: 5px;
    margin-left: 5px;
    border-radius: 50%;
    opacity: 0;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46), -webkit-transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  }
  .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::after {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  .rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
    -webkit-box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.3);
            box-shadow: 0 0 0 2px rgba(217, 217, 217, 0.3);
  }
  :hover.rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
    -webkit-box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.3);
            box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.3);
  }
  .rs-radio-checked .rs-radio-wrapper [type='radio']:focus ~ .rs-radio-inner::before {
    -webkit-box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.3);
            box-shadow: 0 0 0 2px rgba(52, 152, 255, 0.3);
  }
  .rs-row {
    margin-left: -5px;
    margin-right: -5px;
  }
  .rs-row::before,
  .rs-row::after {
    content: ' ';
    display: table;
  }
  .rs-row::after {
    clear: both;
  }
  .rs-row::before,
  .rs-row::after {
    content: ' ';
    display: table;
  }
  .rs-row::after {
    clear: both;
  }
  .rs-picker-select-menu-items {
    margin-bottom: 6px;
    overflow-y: auto;
  }
  .rs-picker-select-menu-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-picker-select-menu-items .rs-picker-select-menu-group:not(:first-child) {
    margin-top: 6px;
    border-top: 1px solid #e5e5ea;
    padding-top: 6px;
  }
  .rs-picker-select-menu-items .rs-picker-select-menu-group-title {
    padding: 8px 12px;
    padding-right: 32px;
    position: relative;
    cursor: default;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    color: #272c36;
  }
  .rs-picker-select-menu-items .rs-picker-select-menu-group-title:hover {
    background-color: #f2faff;
  }
  .rs-picker-select-menu-items .rs-picker-select-menu-group-title .rs-picker-select-menu-group-caret {
    display: inline-block;
    margin-left: 2px;
    position: absolute;
    top: 8px;
    right: 12px;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .rs-picker-select-menu-items .rs-picker-select-menu-group-title .rs-picker-select-menu-group-caret::before {
    content: '\F0D7';
    font-family: 'rsuite-icon-font';
  }
  .rs-picker-select-menu-items .rs-picker-select-menu-group.folded .rs-picker-select-menu-group-caret {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  .rs-picker-select-menu-item {
    display: block;
    padding: 8px 12px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #575757;
    cursor: pointer;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
    text-decoration: none;
    width: 100%;
    /* stylelint-disable-next-line */
  }
  .ReactVirtualized__Grid .rs-picker-select-menu-item {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
  .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
    color: #575757;
    background-color: #f2faff;
    text-decoration: none;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    font-weight: bold;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active,
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover {
    color: #1675e0;
    background-color: #f2faff;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-disabled {
    color: #c5c6c7;
    cursor: not-allowed;
    text-decoration: none;
  }
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active.rs-picker-select-menu-item-disabled,
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active.rs-picker-select-menu-item-disabled:hover {
    color: #a6d7ff;
  }
  .grouped .rs-picker-select-menu-item,
  .rs-picker-select-menu-group-children .rs-picker-select-menu-item {
    padding-left: 26px;
  }
  .rs-sidebar-collapse {
    -webkit-transition: width 0.2s ease-in, -webkit-box-flex 0.2s ease-in;
    transition: width 0.2s ease-in, -webkit-box-flex 0.2s ease-in;
    transition: flex 0.2s ease-in, width 0.2s ease-in;
    transition: flex 0.2s ease-in, width 0.2s ease-in, -webkit-box-flex 0.2s ease-in, -ms-flex 0.2s ease-in;
    min-width: 0;
  }
  .rs-sidenav {
    -webkit-transition: width 0.2s ease-in;
    transition: width 0.2s ease-in;
    width: 56px;
  }
  .rs-sidenav .rs-sidenav-nav > ul {
    font-size: 0;
  }
  .rs-sidenav .rs-nav-item > .rs-nav-item-content,
  .rs-sidenav .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav .rs-dropdown-item > .rs-dropdown-item-content {
    padding: 15px 20px;
    -webkit-transition: color 0.3s linear, background-color 0.3s linear;
    transition: color 0.3s linear, background-color 0.3s linear;
  }
  .rs-sidenav .rs-dropdown-menu > .rs-dropdown-menu-item-focus > .rs-dropdown-item-content,
  .rs-sidenav .rs-dropdown-menu > .rs-dropdown-item-active > .rs-dropdown-item-content {
    font-weight: normal;
    background: transparent;
  }
  .rs-sidenav .rs-dropdown .rs-dropdown-menu {
    -webkit-transition: height 0.2s ease-in;
    transition: height 0.2s ease-in;
  }
  .rs-sidenav .rs-dropdown .rs-dropdown-toggle {
    height: auto;
    padding-right: 40px;
  }
  .rs-sidenav .rs-dropdown .rs-dropdown-toggle .rs-dropdown-toggle-caret {
    right: 20px;
    top: 15px;
  }
  .rs-sidenav .rs-nav.rs-nav-vertical ul > .rs-dropdown:not(:first-child),
  .rs-sidenav .rs-nav.rs-nav-vertical ul > .rs-nav-item:not(:first-child) {
    margin-top: 0;
  }
  .rs-sidenav .rs-nav-item .rs-nav-item-content,
  .rs-sidenav .rs-dropdown .rs-dropdown-toggle {
    font-size: 16px;
    line-height: 1.25;
    padding-left: 56px;
    width: 100%;
    white-space: normal;
  }
  .rs-sidenav .rs-nav-item .rs-nav-item-content > .rs-icon,
  .rs-sidenav .rs-dropdown .rs-dropdown-toggle > .rs-icon {
    font-size: 16px;
    margin-right: 20px;
    position: absolute;
    left: 20px;
    top: 15px;
    line-height: 1.25;
  }
  .rs-sidenav .rs-nav-item .rs-nav-item-content > .rs-icon > svg,
  .rs-sidenav .rs-dropdown .rs-dropdown-toggle > .rs-icon > svg {
    height: 16px;
  }
  .rs-sidenav .rs-nav-item:not(.rs-nav-item-disabled) > .rs-nav-item-content,
  .rs-sidenav .rs-dropdown .rs-dropdown-toggle {
    border-radius: 0;
  }
  .rs-sidenav-collapse-in {
    width: 100%;
  }
  .rs-sidenav-collapse-in .rs-nav-item > .rs-nav-item-content {
    border-radius: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    overflow: hidden;
    position: relative;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
    .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-disable-line property-no-vendor-prefix */
    }
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content:not(.rs-btn-disabled) .rs-ripple,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle:not(.rs-btn-disabled) .rs-ripple {
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content:not(.rs-btn-disabled) .rs-ripple-rippling,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle:not(.rs-btn-disabled) .rs-ripple-rippling {
    -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content:not(.rs-btn-disabled) .rs-ripple-pond,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle:not(.rs-btn-disabled) .rs-ripple-pond {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-disabled .rs-sidenav-collapse-in .rs-dropdown-item-content::after {
    display: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle-caret::before,
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-toggle > .rs-icon::before {
    content: '\ea08' !important;
  }
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle-caret,
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-toggle > .rs-icon {
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
  }
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-expand .rs-dropdown-toggle-caret,
  .rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-item.rs-dropdown-item-expand > .rs-dropdown-item-content > .rs-dropdown-menu-toggle > .rs-icon {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu {
    display: block;
    position: static;
    float: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-animation-name: none;
            animation-name: none;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu .rs-dropdown-item-submenu > .rs-dropdown-item-content {
    padding: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    display: block;
    padding-right: 40px;
  }
  .rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu {
    display: block;
    position: static;
    float: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-animation-name: none;
            animation-name: none;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
  }
  .rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu .rs-dropdown-item-submenu > .rs-dropdown-item-content:hover {
    background-color: transparent;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
  .rs-sidenav-collapse-in .rs-dropdown-menu .rs-dropdown-menu-toggle {
    width: 100%;
    white-space: normal;
  }
  .rs-sidenav-collapse-in .rs-dropdown-menu {
    overflow: hidden;
  }
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-collapse > .rs-dropdown-menu.rs-dropdown-menu-collapse-out,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu.rs-dropdown-item-collapse > .rs-dropdown-item-content > .rs-dropdown-menu.rs-dropdown-menu-collapse-out {
    display: none;
  }
  .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-menu-collapse-in > .rs-dropdown-menu.dropdown-menu-collapse-in,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu.rs-dropdown-menu-collapse-in > .rs-dropdown-item-content > .rs-dropdown-menu.rs-dropdown-menu-collapse-in {
    display: block;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding-left: 56px;
    padding-right: 40px;
    position: relative;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle > .rs-dropdown-menu-toggle-icon {
    position: absolute;
    right: 20px;
    top: 11px;
    width: auto;
    height: 20px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
    padding-top: 11px;
    padding-bottom: 11px;
    line-height: 1.42857143;
    width: 14px;
    position: absolute;
    z-index: 1;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-content,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding: 11px 20px 11px 56px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
    left: 56px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding-left: 76px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-content .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu .rs-dropdown-menu-toggle {
    padding-left: 65px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
    left: 65px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding-left: 85px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-content .rs-dropdown-item-content .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu .rs-dropdown-menu .rs-dropdown-menu-toggle {
    padding-left: 74px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
    left: 74px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding-left: 94px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-content .rs-dropdown-item-content .rs-dropdown-item-content .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu .rs-dropdown-menu .rs-dropdown-menu .rs-dropdown-menu-toggle {
    padding-left: 83px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu .rs-dropdown-menu .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-item-menu-icon {
    left: 83px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu .rs-dropdown-menu .rs-dropdown-menu > .rs-dropdown-item.rs-dropdown-item-with-icon > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    padding-left: 103px;
  }
  .rs-sidenav-collapse-in .rs-dropdown-item > .rs-dropdown-item-content .rs-dropdown-item-content .rs-dropdown-item-content .rs-dropdown-item-content .rs-dropdown-item:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content {
    padding-left: 92px;
  }
  .rs-sidenav-collapse-out .rs-nav-item .rs-nav-item-content,
  .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle {
    padding-right: 0;
    height: 50px;
    padding-left: 56px;
  }
  .rs-sidenav-collapse-out .rs-dropdown-item > .rs-dropdown-item-content {
    padding: 8px 27px 8px 12px;
  }
  .rs-sidenav-collapse-out .rs-dropdown-toggle-caret {
    display: none;
  }
  .rs-sidenav-collapse-out .rs-nav-item-text,
  .rs-sidenav-collapse-out .rs-dropdown-toggle > span {
    -webkit-animation: sideNavFoldedText 0.2s ease-in forwards;
            animation: sideNavFoldedText 0.2s ease-in forwards;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-nav-item .rs-nav-item-content,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-nav-item .rs-nav-item-content,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-item .rs-dropdown-item-content,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item .rs-dropdown-item-content,
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle,
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item-submenu > .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    text-overflow: clip;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-in .rs-dropdown-toggle-caret {
    display: none;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-menu {
    padding-left: 0px;
  }
  .rs-sidenav-collapsing.rs-sidenav-collapse-out .rs-dropdown-item > .rs-dropdown-item-content {
    -webkit-animation: sideNavFoldedText 0.2s ease-in;
            animation: sideNavFoldedText 0.2s ease-in;
  }
  .rs-sidenav-default {
    background-color: #f7f7fa;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    color: #8e8e93;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:hover,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content:hover,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:focus,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content:focus,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle:focus,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle:focus {
    background-color: #e5e5ea;
    color: #575757;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:active,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content:active,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle:active,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle:active {
    background-color: #e5e5ea;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content {
    color: #1675e0;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:hover,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:hover,
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:focus,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:focus {
    background-color: #f7f7fa;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    color: #8e8e93;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover {
    background-color: #e5e5ea;
  }
  .rs-sidenav-default.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle > .rs-icon,
  .rs-sidenav-default.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle > .rs-icon,
  .rs-sidenav-default.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle > .rs-icon {
    color: #1675e0;
  }
  .rs-sidenav-inverse {
    background-color: #3498ff;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse .rs-nav-item .rs-nav-item-content,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse .rs-nav-item .rs-nav-item-content:hover,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse .rs-nav-item .rs-nav-item-content:focus,
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:focus {
    color: #ffffff;
  }
  .rs-sidenav-inverse .rs-sidenav-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle {
    color: #ffffff;
  }
  .rs-sidenav-inverse .rs-sidenav-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content:hover,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content:focus,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: #2589f5;
    color: #ffffff;
  }
  .rs-sidenav-inverse .rs-sidenav-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content:active,
  .rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:active {
    background-color: #1675e0;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse .rs-nav-item-active > .rs-nav-item-content {
    background-color: #1675e0;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    color: #ffffff;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle:focus {
    background-color: #2589f5;
    color: #ffffff;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content:active,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle:active {
    background-color: #1675e0;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content:active,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content:active {
    color: #ffffff;
    background: #1675e0;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content {
    background-color: #3498ff;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content {
    color: #ffffff;
    background-color: #1675e0;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content:hover,
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content:focus,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active:not(.rs-dropdown-item-submenu) > .rs-dropdown-item-content:focus {
    background-color: #1675e0;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    color: #ffffff;
    background-color: #1675e0;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover {
    background-color: #1675e0;
  }
  .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle > .rs-icon,
  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle > .rs-icon {
    color: #ffffff;
  }
  .rs-sidenav-subtle {
    background-color: #fff;
  }
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn {
    background-color: transparent;
  }
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:hover,
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:focus {
    background-color: #e5e5ea;
  }
  .rs-sidenav-subtle .rs-dropdown-open > .rs-dropdown-toggle.btn:active {
    background-color: #e5e5ea;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle {
    color: #8e8e93;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:hover,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content:hover,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:focus,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > li > .rs-dropdown-item-content:focus,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-item-content > .rs-dropdown-menu-toggle:focus,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-item-content > .rs-dropdown-menu-toggle:focus {
    background-color: #f7f7fa;
    color: #575757;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content {
    color: #1675e0;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:hover,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:hover,
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:focus,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu > li.rs-dropdown-item-active > .rs-dropdown-item-content:focus {
    background-color: #fff;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle,
  .rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle {
    color: #8e8e93;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,
  .rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover {
    background-color: #e5e5ea;
  }
  .rs-sidenav-subtle.rs-sidenav-collapse-in .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle > .rs-icon,
  .rs-sidenav-subtle.rs-sidenav-collapsing .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle > .rs-icon,
  .rs-sidenav-subtle.rs-sidenav-collapse-out .rs-dropdown-menu.rs-dropdown-menu-active ~ .rs-dropdown-toggle > .rs-icon {
    color: #1675e0;
  }
  .rs-slider {
    position: relative;
  }
  .rs-slider .rs-tooltip {
    display: none;
  }
  .rs-slider .rs-tooltip.rs-tooltip-placement-top .rs-tooltip-arrow {
    margin: auto;
    left: 0;
    right: 0;
  }
  .rs-slider-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-slider-disabled .rs-slider-bar,
  .rs-slider-disabled .rs-slider-handle::before {
    cursor: not-allowed;
  }
  .rs-slider-with-mark:not(.rs-slider-vertical) {
    margin-bottom: 29px;
  }
  .rs-slider-mark {
    position: absolute;
    top: 15px;
    left: -2px;
    white-space: nowrap;
  }
  .rs-slider-mark-content {
    margin-left: -50%;
  }
  .rs-slider-last-mark {
    left: auto;
    right: -2px;
  }
  .rs-slider-last-mark .rs-slider-mark-content {
    margin-left: 50%;
  }
  .rs-slider-bar {
    height: 6px;
    border-radius: 3px;
    background-color: #f2f2f5;
    cursor: pointer;
  }
  .rs-slider-bar:hover {
    background-color: #f2f2f5;
  }
  .rs-slider-vertical .rs-slider-bar {
    height: 100%;
    width: 6px;
  }
  .rs-slider-graduator {
    width: 100%;
  }
  .rs-slider-graduator ul,
  .rs-slider-graduator li {
    list-style: none;
  }
  .rs-slider-graduator > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    width: 100%;
  }
  .rs-slider-graduator > ul > li {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 1%;
            flex: 1 1 1%;
    position: relative;
  }
  .rs-slider-graduator > ul > li:last-child::after,
  .rs-slider-graduator > ul > li::before {
    content: '';
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #fff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 2px solid #f2f2f5;
    margin-left: -4px;
    top: -1px;
    -webkit-transition: border-color 0.3s ease-in;
    transition: border-color 0.3s ease-in;
  }
  .rs-slider-vertical .rs-slider-graduator > ul > li:last-child::after,
  .rs-slider-vertical .rs-slider-graduator > ul > li::before {
    top: -4px;
    margin-left: -1px;
  }
  .rs-slider-graduator > ul > li:last-child::after {
    right: -4px;
  }
  .rs-slider-vertical .rs-slider-graduator > ul > li:last-child::after {
    left: 0;
    top: auto;
    bottom: -4px;
  }
  .rs-slider-graduator > ul > li.rs-slider-pass::before {
    border-color: #3498ff;
  }
  .rs-slider-graduator > ul > li.rs-slider-active::before {
    visibility: hidden;
  }
  .rs-slider-vertical .rs-slider-graduator {
    display: block;
    height: 100%;
  }
  .rs-slider-vertical .rs-slider-graduator > ul {
    width: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%;
    padding: 0;
  }
  .rs-slider-vertical .rs-slider-graduator > ul > li {
    display: block;
    padding: 0;
  }
  .rs-slider-progress-bar {
    position: absolute;
    height: 6px;
    border-radius: 3px 0 0 3px;
    background-color: #3498ff;
  }
  .rs-slider-vertical .rs-slider-progress-bar {
    width: 6px;
    border-radius: 3px 3px 0 0;
  }
  .rs-slider-handle {
    position: absolute;
    top: -50%;
  }
  .rs-slider-handle::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #3498ff;
    background-color: #fff;
    margin-left: -6px;
    cursor: pointer;
    /* stylelint-disable */
    -webkit-transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    /* stylelint-enable */
  }
  .rs-slider-handle:hover::before {
    -webkit-box-shadow: 0 0 0 4px rgba(204, 233, 255, 0.4);
            box-shadow: 0 0 0 4px rgba(204, 233, 255, 0.4);
  }
  .rs-slider-handle.active.rs-slider-handle::before {
    -webkit-box-shadow: none;
            box-shadow: none;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  .rs-slider-vertical .rs-slider-handle::before {
    left: 3px;
    margin-top: -6px;
  }
  .rs-slider-handle:hover .rs-tooltip,
  .rs-slider-handle.active .rs-tooltip {
    display: block;
    opacity: 1;
    top: -30px;
  }
  .rs-slider-vertical .rs-slider-handle:hover .rs-tooltip,
  .rs-slider-vertical .rs-slider-handle.active .rs-tooltip {
    top: -33px;
    margin-left: 3px;
  }
  .rs-slider-vertical {
    height: 100%;
  }
  .rs-slider-vertical .rs-slider-mark {
    top: -8px;
    left: 15px;
  }
  .rs-slider-vertical .rs-slider-mark-content {
    margin-left: auto;
  }
  .rs-slider-vertical .rs-slider-last-mark {
    top: auto;
    bottom: -8px;
  }
  .rs-steps {
    min-height: 30px;
  }
  .rs-steps-item {
    padding-left: 40px;
    position: relative;
    overflow: hidden;
  }
  .rs-steps-item-icon-wrapper {
    display: block;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 1.75;
    color: #8e8e93;
    position: absolute;
    top: 0;
    left: 0;
  }
  .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
    border-width: 1px;
    border-style: solid;
    border-color: #8e8e93;
    border-radius: 50%;
  }
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon,
  .rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon {
    position: relative;
    text-indent: -9999px;
  }
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before,
  .rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    width: 28px;
    height: 28px;
    font-size: 12px;
    line-height: 2.33333333;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 0;
  }
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
    border-color: #3498ff;
    color: #fff;
  }
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
    content: '\f00c';
    color: #3498ff;
  }
  .rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
    border-color: #f44336;
    color: #fff;
  }
  .rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
    content: '\f00d';
    color: #f44336;
  }
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
    border-color: #3498ff;
    background: #3498ff;
    color: #fff;
  }
  .rs-steps-item-status-finish .rs-steps-item-icon-wrapper,
  .rs-steps-item-status-process .rs-steps-item-icon-wrapper {
    color: #3498ff;
  }
  .rs-steps-item-status-error .rs-steps-item-icon-wrapper {
    color: #f44336;
  }
  .rs-steps-item-icon-wrapper > .rs-steps-item-icon {
    width: 100%;
    display: block;
    text-align: center;
  }
  .rs-steps-item-icon-wrapper > .rs-steps-item-icon .rs-icon {
    vertical-align: middle;
  }
  .rs-steps-item-content {
    color: #8e8e93;
    display: inline-block;
    position: relative;
    width: 100%;
  }
  .rs-steps-item-status-process .rs-steps-item-content {
    color: #575757;
  }
  .rs-steps-item-status-error .rs-steps-item-content {
    color: #f44336;
  }
  .rs-steps-item-title {
    position: relative;
    font-size: 16px;
    line-height: 1.875;
    min-height: 16px;
    padding-right: 10px;
    display: inline-block;
  }
  .rs-steps-item-title:empty {
    padding-right: 0;
  }
  .rs-steps-item-description {
    margin-top: 12px;
  }
  .rs-steps-item-tail,
  .rs-steps-item-title::after {
    position: absolute;
    border-color: #c5c6c7;
  }
  .rs-steps-item-status-process .rs-steps-item-tail,
  .rs-steps-item-status-process .rs-steps-item-title::after {
    border-color: #c5c6c7;
  }
  .rs-steps-item-status-finish .rs-steps-item-tail,
  .rs-steps-item-status-finish .rs-steps-item-title::after {
    border-color: #3498ff;
  }
  .rs-steps-next-error .rs-steps-item-tail,
  .rs-steps-next-error .rs-steps-item-title::after {
    border-color: #f44336;
  }
  .rs-steps-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .rs-steps-horizontal .rs-steps-item {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-negative: 1;
        flex-shrink: 1;
    min-height: 30px;
  }
  .rs-steps-horizontal .rs-steps-item:last-child {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }
  .rs-steps-horizontal .rs-steps-item:last-child .rs-steps-item-title::after {
    display: none;
  }
  .rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: 50px;
  }
  .rs-steps-horizontal .rs-steps-item:not(:first-child) .rs-steps-item-icon-wrapper {
    left: 10px;
  }
  .rs-steps-horizontal .rs-steps-item-content {
    height: 30px;
  }
  .rs-steps-horizontal .rs-steps-item-title::after {
    content: '';
    top: 15px;
    left: 100%;
    width: 9999px;
    border-top-width: 1px;
    border-top-style: solid;
  }
  .rs-steps-vertical .rs-steps-item {
    padding-bottom: 20px;
  }
  .rs-steps-vertical .rs-steps-item:not(:first-child) {
    margin-top: 10px;
  }
  .rs-steps-vertical .rs-steps-item:last-child .rs-steps-item-tail {
    display: none;
  }
  .rs-steps-vertical .rs-steps-item-tail {
    top: 40px;
    bottom: 0;
    left: 15px;
    border-left-width: 1px;
    border-left-style: solid;
  }
  .rs-steps-small {
    min-height: 24px;
  }
  .rs-steps-small.rs-steps-horizontal .rs-steps-item {
    min-height: 24px;
  }
  .rs-steps-small.rs-steps-horizontal .rs-steps-item:not(:first-child) {
    padding-left: 44px;
  }
  .rs-steps-small.rs-steps-vertical .rs-steps-item-tail {
    left: 12px;
  }
  .rs-steps-small.rs-steps-horizontal .rs-steps-item-title::after {
    top: 12px;
  }
  .rs-steps-small .rs-steps-item {
    padding-left: 34px;
  }
  .rs-steps-small .rs-steps-item-icon-wrapper {
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 1.57142857;
  }
  .rs-steps-small .rs-steps-item-title {
    font-size: 14px;
    line-height: 1.71428571;
  }
  .rs-steps-small .rs-steps-item-description {
    margin-top: 12px;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-steps-small .rs-steps-item-status-finish .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before,
  .rs-steps-small .rs-steps-item-status-error .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) .rs-steps-item-icon::before {
    width: 22px;
    height: 22px;
    line-height: 1.83333333;
  }
  .rs-table-pagination-toolbar {
    padding: 20px 20px;
    font-size: 12px;
    line-height: 1.66666667;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .rs-table-pagination-length-menu,
  .rs-table-pagination-page-info {
    color: #8e8e93;
    display: inline-block;
    vertical-align: middle;
  }
  .rs-table-pagination-length-menu .rs-picker-toggle {
    padding: 2px 21px 2px 8px;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-table-pagination-length-menu .rs-picker-toggle-caret::before {
    vertical-align: middle;
  }
  .rs-table-pagination-length-menu .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 0;
    right: 8px;
  }
  .rs-table-pagination-length-menu + .rs-table-pagination-page-info {
    margin-left: 18px;
  }
  .rs-table-pagination-end {
    font-size: 0;
  }
  .rs-table {
    position: relative;
    overflow: hidden;
    background: #fff;
  }
  .rs-table-column-resizing {
    cursor: ew-resize;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-table-row {
    overflow: hidden;
    position: absolute;
    height: 36px;
    width: 100%;
    top: 0;
    border-bottom: 1px solid #f2f2f5;
  }
  .rs-table-row.virtualized {
    pointer-events: none;
    visibility: hidden\9;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-table-row.virtualized {
      visibility: hidden;
    }
  }
  .rs-table-row-expanded {
    position: absolute;
    height: 46px;
    bottom: 0;
    width: 100%;
    z-index: 4;
    border-top: 1px solid #f2f2f5;
    padding: 10px;
    background: #fff;
  }
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group {
    background: #fff;
  }
  .rs-table-hover .rs-table-row:hover .rs-table-cell {
    background: #fff;
  }
  .rs-table-row-header {
    color: #8e8e93;
    background: #fff;
  }
  .rs-table-row-header .rs-table-cell {
    background: #fff;
  }
  .rs-table-row-header .rs-table-cell-group-shadow {
    z-index: 5;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .rs-table-row,
  .rs-table-cell-group,
  .rs-table-cell {
    -webkit-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
  }
  .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
  .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
  .rs-table-hover .rs-table-row:hover .rs-table-cell {
    background: #f2faff;
  }
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
  .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
    background: #fff;
  }
  .rs-table-header-row-wrapper {
    z-index: 2;
    position: relative;
  }
  .rs-table-affix-header {
    z-index: 3;
    visibility: hidden;
  }
  .rs-table-affix-header.fixed {
    visibility: visible;
  }
  .rs-table-body-row-wrapper {
    position: relative;
    overflow: hidden;
    z-index: 0;
  }
  .rs-table-body-info {
    width: 100%;
    text-align: center;
    top: 50%;
    position: absolute;
    margin-top: -20px;
    line-height: 40px;
  }
  .rs-table-body-info .icon {
    margin: 0 10px;
  }
  .rs-table-body-info-wheel-area {
    width: 100%;
  }
  .rs-table-body-wheel-area {
    width: 100%;
  }
  .rs-table-loader-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in;
    transition: opacity 0.2s ease-in;
    pointer-events: none;
    visibility: hidden\9;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-table-loader-wrapper {
      visibility: hidden;
    }
  }
  .rs-table-loader {
    width: 100%;
    text-align: center;
    top: 50%;
    position: absolute;
    margin-top: -20px;
    line-height: 40px;
  }
  .rs-table-loader-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    margin-right: 12px;
    padding-top: 3px;
  }
  .rs-table-loader-icon::before,
  .rs-table-loader-icon::after {
    width: 18px;
    height: 18px;
  }
  .rs-table-loader-icon::before,
  .rs-table-loader-icon::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-table-loader-icon::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-table-loader-icon::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-table-loading .rs-table-loader-wrapper {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 1;
  }
  .rs-table-cell {
    height: 36px;
    border-style: solid;
    border-width: 0;
    border-color: #f2f2f5;
    display: block;
    overflow: hidden;
    position: absolute;
    white-space: normal;
    background: #fff;
  }
  .rs-table-cell.first {
    border-left-width: 0;
  }
  .rs-table-cell-wrap1 {
    display: table;
  }
  .rs-table-cell-wrap2 {
    display: table-row;
  }
  .rs-table-cell-wrap3 {
    display: table-cell;
    vertical-align: middle;
  }
  .rs-table-cell-content {
    padding: 13px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
  .rs-table-cell-header .rs-table-cell-content {
    padding: 10px 10px;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-table-cell-header-sort-wrapper {
    cursor: pointer;
    margin-left: 5px;
    display: inline-block;
  }
  .rs-table-cell-header-icon-sort {
    width: 10px;
    height: 10px;
    color: #3498ff;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-table-cell-header-icon-sort::after {
    content: '\f0dc';
    font-style: normal;
    font-family: 'rsuite-icon-font';
  }
  .rs-table-cell-header-icon-sort-asc::after {
    content: '\f0de';
    color: #3498ff;
  }
  .rs-table-cell-header-icon-sort-desc::after {
    content: '\f0dd';
    color: #3498ff;
  }
  .rs-table-cell-header-sortable .rs-table-cell-content {
    cursor: pointer;
  }
  .rs-table-column-resize-spanner {
    height: 36px;
    width: 3px;
    z-index: 3;
    position: absolute;
    cursor: ew-resize !important;
    outline: none;
    border-left: 3px solid #fff;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
    margin-left: -3px;
  }
  .rs-table-column-resize-spanner::after,
  .rs-table-column-resize-spanner::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    margin: auto;
    bottom: 0;
    top: 0;
    display: none;
  }
  .rs-table-column-resize-spanner::before {
    border-style: dashed solid dashed dashed;
    border-color: transparent #3498ff transparent transparent;
    border-width: 3px;
    right: 4px;
  }
  .rs-table-column-resize-spanner::after {
    border-style: dashed dashed dashed solid;
    border-color: transparent transparent transparent #3498ff;
    border-width: 3px;
    left: 4px;
  }
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner,
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover {
    background-color: #3498ff;
  }
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner::before,
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover::before,
  .rs-table:not(.rs-table-column-resizing) .rs-table-cell-header .rs-table-cell:hover ~ .rs-table-column-resize-spanner::after,
  .rs-table:not(.rs-table-column-resizing) .rs-table-column-resize-spanner:hover::after {
    display: block;
  }
  .rs-table-cell-group-fixed-right,
  .rs-table-cell-group-fixed-left {
    position: absolute;
    z-index: 4;
    background: #fff;
  }
  .rs-table-cell-group-fixed-right {
    margin-left: 10px;
  }
  .rs-table-cell-group-shadow,
  .rs-table-cell-group-left-shadow {
    -webkit-box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
            box-shadow: 3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
  }
  .rs-table-cell-group-right-shadow {
    -webkit-box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
            box-shadow: -3px 0 5px rgba(9, 9, 9, 0.08) /* rtl:ignore */;
  }
  .rs-table-mouse-area {
    display: none;
    background: #3498ff;
    left: -1px;
    top: 0;
    position: absolute;
    width: 1px;
    z-index: 6;
  }
  .rs-table-mouse-area > span {
    height: 36px;
    width: 3px;
    z-index: 3;
    cursor: ew-resize !important;
    outline: none;
    background-color: #3498ff;
    position: absolute;
    left: -1.5px;
  }
  .rs-table-word-wrap .rs-table-cell-content {
    white-space: normal;
    word-break: break-all;
  }
  .rs-table-bordered {
    border: 1px solid #f2f2f5;
  }
  .rs-table-cell-bordered .rs-table-cell {
    border-width: 0 1px 0 0;
  }
  .rs-table-column-group {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
  }
  .rs-table-column-group-header {
    border-bottom: 1px solid #f2f2f5;
    position: absolute;
    width: 100%;
  }
  .rs-table-column-group-header-content {
    display: table-cell;
    padding: 10px;
  }
  .rs-table-column-group-cell {
    position: absolute;
    border-right: 1px solid #f2f2f5;
  }
  .rs-table-column-group-cell-content {
    display: table-cell;
    padding: 10px;
  }
  .rs-table-cell-expanded .rs-table-cell-expand-icon::before {
    font-family: 'rsuite-icon-font';
    content: '\f0d7';
  }
  .rs-table-cell-expand-wrapper {
    margin-right: 10px;
    display: inline-block;
    cursor: pointer;
  }
  .rs-table-cell-expand-wrapper::after {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
  }
  .rs-table-cell-expand-icon {
    width: 10px;
    cursor: pointer;
    outline: none;
    color: #8e8e93;
  }
  .rs-table-cell-expand-icon::before {
    font-family: 'rsuite-icon-font';
    content: '\f0da';
    font-style: normal;
  }
  .rs-table-scrollbar {
    background: #e5e5ea;
    position: absolute;
    -webkit-transition: background 0.1s linear, -webkit-transform 0.1s linear;
    transition: background 0.1s linear, -webkit-transform 0.1s linear;
    transition: background 0.1s linear, transform 0.1s linear;
    transition: background 0.1s linear, transform 0.1s linear, -webkit-transform 0.1s linear;
    opacity: 0.6;
    z-index: 1;
  }
  .rs-table-scrollbar-hide {
    display: none;
  }
  .rs-table-scrollbar-handle {
    position: absolute;
    background-color: #575757;
    border-radius: 4px;
  }
  .rs-table-scrollbar-pressed {
    z-index: 1;
  }
  .rs-table-scrollbar-pressed .rs-table-scrollbar-handle {
    background-color: #272c36;
  }
  .rs-table-scrollbar-horizontal {
    width: 100%;
    height: 10px;
    bottom: 0;
  }
  .rs-table-scrollbar-horizontal.fixed {
    position: fixed;
  }
  .rs-table-scrollbar-horizontal .rs-table-scrollbar-handle {
    height: 8px;
    left: 0 /* rtl:ignore */;
    top: 1px;
  }
  .rs-table-scrollbar-pressed.rs-table-scrollbar-horizontal:hover,
  .rs-table-scrollbar-horizontal:hover {
    -webkit-transform-origin: center bottom;
            transform-origin: center bottom;
    -webkit-transform: scaleY(1.3);
            transform: scaleY(1.3);
  }
  .rs-table-scrollbar-vertical {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 10px;
    background-color: rgba(229, 229, 234, 0.4);
  }
  .rs-table-scrollbar-vertical .rs-table-scrollbar-handle {
    min-height: 20px;
    width: 8px;
    top: 0;
    left: 1px;
  }
  .rs-table-scrollbar-pressed.rs-table-scrollbar-vertical:hover,
  .rs-table-scrollbar-vertical:hover {
    -webkit-transform-origin: right center;
            transform-origin: right center;
    -webkit-transform: scaleX(1.3);
            transform: scaleX(1.3);
  }
  .rs-tag {
    display: inline-block;
    padding: 2px 8px;
    background-color: #f7f7fa;
    color: #575757;
    border-radius: 6px;
    font-size: 12px;
    line-height: 1.66666667;
  }
  .rs-tag-closeable {
    padding-right: 25px;
    position: relative;
  }
  .rs-tag-icon-close {
    position: absolute;
    top: 2px;
    right: 8px;
    font-style: normal;
    font-family: 'rsuite-icon-font';
    cursor: pointer;
    font-size: 12px;
  }
  .rs-tag-icon-close::after {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-tag-default .rs-tag-icon-close:hover {
    color: #f44336;
  }
  .rs-tag-group {
    margin: -10px 0 0 -10px;
  }
  .rs-tag-group > .rs-tag {
    margin-top: 10px;
    margin-left: 10px;
  }
  .rs-tag + .rs-tag {
    margin-left: 10px;
  }
  .rs-tag-red {
    color: #fff;
    background-color: #f44336;
  }
  .rs-tag-orange {
    color: #fff;
    background-color: #ff9800;
  }
  .rs-tag-yellow {
    color: #fff;
    background-color: #ffca28;
  }
  .rs-tag-green {
    color: #fff;
    background-color: #4caf50;
  }
  .rs-tag-cyan {
    color: #fff;
    background-color: #00bcd4;
  }
  .rs-tag-blue {
    color: #fff;
    background-color: #2196f3;
  }
  .rs-tag-violet {
    color: #fff;
    background-color: #673ab7;
  }
  .rs-picker-tag:not(.rs-picker-disabled) .rs-picker-toggle {
    left: 0;
    cursor: text;
  }
  .rs-picker-tag.rs-picker-disabled {
    cursor: not-allowed;
  }
  .rs-picker-tag.rs-picker-disabled .rs-picker-toggle {
    position: absolute;
  }
  .rs-picker-tag .rs-tag {
    position: relative;
    z-index: 6;
    max-width: calc(100% - 6px);
    vertical-align: top;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .rs-picker-tag .rs-picker-search-input,
  .rs-picker-tag .rs-tag {
    margin-top: 5px;
  }
  .rs-picker-tag.rs-picker-has-value .rs-picker-search-input {
    padding-left: 6px;
  }
  .rs-picker-tag .rs-tag,
  .rs-picker-tag .rs-tag + .rs-tag {
    margin-left: 6px;
  }
  .rs-picker-tag .rs-picker-tag-wrapper {
    padding-bottom: 5px;
  }
  .rs-picker-tag .rs-picker-search,
  .rs-picker-tag .rs-picker-search-input,
  .rs-picker-tag .rs-picker-search-input > input {
    display: inline-block;
    width: auto;
    min-width: 14px;
  }
  .rs-picker-tag .rs-picker-search-input > input {
    padding: 0;
  }
  .rs-timeline {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    direction: ltr;
  }
  .rs-timeline-item {
    position: relative;
    text-align: left;
  }
  .rs-timeline-item:not(:last-child) .rs-timeline-item-content {
    padding-bottom: 18px;
  }
  .rs-timeline-item-dot {
    position: absolute;
    top: 5px;
  }
  .rs-timeline-item-dot::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-color: #d9d9d9;
    border-radius: 50%;
  }
  .rs-timeline-item-custom-dot {
    text-align: center;
  }
  .rs-timeline-item-custom-dot::before {
    display: none;
  }
  .rs-timeline-item-last .rs-timeline-item-dot::before {
    background-color: #3498ff;
  }
  .rs-timeline-item-tail {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #d9d9d9;
  }
  .rs-timeline-item:first-child .rs-timeline-item-tail {
    top: 5px;
  }
  .rs-timeline-item:last-child .rs-timeline-item-tail {
    bottom: auto;
    height: 15px;
  }
  .rs-timeline-endless .rs-timeline-item:last-child .rs-timeline-item-tail {
    height: auto;
    min-height: 38px;
  }
  .rs-timeline-item:only-child .rs-timeline-item-tail {
    display: none;
  }
  .rs-timeline-align-left .rs-timeline-item-dot {
    left: 0;
  }
  .rs-timeline-align-left .rs-timeline-item-tail {
    left: 4px;
  }
  .rs-timeline-align-left .rs-timeline-item {
    padding-left: 22px;
  }
  .rs-timeline-align-left .rs-timeline-item {
    padding-left: 22px;
  }
  .rs-timeline-align-right .rs-timeline-item-dot {
    right: 0;
  }
  .rs-timeline-align-right .rs-timeline-item-tail {
    right: 4px;
  }
  .rs-timeline-align-right .rs-timeline-item {
    padding-right: 22px;
  }
  .rs-timeline-align-right .rs-timeline-item-content {
    text-align: right;
  }
  .rs-timeline-align-alternate .rs-timeline-item-dot {
    left: calc(50% - 5px);
  }
  .rs-timeline-align-alternate .rs-timeline-item-tail {
    left: calc(50% - 1px);
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-content {
    text-align: right;
    width: calc(50% - 17px);
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) {
    text-align: right;
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-content {
    display: inline-block;
    text-align: left;
    width: calc(50% - 17px);
  }
  .rs-timeline-with-time .rs-timeline-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
  }
  .rs-timeline-with-time .rs-timeline-item-dot {
    left: calc(50% - 5px);
  }
  .rs-timeline-with-time .rs-timeline-item-tail {
    left: calc(50% - 1px);
  }
  .rs-timeline-with-time .rs-timeline-item-time,
  .rs-timeline-with-time .rs-timeline-item-content {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 50%;
            flex: 1 0 50%;
    max-width: 50%\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-timeline-with-time .rs-timeline-item-time,
    .rs-timeline-with-time .rs-timeline-item-content {
      max-width: 50%;
    }
  }
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n),
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item {
    padding-right: 0;
  }
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time {
    text-align: right;
  }
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time {
    text-align: left;
  }
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-time,
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-content,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-time,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-content {
    padding-right: 17px;
  }
  .rs-timeline-with-time.rs-timeline-align-left .rs-timeline-item-content,
  .rs-timeline-with-time.rs-timeline-align-right .rs-timeline-item-time,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n + 1) .rs-timeline-item-content,
  .rs-timeline-align-alternate .rs-timeline-item:nth-child(2n) .rs-timeline-item-time {
    padding-left: 17px;
  }
  .rs-btn-toggle {
    height: 24px;
    min-width: 44px;
    border-radius: 12px;
    position: relative;
    display: inline-block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    vertical-align: middle;
    background-color: #d9d9d9;
    cursor: pointer;
    -webkit-transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-btn-toggle::after {
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
    border-radius: 9px;
  }
  .rs-btn-toggle:active::after {
    width: 21.6px;
  }
  .rs-btn-toggle .rs-btn-toggle-inner {
    margin-left: 24px;
    margin-right: 8px;
    height: 24px;
    line-height: 2;
    font-size: 12px;
  }
  .rs-btn-toggle .rs-btn-toggle-inner .rs-icon {
    font-size: 12px;
  }
  .rs-btn-toggle.rs-btn-toggle-checked::after {
    left: 100%;
    margin-left: -21px;
  }
  .rs-btn-toggle.rs-btn-toggle-checked:active::after {
    margin-left: -24.6px;
  }
  .rs-btn-toggle.rs-btn-toggle-checked .rs-btn-toggle-inner {
    margin-right: 24px;
    margin-left: 8px;
  }
  .rs-btn-toggle:hover {
    background-color: #c5c6c7;
  }
  .rs-btn-toggle::after {
    content: '';
    cursor: pointer;
    position: absolute;
    background-color: #fff;
    -webkit-transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
    transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
  }
  .rs-btn-toggle-inner {
    color: #ffffff;
    display: block;
    -webkit-transition: margin 0.2s ease-in-out;
    transition: margin 0.2s ease-in-out;
  }
  .rs-btn-toggle-checked {
    background-color: #3498ff;
  }
  .rs-btn-toggle-checked:hover {
    background-color: #2589f5;
  }
  .rs-btn-toggle-disabled,
  .rs-btn-toggle-disabled::after {
    cursor: not-allowed;
  }
  .rs-btn-toggle-disabled,
  .rs-btn-toggle-disabled:hover {
    background-color: #f7f7fa;
  }
  .rs-btn-toggle-disabled.rs-btn-toggle-checked,
  .rs-btn-toggle-disabled.rs-btn-toggle-checked:hover {
    background-color: #cce9ff;
  }
  .rs-btn-toggle-sm {
    height: 14px;
    min-width: 26px;
    border-radius: 7px;
  }
  .rs-btn-toggle-sm::after {
    width: 10px;
    height: 10px;
    left: 2px;
    top: 2px;
    border-radius: 5px;
  }
  .rs-btn-toggle-sm:active::after {
    width: 12px;
  }
  .rs-btn-toggle-sm .rs-btn-toggle-inner {
    margin-left: 14px;
    margin-right: 6px;
    height: 14px;
    line-height: 1.4;
    font-size: 10px;
  }
  .rs-btn-toggle-sm .rs-btn-toggle-inner .rs-icon {
    font-size: 10px;
  }
  .rs-btn-toggle-sm.rs-btn-toggle-checked::after {
    left: 100%;
    margin-left: -12px;
  }
  .rs-btn-toggle-sm.rs-btn-toggle-checked:active::after {
    margin-left: -14px;
  }
  .rs-btn-toggle-sm.rs-btn-toggle-checked .rs-btn-toggle-inner {
    margin-right: 14px;
    margin-left: 6px;
  }
  .rs-btn-toggle-md {
    height: 24px;
    min-width: 44px;
    border-radius: 12px;
  }
  .rs-btn-toggle-md::after {
    width: 18px;
    height: 18px;
    left: 3px;
    top: 3px;
    border-radius: 9px;
  }
  .rs-btn-toggle-md:active::after {
    width: 21.6px;
  }
  .rs-btn-toggle-md .rs-btn-toggle-inner {
    margin-left: 24px;
    margin-right: 8px;
    height: 24px;
    line-height: 2;
    font-size: 12px;
  }
  .rs-btn-toggle-md .rs-btn-toggle-inner .rs-icon {
    font-size: 12px;
  }
  .rs-btn-toggle-md.rs-btn-toggle-checked::after {
    left: 100%;
    margin-left: -21px;
  }
  .rs-btn-toggle-md.rs-btn-toggle-checked:active::after {
    margin-left: -24.6px;
  }
  .rs-btn-toggle-md.rs-btn-toggle-checked .rs-btn-toggle-inner {
    margin-right: 24px;
    margin-left: 8px;
  }
  .rs-btn-toggle-lg {
    height: 30px;
    min-width: 55px;
    border-radius: 15px;
  }
  .rs-btn-toggle-lg::after {
    width: 22px;
    height: 22px;
    left: 4px;
    top: 4px;
    border-radius: 11px;
  }
  .rs-btn-toggle-lg:active::after {
    width: 26.4px;
  }
  .rs-btn-toggle-lg .rs-btn-toggle-inner {
    margin-left: 30px;
    margin-right: 10px;
    height: 30px;
    line-height: 2.14285714;
    font-size: 14px;
  }
  .rs-btn-toggle-lg .rs-btn-toggle-inner .rs-icon {
    font-size: 14px;
  }
  .rs-btn-toggle-lg.rs-btn-toggle-checked::after {
    left: 100%;
    margin-left: -26px;
  }
  .rs-btn-toggle-lg.rs-btn-toggle-checked:active::after {
    margin-left: -30.4px;
  }
  .rs-btn-toggle-lg.rs-btn-toggle-checked .rs-btn-toggle-inner {
    margin-right: 30px;
    margin-left: 10px;
  }
  .rs-tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-size: 12px;
    opacity: 0;
    line-height: 1.66666667;
  }
  .rs-tooltip.fade {
    -webkit-transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, -webkit-transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out;
    transition: opacity 0.1s linear, transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
  }
  .rs-tooltip.in {
    opacity: 1;
    -webkit-transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, -webkit-transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in;
    transition: opacity 0.15s linear, transform 0.15s ease-in, -webkit-transform 0.15s ease-in;
  }
  .rs-tooltip .rs-tooltip-inner {
    max-width: 250px;
    padding: 2px 10px;
    color: #fff;
    text-align: center;
    background-color: #272c36;
    border-radius: 4px;
    overflow-wrap: break-word;
  }
  .rs-tooltip .rs-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .rs-tooltip[class*='placement-top'] {
    margin-top: -6px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-tooltip[class*='placement-top'].in {
    -webkit-transform: translate(0, -2px);
            transform: translate(0, -2px);
  }
  .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
    bottom: -6px;
    margin-left: -6px;
    border-width: 6px 6px 0;
    border-top-color: #272c36;
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
      bottom: -5px;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-top'] .rs-tooltip-arrow {
      bottom: -5px;
    }
  }
  .rs-tooltip[class*='placement-bottom'] {
    margin-top: 6px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-tooltip[class*='placement-bottom'].in {
    -webkit-transform: translate(0, 2px);
            transform: translate(0, 2px);
  }
  .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
    top: -6px;
    margin-left: -6px;
    border-width: 0 6px 6px;
    border-bottom-color: #272c36;
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
      top: -5px;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-bottom'] .rs-tooltip-arrow {
      top: -5px;
    }
  }
  /* rtl:begin:ignore */
  .rs-tooltip[class*='placement-right'] {
    margin-left: 6px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-tooltip[class*='placement-right'].in {
    -webkit-transform: translate(2px, 0);
            transform: translate(2px, 0);
  }
  .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
    left: -6px;
    margin-top: -6px;
    border-width: 6px 6px 6px 0;
    border-right-color: #272c36;
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
      left: -5px;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-right'] .rs-tooltip-arrow {
      left: -5px;
    }
  }
  .rs-tooltip[class*='placement-left'] {
    margin-left: -6px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-tooltip[class*='placement-left'].in {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
    right: -6px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #272c36;
  }
  @supports (-ms-ime-align: auto) {
    .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
      right: -5px;
    }
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-tooltip[class*='placement-left'] .rs-tooltip-arrow {
      right: -5px;
    }
  }
  /* rtl:end:ignore */
  .rs-tooltip.placement-bottom .rs-tooltip-arrow,
  .rs-tooltip.placement-top .rs-tooltip-arrow {
    left: 50%;
  }
  .rs-tooltip.placement-bottom-start .rs-tooltip-arrow,
  .rs-tooltip.placement-top-start .rs-tooltip-arrow {
    left: 10px;
  }
  .rs-tooltip.placement-bottom-end .rs-tooltip-arrow,
  .rs-tooltip.placement-top-end .rs-tooltip-arrow {
    right: 4px;
  }
  .rs-tooltip.placement-right .rs-tooltip-arrow,
  .rs-tooltip.placement-left .rs-tooltip-arrow {
    top: 50%;
  }
  .rs-tooltip.placement-right-start .rs-tooltip-arrow,
  .rs-tooltip.placement-left-start .rs-tooltip-arrow {
    top: 10px;
  }
  .rs-tooltip.placement-right-end .rs-tooltip-arrow,
  .rs-tooltip.placement-left-end .rs-tooltip-arrow {
    bottom: 4px;
  }
  .rs-tree {
    background-color: #fff;
    max-height: 360px;
    height: 100%;
    overflow-y: auto;
    /* stylelint-disable-next-line */
  }
  .rs-tree .ReactVirtualized__Grid.ReactVirtualized__List:focus {
    outline: none;
  }
  .rs-tree-drag-node-mover {
    position: absolute;
    top: -1000px;
    color: #575757;
    background: #fff;
    display: inline-block;
    margin: 0;
    padding: 8px 12px;
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 8px #00000012;
            box-shadow: 0 1px 8px #00000012;
    z-index: 1060;
    cursor: move;
  }
  .rs-tree-node {
    position: relative;
    font-size: 0;
    text-align: left;
    margin: 0 0 4px 0;
  }
  .rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
    font-weight: bold;
    color: #1675e0;
    background-color: #f2faff;
  }
  .rs-tree-node > .rs-tree-node-label {
    position: relative;
    margin: 0;
    padding-left: 16px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
    padding: 6px 12px 6px 8px;
    display: inline-block;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
  }
  .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content:hover {
    background-color: #f2faff;
  }
  .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content:focus {
    background-color: #f2faff;
  }
  .rs-tree-node-drag-over {
    background-color: #f2faff;
  }
  .rs-tree-node-dragging {
    outline: 1px dashed #3498ff;
  }
  .rs-tree-node-drag-over-top.rs-tree-node-label-content {
    border-top: 2px solid #1675e0 !important;
  }
  .rs-tree-node-drag-over-top.rs-tree-node-label-content::after {
    content: '';
    position: absolute;
    top: -2px;
    left: 12px;
    width: 0;
    height: 0;
    border-left: 6px solid #1675e0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
  .rs-tree-node-drag-over-bottom.rs-tree-node-label-content {
    border-bottom: 2px solid #1675e0 !important;
  }
  .rs-tree-node-drag-over-bottom.rs-tree-node-label-content::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 12px;
    width: 0;
    height: 0;
    border-left: 6px solid #1675e0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
  .rs-tree-node-drag-disabled.rs-tree-node-label-content {
    cursor: not-allowed;
  }
  .rs-tree-node-disabled {
    color: #c5c6c7;
    cursor: not-allowed;
  }
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content {
    cursor: not-allowed;
  }
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content,
  .rs-tree-node-disabled > .rs-tree-node-label > .rs-tree-node-label-content:hover {
    background: transparent;
  }
  .rs-tree-node-disabled.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content,
  .rs-tree-node-disabled.rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content:hover {
    color: #a6d7ff;
  }
  .rs-tree-node:not(.rs-tree-node-disabled):focus > .rs-tree-node-label > .rs-tree-node-label-content {
    background-color: #f2faff;
  }
  .rs-tree-node:not(.rs-tree-node-disabled) > .rs-tree-node-label:focus > .rs-tree-node-label-content {
    background-color: #f2faff;
  }
  .rs-tree-node-expand-icon-wrapper {
    display: inline-block;
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
    display: inline-block;
    padding: 8px 0;
    padding-right: 8px;
    height: 36px;
    font-size: 14px;
    line-height: 1.42857143;
    -webkit-transform: rotate(-90deg) translateX(-2px) /* rtl: rotate(90deg) translateX(-6px) translateY(-8px) */;
            transform: rotate(-90deg) translateX(-2px) /* rtl: rotate(90deg) translateX(-6px) translateY(-8px) */;
    -webkit-transform-origin: 3.5px 16px;
            transform-origin: 3.5px 16px;
    margin-left: -2px;
    font-family: 'rsuite-icon-font';
    font-style: normal;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon::before {
    content: '\F0D7';
  }
  .rs-tree-open > .rs-tree-node .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expand-icon {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-expanded {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    margin-left: 0;
    margin-top: 0;
  }
  .rs-tree-node-expand-icon-wrapper > .rs-tree-node-custom-icon {
    width: 14px;
    line-height: 36px;
    vertical-align: middle;
  }
  .rs-tree-node-children > .rs-tree-children {
    display: none;
  }
  .rs-tree-open.rs-tree-node-children > .rs-tree-children {
    display: block;
  }
  .rs-picker-menu.rs-tree-menu {
    padding-top: 12px;
  }
  .rs-picker-menu.rs-tree-menu .rs-picker-search-bar {
    padding-top: 0;
  }
  .rs-picker-menu .rs-tree {
    padding: 0 12px 12px 0;
  }
  .rs-picker-menu .rs-tree-node {
    margin: 0;
  }
  .rs-picker-menu .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    display: block;
  }
  /* rtl:begin:ignore */
  [dir='rtl'] .rs-tree-node-expand-icon-wrapper {
    right: 0;
    padding-right: inherit;
    background: #fff;
  }
  /* stylelint-disable */
  [dir='rtl'] .ReactVirtualized__Grid__innerScrollContainer .rs-tree-node > .rs-tree-node-label::before {
    display: none;
  }
  /* stylelint-enable */
  /* rtl:end:ignore */
  .rs-uploader-trigger input[type='file'] {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
  }
  .rs-uploader-trigger-disabled {
    opacity: 0.3;
  }
  .rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    cursor: not-allowed !important;
  }
  .rs-uploader-file-item-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .rs-uploader-picture .rs-uploader-file-item-disabled .rs-uploader-file-item-status {
    cursor: not-allowed;
  }
  .rs-uploader-file-item-disabled .rs-uploader-file-item-btn-remove {
    display: none;
  }
  .rs-uploader-file-item-icon-reupload {
    cursor: pointer;
    margin-left: 10px;
    color: #575757;
  }
  .rs-uploader-file-item-icon-reupload::before {
    font-family: 'rsuite-icon-font';
    content: '\ea1f';
    font-style: normal;
  }
  .rs-uploader-file-item-icon-reupload::before,
  .rs-uploader-file-item-icon-reupload::before:hover {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-items {
    margin-top: 10px;
  }
  .rs-uploader-text .rs-uploader-file-item {
    padding: 8px 30px 8px 36px;
    position: relative;
    -webkit-transition: 0.3s linear background-color;
    transition: 0.3s linear background-color;
  }
  .rs-uploader-text .rs-uploader-file-item:hover {
    background-color: #f7f7fa;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-wrapper {
    position: absolute;
    display: inline-block;
    top: 8px;
    left: 12px;
    width: 20px;
    text-align: center;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    width: 18px;
    height: 18px;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    width: 18px;
    height: 18px;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-uploader-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-uploader-text .rs-uploader-file-item-icon::before {
    font-family: 'rsuite-icon-font';
    content: '\ea10';
    font-style: normal;
    color: #575757;
    font-size: 16px;
    line-height: 1.25;
  }
  .rs-uploader-text .rs-uploader-file-item-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .rs-uploader-text .rs-uploader-file-item-content,
  .rs-uploader-text .rs-uploader-file-item-content > a {
    color: #575757;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
  }
  .rs-uploader-text .rs-uploader-file-item-content > a:hover,
  .rs-uploader-text .rs-uploader-file-item-content > a:focus {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
  }
  .rs-uploader-text .rs-uploader-file-item-size {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    color: #8e8e93;
    padding: 0 8px;
  }
  .rs-uploader-text .rs-uploader-file-item-status {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    float: right\9;
    display: table\9;
  }
  .rs-uploader-text .rs-uploader-file-item-status > a,
  .rs-uploader-text .rs-uploader-file-item-status > a:hover,
  .rs-uploader-text .rs-uploader-file-item-status > a:focus,
  .rs-uploader-text .rs-uploader-file-item-status > a:active {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-item-btn-remove {
    position: absolute;
    font-size: 12px;
    top: 12px;
    right: 12px;
    color: #8e8e93;
    cursor: pointer;
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
  }
  .rs-uploader-text .rs-uploader-file-item-btn-remove:hover,
  .rs-uploader-text .rs-uploader-file-item-btn-remove:focus {
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-file-item-btn-remove::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-uploader-text .rs-uploader-file-item-btn-remove [aria-hidden] {
    display: none;
  }
  .rs-uploader-text .rs-uploader-file-item-panel {
    position: relative;
  }
  .rs-uploader-text .rs-uploader-file-item-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
  }
  .rs-uploader-text .rs-uploader-file-item-progress-bar {
    height: 2px;
    background-color: #3498ff;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-content,
  .rs-uploader-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title {
    color: #f44336;
  }
  .rs-uploader-picture {
    display: inline-block;
  }
  .rs-uploader-picture::before,
  .rs-uploader-picture::after {
    content: ' ';
    display: table;
  }
  .rs-uploader-picture::after {
    clear: both;
  }
  .rs-uploader-picture::before,
  .rs-uploader-picture::after {
    content: ' ';
    display: table;
  }
  .rs-uploader-picture::after {
    clear: both;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* stylelint-disable */
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    /* stylelint-enable */
    overflow: hidden;
    position: relative;
    -webkit-transition: color 0.2s linear, background-color 0.3s linear;
    transition: color 0.2s linear, background-color 0.3s linear;
    color: #8e8e93;
    background-color: transparent;
    width: 66px;
    height: 66px;
    margin: 0;
    padding: 0;
    border: 2px dotted #e5e5ea;
    border-radius: 6px;
    margin-top: 10px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn:focus {
    color: #575757;
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active {
    outline: 0;
    background-image: none;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-uploader-picture .rs-uploader-trigger-btn {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-disable-line property-no-vendor-prefix */
    }
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple {
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-rippling {
    -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-pond {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn:hover {
    color: #fff;
    background: #f7f7fa;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn {
    color: #fff;
    background-color: #b2b1f0;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.focus {
    color: #fff;
    background-color: #b2b1f0;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-disabled:active {
    opacity: 1;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn:hover {
    color: #575757;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn {
    color: #272c36;
    background-color: #e5e5ea;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.focus {
    color: #272c36;
    background-color: #e5e5ea;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red:hover {
    color: #fff;
    background: #f44336;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red {
    color: #fff;
    background-color: #eb3626;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.focus {
    color: #fff;
    background-color: #eb3626;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-disabled.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-disabled:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-red.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange:hover {
    color: #fff;
    background: #ff9800;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange {
    color: #fff;
    background-color: #f59700;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.focus {
    color: #fff;
    background-color: #f59700;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-disabled.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-disabled:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-orange.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow:hover {
    color: #fff;
    background: #ffca28;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow {
    color: #fff;
    background-color: #f5c31d;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.focus {
    color: #fff;
    background-color: #f5c31d;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-disabled.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-disabled:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-yellow.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green:hover {
    color: #fff;
    background: #4caf50;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green {
    color: #fff;
    background-color: #37ab3c;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.focus {
    color: #fff;
    background-color: #37ab3c;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-disabled.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-disabled:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-green.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan:hover {
    color: #fff;
    background: #00bcd4;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan {
    color: #fff;
    background-color: #00b1cc;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.focus {
    color: #fff;
    background-color: #00b1cc;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-disabled.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-disabled:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-cyan.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue:hover {
    color: #fff;
    background: #2196f3;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue {
    color: #fff;
    background-color: #1787e8;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.focus {
    color: #fff;
    background-color: #1787e8;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-disabled.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-disabled:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-blue.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-loading:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-loading:not(.rs-btn-disabled):hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-loading:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-loading:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-loading:hover:active {
    color: #8e8e93;
    background-color: transparent;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet:hover {
    color: #fff;
    background: #673ab7;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-active,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet {
    color: #fff;
    background-color: #5f2bb3;
    background-image: none;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet:active:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-active:hover,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet:active:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-active:focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet:active.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-active.focus,
  .rs-open > .rs-dropdown-toggle.rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.focus {
    color: #fff;
    background-color: #5f2bb3;
  }
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-disabled,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-disabled:hover,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-disabled:focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-disabled.focus,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-disabled:active,
  .rs-uploader-picture .rs-uploader-trigger-btn.rs-btn-violet.rs-btn-disabled:hover:active {
    color: #c5c6c7;
    background: none;
  }
  .rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    color: #8e8e93 !important;
    background-color: transparent !important;
  }
  .rs-uploader-picture .rs-uploader-trigger.rs-uploader-trigger-disabled .rs-uploader-trigger-btn::after {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-trigger,
  .rs-uploader-picture .rs-uploader-file-item {
    float: left;
  }
  .rs-uploader-picture .rs-uploader-file-items {
    display: inline;
  }
  .rs-uploader-picture .rs-uploader-file-item {
    width: 66px;
    height: 66px;
    overflow: hidden;
    background-color: #d9d9d9;
    border-radius: 6px;
    position: relative;
    margin-top: 10px;
    margin-right: 10px;
  }
  .rs-uploader-picture .rs-uploader-file-item-preview {
    position: relative;
    height: 66px;
    vertical-align: middle;
    text-align: center;
    z-index: 1;
  }
  .rs-uploader-picture .rs-uploader-file-item-preview > img {
    width: 100%;
    height: auto;
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .rs-uploader-picture .rs-uploader-file-item-preview > img {
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
  }
  .rs-uploader-picture .rs-uploader-file-item-status {
    position: absolute;
    top: 0;
    left: 0;
    width: 66px;
    height: 66px;
    text-align: center;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.8);
    cursor: default;
    color: #f44336;
    padding-top: 14px;
  }
  .rs-uploader-picture .rs-uploader-file-item-status > span {
    white-space: nowrap;
  }
  .rs-uploader-picture .rs-uploader-file-item-status > a:hover {
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-wrapper {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove {
    color: #8e8e93;
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 3px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
    z-index: 4;
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove:hover,
  .rs-uploader-picture .rs-uploader-file-item-btn-remove:focus {
    text-decoration: none;
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-uploader-picture .rs-uploader-file-item-btn-remove [aria-hidden] {
    display: none;
  }
  .rs-uploader-picture .rs-uploader-file-item:hover .rs-uploader-file-item-btn-remove {
    opacity: 1;
    visibility: visible;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading {
    display: block;
    width: 66px;
    height: 66px;
    line-height: 66px;
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    text-align: center;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    width: 18px;
    height: 18px;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-uploader-picture .rs-uploader-file-item-icon-reupload {
    margin-left: 0;
    display: block;
  }
  .rs-uploader-text .rs-uploader-trigger-btn,
  .rs-uploader-picture-text .rs-uploader-trigger-btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    outline: 0 !important;
    white-space: nowrap;
    border: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    /* stylelint-disable */
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 6px;
    /* stylelint-enable */
    color: #575757;
    background: #f7f7fa;
  }
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-ghost,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-ghost {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon {
    padding-left: 24px;
    height: 36px;
    position: relative;
  }
  .rs-btn-ghost.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon,
  .rs-btn-ghost.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon {
    padding-left: 22px;
  }
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-left {
    padding-left: 44px;
  }
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right {
    padding-left: 12px;
    padding-right: 44px;
  }
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon.rs-btn-icon-with-text.rs-btn-icon-placement-right > .rs-icon {
    left: auto;
    right: 0;
  }
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 36px;
    height: 36px;
    padding: 8px 0;
    font-size: 14px;
    line-height: 1.42857143;
  }
  .rs-btn-ghost.rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon,
  .rs-btn-ghost.rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon {
    width: 34px;
    height: 34px;
    padding: 7px 0;
  }
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon > svg,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon > .rs-icon > svg {
    height: 14px;
    vertical-align: middle;
  }
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-icon-circle,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-icon-circle {
    width: 36px;
    padding: 8px;
  }
  .rs-uploader-text .rs-uploader-trigger-btn:hover,
  .rs-uploader-picture-text .rs-uploader-trigger-btn:hover,
  .rs-uploader-text .rs-uploader-trigger-btn:focus,
  .rs-uploader-picture-text .rs-uploader-trigger-btn:focus {
    color: #575757;
    text-decoration: none;
  }
  .rs-uploader-text .rs-uploader-trigger-btn:active,
  .rs-uploader-picture-text .rs-uploader-trigger-btn:active,
  .rs-uploader-text .rs-uploader-trigger-btn.rs-btn-active,
  .rs-uploader-picture-text .rs-uploader-trigger-btn.rs-btn-active {
    outline: 0;
    background-image: none;
  }
  .rs-uploader-text .rs-uploader-trigger-disabled .rs-uploader-trigger-btn,
  .rs-uploader-picture-text .rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    cursor: not-allowed;
  }
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn {
    overflow: hidden;
    position: relative;
  }
  @media not all and (min-resolution: 0.001dpcm) {
    .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn,
    .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn {
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      /* stylelint-disable-line property-no-vendor-prefix */
    }
  }
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple {
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transition: 0s;
    transition: 0s;
  }
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-rippling,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-rippling {
    -webkit-transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 0.55s cubic-bezier(0.4, 0, 0.2, 1), opacity 1s cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 0.55s cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-pond,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:not(.rs-btn-disabled) .rs-ripple-pond {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:focus,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:focus,
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.focus,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.focus {
    color: #575757;
    background-color: #e5e5ea;
  }
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover {
    color: #575757;
    background-color: #e5e5ea;
  }
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active,
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active {
    color: #575757;
    background-color: #d9d9d9;
    background-image: none;
  }
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active:hover,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active:hover,
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active:hover,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active:hover,
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active:focus,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active:focus,
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active:focus,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active:focus,
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active.focus,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:active.focus,
  .rs-uploader-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active.focus,
  .rs-uploader-picture-text .rs-uploader-trigger:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn.rs-btn-active.focus {
    color: #575757;
    background-color: #d9d9d9;
  }
  .rs-uploader-picture-text .rs-uploader-file-item {
    position: relative;
    height: 50px;
    padding-left: 50px;
    margin-top: 10px;
    padding-right: 30px;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    overflow: hidden;
    -webkit-transition: 0.3s linear background-color;
    transition: 0.3s linear background-color;
  }
  .rs-uploader-picture-text .rs-uploader-file-item:hover {
    background-color: #f7f7fa;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-wrapper {
    display: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    text-align: center;
    background-color: #d9d9d9;
    overflow: hidden;
    border-radius: 6px 0 0 6px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
    width: 100%;
    height: auto;
  }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .rs-uploader-picture-text .rs-uploader-file-item-preview > img {
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content {
    padding: 5px 12px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title,
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
    display: block;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: #575757;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title:hover,
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-title:focus {
    text-decoration: none;
    cursor: default;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-content .rs-uploader-file-item-size {
    color: #8e8e93;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove {
    position: absolute;
    top: 0;
    right: 12px;
    color: #8e8e93;
    cursor: pointer;
    height: 50px;
    line-height: 50px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove:hover,
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove:focus {
    text-decoration: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove::before {
    font-family: 'rsuite-icon-font';
    font-style: normal;
    line-height: 1;
    content: '\f00d';
  }
  .rs-uploader-picture-text .rs-uploader-file-item-btn-remove [aria-hidden] {
    display: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-progress {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding-left: 50px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-progress-bar {
    height: 2px;
    background-color: #3498ff;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 50px;
    height: 50px;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    line-height: 50px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    width: 18px;
    height: 18px;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before,
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    border-radius: 50%;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::before {
    border: 3px solid rgba(247, 247, 250, 0.8);
  }
  .rs-uploader-picture-text .rs-uploader-file-item-icon-loading .rs-uploader-file-item-icon::after {
    border-width: 3px;
    border-style: solid;
    border-color: #a6a6a6 transparent transparent;
    -webkit-animation: loaderSpin 0.6s infinite linear;
            animation: loaderSpin 0.6s infinite linear;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-size {
    display: none;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title,
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status {
    display: block;
    color: #f44336;
  }
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title a,
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status a,
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-title a:hover,
  .rs-uploader-picture-text .rs-uploader-file-item-has-error .rs-uploader-file-item-status a:hover {
    text-decoration: none;
  }
  .rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
    display: block;
    background: #fff;
    border: 1px dashed #e5e5ea;
  }
  .rs-uploader-draggable .rs-uploader-trigger.rs-uploader-trigger-customize:not(.rs-uploader-trigger-disabled) .rs-uploader-trigger-btn:hover,
  .rs-uploader-draggable .rs-uploader-trigger-drag-over .rs-uploader-trigger-btn.rs-uploader-trigger-customize {
    border-color: #3498ff;
  }
  .rs-uploader-draggable .rs-uploader-trigger-disabled {
    cursor: not-allowed;
  }
  .rs-uploader-draggable .rs-uploader-trigger-disabled .rs-uploader-trigger-btn {
    pointer-events: none;
  }
  .rs-rate {
    position: relative;
    display: inline-block;
    color: #ffca28;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .rs-rate-character {
    position: relative;
    display: inline-block;
    margin: 3px 10px 3px 0;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .rs-rate-character-before {
    position: absolute;
    width: 50%;
    height: 100%;
    overflow: hidden;
    opacity: 0;
  }
  .rs-rate-character-after {
    color: #e5e5ea;
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
  }
  .rs-rate-character-before,
  .rs-rate-character-after {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 1;
  }
  .rs-rate-character-before > .rs-icon,
  .rs-rate-character-after > .rs-icon {
    font-size: inherit;
  }
  .rs-rate-character-half .rs-rate-character-before {
    opacity: 1;
    -webkit-filter: none;
            filter: none;
  }
  .rs-rate-character-full .rs-rate-character-after {
    color: inherit;
    -webkit-filter: none;
            filter: none;
  }
  .rs-rate-character-vertical {
    width: 100%;
    height: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    bottom: 0;
  }
  .rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  .rs-rate:not(.rs-rate-readonly).rs-rate:not(.rs-rate-disabled) .rs-rate-character:active {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .rs-rate-disabled {
    opacity: 0.3;
  }
  .rs-rate-disabled .rs-rate-character {
    cursor: not-allowed;
  }
  .rs-rate-readonly .rs-rate-character {
    cursor: default;
    pointer-events: none;
  }
  .rs-rate-red {
    color: #f44336;
  }
  .rs-rate-orange {
    color: #ff9800;
  }
  .rs-rate-yellow {
    color: #ffca28;
  }
  .rs-rate-green {
    color: #4caf50;
  }
  .rs-rate-cyan {
    color: #00bcd4;
  }
  .rs-rate-blue {
    color: #2196f3;
  }
  .rs-rate-violet {
    color: #673ab7;
  }
  .rs-rate-xs {
    font-size: 18px;
  }
  .rs-rate-xs .rs-icon > svg {
    height: 18px;
    width: 18px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-rate-xs .rs-icon > svg {
      width: 18px;
    }
  }
  .rs-rate-sm {
    font-size: 24px;
  }
  .rs-rate-sm .rs-icon > svg {
    height: 24px;
    width: 24px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-rate-sm .rs-icon > svg {
      width: 24px;
    }
  }
  .rs-rate-md {
    font-size: 30px;
  }
  .rs-rate-md .rs-icon > svg {
    height: 30px;
    width: 30px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-rate-md .rs-icon > svg {
      width: 30px;
    }
  }
  .rs-rate-lg {
    font-size: 36px;
  }
  .rs-rate-lg .rs-icon > svg {
    height: 36px;
    width: 36px\9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .rs-rate-lg .rs-icon > svg {
      width: 36px;
    }
  }
  
  /*# sourceMappingURL=index.css.map */
  