.body {
    /* background: #10131B 0% 0% no-repeat padding-box; */
    /* background: #FBFBFB 0% 0% no-repeat padding-box;  */
    background: #ffffff 0% 0% no-repeat padding-box; 
    /* background: #0E2438 0% 0% no-repeat padding-box;  */
    opacity: 1;
    /* height: 100vh; */
  }


.bodyText {
  /* font-size: 1.2rem; */
  letter-spacing : 0.05em;
  font: 14px/22px Axiform;
  color: #424B4F ;
  text-align: justify ;
}

@media only screen and (max-width: 767px) {
  .bodyText {
    margin-top:20px ;
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing : 0.05em;
    font: 12px/17px Axiform;
    color: #424B4F ;
    text-align: justify ;
      
  }
}

.hText {
  color: white;
}

.iconLi {
  color: #0E65C2;
}

.iconTweet {
  color: #50ABF1;
}