
.favoritesText {
    font-size: 0.75rem;
    color : #686767 ;
    /* border: 1px dotted black;  */
    padding: 0.30rem;
    border-radius: 2px;
    cursor: move;
    letter-spacing: 0.03rem ;
}

.favoritesText:hover {
    background-color: #FD8F46 ;
}