.Livingwith-wrapper .Livingwith-block {
    display: flex;
    align-items: center;
}
.Livingwith-wrapper .Livingwith-contents{
    padding: 30px;
}
.Livingwith-wrapper .Livingwith-content{
    width: 55%;
    min-height: 360px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    margin-bottom: 16px;
}
.Livingwith-wrapper .household-contents{
    padding: 30px;
}
.Livingwith-wrapper .household-content{
    width: 44%;
    min-height: 360px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
    margin-bottom: 16px;
    margin-left: 16px;
}

.Livingwith-wrapper .Livingwith-title{
    font-size: 16px;
    line-height: 18px;
    position: relative;
}
.Livingwith-wrapper .Livingwith-title::after {
    content: '';
    /* background-image: url('https://res.cloudinary.com/zipscores-collection/image/upload/v1658682911/free-tools/family_1.svg'); */
    background-image: none !important; 
    height: 60px;
    width: 60px;
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
}
.Livingwith-wrapper .Livingwith-title.household::after {
    /* background-image: url('https://res.cloudinary.com/zipscores-collection/image/upload/v1658683171/free-tools/house_1.svg'); */
    background-image: none !important;
}

.Livingwith-wrapper .Living-description {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.Livingwith-wrapper .household-description {
    padding-top: 20px;
}
.Livingwith-wrapper .household-map {
    text-align: center;
}
.Livingwith-wrapper .hold-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}
.Livingwith-wrapper .Living-desc {

    padding-bottom: 12px;
    position: relative;
    color: #363636 ; 
    font : Normal 14px/20px Axiform ; 
}
.Living-desc-wrapper {
    display: block;
    position: relative;
}
.Livingwith-wrapper .Living-desc-after {
    content: '';
    background-color: #EF8636;
    width: 10px;
    height: 10px;
    position: absolute;
    left: -18px;
    right: auto;
    top: 5px;
    border-radius: 100%;
}
.Livingwith-wrapper .Living-desc.desc2::after {
    background-color: #F5BE82;
}
.Livingwith-wrapper .Living-desc.desc3::after {
    background-color: #B3C6E5;
}
.Livingwith-wrapper .Living-desc.desc4::after {
    background-color: #3B75AF;
}
.Livingwith-wrapper .household-desc {
    color: #363636 ; 
    font : Normal 14px/20px Axiform ; 
    /* font-size: 16px;
    line-height: 20px; */
    padding: 0 16px;
    position: relative;
}
.Livingwith-wrapper .household-desc::after {
    content: '';
    background-color: #EF8636;
    width: 10px;
    height: 10px;
    position: absolute;
    left: 0;
    right: auto;
    top: 5px;
    border-radius: 100%;
}
.Livingwith-wrapper .household-desc.desc2::after {
    background-color: #F5BE82;
}
.Livingwith-wrapper .household-desc.desc3::after {
    background-color: #B3C6E5;
}
.Livingwith-wrapper .household-desc.desc4::after {
    background-color: #3B75AF;
}
.household-description .recharts-wrapper{
    margin: auto;
}

.fontstyleaxiform {
    font-style: Axiform !important;
    color: #363636 !important;
    
}

@media (max-width:1240px){
    .Livingwith-wrapper .Livingwith-contents,.Livingwith-wrapper .household-contents{
        padding: 20px;
    }
    .Livingwith-wrapper .hold-content {
        flex-wrap: wrap;
    }
}
@media (max-width:991px){
    .Livingwith-wrapper .Livingwith-content{
        width: 100%;
    }
    .Livingwith-wrapper .household-content{
        margin-left: 0;
        width: 100%;
    }
    .Livingwith-wrapper .Livingwith-block{
        flex-wrap: wrap;
    }
}

@media (max-width:629px){
    .Livingwith-wrapper .Living-description{
        flex-wrap: wrap;
    }
}
@media (max-width:375px){
    .Livingwith-wrapper .Living-desc{
        font-size: 15px;
        line-height: 18px;
        padding-left: 12px;


    }
    .Livingwith-wrapper .Living-desc-after{
        left: -3px;
    }
}