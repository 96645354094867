@font-face {
  font-family: "Axiform";
  font-weight: 400;
  src: url(../assets/fonts/Axiform-Regular.otf);
  font-display: swap;
}

@font-face {
  font-family: "Axiform-bold";
  font-weight: 400;
  src: url(../assets/fonts/Axiform-Bold.otf);
  font-display: swap;
}

@font-face {
  font-family: "Axiform-black";
  font-weight: 400;
  src: url(../assets/fonts/Axiform-Black.otf);
  font-display: swap;
}
