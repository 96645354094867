
.FilterTabsContainer {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    /* font-size: 14px; */
    font-size: 12px;
    /* line-height: 1.42857143; */
    line-height: 1;
    color: #FE6926 ;
}

.selectedFilterContainer {
    height: 84%;
    /* width: 100%; */
    padding: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: white;
    overflow-y: hidden ;
}


@media only screen and (min-width: 1824px)  {

    .FilterTabsContainer {
        height: 90%;
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow: hidden;
        font-size: 12px; 
        /* line-height: 1.42857143; */
        line-height: 1;
        color: #FE6926 ;
    }

    .selectedFilterContainer {
        height: 90%;
        width: 100%;
        padding: 10px;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: white;
        overflow-y: hidden ;
    }

}



.selectedFilterContainerWithData {
    /* height: 84%; */
    /* height: 84%; */
    width: 100%;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: white;
}

.ContainerFilter {
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: 10%;
    align-items: center;
    /* border-top-width: 1px; */
    /* border-top-style: solid; */
    border-color: white;
}

.tabItem {
    /* margin-top: -16%; */
    margin-top: -16.0%;
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* border: 1px solid white; */
    border: 1px solid transparent;
    /* top: 100%; */
    color: transparent;
    transform: translateY(40%);
    /* margin-left: 10px; */
}

.tabItemSelected {
    background: #AAAEAE;
    border: 1px solid transparent;
    /* background: #CAD2D3;  */
    color: black;
}
