.l-slider-cont {
  text-align: center;
  box-sizing: border-box;
  padding: 0px 10%;
  letter-spacing: 0.05rem;
}
@media only screen and (max-width: 767px) {
  .l-slider-cont {
    padding: 0px 5%;
    margin-top: 120px;
  }
}
.l-slider-cont h2 {
  margin-bottom: 30px;
  font-size: 50px;
  font-family: Axiform-bold;
}
@media only screen and (max-width: 767px) {
  .l-slider-cont h2 {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 1399px) {
  .l-slider-cont h2 {
    font-size: 2em;
  }
}
.l-slider-cont .tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.l-slider-cont .tabs::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: rgba(54, 54, 54, 0.1);
  left: 0;
  top: 180px;
}
@media only screen and (max-width: 767px) {
  .l-slider-cont .tabs::after {
    top: 111px;
  }
}
.l-slider-cont .tabs .tab {
  margin: 30px;
  /* padding: 20px; */
  padding: 10px;
  /* height: 50px; */
  height: 100px;
  /* width: 50px; */
  width: 100px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .l-slider-cont .tabs .tab {
    padding: 10px;
    /* width: 30px; */
    width: 50px;
    /* height: 30px; */
    height: 50px;
  }
}
.l-slider-cont .tabs .tab.active {
  box-shadow: 0px 5px 10px rgba(54, 54, 54, 0.1);
}
.l-slider-cont .tabs .tab.active::after {
  content: "";
  position: absolute;
  top: 149px;
  width: 100%;
  height: 4px;
  background: #f06022;
  border-radius: 50px;
  z-index: 10;
}
@media only screen and (max-width: 767px) {
  .l-slider-cont .tabs .tab.active::after {
    top: 80px;
  }
}


.l-slider-cont .tabs .tab.active.tab1 #Ellipse_1, .l-slider-cont .tabs .tab.active.tab2 #Ellipse_1, .l-slider-cont .tabs .tab.active.tab1 path, .l-slider-cont .tabs .tab.active.tab2 path, .l-slider-cont .tabs .tab.active.tab1 svg line, .l-slider-cont .tabs .tab.active.tab2 svg line {
  stroke: #f06022;
}

.l-slider-cont .tabs .tab.active.tab3 svg path {
  stroke: #f06022;
}

.l-slider-cont .tabs .tab.active.tab1 svg path, .tab.active.tab4 svg path {
  fill: #f06022;
}


.rec-carousel-wrapper {
  background: url(../assets/images/dotted-bg.png);
  padding-top: 30px;
  padding-bottom: 30px;
  /* padding-bottom: 10px; */
}


.l-slider {
  /* margin-top: 120px; */
  margin-top: 40px;
  /* margin-bottom: 120px; */
  margin-bottom: 100px;
}
@media only screen and (max-width: 767px) {
  .l-slider {
    margin-top: 60px;
  }
}
.l-slider h3 {
  margin-bottom: 30px;
  font-size: 30px;
  font-family: Axiform-bold;
}
.l-slider img {
  width: 60%;
  margin-top: 10px;
  margin-bottom: 30px;
  box-shadow: 0px 5px 10px rgba(54, 54, 54, 0.1);
}
@media only screen and (max-width: 767px) {
  .l-slider img {
    width: 90%;
  }
}
@media only screen and (max-width: 1399px) {
  .l-slider h3 {
    font-size: 1.5em;
  }
}
.l-slider .l-slider-btn {
  letter-spacing: 0.05rem;
  height: 40px;
  width: 40px;
  margin-top: 240px;
  background: white;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 5px 10px rgba(54, 54, 54, 0.1);
}

.l-slider .audience_des {
  width: 80%;
  text-align: center;
  margin: auto;
  font-size: 1.1em;
  font-family: 'Axiform';
  /* margin-bottom: 1em; */
  margin-bottom: 2em;
}
@media only screen and (max-width: 767px) {
  .l-slider .l-slider-btn {
    margin-top: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .l-slider .audience_des {
    /* width: 100%; */
    text-align: center;
    /* margin: auto; */
    font-size: 0.8rem;
    /* text-align: justify; */
    text-justify: inter-word;
    font-family: 'Axiform';
    /* margin-bottom: 1em; */
    margin-bottom: 2em;
  }
}


.l-slider .l-slider-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.rec-pagination {
  transform: translateY(60px);
}
.rec-pagination button {
  border: none !important;
  box-shadow: none !important;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}
.rec-pagination button.rec-dot_active {
  background: #f06022;
}

.l-slider .l-slider-item>div.video-react {
  /* margin: auto; */
  margin: auto !important;
  box-shadow: 0 1px 8px rgb(0 0 0 / 30%);
  border-radius: 4px;
}

.l-slider .video-react-big-play-button {
  display: none !important;
}

.l-slider .video-react-poster {
  box-shadow: 0 1px 8px rgb(0 0 0 / 30%);
  border-radius: 4px;
}


.sliderVideoSize {
  width : 600 ;
  height : 400 
}

.sliderImgStyle {
  width: 65% !important; 
  height: auto ; 
  border-radius: 5px;
  /* -webkit-box-shadow: 11px -9px 6px 5px #EEEEEE;  */
  /* box-shadow: 11px -9px 6px 5px #EEEEEE; */
}

@media only screen and (max-width: 767px) {
  .sliderImgStyle {
    width: 100% !important; 
    height: auto ; 
    border-radius: 5px;
    /* -webkit-box-shadow: 11px -9px 6px 5px #EEEEEE;  */
    /* box-shadow: 11px -9px 6px 5px #EEEEEE; */
  }
}

.iframeVideoVimeo {
  box-shadow: rgba(212, 182, 182, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
