.dashboard-content-wrapper .dashboard-title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.dashboard-content-wrapper .dashboard-title h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 30px;
    margin: 0;
    /* color: #333333; */
    color: #363636 ;
    margin-left: 10px;
}
.dashboard-info-block p {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 12.5px;
    line-height: 26px;
    color: #4F4F4F;
    margin: 0;
}
.dashboard-content-wrapper {
    background: #FFFFFF;
    border: 1px solid #db7f58;
    border-radius: 8px;
    padding: 24px;
}
.dashboard-content-wrapper-main {
    padding: 24px 0;
    /* padding: 10px 0; */
}

.dashboard-content-wrapper-link {
        font-family: 'Axiform';
        font-size: 12px;
        font-weight: bolder;
        color: #0477F5;
        letter-spacing: 0.05rem;
}