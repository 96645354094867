
.tooltipSwitch {
    position: relative;
    display: inline-block;
    /* border-bottom: 1px dotted black; */
  }
  
  .tooltipSwitch .tooltiptext {
    visibility: hidden;
    width: 100px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    margin-left: -80px ; 
    margin-top: -20px; 
    position: absolute;
    z-index: 1;
  }
  
  .tooltipSwitch:hover .tooltiptext {
    visibility: visible;
  }