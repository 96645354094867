
.l-header .btn {
  color: #363636;
  padding: 10px 20px;
  border: none;
  background: transparent;
  color: white;
  position: relative;
  cursor: pointer;
  font-size: 15px;
  font-family: Axiform-black;
  margin-top: -20px; 
  letter-spacing: 0.05rem;
  
}
.l-header .btn.primary {
  background: #f06022;
  border-radius: 5px;
  transition: 0.3s;
  width: 140px;
  font-size: 0.96rem;
}

@media only screen and (max-width: 767px) {
  .l-header .btn.primary {
    width: unset;
  }
}



.l-header .btn.primary:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 10px #8b310b; 
}
.l-header ul {
  list-style: none;
  letter-spacing: 0.05rem;
  /* margin-left: -35px; */
  /* margin-left: -2.188rem; */
  margin-left: -1rem;
  
}
.l-header {
  position: fixed;
  top: 0;
  left: 0;
  height: 100px;
  width: 100vw;
  box-sizing: border-box;
  padding: 0px 10%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1080;
  transition: 0.3s;
  color: #f06022 !important;
  /* letter-spacing: 0.05rem; */
}
.l-header.scrolled {
  background: rgba(255, 255, 255, 0.8);
  color: #363636;
  backdrop-filter: blur(10px);
  box-shadow: 0px 5px 10px rgba(54, 54, 54, 0.1);
}
.l-header.scrolled li {
  color: #363636;
  
}
.l-header.scrolled .btn {
  color: #363636;
}
.l-header.scrolled .btn.primary {
  color: white;
}
.l-header img {
  width: 120px;
  z-index: 10;
}

.l-header .top-nav-1, .l-header-cta button {
  font-family: Axiform-bold;
  font-size: 16px;
  /* color: #F16022 ; */
  /* color: #363636; */
}

@media only screen and (max-width: 767px) {
  .l-header img {
    width: 70px;
  }
}
.l-header .hamburger-menu {
  display: none;
  
}
@media only screen and (max-width: 767px) {
  .l-header .hamburger-menu {
    display: inline-block;
    padding: 3px;
    width: 29px;
    position: relative;
    z-index: 10;
  }
}
.l-header .bars-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
}
.l-header .bars-container, .l-header .bar {
  cursor: pointer;
}
.l-header .bar {
  width: 100%;
  height: 3px;
  background-color: #363636;
  border-radius: 5px;
  display: block;
}
.l-header .overlap-container {
  position: relative;
  padding: 0 0 0 0;
  top: -1.3px;
}
.l-header .overlap-container .bar {
  position: absolute;
  top: 0;
}
.l-header .bar1, .l-header .bar4 {
  transform-origin: center left;
  position: relative;
}
.l-header .bars-container2 {
  position: relative;
}
.l-header .bars-container2 .bar {
  position: absolute;
  transform-origin: center;
}
.l-header .bars-container2 .bar1 {
  top: 5%;
  animation: makeCross1Backward 0.25s ease 0s 1 forwards, moveDownBackward 0.25s ease 0.25s 1 forwards;
}
.l-header .bars-container2 .bar2 {
  top: 50%;
  transition: opacity 0.4s ease;
}
.l-header .bars-container2 .bar3 {
  top: 95%;
  animation: makeCross2Backward 0.25s ease 0s 1 forwards, moveUpBackward 0.25s ease 0.25s 1 forwards;
}
.l-header .input-check2:checked + label .bar1 {
  animation: moveDown 0.25s ease 0s 1 forwards, makeCross1 0.25s ease 0.25s 1 forwards;
}
.l-header .input-check2:checked + label .bar2 {
  opacity: 0;
}
.l-header .input-check2:checked + label .bar3 {
  animation: moveUp 0.25s ease 0s 1 forwards, makeCross2 0.25s ease 0.25s 1 forwards;
}
@keyframes moveDown {
  from {
    top: 5%;
    transform: translateY(0);
  }
  to {
    top: 50%;
    transform: translateY(-50%);
  }
}
@keyframes moveDownBackward {
  from {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 5%;
    transform: translateY(0);
  }
}
@keyframes moveUp {
  from {
    top: 95%;
    transform: translateY(0);
  }
  to {
    top: 50%;
    transform: translateY(-50%);
  }
}
@keyframes moveUpBackward {
  from {
    top: 50%;
    transform: translateY(-50%);
  }
  to {
    top: 95%;
    transform: translateY(0);
  }
}
@keyframes makeCross1 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(40deg);
  }
}
@keyframes makeCross1Backward {
  from {
    top: 50%;
    transform: rotate(40deg) translateY(-50%);
  }
  to {
    top: 50%;
    transform: rotate(0deg) translateY(-50%);
  }
}
@keyframes makeCross2 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-40deg);
  }
}
@keyframes makeCross2Backward {
  from {
    top: 50%;
    transform: rotate(-40deg) translateY(-50%);
  }
  to {
    top: 50%;
    transform: rotate(0deg) translateY(-50%);
  }
}
.l-header .mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 100vw;
  transition: 0.3s;
  background: white;
}
.l-header .mobile-menu.menu-opened {
  left: 0;
}
.l-header .mobile-menu nav {
  width: 100%;
  flex-direction: column;
  background: white;
  display: flex;
  height: 100%;
  justify-content: center;
}
.l-header .mobile-menu nav ul {
  flex-direction: column;
  margin-bottom: 60px;
  
}
.l-header .mobile-menu nav ul li {
  color: #363636;
  font-size: 2.2em;
  margin: 20px 0px;
  
}
.l-header .mobile-menu nav .btn {
  margin-left: 0;
  font-size: 2.2em;
  padding: 15px 30px;
}
.l-header nav {
  width: 50%;
  display: flex;
  /* justify-content: space-between; */
  justify-content: right;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .l-header nav {
    display: none;
  }
}
.l-header nav ul {
  display: flex;
}
.l-header nav ul li {
  margin: 0px 20px;
  text-align: center;
  color: white;
  transition: 0.3s;
  cursor: pointer;
  position: relative;
}
.l-header nav ul li::after {
  content: "";
  position: absolute;
  height: 2px;
  background: white;
  width: 0;
  left: 0;
  transition: 0.3s;
  top: 120%;
}
.l-header nav ul li:hover::after {
  width: 100%;
}
.l-header nav .btn {
  transition: 0.3s;
}
.l-header nav .primary {
  margin-left: 20px;
}