.header-wrapper{
    background-color: #ffffff;
    box-shadow: 0px 1px 0px #E0E0EA;
    /* height: 65px; */
    height: 75px;
    /* position: sticky; top:0 ;  */
}


.header-wrapper .account-block,.header-left-block{
    display: flex;
    align-items: center;
}

.header-logo {
    width: 190px ; 
    /* height: 50px ; */
    margin-top: -30px;
    margin-left: 12px
}

.header-wrapper .account-block{
    padding: 11px 24px;
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    cursor: pointer;
}
.header-wrapper .account-block-wrapper{
    padding:10px 16px 10px 0;
}
.header-wrapper .account-block:hover .myaccount-text{
    color: #F06022;
}
.header-wrapper .myaccount-icon {
    width: 16px;
    height: 18px;
}
.header-wrapper .myaccount-text{
    padding-left: 8px;
    font-size: 15px;
    line-height: 16px;
    color: #333333; 
    font-weight: 500;
}
.header-wrapper .search-wrapper {
    /* padding: 16px; */
    /* padding: 12px !important; */
    position: relative !important;
}

.header-wrapper .search-wrapper::after {
    content: '';
    position: absolute;
    background-image: url(../../../../common/shared-charts/images/search.png);
    height: 14px;
    width: 14px;
    top: 0;
    bottom: 0;
    left: 36px;
    margin: auto;
}

.header-wrapper .search-wrapper input{
    width: 359px;
    height: 40px;
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    padding: 0 20px 0 40px;
    font-size: 17px;
    line-height: 20px;
    color: #4F4F4F;
    outline: none;
}
.header-wrapper .menu-dropdown {
    position: relative;
}   
.header-wrapper .menu-dropdown::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    background-image: url(../../../../common/shared-charts/images/arrow.png) ; 
    height: 8px;
    width: 14px;
}
.header-wrapper select.menu-title {
    background: #F06022;
    border-radius: 16px;
    color: #ffffff;
    width: 82px;
    height: 38px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0 24px;
    font-weight: 600;
    font-size: 18px;
    box-shadow: none;
    outline: none;
}
.header-wrapper .menu-dropdown {
    padding: 10px 0;
}
@media(max-width:991px){
    .header-wrapper .row {
        flex-wrap: wrap;
        margin-left: 12px;
    }
    .header-wrapper {
        padding: 20px 15px 10px;
    }

    .header-logo {
        /* width: 184.5px ;  */
        /* margin-top: -50px; */
        /* left: 0 "important" ;  */
    }

    #geocoder-header {
        visibility: hidden;
    }


}
@media(max-width:767px){
    .header-wrapper .search-wrapper input{
        width: 300px;
    }
    .header-wrapper .account-block {
        padding: 11px 12px;
    }
    .header-left-block {
        flex-wrap: wrap;
        justify-content: center;
    }
    .header-wrapper .search-wrapper {
        max-width: 370px;
        margin: auto;
        padding: 10px;
    }  

}
@media(max-width:660px){
    .serch-block {
        width: 100%;
        text-align: center;
    } 
    .header-logo {
        width: 184.5px ; 
        margin-top: 20px;
        margin-left: 0px;  
    }

    #geocoder-header {
        visibility: hidden;
    }
}
@media(max-width:425px){
    .header-wrapper .search-wrapper input {
        width: 230px;
    }
}

#geocoder-header {
    /* padding : 16px !important ;  */
    margin-top: 16px !important ; 
    position: relative !important;
    border-radius: 16px !important;
    display : flex !important ; 
    margin-right: 0 !important;
}

#geocoder-header .mapboxgl-ctrl-geocoder--input {
    width: 359px !important;
    /* height: 40px !important; */
    background-color: #FFFFFF !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 16px !important;
    padding: 0 20px 0 40px !important;
    /* font-size: 17px !important; */
    line-height: 20px !important;
    color: #4F4F4F !important;
    outline: none !important;
}

#geocoder-header .mapboxgl-ctrl-geocoder--icon {
    /* display : none !important; 
    visibility: hidden !important; */
    width: 28px;
    /* height: 18px; */
    border-radius: 16px !important;
  }


  @media screen and (min-width: 640px) {
    #geocoder-header .mapboxgl-ctrl-geocoder--input {
        width: 359px !important;
        /* height: 40px !important; */
        background-color: #FFFFFF !important;
        border: 1px solid #E0E0E0 !important;
        border-radius: 16px !important;
        padding: 0 20px 0 40px !important;
        /* font-size: 16px !important; */
        line-height: 20px !important;
        color: #4F4F4F !important;
        outline: none !important;
        /* margin-right: 12px; */
    }

    #geocoder-header .mapboxgl-ctrl-geocoder {
        width: 100% !important;
        border-radius: 16px !important;
        box-shadow: none;
        margin-right: 12px;
    }
  } 