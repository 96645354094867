.centered {
    margin: 0;
    position: fixed;
    top: 55%;
    left: 82%;
    color: #424B4F ;
    transform: translate(-50%, -50%) ;
    cursor: default;
    letter-spacing : 0.03em;
    font: 14px/24px Axiform;
    /* color: black ; */
}

@media only screen and (max-width: 767px){
    .centered {
       position:absolute ; 
       top:auto;
       left:auto ;
    }
}

.displayFlex > div > input {
    display: inline-flex ; 
    width: 15rem ;
}

.displayFlex > div > select {
    display: inline-flex ; 
    width: 100% ;
}

.displayFlex > button {
    width: 30%; 
    height: 2rem;
    color: #000000;
}

.responseText {
    font: 19px/19px Mont;
    color: #ffffff;
    letter-spacing: 1px;
    cursor: default;
}

.blogHeader {
      /* font-size: 1.2rem; */
    /* letter-spacing : 0.05em; */
    color: #424B4F ;
    margin-left: 3rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 2rem; 

    
}

.tags {
    cursor: default;
    margin: 5px;
    font-size: small;
    /* color: #1972F5;  */
    color: crimson;
    letter-spacing: 0.01rem;

}

.tagsSelected {
    cursor: default;
    /* margin: 10px; */
    font-size: small;
    color: #1972F5; 
    /* color: crimson;  */
    letter-spacing: 0.01rem;
    /* text-decoration: underline !important; */
    border-bottom: 1px solid #1972F5; 
    padding: 2px;
    border-radius: 2px;

}

.tags:hover {
    border-bottom: 1px solid #1972F5; ;
}

.filterBy {
    cursor: default;
    font-size: small;
    font-style: italic;
    letter-spacing: 0.01rem;
    margin-right: 3px;
    margin-left: 3rem;
}

.btnPrimaryBlog {
    color: #fff;
    background: #f06022;
    border-radius: 5px;
    transition: 0.3s;
    padding: 10px 25px;
    font-size: 14px;
    font-family: Axiform-bold;
    margin-bottom: 10px;
    cursor: pointer;
    border: none;
    letter-spacing: 1px;
  }

.blogItemsText {
    letter-spacing : 0.03em;
    font: 12px/22px Axiform;
    color: #424B4F ;
    /* text-align: justify ;  */
}

.zipText {
    color: #1972F5 ; 
}

.zipText:hover {
    /* text-decoration: underline !important; */
}

.contentText {

    overflow-wrap: break-word;
    min-height: 300px;
    padding: 1.7rem;
    font-size: 0.875rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5;
    color: #4d4d4d;
    white-space: pre-line;
    margin: 0px auto;
    border: none !important;
    /* text-align: justify; */
}


.blogEntryTitle {
    color: #424B4F ;
    font-size: 27px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.blogEntryTitleDiv {
    margin-top: 15px;
    /* margin-left: -2rem ; */
    margin-bottom: -15px ; 
    text-align : center  ;
}
