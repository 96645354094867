
.paneled {
    height: 40px;
    overflow: hidden;
    margin: 16px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.5s;
    border-radius: 8px;
    width: 100%;
    background-color: rgba(138, 138, 138, 0.5);
    opacity: 1 ;
    overflow-y: hidden ;

}

.visible {
  height: 75vh;
}


@media only screen and (min-width: 1824px)  {
    .visible {
        height: 690px;
    }
}


.headerContainer {
    display: flex;
    /* height: 50px; */
    height: 3rem;
    padding: 0.2rem;
}

.headerCountrySelection {
    margin-left: 0.5rem;
    margin-top: 0.1rem;
    width: 30px; 
    height: 30px;
    border-radius: 50%;
    display: flex; /* or inline-flex */
    align-items: center; 
    justify-content: center;
    background-color: #FFFFFF ;
    color: #363636;
    font-weight: bolder;
    font: normal normal bold 14px/24px Mont;
    text-transform: uppercase;
}

.togglePanelLines {
    color : #6a6d6d;
    margin-left: 31% ;
}

.header {
    font-size: 20px;
    margin: 5px;
}

.expand {
    cursor: pointer;
}



.selectedPanel {
    height: 370px; 
    
}

.selectedData {
    height: 400px;
}

.avatarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    /* new add */
    /* height:40%; */
}

.submitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 16px;
    padding: 5px;
}

.selectionDataContainer {
    justify-content: center;
    position: relative;
    display: flex;
    /* height: 25px ; */
}

.clearSelection {
    margin-top: -4px;;
    margin-right: -45px;
    height: 25px;
    width: 25px;
    position: absolute;
    right: 40px;
    color: black;
    font-size: 1rem;
}

.imageStyle {
    height: 12em;
    width: 11.5em;
}


.selectedAttributes {
    font-size: 0.7rem;
    color : #686767 ;
    /* border: 1px dotted black;  */
    padding: 0.30rem;
    cursor: default;
    letter-spacing: 0.03rem ;
    border: 1px dotted #dd9064; 
    border-radius: 3px;
    text-transform: capitalize;
}

.selectedPercentileText {
    font-size: 0.7rem;
    color : #686767 ;
    padding: 0.30rem;
    cursor: default;
    letter-spacing: 0.03rem ;
}

.selectedPlzOnMapText {
    font-size: 0.7rem;
    color : #686767 ;
    padding: 0.30rem;
    cursor: default;
    letter-spacing: 0.03rem ;
}

.selectionsHighlightText {
    color: #2378CD ; 
    font-size: 0.7rem;
}

/* style={{ padding: "15px", border: '1px dotted' , borderRadius: "8px" }} */
.favoriteTextAfterDrag {
    padding: 1rem;
    font-size: 1rem;
    color : #686767 ;
    border: 2px dotted #d8d6d6 ; 
    border-radius: 5px ;
}

.ZipPopupPosition {
    position: absolute ;
    bottom: 50 ;
    left: 22 ;
    z-index: 5 ;
    background-color: #363636 ;
    color: #FFFFFF ;
    padding: 7 ;
    border-radius: 5px ; 
    font-size: 0.6rem ;
    letter-spacing: 0.03rem ;
    cursor: default
}

.tab1PremiumFeatureText {
    position: absolute ; 
    top: 75% ; 
    left: 10% ; 
    right : 10% ; 
    text-align : center ; 
    
}
