

.headerDivModal {
    /* margin-top: -0.5rem; */
    padding: 10px;
    text-align: center;
    background-color: #f5f6f7;
    color: #4a4e52; 
  }


.icon:hover {
  color: black;
}

.loginButton {
  background: #F75E02 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #F75E0240;
  /* width: 100%;  */
  width: 120px; 
  height: 38px;
  padding:5; 
  border-radius: 5px;
  border: none;
  opacity: 1;
}

.loginText {
  text-align: center;
  color: #FFFFFF;
  opacity: 1;
}

.placeholderText {
  text-align: left;
  color: #A2A2A2;
  opacity: 1;
}

.textAreaPlaceholder{
  text-align: left;
  font-size: 1rem ;
  color: #A2A2A2;
  opacity: 1;
}

.displayFlex {
  height: 50px !important;
  width: 60% ;
}

.addition_card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* align-items: start; */
  /* border: 1px solid #F0F0F7; */
  border: 1px solid #ced4da ; 
  box-sizing: border-box;
  border-radius: 0.25rem;
  padding: 8px;
  font-size: small;
  background: #FFFFFF;
  /* height: 45px !important ; */
  width: 120px;
  font-weight: 400;

}

