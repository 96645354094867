.container {
    height: 100%;
}

.item {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.selectionContainer {
    height: 20px;
    width: 20px;
    border: 2px solid #aaaeae;
    border-radius: 50%;
    padding: 4px;
}

.selectionInner {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

.activatedList {
    background: #FF7C45;
}

.selection {
    display: flex;
    align-items: center;
    justify-content: center;
}

.defaultLabel {
    margin-right: 5px;
    font-style: italic;
    opacity: 0.7;
}

.percentileContainer {
    justify-content: space-between;
    align-items: baseline;
    
}

.percentileTextInput {
    /* border: white; */
    border: 1px solid #aaaaaa;
    border-radius: 4px;
    padding: 5px 10px;
    /* color: rgb(26, 24, 24) ;  */
    /* width: 25%; */
    width: 5em;
    font-weight: bold;
    font-size: 0.9rem;
}

.button {
    background: #c0c0bf;
    border: 1px solid #c0c0bf;
}

.success {
    color: #27ae60;
}

.danger {
    color: #c0392b;
}

.settingsTabText {
    font-size: 0.80rem;
    color : #686767 ;
    padding: 0.30rem;
    letter-spacing: 0.03rem ;
}

.paywall-div-image-tab1 {
    background-image: url("../paywalls-img/tab3_paywall.png");
    margin-left: -0.7em;
    filter: blur(0.1px);
    -webkit-filter: blur(0.7px);
    height: 60vh;  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.paywall-div-image-tab2 {
    background-image: url("../paywalls-img/tab2_paywall.png");
    margin-top: 0.7em; 
    margin-left: 0.3em;
    filter: blur(0.1px);
    -webkit-filter: blur(0.7px);
    height: 50vh;  
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Large screens ----------- */
@media only screen and (min-width: 1824px) {

    /* Styles */
    .paywall-div-image-tab1 {
        background-image: url("../paywalls-img/tab3_paywall.png");
        filter: blur(0.1px);
        -webkit-filter: blur(0.7px);
        height: 50vh;  
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .paywall-div-image-tab2 {
        /* The image used */
        margin-top: 5px; 
        margin-left: -10px;
        background-image: url("../paywalls-img/tab2_paywall.png");
        /* Add the blur effect */
        filter: blur(0.1px);
        -webkit-filter: blur(0.7px);
        /* Full height */
        height: 40vh;  
        /* Center and scale the image nicely */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }


}