#listing-billboard .map-billboard-list {
    /* height: auto;
    width: 40%;
    padding-left: 20px;
    padding-bottom: 20px; */
}

#listing-billboard-detail .view-listing-details-block .map-billboard-list {
    width: 35%;
}

#listing-billboard-detail .map-billboard-list iframe {
    width: 98%;
    height: 750px;
    border-radius: 8px;
    border: none;
    margin-left: 20px 
}

#listing-billboard-detail .map-billboard-list > div {
    width: 98%;
    height: 750px;
    border-radius: 8px;
    border: none;
    margin-left: 20px 
}


#listing-billboard .mapboxgl-map {
    width: 36.9%;
    height: 750px;
    border-radius: 8px;
    border: none;
    margin-left: 18px;
}


@media(max-width:992px) {
    #listing-billboard .view-listing-details-block .map-billboard-list {
        width: 100%;

    }

    #listing-billboard .map-billboard-list iframe {
        height: 100%;
    }
}
