.Races-wrapper {
    padding-bottom: 20px !important;
}

.Races-wrapper .Races-block {
    background-color: #ffffff;
    border-radius: 16px;
    padding: 30px !important;
    border: 1px solid #E0E0E0;
}

.Races-wrapper .Races-description {
    display: flex;
    align-items: center;
}

.Races-wrapper .Races-map {
    width: 300px;
}

.Races-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 14px;
    margin-bottom: 8px;
    border-radius: 0px 0px 6px 0px;
    font-weight: 700;
    font-size: 14px;
}

.Races-data.data1 {
    background: rgba(59, 117, 174, 0.12);
    color: #3B75AE;
    border-radius: 6px 6px 0px 0px;
}

.Races-data.data2 {
    background: rgba(197, 58, 50, 0.12);
    color: #C53A32;
}

.Races-data.data3 {
    background: rgba(142, 203, 117, 0.12);
    color: #8ECB75;
}

.Races-data.data4 {
    background: rgba(245, 176, 100, 0.12);
    color: #F5B064;
}

.Races-data.data5 {
    background: rgba(124, 159, 215, 0.12);
    color: #7C9FD7;
}

.Races-data.data6 {
    background: rgba(187, 107, 217, 0.12);
    color: #BB6BD9;
}

.Races-data.data7 {
    background: rgba(79, 79, 79, 0.12);
    color: #4F4F4F;
}

.Races-details {
    width: 744px;
    padding-left: 70px;
}

@media(max-width:991px) {
    .Races-wrapper .Races-block {
        padding: 20px;
    }

    .Races-details {
        width: 660px;
        padding-left: 50px;
    }
}

@media(max-width:860px) {
    .Races-details {
        width: 620px;
        padding-left: 20px;
    }

    .Races-wrapper .Races-map {
        width: 250px;
    }

    .Races-data-text {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media(max-width:820px) {
    .Races-wrapper .Races-description {
        flex-wrap: wrap;
        justify-content: center;
    }

    .Races-data {
        flex-wrap: wrap;
    }

    .Races-details {
        width: 100%;
        padding-left: 0;
    }
}