#listing-billboard-detail .viewListingDetails_section {
    width: 65%;
}

#listing-billboard-detail .viewListingDetails_section .title {
    margin-bottom: 16px;
}

#listing-billboard-detail .viewListingDetails_section .title .link {
    color: #333333;
    font-weight: 500;
    font-size: 12px;
}

#listing-billboard-detail .viewListingDetails_section .title .link a {
    color: #2F80ED;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .back_link {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
    margin-bottom: 20px;
    cursor: pointer;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .back_link svg {
    margin-right: 4px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box {
    background: #FFFFFF;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box:not(:last-child) {
    margin-bottom: 16px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title h2 {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    line-height: normal;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .available_box {
    display: flex;
    align-items: center;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .available_box .css-j204z7-MuiFormControlLabel-root {
    margin: 0 16px 0 0;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .available_box .css-j204z7-MuiFormControlLabel-root .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0 6px 0 0;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .available_box .MuiCheckbox-root:hover {
    background-color: transparent;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .available_box .available_check.Mui-checked svg {
    color: #27AE60;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .available_box .favorite {
    padding: 0;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .available_box .favorite.Mui-checked svg {
    color: #eb5757;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .address {
    display: flex;
    align-items: center;
    margin-right: 24px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .address img {
    margin-right: 6px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .address {
    font-weight: 500;
    font-size: 12px;
    color: #4F4F4F;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .daily_update {
    display: flex;
    align-items: center;
    margin-right: 24px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .daily_update .daily_impre img,
.viewListingDetails_section .view_details_box .view_box .daily_update .daily_approx img {
    margin-right: 6px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .daily_update .daily_impre,
.viewListingDetails_section .view_details_box .view_box .daily_update .daily_approx {
    font-weight: 500;
    font-size: 12px;
    color: #4F4F4F;
    display: flex;
    align-items: center;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .daily_update .daily_impre {
    margin-right: 16px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .hr {
    margin: 12px 0;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .request_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .info {
    font-weight: 600;
    font-size: 12px;
    color: #DF6937;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .info {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title .info svg {
    margin-right: 6px;
}

#listing-billboard-detail .d-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .detail {
    font-weight: 500;
    font-size: 13px;
    color: #4F4F4F;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .view_detail {
    font-weight: 600;
    font-size: 12px;
    color: #2F80ED;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .view_detail {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .view_detail svg {
    margin-left: 2px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .point {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 12px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .point .point_tag {
    padding: 12px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .point .point_tag svg {
    margin-right: 6px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .point .point_tag:not(:last-child) {
    margin-right: 16px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .point_restaurant {
    color: #9B51E0;
    background: rgba(155, 81, 224, 0.08);
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .point_lodge {
    color: #219653;
    background: rgba(39, 174, 96, 0.08);
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .point_shop {
    color: #F2994A;
    background: rgba(242, 153, 74, 0.08);
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .point_park {
    color: #2F80ED;
    background: rgba(47, 128, 237, 0.08);
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items {
    display: flex;
    align-items: center;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .driving_item {
    padding-left: 12px;
    flex: 0 1;
    flex-grow: 1;
    border-left: 2px solid;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .speed {
    border-color: #F2C94C;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .congestion {
    border-color: #56CCF2;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .jam {
    border-color: #EB5757;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .uncapped {
    border-color: #27AE60;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .driving_item:not(:last-child) {
    margin-right: 16px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .driving_item h2 {
    margin: 0 0 2px;
    font-weight: 500;
    font-size: 12px;
    color: #4F4F4F;
    display: flex;
    align-items: center;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .driving_item svg {
    margin-right: 8px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .driving_item p {
    font-size: 16px;
    font-weight: 600;
    color: #4F4F4F;
    margin: 0;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .report_section {
    padding: 24px;
    background: white;
    border: 1px solid #DF6937;
    border-radius: 8px;
    display: flex;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .report_section .detail {
    margin-left: 24px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .report_section .detail p {
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    margin: 0 0 12px;
}

#listing-billboard-detail .viewListingDetails_section .view_details_box .report_section .detail button {
    background: #DF6937;
    border-radius: 8px;
    padding: 10px 16px;
    color: white;
    cursor: pointer;
    border: none;
}


@media (max-width: 992px) {
    #listing-billboard-detail .viewListingDetails_section {
        margin-bottom: 20px;
    }

    #listing-billboard-detail .viewListingDetails_section,
    .map {
        width: 100%;
    }

    #listing-billboard-detail .map {
        height: 500px;
        padding: 0;
    }

    #listing-billboard-detail .point {
        margin-bottom: 0 !important;
    }

    #listing-billboard-detail .point .point_tag {
        margin-bottom: 12px;
    }

    #listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items {
        flex-wrap: wrap;
    }

    #listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .driving_item {
        width: 50%;
        flex: none;
        flex-grow: unset;
        margin: 0 0 10px 0 !important;
    }
}

@media (max-width: 575px) {
    #listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .driving_section .driving_items .driving_item {
        width: 100%;
    }

    #listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .title {
        flex-wrap: wrap;
    }

    #listing-billboard-detail .viewListingDetails_section .view_details_box .view_box .daily_update {
        flex-wrap: wrap;
    }
}