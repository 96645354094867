.modalPricing {
    
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    /*
    min-width: 50em;
    padding: 3rem;  
    background-color: #e8e6e6;
    */
    min-height: auto;
    font-family: Axiform;
    color: #424B4F;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    outline: transparent;
    
  }
  
  .overlayPricing {
    z-index: 99;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.4);
  }


  .overImageUpgradeButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    /* width: 40%; */
    border: 2px solid #F75E02;
    background-color: #F75E02;
    border-radius: 5px;
    opacity: 1;
    font: 14px/20px Axiform;
    letter-spacing: 1.4px;
    color: #424B4F;
    opacity: 1;
    cursor: pointer ; 
    padding: 8px;
    text-align: center;
  }

  .textUpgradeVersion{
    font: 10px/16px Axiform ;
    letter-spacing: 0.05em;
    color: #424B4F;
    cursor: default;
  }

  .textUpgradeUsers{
    font: 16px/17px Axiform ;
    letter-spacing: 0.05em;
    color: #424B4F;
    cursor: default;
  }

  .textUpgradeLookup{
    font: 14px/20px Axiform ;
    letter-spacing: 0.05em;
    color: #424B4F;
    cursor: default;
  }

  
  .pricing {
    font: 12px/30px Axiform;
    letter-spacing: 0.07rem; 
    color: #F75E02 ;
    opacity: 1;
    cursor: pointer ; 
    /* border-bottom: 1px dotted #F75E02 ; */
  }

  .hoverUnderlineAnimation {
    display: inline-block;
    position: relative;
    color: #0087ca;
    /* font-weight: bolder; */
    font-weight: bold;
    letter-spacing: 0.05rem ;
    cursor: default;
  }
  
  .hoverUnderlineAnimation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hoverUnderlineAnimation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }


  .upgradePaywalunderline {
    display: inline-block;
    position: relative;
    color: #F75E04 ;
    cursor: default;
  }
  
  .upgradePaywalunderline:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .upgradePaywalunderline:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  