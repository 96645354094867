/* .prompt-library-app {
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1e1e1e;
    color: #eaeaea; 
    padding: 20px;
  } */

  .prompt-library-app {
    font-family: 'Arial', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: #181818;
    padding: 20px;
  }
  
  .prompt-library-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    max-width: 1000px; /* Setting a max-width ensures it's not too stretched on larger screens */
  }
  
  .prompt-library-card {
    background-color: #333; /* Slightly lighter than the background for a bit of contrast */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    transition: transform 0.2s;
    cursor: default ; 
  }
  
  .prompt-library-card:hover {
    transform: translateY(-5px);
    background-color: #444; /* Slightly change color on hover for better effect */
  }
  
  .prompt-library-title {
    font-size: 16px;
    font-weight: bold; /* Make the title bold */
    margin: 0;
    margin-bottom: 8px;
    color: #eaeaea;
    border-bottom: 2px solid #58a6ff; /* Introducing a blueish color for the bottom border */
    padding-bottom: 8px; /* Padding to give space between the text and the border */
  }
  
  .prompt-library-subtitle {
    font-size: 14px;
    margin: 0;
    margin-top: 8px; /* Space from the title */
    color: #b0b0b0;
    /* border-bottom: 1px solid #757575; */
    /* padding-bottom: 8px;  */
    cursor: default;
  }
  

  /* Hide the FontAwesome copy icon by default */
.prompt-library-card .fa-copy {
    visibility: hidden;
    cursor: pointer; /* To indicate it's clickable */
    transition: visibility 0.3s ease;
    margin-top: 10px; /* You can adjust this as needed */
  }
  
  /* Show the FontAwesome copy icon when hovering over the card */
  .prompt-library-card:hover .fa-copy {
    visibility: visible;
  }
  

  .prompt-library-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjusted from 'center' to 'space-between' */
    padding: 10px 20px;
    /* background-color: #333;  */
    margin-bottom: 20px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    border-radius: 8px;
    position: relative; /* Added to allow positioning of title */
  }
  
  .prompt-library-imgCenterNav {
    height: 40px; 
  }
  
  .prompt-library-navbar-title {
    font-size: 32px;
    color: #eaeaea; 
    /* font-weight: bold;  */
    position: absolute; /* Make the title position absolute */
    left: 50%; /* Center the title */
    transform: translateX(-50%); /* Ensures perfect centering */
  }
  
  .prompt-library-content {
    display: flex;
    justify-content: center;
    width: 100%;
  }

