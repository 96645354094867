
/*
* {
  text-decoration: none!important;
  }

  */ 
* > a {
  text-decoration: none!important;
  }  

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} 



.sticky-header-message {
  /* position: sticky; */
  top: 0;
  font-size: 12px;
}
