.l-footer {

    display: flex;
    height: 170px;
    padding: 0px 10%; 
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid rgba(54, 54, 54, 0.1);
    letter-spacing: 0.01rem;
    font-size: 14px;
}
.l-footer p {
    font-size: 14px;
    /* font-size: 12px; */
    font-family: Axiform;
}
.l-footer .socials {
    display: flex;
    /* margin-right: -20px; */
}
.l-footer .socials img {
    margin-left: 20px;
}

.l-footer-right {
    width: 55%;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}

.l-footer-made {
    display: inline-flex;
}

@media only screen and (max-width: 767px) {
    .l-footer-made {
        margin-bottom: 10px;
    }

    .l-footer-right {
        width: 50%;
        display: grid;
        justify-content: left;
    }   
}

@media only screen and (max-width: 600px) {
    .l-footer {
        display: grid;
        justify-content: center;
    }

    .l-footer-right {
        width: 100%;
        justify-content: center;
    }
}

.de-footer {
    font-weight: bold;
    color: #FBFBFB ;
    border-radius: 2px;
    padding: 4px;
    background : linear-gradient(to bottom, #000000 0%, #000000 10%,#DE0102  33%,#DE0102 67%, #DE0102  67%, #FFCF00  100%);
    letter-spacing: 0.1rem;
    font-size: 13px;
    font-family: Axiform;
}

.iconLi {
    color: #0E65C2;
    font-size: 1.8rem;

  }
  
  .iconTweet {
    color: #50ABF1;
    font-size: 1.8rem;
    /* font-size: 2rem; */
  }