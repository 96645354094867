

/* sign in  */
#Sign-up {
  font-size: 14px !important;
  font-family: 'Roboto', sans-serif;
}

#Forgot-password h3 {
  font-family: 'Roboto', sans-serif;
  color: #4a4e52; 
}

#Forgot-password p {
  font-family: 'Roboto', sans-serif;
  color: #9a9ea2;
  font-size: 16px; 
}

.signIn-Page{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px ;
    font-family: 'Roboto', sans-serif;
    background-color: #ffffff;
  }
  /*
  #Sign-up .googlebtn,
  .signIn-Page button.googlebtn {
    background-color: #4d8edd;
    color: #fff;
    padding: 6px;
    width: 100%;
    font-size: 18px !important;
    border-radius: 5px;
    border: none !important
  }
  */

  #Sign-up .googlebtn,
  .signIn-Page button.googlebtn {
    width: 100%;
    font-size: 18px !important;
    border-radius: 5px;
    padding: 6px;
    border-radius: 8px;
    border: 1px solid #0477F5 ; 
    /* background-color: #4d8edd1f; */
    background-color: #7fb5f71f;
    color: #0477F5
  }

  #Sign-up .googlebtn img {
    width: 20px; 
    margin-right: 5px;
    margin-top: -2px;
  }

  #signIn .googlebtn img {
    width: 20px; 
    margin-right: 15px !important;
    margin-top: -2px;
    margin-left: -15px; 
  }
  
  .signin-inner {
    /* width: 360px; */
    width: 400px;
    padding:10px
  }
  
  .signIn-Page .password-submit-block input.submitbtn {
    width: 30%;
    margin-left: 10px;
    border: 1px solid #4d8edd;

  }
  
  .or-contain p{
    align-items: center;
  }
  
  #Sign-up input, 
  #Forgot-password input,
  #signIn input {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid #808080ad;
    border-radius: 5px;
    padding-left: 40px;
  }
  

  .signIn-Page .password-submit-block input.submitbtn {
    width: 30%;
    margin-left: 10px;
    
  }

  
  .signIn-Page .password-submit-block {
    display: flex;
  }


  
  .signIn-Page .sign-forgot-link-block {
    display: flex;
    justify-content: space-around;
    
  }
  
  .sign-In-link-block a,
  .sign-forgot-link-block a {
    /* text-decoration: underline; */
    border-bottom: 1px solid #808080ad;
    color: #808080ad;
    font-size: 14px !important;
  }
  
  
  .password-submit-block .submitbtn{
  padding: 0px;
  margin-left: 10px;
  height: 40px;
  border-radius: 5px;
  width: 22%;
  background: #f06022;
  border: none !important
  }
  
  .password-submit-block .submitbtn i{
    color: #ffffff;
  }
  
  .or-contain p {
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    font-weight: 600;
  }
  .or-contain p:before, p:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
    color: #808080ad;
  }
  .or-contain p:after {
    margin-left: 10px
  }
  .or-contain p:before {
    margin-right: 10px
  }
  
  
  /*  forgot password*/
  
  .Forgot-password-inner {
    width: 450px !important;
    text-align: center;
    padding: 10px;
  }
  
  .Forgot-password-title-block p{
    margin-top: 10px;
  }
  
  .form-forgotpassword {
    display: flex;
    margin-top: 15px;
  }



  
  .form-forgotpassword i.fa.fa-arrow-right {
    color: white;
  }
  
  .sign-In-link-block{
    display: flex;
    justify-content: space-evenly;
  }
  
  .form-forgotpassword .submitbtn
  {
    padding: 0px;
  margin-left: 10px;
  height: 40px;
  border-radius: 5px;
  /* border: 1px solid #4d8edd; */
  width: 22%;
  /* background-color: #4d8edd; */
  background: #f06022;
  border: none !important
  }
  
  .form-forgotpassword .submitbtn i{
    color:'white'
  }
  
  /* signup */
  
  .sign-up-block {
    display: flex;
    flex-wrap: wrap;
    height: 100vh;
    align-items: center;
  }
  
  #Sign-up h3{
    font-weight: 400;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #575757; 
  }
  
  .Sign-up-inner{
    padding:40px;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sign-up-inner2{
    text-align: center;
    width: 50%;
    height: 100%;
    /* background-color: #f5f6f7; */
    background-color: #E6E7E8;
    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .sign-up-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    padding: 10px 0px;
  }
  
  .sign-up-content p{
    color:#808080ad ;
    margin-top: 10px;
  }
  
  .sign-up-content p a{
    color:#343a40 ;
  }
  

  .signupbtn{
    /* background-color: #4d8edd1f; */
    /* color: #4d8edd; */
    padding: 6px;
    width: 100%;
    font-size: 18px;
    border-radius: 5px;
    /* border: 1px solid #4d8edd; */
    border: none !important;
    color: #fff;
    background: #f06022;


  
  }
  /* .signupbtn{
    background-color: #4d8edd1f;
    color: #4d8edd;
    padding: 6px;
    width: 100%;
    font-size: 18px;
    border-radius: 5px;
    border: 1px solid #4d8edd;
  
  } */
  
  #signIn .icon,
  #Forgot-password .icon,
  #Sign-up .icon{
    color:darkgray
  }
  
  .commany-ads img{
    height: 24px;
    margin: 5px 10px;
  }
  
  .Sign-up-left {
    width: 60%;
  }
  .Sign-up-left .commany-ads {
    text-align: center;
    margin-bottom: 15px;
  }

  .thumb-block{
    margin-bottom: 40px;
    width: 100%;
  }
  
  .thumb-block img {
    width: 75%;
  }
  .flodash-description{
    color: #000;
    text-align: center;
    font-size: 1.25rem;
    
  }
  
  .flodash-description p {
    color: #4a4e52;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 25px;
  }
  .flodash-tab-inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flodash-tab-inner img {
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }
  .flodash-tab-inner .flodash-tab-inner-title {
    text-align: left;
    padding-left: 15px;
  }
  .flodash-tab-inner .flodash-tab-inner-title h4 {
    font-size: 16px;
    color: #4a4e52;
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 16px;
  }
  .flodash-tab-inner .flodash-tab-inner-title h6 {
    color: #9a9ea2;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
  /* icon */
  .input-icons i {
    position: absolute;
  }
  
  .input-icons {
    width: 100%;
  }
  
  .icon {
    padding: 10px;
    min-width: 40px;
    font-size: 21px;
  
  
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    text-align: justify;
    padding-left: 40px;
  }
  
  @media(max-width:1400px){
    .Sign-up-left {
      width: 70%;
    }
  }
  @media(max-width:1199px){
    .Sign-up-left {
      width: 100%;
    }
    .sign-up-inner2{
      padding: 20px;
    }
  }
  @media(max-width:991px){
    .sign-up-block{
      display: block;
    }
    .Sign-up-inner {
      padding: 20px;
      width: 100%;
    }
    .sign-up-inner2{
      display: none;
    }
  }

  .errorTextForm {
    color: #d44a4a;
    font-size: 0.75rem;
}

.expired-token {
  font-family: 'Roboto', sans-serif;
  color: #4a4e52; 
}

/* ====== reusable sign up component *========= */ 


#Sign-up-reuse .googlebtn{
  background: none !important ;
  padding: 11px 24px;
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,.1) ; 
}

.global-btn-primary {
  color: #fff;
  background: #f06022;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 14px;
  cursor: pointer;
  border: none;
}

#favorite-logo {
  position:relative;
  width: 150px;
}

.top-right-stick {
  position:absolute;
  top:0;
  right:0;
}

.search-location-logo {
    width: 140px; 
    height: auto; 
    margin-top: -5px;
    opacity: 1; 
    cursor: default ;
    position: absolute;
}

.signUpCenterLogo {
  width: 160px; 
  height: auto ; 
  opacity: 1; 
  /* transform: 'translateY(calc(50% - 25vh))' */
  transform: translateY(calc(50% - 25vh))
}