#listing-billboard .listing-section {
    background-color: #F8F8F8;
    height: calc(100vh - 72px);
    min-height: 800px;
}

#listing-billboard .listing-section .container {
    padding-top: 20px;
    height: 100%;
}

#listing-billboard .listing-section .sideBar {
    max-width: 257px;
    width: 100%;
    height: calc(100% - 20px);
    margin-bottom: 20px;
    overflow-y: scroll;
}

#listing-billboard .listing-section .container .row {
    justify-content: start;
    align-items: flex-start;
    height: 100%;
    flex-wrap: unset; 
}

#listing-billboard .listing-section .container .row .listing-content {
    display: flex;
    width: 100%;
    padding-left: 20px;
    height: 100%;
}

@media (max-width: 992px) {
    #listing-billboard .listing-section {
        height: auto;
        min-height: auto;
    }

    #listing-billboard .listing-section .row {
        flex-wrap: wrap;
    }

    #listing-billboard .listing-section .container {
        padding: 20px 15px;
    }

    #listing-billboard .listing-section .sideBar {
        max-width: 100%;
        margin-bottom: 30px;
        width: 100%;
        height: auto;
    }

    #listing-billboard .listing-section .container {
        height: auto;
    }

    #listing-billboard .listing-section .container .row {
        height: auto;
        max-height: unset;
    }

    #listing-billboard .listing-section .container .row .listing-content {
        padding-left: 0;
    }
}

@media (max-width: 575px) {
    #listing-billboard .listing-section .sideBar {
        padding-right: 0;
        max-width: 100%;
        width: 100%;
    }
}