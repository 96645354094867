.dashboard-content-wrapper-info .dashboard-title-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}
.dashboard-content-wrapper .dashboard-title h4 {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    margin: 0;
    color: #363636 ;
    margin-left: 10px;
}
.dashboard-info-block-info p {
    /* font-family: 'Poppins'; */
    font-style: normal;
    font-weight: 400;
    font-size: 12.5px;
    line-height: 26px;
    color: #4F4F4F;
    margin: 0;
}
.dashboard-content-wrapper-info {
    background: #FFFFFF;
    border: 1px solid #db7f58;
    border-radius: 8px;
    padding: 18px;
}
.dashboard-content-wrapper-main-info {
    padding: 24px 0 
    /* padding: 10px 0 !important; */
}

.dashboard-content-wrapper-info-noborder {
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    padding: 18px;
}
