.l_pricing {
    padding: 40px 40px;
    font-family: 'Axiform';
    background-color: #FFF6ED ; 
    
    text-align: center;
    min-height: 100vh; 
    letter-spacing: 0.05rem;
    color: #363636 ;
    border-radius: 16px ; 
}

.headline {
    /* font-family: Inter; */
    font-size: 45px;
    font-weight: 800;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: center;
    /* color: #111827 ;  */

}

.enterpriseText {

    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #363636 ;
    /* color: #6B7280; */

}



.heading {
    color: #666666;
    font-size: 16px;
    margin-top: 1em;
}

.startBtnArea {
    /* margin: 3em auto 1em; */
    margin: 1.5em auto 1em;
}

.startBtn {
    color: #fff;
    background: #f06022;
    border-radius: 5px;
    transition: 0.3s;
    padding: 15px 30px;
    font-size: 16px;
    font-family: Axiform-bold;
    /* margin-bottom: 30px; */
    margin-bottom: 10px;
    cursor: pointer;
    border: none;
    /* letter-spacing: 0.05rem; */
    letter-spacing: 1.2px;
}
.startBtn:hover {
    transform: translateY(-3px);
    box-shadow: 0px 5px 10px #8b310b;
}

.bestvalue {
    padding: 15px 70px;
    /* background: #232a3c; */
    font-family: Axiform-bold;
    background: #363636;
    color: #fff;
    border-radius: 5px 5px 0 0;
    font-size: 15px;
}

.planslist {
    display: flex;
    /* max-width: 1000px; */
    /* max-width: 50vw; */
    margin: auto;
    justify-content: center;
}

.l_Plancard {

    /*
    min-width: 320px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-radius: 16px;
    text-align: left;
    cursor: pointer;
    */ 
    
    padding: 2em 2em 0.01em;
    /* padding: 0px; */
    min-width: 286px;
    min-height: 645px;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 16px;

}


.lp_planhead {
    display: flex;
    justify-content: space-between;
}

.lp_title {

    /* font-style: normal; */
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */
    /* gray/900 */
    color: #111827;
    margin-bottom: 32px;
}

.lp_cta {
    padding: 9px 17px;
    width: 236px;
    height: 38px;
    background: #E06937;
    border-radius: 25px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-bottom: 32px;
}

.lp_save {
    margin-top: -10px;
    font-size: 0.8em;
    color: #f06022;
    line-height: 1.8;
}

.lp_pricerow {
    display: flex;
}

.pricevalue {
    /* font-size: 3.5rem; */
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 40px;
    /* identical to box height, or 111% */
    color: #000958;
}

.pricevaluesubtext {
        /* font-size: 1.2em; */
        font-size: 16px;
        line-height: 4.5;
        margin-left: 5px;
        color: #6B7280;
}

/*
.lp_pricerow h3:first-child {

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #6B7280;
    
}
*/

.lp_pricerow h3:last-child {
    /* font-size: 1.2em; */
    font-size: 16px;
    line-height: 4.5;
    margin-left: 5px;
    color: #6B7280;
    
}


.lp_yearly {
    color: #ccc;
    font-size: 1em;
    margin-top: -20px;
    margin-bottom: 25px;
}

.lp_details {

    align-self: stretch;
    text-align: left;
    
}

.lp_details p {


    /* font-family: Inter; */
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;


}

.lp_details b {
    /* color: #212529; */
    color: #363636;
}


.switchArea {
    margin: auto;
    /* margin-top: 4em; */
    margin-top: 1em;
    display: inline-flex;
}
.switchArea_modal {
    margin: auto;
    /* margin-top: 4em; */
    margin-top: 1em;
    display: inline-flex;
}

.monthlyBill {
    font-size: 14px;
    color: #aaa;
    line-height: 1.8;
    margin-left: 15px;
}

.annualArea {
    display: grid;
    font-size: 14px;
    color: #f06022;
    margin-top: -7px;
    margin-right: 15px;
}

.yearlyBill {
    color: grey;
}

@media only screen and (max-width: 1050px) {
    .l_Plancard {
        min-width: 260px;
    }
}

@media only screen and (max-width: 870px) {
    .l_Plancard {
        min-width: 80vw;
        margin-bottom: 1em;
    }

    .planslist {
        display: grid;
        justify-content: center;
    }
}
