.buttonContainer {
    margin-top: 65vh;
    /* height: auto; */
    /* height: 90;  */
    padding: 0.5rem;
    /* border: 1px solid #fff; */
    overflow: hidden;
    /* box-shadow: 0px 10px 20px #00000040; */
    box-shadow: 0px 10px 20px #A2A2A2;
    transition: 0.5s;
    border-radius: 8px;
    /* backborder: 1px red !important;ground-color: white; */
    background: #FFFFFF ;
    /* background: #FFFFFF 0% 0% no-repeat padding-box ; */
    opacity: 1 ;
}

/* .headerCountrySelection {
    margin-left: 10px;
    width: 33px;
    height: 33px;
    border: 1px solid #A2A2A2;
    border-radius: 50%;
    justify-content: center;
    padding: 0.35rem;
    background-color: #FFFFFF;
    font-weight: bold;
} */

.circle {
    width: 39px; 
    height: 39px;
    border-radius: 50%;
    display: flex; /* or inline-flex */
    align-items: center; 
    justify-content: center;
    /* border: 1px solid #949393; */
    /* border: 1px solid #363636 ; */
    /* background-color: #868585; */
    background-color: #A2A2A2 ;
    /* color: white; */
    color: #363636;
    font-weight: bolder;
    font: normal normal bold 14px/24px Mont;
  }

.radiusText {
    margin-left: -20px;
    /* background-color: #868585; */
    background-color: #A2A2A2;
    /* color: #787c82; */
    color: #363636;
    padding-top: 0.5rem ;
    padding-left: 0.7rem ;
    padding-right: 0.7rem ;
    text-align: center;
    /* font-weight: bold; */
    font: normal normal normal 14px/24px Mont;
    border-radius: 8px;
    letter-spacing: 0.05em;
    /* border: 1px solid #A2A2A2; */
}

.loginButton {
    background: #F75E02 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #F75E0240;
    width: 70%; 
    height: 3em;
    padding:5; 
    border-radius: 5px;
    border: none;
    opacity: 1;
    outline: focus !important;
}

.loginText {
    text-align: center;
    font: Bold 18px/30px Mont;
    letter-spacing: 1.4px;
    color: #FFFFFF;
    opacity: 1;
}
