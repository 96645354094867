

.copilotcard_wrap {
    z-index: 3;
    /* position: fixed;  */
    /* bottom: 30px; */
    left: 0;      
    right: 0;      
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    /* margin: 0 auto;  */
    margin-top: 15px ;
    justify-content: center;

}


/* .copilotcard_wrap {
    z-index: 3;
    position: fixed; 
    bottom: 45px;   
    display: flex;
    flex-direction: row;
    gap: 8px; 
    width: 100%; 
    margin: 0 auto; 
    justify-content: center;
} */


.copilotaddition_card {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    background: #E5682B !important;
    border: 1px solid #F0F0F7 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    padding: 10px !important;
    width: 20% !important;
    height: 35px;
    cursor: default;
}

.copilotone_card {
    padding: 10px;
    background: #FFFFFF;
    /* background: #C5D3D5; */
    border: 1px solid #F0F0F7;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    height: 35px;
    flex: 1; /* allows the card to expand in width */
    min-width: 150px; /* set a minimum width */
}

.copilotaddition_card img,
.copilotone_card > img {
    width: 12px;
    height: 12px;
}

.copilotaddition_card p,
.copilotone_card h6,
.copilotone_card p,
.copilotone_card small {
    margin: 0;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.02em;
    
}

.copilotaddition_card p {
    margin-right: 6px;
    color: #fff;
    text-align: left;    
}

.copilotone_card h6 {
    color: #333333;
    font-weight: bold;
}

.copilotone_card small {
    color: #828282;
}

.copilotone_card p {
    color: #E5682B;
    font-weight: 600;
    /* margin-top: 4px; */
}

.copilotone_card > div {
    margin-left: 10px ;
    display: flex;
    flex-direction: column;
    text-align: left;
}




/* Responsive styling for larger screens can be added as needed */
