.logoDimensions {
  z-index: 169 !important;
  margin-top: -20px;
  /* margin-right: 12px;  */
  /* width: 110px;  */
  width: 85px; 
  /* height:33px;  */
  /* right:0 ;  */
  opacity: 1; 
  cursor: default ;
  position: absolute;
}

.icons {
  /* height: 6rem; */
  height: 6rem;
}

.vectors {
  height: 12rem; 
  
}

.circleTopMargin {
  margin-top: -2.5rem ; 
}

.texts {
      font: Normal 14px/20px Axiform;
      color: #363636;
      letter-spacing: 1px;
      padding: 10px;
}

.numbers {
  font-size: 20px;
  line-height: 2rem;
  color: #FD2D2C ; 
}

.textsDesc {
  font: Normal 14px/25px Axiform;
  color: #363636;
  letter-spacing: 1px;
  padding: 10px;
}

.descNum {
  font-size: 45px;
  line-height: 2rem;
  color: #F16022 ; 
  /* color: #363636; */
}

.circle {
  width: 7rem;
  height: 7rem;
  line-height: 6rem;
  border-radius: 50%;
  font-size: 38px;
  color: #F16022; 
  text-align: center;
  border: 8px solid #A4C3F9;
  margin-top: -2.5rem;
}

.btnPrimary {
  color: #fff;
  background: #f06022;
  border-radius: 5px;
  transition: 0.3s;
  padding: 15px 30px;
  font-size: 16px;
  font-family: Axiform-bold;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  letter-spacing: 1.2px;
}

.btnPrimary:hover {
  transform: translateY(-3px);
  box-shadow: 0px 5px 10px #8b310b;
}

@media screen and (min-width: 1800px) {
  .vectors {
    height: 18rem; 
  }
  .circle {
    margin-top: -7rem;
  }
}