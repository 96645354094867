.map-wrapper .map-title {
    padding: 34px 0;
    font-size: 16px;
    line-height: 18px;
    color: #363636;
    font-weight: 500;
}
.map-wrapper .map-contentblock{
    display: flex;
    justify-content: space-between;
}
.map-wrapper .map-content {
    flex: 0 0 27%;
    padding-right: 15px;
    margin-bottom: 20px;
}
.map-wrapper .map-block {
    flex: 0 0 72%;
    margin-bottom: 20px;
}
.map-wrapper .map-detail {
    /* width: 320px; */
    
    min-height: 359px;
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
}
.map-wrapper .map-details{
    padding: 30px;
}
.map-wrapper .map-detail.gender {
    min-height: 375px;
}
.map-wrapper .map-detail.residents {
    margin-bottom: 16px;
}
.map-wrapper .resident-title {
    font: Normal 14px/28px Axiform;
    color: #363636;
    /* letter-spacing: 1px; */
}
.map-wrapper .resident-count {
    color: #F06022;
    font-size: 32px;
    line-height: 35px;
    font-weight: 500;
}
.map-wrapper .gender-chart {
    text-align: center;
    padding: 48px 0 35px;
}
.map-wrapper .gender-block,
.female-gender-block,.male-gender-block{
    display: flex;
    align-items: center;
    justify-content: center;
}
.female-gender-block{
    padding-right: 12px;
}
.female-gender-block .female-color,
.male-gender-block .male-color{
    height: 9px;
    width: 9px;
    border-radius: 100%;
    margin-right: 5px;
}

.female-title, .male-title {
    /*
    font-style: Axiform ;
    line-height: 16px;
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    font-style: Axiform ;
    */
    color: #363636 ; 
    font : Normal 14px/28px Axiform
}
.map-detail.gender .gender-title {
    /* font-size: 16px;
    line-height: 20px;
    font-weight: 500; */

    font : Normal 14px/28px Axiform ; 
    color: #363636 ; 
}
.map-content .recharts-wrapper{
    margin: auto;
    padding: 30px 0 35px;
}
.mapouter {
    position:relative;
    text-align:right;
    border-radius: 16px;
}
.gmap_canvas {
    overflow:hidden;
    background:none!important;
    height: 749px;
    width: 864px;
    border-radius: 16px;
    border: 1px solid #E0E0E0;
}

@media (max-width:1240px){
    .map-wrapper .map-block {
        flex: 0 0 65%;
    }
    .map-wrapper .map-contentblock{
        flex-wrap: wrap;
    }
    .gmap_canvas {
        width: 750px;
    }
}
@media (max-width:1199px){
    .gmap_canvas {
        width: 650px;
        height: 660px;
    }
    .map-wrapper .map-detail {
        min-height: 260px;
    }
}
@media (max-width:1024px){
    .gmap_canvas {
        width: 100%;
    }
    .map-wrapper .map-block {
        flex: 0 0 58%;
    }
    .map-wrapper .map-details {
        padding: 20px;
    }
}
@media (max-width:830px){
    .map-wrapper .map-block,.map-wrapper .map-content {
        flex: 0 0 100%;
    }
    .map-wrapper .map-detail{
        width: 100%;
    }
}

