.state-content{
    padding: 24px 0;
}
.state-content .state-title {
    font-family: 'Axiform';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}
.state-content .state-subtitle{
    font-family: 'Axiform';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}
.state-wrapper .state-map{
    background-color: #F1F1F1;
    border-radius: 16px;
    text-align: center;
}
.state-city {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.state-city div {
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    margin: 16px 10px 0 0;
    text-align: center;
    
    padding: 12px 0;
    width: 200px;
    font-family: 'Axiform';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    cursor: default ;
}

.state-city div:hover{
    background-color: #bccae8;
}

.selected-value-bg-city-state {
    background-color: #9ab3ea !important;
}

.rsm-geography:focus,.rsm-geography:active,.rsm-geography:hover {
    outline: none;
    fill: #9ab3ea;
}
.rsm-geographies text {
    font-size: 11px;
    font-weight: 400;
    cursor: default
}
@media(max-width:991px){
    .state-map svg{
        width: 730px;
        height: 560px;
    }
}
@media(max-width:767px){
    .state-map svg{
        width: 560px;
        height: 400px;
    }
}
@media(max-width:577px){
    .state-map svg{
        width: 100%;
        height: 300px;
    }
}
@media(max-width:510px){
    .state-city div{
        width: 200px;
        padding: 12px 5px;
    }
}
@media(max-width:473px){
    .state-city div{
        width: 100%;
        margin: 5px auto;
    }
    .state-city {
        margin-top: 15px;
    }
}