
/*
.logoDimensions {
    margin-left: 5px; 
    width: 140px; 
    height: 32px;
    opacity: 1; 
}
*/

.logoDimensions {
    margin-left: 5px; 
    width: 184.7px; 
    height: 40px;
    opacity: 1; 
}

.whited {
    background-color: #FFFFFF;
}

.dropdown {
    /* display:blocked;  */
    margin-right: 4.5rem; 
    width: 3rem;
    height: 3rem;
    border-radius: 15px;
    opacity: 1;
    /* color: #363636; */
    opacity: 1;
    border: 1px; 
    border-radius: 15px;
    opacity: 1;
    /* border-style: solid; */
    /* border-color: #363636 ;   */
    background: #F75E02;


}

.dropdown a {
    /* color: black ; */
    color: #363636; 
    padding: 0.7rem;
    font-size: 1.2rem;
    font-weight: bolder;
}

.dropdown a:hover {
    color: #363636; 
}

.dropdown div{
    font-size: 1rem;
    min-width:  5rem;
    margin-left: -1rem;
}
.dropdown div a{
    font-size: 0.8rem;
    padding: 0.25rem; 
    font-weight: normal;
    margin-right: 1.1rem;
    margin-left: 0.1rem; 
}

.centerNav {
    position:fixed;
    top: 0;    
    left: 0;
    transform: translate(calc(50vw - 50%));
    margin-top: 20px;
    z-index: 95;
}


.imgCenterNav{
    width: 160px; 
    height: auto ; 
    opacity: 1; 
    
}


.addition_card {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: end;
    border: 1px solid #F0F0F7;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 14px;

    background: #FFFFFF;

    justify-content: space-between;
    width: 100%;
}

.addition_card p {
    
    font-size: 0.8rem !important;
    margin: 0;
    margin-right: 5px;
    margin-left: 7px;
    /* color: #fff; */
}

.upgradePremium {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    gap: 24px;
    position: relative;
    /* width: 155px; */
    width: 100%;
    /* height: 48px; */
    background: linear-gradient(90deg, #FFC036 0%, #FFAC10 100%);
    border-radius: 16px;
    /* border: 2px solid #A20F1B; */
    
  }
  
  .premiumText {
  
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    color: #FFFFFF;

    font-size: 0.8rem !important;
    margin: 0;
    margin-right: 5px;
    /* margin-left: 7px; */
  }